import { decorate, observable, action, runInAction } from 'mobx'
import agent from 'agent'
import _ from 'lodash'
import BaseStore from './BaseStore'
import ListEntityValue from './ListEntityValue.store'

class ListEntity extends BaseStore {
  id
  name
  values

  constructor(args) {
    super(args)
    this.setup(args)
  }

  setup({ id, name, values }) {
    this.id = id
    this.name = name
    this.values = []
    if (values) {
      values.forEach(synonym => {
        this.values.push(new ListEntityValue(synonym))
      })
    }
  }

  async save() {
    const data = {
      id: this.id,
      name: this.name,
    }
    await agent.ListEntity.update(data)
  }

  async removeValue(id) {
    _.remove(this.values, { id })
    await ListEntityValue.delete(id)
  }

  async addValue(name) {
    const data = {
      name,
      listEntity: this.id,
    }
    const value = await ListEntityValue.create(data)
    runInAction(() => {
      this.values.unshift(value)
    })
    await value.save()
  }

  static async delete(id) {
    await agent.ListEntity.delete(id)
  }
}

decorate(ListEntity, {
  id: observable,
  name: observable,
  values: observable,
  setup: action,
  save: action,
  removeValue: action,
})

export default ListEntity
