import { observable } from 'mobx'
import TextMessageStore from './TextMessageStore'
import VideoMessageStore from './VideoMessageStore'
import ImageMessageStore from './ImageMessageStore'
import LinkMessageStore from './LinkMessageStore'
import GeoLocationStore from './GeoLocationMessageStore'
import BaseStore from '../BaseStore'

class GenericMessageStore extends BaseStore {
  @observable id = ''
  @observable textMessage = null
  @observable videoMessage = null
  @observable imageMessage = null
  @observable linkMessage = null
  @observable geoLocationMessage = null

  constructor(args) {
    super()
    this.setup(args)
  }

  setup(args) {
    this.id = args.id
    if (args.textMessage)
      this.textMessage = new TextMessageStore(args.textMessage)
    else if (args.videoMessage)
      this.videoMessage = new VideoMessageStore(args.videoMessage)
    else if (args.imageMessage)
      this.imageMessage = new ImageMessageStore(args.imageMessage)
    else if (args.linkMessage)
      this.linkMessage = new LinkMessageStore(args.linkMessage)
    else if (args.geoLocationMessage)
      this.geoLocationMessage = new GeoLocationStore(args.geoLocationMessage)
  }
}

export default GenericMessageStore
