import EmailTemplate from 'agent/emailTemplate';
import FacebookLead from 'agent/facebookLead.agent';
import GoogleAnalyticsEvent from 'agent/googleAnalyticsEvent';
import GroupAppointment from 'agent/groupAppointment.agent';
import Mapper from 'agent/mapper';
import ProcessorTemplate from 'agent/processorTemplate';
import Wizard from 'agent/wizard';
import Action from './action';
import ActionWithOptions from './actionWithOptionsAction';
import Agents from './agents';
import AllAtsFieldMappings from './ats-mapping.agent';
import AtsFieldMappingAgent from './atsFieldMapping.agent';
import Block from './block.agent';
import CalendarAvailability from './calendar-availability.agent';
import Calendar from './calendar.agent';
import AtsBatchAction from './campaigns/atsBatchAction.agent';
import Automatch from './campaigns/automatch.agent';
import Broadcast from './campaigns/broadcast.agent';
import CampaignAnalyticsAgent from './campaigns/campaign-analytics.agent';
import CampaignConversationAgent from './campaigns/campaign-conversations.agent';
import Campaign from './campaigns/campaign.agent';
import FollowUp from './campaigns/followup.agent';
import Query from './campaigns/query.agent';
import WhatsApp from './campaigns/whatsApp';
import WhatsAppButtonAgent from './campaigns/whatsapp-button.agent';
import WhatsAppDefinitionAgent from './campaigns/whatsapp-definition.agent';
import WhatsAppParameterAgent from './campaigns/whatsapp-parameter.agent';
import WhatsAppTemplateAgent from './campaigns/whatsapp-template.agent';
import WhatsAppVariationAgent from './campaigns/whatsapp-variation.agent';
import Chatbot from './chatbot';
import CustomAnalytics from './customAnalytics';
import Employee from './employee.agent';
import Entity from './entity.agent';
import EventNotification from './eventNotification.agent';
import Flow from './flow';
import InboxLabel from './inboxLabel.agent';
import Intent from './intent';
import Keyword from './keyword';
import ListEntity from './listEntity.agent';
import ListEntitySynonym from './listEntitySynonym.agent';
import ListEntityValue from './listEntityValue.agent';
import GeoLocationMessage from './message/geoLocationMessage';
import ImageMessage from './message/imageMessage';
import LinkMessage from './message/linkMessage';
import MessageClass from './message/messageClass';
import TextMessage from './message/textMessage';
import VideoMessage from './message/videoMessage';
import NLP from './nlp.agent';
import Option from './option.agent';
import OptionLabel from './optionLabel';
import Processor from './processor';
import ScalarName from './scalarName';
import Sentence from './sentence';
import SmartInbox from './smart-inbox.agent';
import StandardAction from './standardAction';
import State from './state';
import StateGroup from './stateGroup';
import StateGroupTemplate from './stateGroupTemplate';
import Tag from './tag.agent';
import Translation from './translation.agent';
import User from './user';
import Validator from './validator';
import Variation from './variation';

const agent = {
  Action,
  ActionWithOptions,
  Agents,
  AllAtsFieldMappings,
  AtsBatchAction,
  AtsFieldMapping: AtsFieldMappingAgent,
  Automatch,
  Block,
  Broadcast,
  Calendar,
  CalendarAvailability,
  Campaign,
  CampaignAnalytics: CampaignAnalyticsAgent,
  CampaignConversations: CampaignConversationAgent,
  Chatbot,
  CustomAnalytics,
  EmailTemplate,
  Employee,
  Entity,
  EventNotification,
  FacebookLead,
  Flow,
  FollowUp,
  GeoLocationMessage,
  GoogleAnalyticsEvent,
  GroupAppointment,
  ImageMessage,
  InboxLabel,
  Intent,
  Keyword,
  LinkMessage,
  ListEntity,
  ListEntitySynonym,
  ListEntityValue,
  Mapper,
  MessageClass,
  NLP,
  Option,
  OptionLabel,
  Processor,
  ProcessorTemplate,
  ScalarName,
  Sentence,
  SmartInbox,
  StandardAction,
  State,
  StateGroup,
  StateGroupTemplate,
  Tag,
  TextMessage,
  Translation,
  User,
  Validator,
  Variation,
  VideoMessage,
  WhatsApp,
  Query,
  WhatsAppButton: WhatsAppButtonAgent,
  WhatsAppDefinition: WhatsAppDefinitionAgent,
  WhatsAppParameter: WhatsAppParameterAgent,
  WhatsAppTemplate: WhatsAppTemplateAgent,
  WhatsAppVariation: WhatsAppVariationAgent,
  Wizard,
};

export default agent;
