import _ from 'lodash';
import requests from './requests';

const listQuery = `
{
  allFacebookPages {
    pageName
    pageId
    isActive
  }
}
`;

const updateMutation = `
  mutation UpdateFacebookPages($shortLivedUserAccessToken: String!) {
    updateFacebookPages(shortLivedUserAccessToken: $shortLivedUserAccessToken) {
      ok
      errorMessage
    }
  }
`;

const updateActivityMutation = `
  mutation updateFacebookPage($lead: JSONString!) {
    updateFacebookPage(facebookPage: $lead) {
      ok
      errorMessage
    }
  }
`;

const deleteMutation = `
  mutation DeleteFacebookPage($lead: JSONString!) {
    deleteFacebookPage(facebookPage: $lead) {
      ok
      errorMessage
    }
  }
`;

const FacebookLead = {
  data: async () => {
    const res = await requests.gql.chatserver.query(listQuery);
    return {
      facebookLeads: _.get(res, 'data.allFacebookPages'),
    };
  },
  update: async (shortLivedUserAccessToken) => {
    const res = await requests.gql.chatserver.mutate(updateMutation, {
      shortLivedUserAccessToken,
    });
    return _.get(res, 'data.updateFacebookPages');
  },
  updateActivity: async (lead) => {
    const cleanedLead = {
      page_name: lead.pageName,
      page_id: lead.pageId,
      is_active: lead.isActive,
    };
    const res = await requests.gql.chatserver.mutate(updateActivityMutation, {
      lead: JSON.stringify(cleanedLead),
    });
    return _.get(res, 'data.updateFacebookPage');
  },
  delete: async (lead) => {
    const cleanedLead = {
      page_name: lead.pageName,
      page_id: lead.pageId,
      is_active: lead.isActive,
    };
    const res = await requests.gql.chatserver.mutate(deleteMutation, {
      lead: JSON.stringify(cleanedLead),
    });
    return _.get(res, 'data.deleteFacebookPage');
  },
};

export default FacebookLead;
