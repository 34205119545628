import requests from './requests';

const deleteActionMutation = `
  mutation DeleteStandardAction($actionId: ID!) {
    actionDelete(id: $actionId) {
      ok
    }
  }
`;

const replayActionMutation = `
  mutation ReplayAction(
    $clientId: ID!,
    $clientStateId: ID!,
    $processorId: ID!,
    $mapping: JSONString!,
    $userData: JSONString!,
    $campaignId: ID,
    $campaignConversationId: ID,
  ) {
    replayMicroserviceCall(
      clientId: $clientId,
      clientStateId: $clientStateId,
      processorId: $processorId,
      mapping: $mapping,
      userData: $userData,
      campaignId: $campaignId,
      campaignConversationId: $campaignConversationId
    ) {
      ok
      errorMessage
      result
    }
  }
`;

const Action = {
  get: (id, chatbot, startDate, endDate, pageNum, pageSize, clientId = '') =>
    requests.get('/api/v1/actions/', {
      processor_id: id,
      project_id: chatbot,
      start_date: startDate,
      end_date: endDate,
      page_num: pageNum,
      page_size: pageSize,
      client_id: clientId,
    }),
  delete: (id) =>
    requests.gql.chatserver.mutate(deleteActionMutation, { actionId: id }),
  replayAction: (
    clientId,
    clientStateId,
    processorId,
    mapping,
    userData,
    campaignId,
    campaignConversationId
  ) =>
    requests.gql.chatserver.mutate(replayActionMutation, {
      clientId,
      clientStateId,
      processorId,
      mapping: JSON.stringify(mapping),
      userData: JSON.stringify(userData),
      campaignId,
      campaignConversationId,
    }),
};

export default Action;
