import { action, decorate, observable, runInAction } from 'mobx';
import AtsOptionStore from './AtsOptionStore';
import BaseStore from './BaseStore';
import OptionStore from './OptionStore';

class AtsMappingOption extends BaseStore {
  id;
  option = [];
  atsOptions = [];
  value;
  doStoreValue;

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    runInAction(() => {
      if (args) {
        this.id = args?.id;
        this.option = new OptionStore(args?.option);
        this.atsOptions = args?.atsOptions?.map(
          (atsOption) => new AtsOptionStore(atsOption)
        );
        this.value = args?.value;
        this.doStoreValue = args?.doStoreValue;
      }
    });
  }
}

decorate(AtsMappingOption, {
  id: observable,
  option: observable,
  atsOptions: observable,
  value: observable,
  doStoreValue: observable,
  setup: action,
});

export default AtsMappingOption;
