const defaultLink = 'https://docs.joboti.com';
const defaultButtonText = 'components/navigation/NavBar/documentation';

export const documentationData = {
  BUILDER: {
    buttonText: 'components/FlowBuilderDocumentationButtonText',
    storyLaneLinks: {
      'en-US': 'https://app.storylane.io/demo/vftd0e3i8h51',
      'nl-NL': 'https://app.storylane.io/demo/wlghlmjb5bvo',
    },
  },
  ANALYTICS: {
    docsLink: 'https://docs.joboti.com/resultaten-1/smart-inbox-analytics',
    buttonText: 'components/SmartInboxAnalyticsDocumentationButtonText',
  },
  CALENDAR: {
    docsLink: 'https://docs.joboti.com/Automation/automations-overzicht',
    buttonText: 'components/navigation/NavBar/documentation',
  },
  CAMPAIGNS: {
    docsLink: 'https://docs.joboti.com/Automation/automations-overzicht',
    buttonText: 'components/AutomationDocumentationsButtonText',
  },
  WHATSAPP: {
    docsLink: defaultLink,
    buttonText: defaultButtonText,
  },
  NLP: {
    docsLink: defaultLink,
    buttonText: defaultButtonText,
  },
  DASHBOARD: {
    docsLink: defaultLink,
    buttonText: defaultButtonText,
  },
  CHATBOT_SETTINGS: {
    docsLink: 'https://docs.joboti.com/smart-1/agenda-koppelen',
    buttonText: 'components/ChatbotSettingsDocumentationButtonText',
  },
  WHATSAPP_TEMPLATE: {
    docsLink: 'https://docs.joboti.com/resultaten/whatsapp-templates-aanmaken',
    buttonText: 'components/TemplateDocumentationsButtonText',
  },
  USERS_MANAGEMENT: {
    docsLink:
      'https://docs.joboti.com/smart-1/user-management/wachtwoord-wijzigen',
    buttonText: 'components/UserManagementDocumentationButtonText',
  },
  VALIDATOR: {
    docsLink: defaultLink,
    buttonText: defaultButtonText,
  },
};
