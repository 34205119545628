import _ from 'lodash';
import requests from 'agent/requests';
import {
  templateMutationFragment,
  templateProviderCreateMutationFragment,
  templateProviderDeleteMutationFragment,
} from './fragments';

const listQuery = `
query WhatsAppTemplates {
  cmTemplates
}
`;

const createMutation = `
mutation CreateWhatsAppTemplate($newWhatsAppTemplate: WhatsAppTemplateCreateGenericType!) {
  createWhatsAppTemplate(newWhatsapptemplate: $newWhatsAppTemplate) {
    ...templateMutationFragment
  }
}
${templateMutationFragment}
`;

const updateMutation = `
mutation UpdateWhatsAppTemplate($newWhatsAppTemplate: WhatsAppTemplateUpdateGenericType!) {
  updateWhatsAppTemplate(newWhatsapptemplate: $newWhatsAppTemplate) {
    ...templateMutationFragment
  }
}
${templateMutationFragment}
`;

const deleteMutation = `
mutation DeleteWhatsAppTemplate($id: ID!) {
  deleteWhatsAppTemplate(id: $id) {
    ...templateMutationFragment
  }
}
${templateMutationFragment}
`;

const deleteTemplatesMutation = (ids) => {
  const interpolatedTemplateFields = ids.map((id) => {
    return `
      templateDelete${id}: deleteWhatsAppTemplate(id: ${id}) {
        ...templateMutationFragment
      }
    `;
  });

  return `
    mutation DeleteWhatsAppTemplatesByIds {
      ${interpolatedTemplateFields}
    }
    ${templateMutationFragment}
  `;
};

const providerCreateMutation = `
mutation CreateProviderTemplate($id: ID!) {
  createProviderTemplate(templateId: $id) {
    ...templateProviderCreateMutationFragment
  }
}
${templateProviderCreateMutationFragment}
`;

const providerDeleteMutation = `
mutation DeleteProviderTemplate($id: ID!) {
  deleteProviderTemplate(templateId: $id) {
    ...templateProviderDeleteMutationFragment
  }
}
${templateProviderDeleteMutationFragment}
`;

const WhatsAppTemplateAgent = {
  list: async () => {
    const res = await requests.gql.chatserver.query(listQuery);
    return _.get(res, 'data.cmTemplates');
  },
  create: async (newWhatsAppTemplate) => {
    const res = await requests.gql.chatserver.mutate(createMutation, {
      newWhatsAppTemplate,
    });
    return _.get(res, 'data.createWhatsAppTemplate');
  },
  update: async (newWhatsAppTemplate) => {
    const res = await requests.gql.chatserver.mutate(updateMutation, {
      newWhatsAppTemplate,
    });
    return _.get(res, 'data.updateWhatsAppTemplate');
  },
  delete: async (id) => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, {
      id,
    });
    return _.get(res, 'data.deleteWhatsAppTemplate');
  },
  deleteTemplates: async (ids) => {
    const res = await requests.gql.chatserver.mutate(
      deleteTemplatesMutation(ids)
    );
    return _.get(res, 'data.deleteWhatsAppTemplate');
  },
  providerCreate: async (id) => {
    const res = await requests.gql.chatserver.mutate(providerCreateMutation, {
      id,
    });
    return _.get(res, 'data.createProviderTemplate');
  },
  providerDelete: async (id) => {
    const res = await requests.gql.chatserver.mutate(providerDeleteMutation, {
      id,
    });
    return _.get(res, 'data.deleteProviderTemplate');
  },
};

export default WhatsAppTemplateAgent;
