import { observable, action, decorate } from 'mobx'
import _ from 'lodash'

import VariationStore from './VariationStore'
import agent from '../../agent'
import BaseStore from '../BaseStore'

class MessageStore extends BaseStore {
  id = ''
  variations = []

  constructor(args) {
    super()
    this.setup(args)
  }

  setup(args) {
    this.id = args.id
    this.variations = []
    args.variations.forEach(variation => {
      this.variations.push(new VariationStore(variation))
    })
  }

  addVariationStore(variationObject, tag) {
    const variationStore = new VariationStore(variationObject)
    this.variations.push(variationStore)
    if (tag) {
      variationStore.addTag(tag)
    }
  }

  async addTextVariation(tag) {
    const data = { messageClassId: this.id }
    const res = await agent.MessageClass.addTextVariation(data)
    const ok = _.get(res, 'data.addTextMessageMutation.ok')
    if (!ok) {
      throw Error("Can't add variation")
    }
    const variationObject = _.get(res, 'data.addTextMessageMutation.variation')
    this.addVariationStore(variationObject, tag)
  }

  async addVideoVariation(tag) {
    const data = { messageClassId: this.id }
    const res = await agent.MessageClass.addVideoVariation(data)
    const ok = _.get(res, 'data.addVideoMessageMutation.ok')
    if (!ok) {
      throw Error("Can't add variation")
    }
    const variationObject = _.get(res, 'data.addVideoMessageMutation.variation')
    this.addVariationStore(variationObject, tag)
  }

  async addImageVariation(tag) {
    const data = { messageClassId: this.id }
    const res = await agent.MessageClass.addImageVariation(data)
    const ok = _.get(res, 'data.addImageMessageMutation.ok')
    if (!ok) {
      throw Error("Can't add variation")
    }
    const variationObject = _.get(res, 'data.addImageMessageMutation.variation')
    this.addVariationStore(variationObject, tag)
  }

  async addDocumentVariation(tag) {
    const data = { messageClassId: this.id }
    const res = await agent.MessageClass.addDocumentVariation(data)
    const ok = _.get(res, 'data.addDocumentMessageMutation.ok')
    if (!ok) {
      throw Error("Can't add variation")
    }
    const variationObject = _.get(
      res,
      'data.addDocumentMessageMutation.variation',
    )
    this.addVariationStore(variationObject, tag)
  }

  async addLinkVariation(tag, isDocument = false) {
    const data = { messageClassId: this.id, isDocument }
    const res = await agent.MessageClass.addLinkVariation(data)
    const ok = _.get(res, 'data.addLinkMessageMutation.ok')
    if (!ok) {
      throw Error("Can't add variation")
    }
    const variationObject = _.get(res, 'data.addLinkMessageMutation.variation')
    this.addVariationStore(variationObject, tag)
  }

  async addGeoLocationVariation(tag) {
    const data = { messageClassId: this.id }
    const res = await agent.MessageClass.addGeoLocationVariation(data)
    const ok = _.get(res, 'data.addGeoLocationMutation.ok')
    if (!ok) {
      throw Error("Can't add variation")
    }
    const variationObject = _.get(res, 'data.addGeoLocationMutation.variation')
    this.addVariationStore(variationObject, tag)
  }

  async delete(variationId) {
    _.remove(this.variations, variation => variation.id === variationId)
    const res = await agent.Variation.delete(variationId)
    const ok = _.get(res, 'data.deleteVariationMutation.ok')
    if (!ok) {
      throw Error('Could not delete variation')
    }
  }
}

decorate(MessageStore, {
  id: observable,
  variations: observable,
  setup: action,
  addVariationStore: action,
  addTextVariation: action,
  addVideoVariation: action,
  addImageVariation: action,
  addDocumentVariation: action,
  addLinkVariation: action,
  addGeoLocationVariation: action,
  delete: action,
})

export default MessageStore
