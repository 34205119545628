export const EMPTY_STRING = /^\s*$/;
export const EMAIL_REGEX = /\S+@\S+\.\S+/;
// Extended Email Regex following the RFC guidelines
export const EMAIL_EXTENDED_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const DJANGO_VARIABLE_REGEX = /^{{[a-zA-Z_.]*}}$/; // use when no space should be before or after the variable
export const DJANGO_TAG_REGEX = /\{%\s*[\w\s_]+\s*%\}/g;
export const HTML_REGEX = /<[^>]*>?/gm;
export const VARIABLE_REGEX = /{{\s*[\w.]+\s*}}/;
export const NEWLINE_REGEX = /\r\n|\r|\n/;
export const SPECIAL_CHARACTERS_REGEX =
  /[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\\?\-_]/g;
export const MULTIPLE_WHITESPACE_REGEX = /\s\s+/g;
export const PHONE_NUMBER_REGEX =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const URL_REGEX = /^(ftp|http|https):\/\/[^ "]+$/;
export const IS_NUMERIC_REGEX = /^\d+$/;
export const PARAMETER_NUMBER_BETWEEN_BRACES = /{{(\d+)}}/;
// This pattern groups the whole value. We do this because this way it includes the value when splitting the text
export const WHATSAPP_TEMPLATE_PARAM_PATTERN = /({{(?:[^}]?)*}})/g;
// This pattern includes a group on the value between the curlies {{ *here* }}. This way we can easily extract it
export const WHATSAPP_TEMPLATE_PARAM_PATTERN_NAME_GROUP = new RegExp(
  '{{ ?([^}]+) ?}}'
);
export const WHATSAPP_TEMPLATE_PARAM_PATTERN_NAME_GROUP_GLOBAL =
  /{{ ?([^}]+) ?}}/g;

// Minimum length of 8, with at least a symbol, upper and lower case letters and a number.
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const PASSWORD_SYMBOLS_REGEX = /[!@#$%^&*]+/;
export const PASSWORD_NUMBER_REGEX = /\d/;

export const URL_PATTERN =
  /(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;

export const INTRODUCTION_TEXT_TRANSLATIONS_INPUT = /.*\S.*/;

// check if the text input field has [[{}]] this pattern of tags and use it to switch it back to {{}}
export const TAGS_INPUT_PARAMETERS =
  /\[\[{"value": ?"?([^}]+),"index": ?(\d+)}]]/g;

export const TAGS_CURLY_BRACES_PARAMETERS = /({{)([0-9])(}})/g;

export const COMMAS_OF_TAGS =
  /(,)(\[\[{"value": ?"?([^}]+),"index" ?:(\d+)}]])(,)/g;

export const ENGLISH_CHARACTERS = /^[a-zA-Z0-9.?!:;\- ]+$/;

export const IS_NOT_EMPTY_REGEX = /[\S\s]+[\S]+/;
