import _ from 'lodash';
import requests from './requests';

const listQuery = `
query ListCalendarAvailabilities($userId: ID!) {
  allCategories
  allInterviewTypes
  allAppointmentTypes {
      id
      type
      preTime
      postTime
      planRange
      workingDaysOnly
      offsetDays
      slotDuration
      isDefault
    }
  calendarAvailabilities(userId: $userId) {
    id
    calendarEmail
    contactName
    interviewType
    categories
    timeZone
    maxDailyAppointments
    appointmentType {
      id
      type
      preTime
      postTime
      planRange
      workingDaysOnly
      offsetDays
      slotDuration
      isDefault
    }
    blocks {
      id
      endTime
      startTime
      day
    }
  }
}
`;

const createMutation = `
mutation CreateCalendarAvailability($data: CalendarAvailabilityCreateGenericType!) {
  createCalendarAvailability(newCalendaravailability: $data) {
    calendaravailability {
      id
      calendarEmail
      contactName
      interviewType
      appointmentType {
        id
        type
        preTime
        postTime
        planRange
        workingDaysOnly
        offsetDays
        slotDuration
        isDefault
      }
      categories
      timeZone
      maxDailyAppointments
      blocks: blockSet {
        id
        endTime
        startTime
        day
      }
    }
  }
}
`;

const updateMutation = `
mutation UpdateCalendarAvailability($data: CalendarAvailabilityUpdateGenericType!) {
  updateCalendarAvailability(newCalendaravailability: $data) {
    calendaravailability {
      id
      calendarEmail
      contactName
      interviewType
      appointmentType {
        id
        type
        preTime
        postTime
        planRange
        workingDaysOnly
        offsetDays
        slotDuration
        isDefault
      }
      categories
      timeZone
      maxDailyAppointments
      blocks: blockSet {
        id
        endTime
        startTime
        day
      }
    }
  }
}

`;

const deleteMutation = `
mutation DeleteCalendarAvailability($id: ID!) {
  deleteCalendarAvailability(id: $id) {
    ok
  }
}
`;

const CalendarAvailability = {
  data: async (userId) => {
    const res = await requests.gql.chatserver.query(listQuery, { userId });
    return {
      calendarAvailabilities: _.get(res, 'data.calendarAvailabilities'),
      allCategories: _.get(res, 'data.allCategories'),
      allInterviewTypes: _.get(res, 'data.allInterviewTypes'),
      allAppointmentTypes: _.get(res, 'data.allAppointmentTypes'),
    };
  },
  create: async (data) => {
    const res = await requests.gql.chatserver.mutate(createMutation, { data });
    return _.get(res, 'data.createCalendarAvailability.calendaravailability');
  },
  update: async (data) => {
    const res = await requests.gql.chatserver.mutate(updateMutation, { data });
    return _.get(res, 'data.updateCalendarAvailability.calendaravailability');
  },
  delete: async (id) => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, { id });
    const ok = _.get(res, 'data.deleteCalendarAvailability.ok');
    if (!ok) throw new Error('can not delete calendar');
  },
};

export default CalendarAvailability;
