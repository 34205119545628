export const baseAtsEntityType = /* GraphQL */ `
  fragment baseAtsEntityType on AtsEntityTypeNode {
    id
    name
    systemName
    allHumanReadableNames {
      ...HumanReadableNamesFragment
    }
    humanReadableNames {
      ...HumanReadableNamesFragment
    }
  }
`;

export const automatchFragment = /* GraphQL */ `
  fragment automatchFragment on AutomatchNode {
    id
    connectorUuid
    matchPageLimit
    created
    modified
    useEngageConnector
    version
    onReceiveProcessors {
      ...processorFragment
    }
    searchQuery {
      id
      baseAtsEntityType {
        ...baseAtsEntityType
      }
      queryStatements {
        ...queryStatementFragment
      }
      targetHandler
    }
    matchQuery {
      id
      baseAtsEntityType {
        ...baseAtsEntityType
      }
      queryStatements {
        ...queryStatementFragment
      }
      targetHandler
    }
    facetsQuery {
      id
      baseAtsEntityType {
        ...baseAtsEntityType
      }
      queryStatements {
        ...queryStatementFragment
      }
      targetHandler
    }
  }
  ${baseAtsEntityType}
`;
