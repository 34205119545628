import requests from 'agent/requests';
import { humanReadableNameOnTypeTranslation } from './fragments/index';
import { queryStatementFragment } from './fragments/queryBuilderFragments';

const createQueryStatementMutation = /* GraphQL */ `
  mutation CreateQueryStatement(
    $queryStatement: QueryStatementCreateGenericType!
  ) {
    createQuerystatement(newQuerystatement: $queryStatement) {
      ok
      querystatement {
        ...queryStatementFragment
      }
    }
  }
  ${queryStatementFragment}
  ${humanReadableNameOnTypeTranslation}
`;

const deleteQueryStatementMutation = /* GraphQL */ `
  mutation deleteQueryStatement($id: ID!) {
    deleteQuerystatement(id: $id) {
      ok
      errors {
        messages
      }
    }
  }
`;

const updateQueryStatementMutation = /* GraphQL */ `
  mutation updateQueryStatement(
    $newQuerystatement: QueryStatementUpdateGenericType!
  ) {
    updateQuerystatement(newQuerystatement: $newQuerystatement) {
      ok
      errors {
        messages
      }
      querystatement {
        id
      }
    }
  }
`;

const updateQuery = /* GraphQL */ `
  mutation updateQueryStatement($query: QueryUpdateGenericType!) {
    updateQuery(newQuery: $query) {
      ok
      errors {
        messages
      }
    }
  }
`;

const testSearchQuery = /* GraphQL */ `
  mutation TestSearch($campaignId: String!, $maxNumberMatches: Int!) {
    testSearch(campaignId: $campaignId, maxNumberMatches: $maxNumberMatches) {
      success
      message
      documents {
        id
        fields
      }
    }
  }
`;

const testMatchQuery = /* GraphQL */ `
  mutation TestMatch(
    $campaignId: String!
    $documentIds: [String]!
    $maxNumberMatches: Int!
  ) {
    testMatch(
      campaignId: $campaignId
      documentIds: $documentIds
      maxNumberMatches: $maxNumberMatches
    ) {
      success
      message
      documents {
        id
        vacancyId
        fields
      }
    }
  }
`;

const Query = {
  createQueryStatement: async (queryStatement) => {
    const { data } = await requests.gql.chatserver.mutate(
      createQueryStatementMutation,
      {
        queryStatement,
      }
    );
    return data.createQuerystatement;
  },
  deleteQueryStatement: async (id) => {
    const { data } = await requests.gql.chatserver.mutate(
      deleteQueryStatementMutation,
      { id }
    );
    return data.deleteQuerystatement;
  },
  updateQueryStatement: async (newQuerystatement) => {
    const { data } = await requests.gql.chatserver.mutate(
      updateQueryStatementMutation,
      { newQuerystatement }
    );
    return data.updateQuerystatement;
  },
  updateQuery: async (query) => {
    const { data } = await requests.gql.chatserver.mutate(updateQuery, {
      query,
    });
    return data.updateQuery;
  },
  testSearchQueryStatement: async (campaignId, maxNumberMatches) => {
    const res = await requests.gql.chatserver.mutate(testSearchQuery, {
      campaignId,
      maxNumberMatches,
    });

    return res.data;
  },
  testMatchQueryStatement: async (
    campaignId,
    documentIds,
    maxNumberMatches
  ) => {
    const res = await requests.gql.chatserver.mutate(testMatchQuery, {
      campaignId,
      documentIds,
      maxNumberMatches,
    });

    return res.data;
  },
};

export default Query;
