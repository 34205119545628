import { DEFAULT_ATS_FIELDS_HANDLER } from 'constants/campaign.constants';
import {
  atsFieldOrderingFragment,
  mappingOptionFragment,
  statesFragment,
} from './fragments/builder/atsMappingFragments';
import requests from './requests';

const getCurrentAtsFieldMappingContext = /* GraphQL */ `
  query CurrentAtsFieldMappingContext($stateId: ID!) {
    currentAtsFieldMappingContext(stateId: $stateId) {
      id
      type
      dataType
      isDefaultMapping
      isLocked
      prepend
      append
      postModifiers
      getModifiers
      prependAppendSeparator
      states {
        ...statesFragment
      }
      scalarName {
        id
        name
      }
      mappingOptions {
        ...mappingOption
      }
      atsFieldOrdering: atsfieldorderingSet {
        ...atsFieldOrderingFragment
      }
    }
  }
  ${atsFieldOrderingFragment}
  ${mappingOptionFragment}
  ${statesFragment}
`;

const getAllAtsFieldMappingQuery = /* GraphQL */ `
  query AllAtsFieldMapping($isDefaultMapping: Boolean, $targetHandler: String) {
    allAtsFieldMappings(
      isDefaultMapping: $isDefaultMapping
      targetHandler: $targetHandler
    ) {
      scalarName {
        id
        name
      }
      id
      type
      dataType
      isDefaultMapping
      prepend
      append
      prependAppendSeparator
      value
      atsFieldOrdering: atsfieldorderingSet {
        ...atsFieldOrderingFragment
      }
    }
  }
  ${atsFieldOrderingFragment}
`;

const cloneAtsFieldMapping = /* GraphQL */ `
  mutation CloneAtsFieldMapping($atsFieldMappingId: ID!) {
    cloneAtsFieldMapping(atsFieldMappingId: $atsFieldMappingId) {
      ok
      errorMessage
      atsFieldMapping {
        id
        type
        dataType
        value
        isDefaultMapping
        prepend
        append
        prependAppendSeparator
        mappingOptions {
          ...mappingOption
        }
        atsFieldOrdering: atsfieldorderingSet {
          ...atsFieldOrderingFragment
        }
        scalarName {
          id
          name
        }
      }
    }
  }
  ${atsFieldOrderingFragment}
  ${mappingOptionFragment}
`;

const deleteAtsFieldMapping = /* GraphQL */ `
  mutation DeleteAtsFieldMapping($id: ID!) {
    deleteAtsFieldMapping(id: $id) {
      ok
      atsfieldmapping {
        id
      }
    }
  }
`;

const updateAtsFieldMapping = /* GraphQL */ `
  mutation UpdateAtsFieldMapping(
    $newAtsfieldmapping: AtsFieldMappingUpdateGenericType!
  ) {
    updateAtsFieldMapping(newAtsfieldmapping: $newAtsfieldmapping) {
      ok
      errors {
        field
        messages
      }
      atsfieldmapping {
        id
        type
        dataType
        value
        isDefaultMapping
        isLocked
        append
        prepend
        postModifiers
        getModifiers
        prependAppendSeparator
        atsOptions {
          value
          humanReadableName
          id
        }
        mappingOptions {
          ...mappingOption
        }
        atsFieldOrdering: atsfieldorderingSet {
          ...atsFieldOrderingFragment
        }
        scalarName {
          name
          id
        }
      }
    }
  }
  ${atsFieldOrderingFragment}
  ${mappingOptionFragment}
`;

const supportedEntitiesQuery = /* GraphQL */ `
  query GetCreatableAtsEntities($targetHandler: String) {
    creatableAtsEntities(targetHandler: $targetHandler) {
      id
      systemName
      name
      humanReadableNames {
        id
        content
        tags {
          content
        }
      }
      name
    }
  }
`;

const getCreatableAtsFields = /* GraphQL */ `
  query GetCreatableAtsFields(
    $entityTypeId: ID!
    $search: String
    $targetHandler: String
    $pageSize: Int
    $page: Int
  ) {
    creatableAtsFieldsOptimized(
      entityTypeId: $entityTypeId
      search: $search
      targetHandler: $targetHandler
      pageSize: $pageSize
      page: $page
    ) {
      hasNextPage
      atsFields {
        id
        dataType
        required
        extractionPath
        entityType {
          id
          humanReadableNames {
            id
            content
            tags {
              content
            }
          }
          name
          systemName
        }
        targetEntityType {
          id
        }
        humanReadableNames {
          content
          tags {
            id
            content
          }
        }
        postPath
        getPath
      }
    }
  }
`;

const autoCreateAtsFieldMapping = /* GraphQL */ `
  mutation AutoCreateAtsFieldMapping(
    $scalarNameId: ID
    $atsFieldId: ID!
    $atsEntityTypeId: ID!
    $targetHandler: String
  ) {
    autoCreateAtsFieldMapping(
      scalarNameId: $scalarNameId
      atsFieldId: $atsFieldId
      atsEntityTypeId: $atsEntityTypeId
      targetHandler: $targetHandler
    ) {
      ok
      errorMessage
      atsFieldMapping {
        atsFields {
          dataType
          id
          getPath
        }
        id
        type
        dataType
        value
        isDefaultMapping
        prepend
        append
        prependAppendSeparator
        mappingOptions {
          ...mappingOption
        }
        atsFieldOrdering: atsfieldorderingSet {
          ...atsFieldOrderingFragment
        }
        scalarName {
          id
          name
        }
      }
    }
  }
  ${atsFieldOrderingFragment}
  ${mappingOptionFragment}
`;

const AllAtsFieldMappings = {
  get: async (isDefaultMapping) => {
    const response = await requests.gql.chatserver.query(
      getAllAtsFieldMappingQuery,
      {
        isDefaultMapping,
        targetHandler: DEFAULT_ATS_FIELDS_HANDLER,
      }
    );
    return response;
  },
  getStateAtsFieldMapping: async (stateId) => {
    const response = await requests.gql.chatserver.query(
      getCurrentAtsFieldMappingContext,
      { stateId }
    );
    return response;
  },
  cloneAtsFieldMapping: async (atsFieldMappingId) => {
    const response = await requests.gql.chatserver.mutate(
      cloneAtsFieldMapping,
      {
        atsFieldMappingId,
      }
    );
    return response;
  },
  deleteAtsFieldMapping: async (id) => {
    const response = await requests.gql.chatserver.mutate(
      deleteAtsFieldMapping,
      { id }
    );
    return response;
  },
  updateAtsFieldMapping: async (newAtsfieldmapping) => {
    const response = await requests.gql.chatserver.mutate(
      updateAtsFieldMapping,
      { newAtsfieldmapping }
    );
    return response;
  },
  getSupportedEntities: async (targetHandler) => {
    const response = await requests.gql.chatserver.query(
      supportedEntitiesQuery,
      { targetHandler }
    );
    return response;
  },

  getCreatableAtsFields: async ({
    entityTypeId,
    search,
    pageSize,
    page,
    targetHandler,
  }) => {
    const response = await requests.gql.chatserver.query(
      getCreatableAtsFields,
      {
        entityTypeId,
        search,
        pageSize,
        page,
        targetHandler: targetHandler.toLowerCase(),
      }
    );
    return response;
  },

  autoCreateAtsFieldMapping: async (
    scalarNameId,
    atsFieldId,
    atsEntityTypeId,
    targetHandler
  ) => {
    const response = await requests.gql.chatserver.query(
      autoCreateAtsFieldMapping,
      {
        scalarNameId,
        atsFieldId,
        atsEntityTypeId,
        targetHandler: targetHandler.toLowerCase(),
      }
    );
    return response;
  },
};

export default AllAtsFieldMappings;
