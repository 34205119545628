import { action, computed, decorate, observable, runInAction } from 'mobx';
import agent from '../agent';
import { getTranslationFromSelectedLanguageTag } from '../utils/translateFromSelectedLanguageTag';
import AtsField from './AtsField.Store';
import AtsMappingOption from './AtsMappingOption';
import AtsOptionStore from './AtsOptionStore';
import BaseStore from './BaseStore';
import ScalarNameStore from './ScalarNameStore';

class AtsFieldMapping extends BaseStore {
  id;
  atsFields;
  scalarName;
  isDefaultMapping = false;
  dataType = '';
  type = '';
  value;
  mappingOptions = [];
  atsFieldOptions = [];
  atsOptions = [];
  states = [];
  isLocked;
  prepend = false;
  append = false;
  prependAppendSeparator = `' '`;
  getModifiers = [];
  postModifiers = [];

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    runInAction(() => {
      this.id = args?.id;
      this.atsFields = args?.atsFieldOrdering?.map(
        (field) => new AtsField(field)
      );
      this.scalarName = new ScalarNameStore(args?.scalarName);
      this.isDefaultMapping = args?.isDefaultMapping;
      this.dataType = args?.dataType;
      this.type = args?.type;
      this.value = args?.value;
      this.mappingOptions = args?.mappingOptions
        ? args?.mappingOptions.map(
            (mappingOption) => new AtsMappingOption(mappingOption)
          )
        : [];
      this.atsOptions = !args?.atsOptions
        ? []
        : args?.atsOptions?.map((atsOption) => new AtsOptionStore(atsOption));
      this.states = args?.states ? args?.states : [];
      this.isLocked = args?.isLocked;
      this.prepend = args?.prepend;
      this.append = args?.append;
      if (args?.prependAppendSeparator) {
        this.prependAppendSeparator = args?.prependAppendSeparator;
      }

      this.isDefaultMapping = args?.isDefaultMapping;
      this.postModifiers = args?.postModifiers;
      this.getModifiers = args?.getModifiers;
    });
  }

  /**
   * @param newDataType {String}
   */
  async updateDataType(newDataType) {
    runInAction(() => {
      this.dataType = newDataType;
    });
    const {
      data: { updateAtsFieldMapping },
    } = await agent.AllAtsFieldMappings?.updateAtsFieldMapping({
      id: this.id,
    });
    if (!updateAtsFieldMapping.ok) {
      return false;
    }
    return true;
  }

  /**
   * @param newScalarName {object}
   */
  async updateScalarName(newScalarName) {
    runInAction(() => {
      this.scalarName = newScalarName;
    });
    const {
      data: { updateAtsFieldMapping },
    } = await agent.AllAtsFieldMappings.updateAtsFieldMapping({
      id: this.id,
      dataType: this.dataType,
      scalarName: this.scalarName?.id,
    });

    return updateAtsFieldMapping.ok;
  }

  /**
   * @param value {string}
   */
  async updateAtsFieldValue(value) {
    runInAction(() => {
      this.value = value;
    });
    const {
      data: { updateAtsFieldMapping },
    } = await agent.AllAtsFieldMappings.updateAtsFieldMapping({
      id: this.id,
      value: this.value,
    });
    if (!updateAtsFieldMapping.ok) {
      return false;
    }
    return true;
  }

  // this function retrieve the translated human humanReadableNames based on the language of the app
  get translatedHumanReadableName() {
    const lastAtsFieldsIndex = this.atsFields.length - 1;
    return getTranslationFromSelectedLanguageTag(
      this.atsFields[lastAtsFieldsIndex]?.humanReadableNames
    );
  }

  get selectedFieldName() {
    // Filter the array to get items with excludeFromPost === 'false'
    const filteredFields = this.atsFields.filter(
      (item) => item.excludeFromPost === false
    );
    // Get the last item from the filtered array
    const lastItem = filteredFields[filteredFields.length - 1];
    return getTranslationFromSelectedLanguageTag(lastItem?.humanReadableNames);
  }

  // renders the path of the ats field(relational path)
  get atsPath() {
    const atsEntityTypeName = this.atsFields[0]?.atsEntityType?.name;
    return `${atsEntityTypeName}.${this.atsPathName}`;
  }

  get atsPathName() {
    return this.atsFields
      .map((atsField) => atsField?.getPath)
      .sort((a, b) => a.number - b.number)
      .join()
      .replaceAll(',', '.');
  }

  /**
   * @param atsFieldMappingId {string}
   */
  async getAtsFieldOptions(atsFieldMappingId) {
    if (this.atsFieldOptions?.length) return;
    const res = await agent.AtsFieldMapping.getAtsFieldOptions(
      atsFieldMappingId || this.id
    );
    runInAction(() => {
      if (res) {
        this.atsFieldOptions = res?.data?.atsFieldOptions?.map(
          (fieldOption) => new AtsOptionStore(fieldOption)
        );
      }
    });
  }

  /**
   * @param newAtsOption {object} we pass the new AtsOption we need to create
   * @param optionId {string} this is the optionId of the mappingOption where the atsOption belong
   * @param selectedMappingOption {object} we use this to determine if the atsFieldMapping has already mapping option in
   *  and is the targeted mappingOption where we create the new AtsOption in
   * @param isFixedOptionsType {bool} this param we pass it to check if the atsFieldMapping type is FIXED_OPTIONS
   */
  async createNewAtsOption({
    newAtsOption,
    optionId,
    selectedMappingOption,
    isFixedOptionsType = false,
  }) {
    // create new AtsOption
    const res = await agent.State.createAtsOption({ newAtsOption });
    const { createAtsOption } = res.data;

    // if the type of atsFieldMapping is FIXED_OPTIONS then we just add new AtsOption to the atsFieldMapping
    if (isFixedOptionsType) {
      runInAction(() => {
        this.atsOptions?.push(new AtsOptionStore(createAtsOption?.atsoption));
      });
      this.saveAtsFieldMapping();
    }

    // if there is Mapping option then we add the new AtsOption to the selected mappingOption
    else if (selectedMappingOption) {
      runInAction(() => {
        // push it in the mappingOptions
        selectedMappingOption?.atsOptions.push(
          new AtsOptionStore(createAtsOption?.atsoption)
        );
      });

      // update the mappingOptions using updateMappingOption function
      const newAtsMappingOption = {
        id: selectedMappingOption?.id,
        atsOptions: selectedMappingOption?.atsOptions.map(
          (atsOption) => atsOption?.id
        ),
      };

      this.updateAtsMappingOption(newAtsMappingOption);
    }
    // if mappingOptions is doesn't have mapping atsMappingOption then we create newAtsMappingOption
    else {
      const newAtsmappingoption = {
        atsOptions: [createAtsOption?.atsoption?.id],
        option: optionId,
      };

      this.createAtsMappingOption(newAtsmappingoption);
    }

    return createAtsOption.ok;
    // END OF THE FUNCTION
  }

  /**
   * @param newAtsMappingOption {object} this param of newMappingOption we create in case the atsFieldMapping doesn't have one
   */
  async createAtsMappingOption(newAtsmappingoption) {
    const res = await agent.State.createAtsMappingOption({
      newAtsmappingoption,
    });
    const { createAtsMappingOption } = res.data;

    runInAction(() => {
      this.mappingOptions.push(
        new AtsMappingOption(createAtsMappingOption?.atsmappingoption)
      );

      // after creating new atsMappingOption we save the atsFieldMapping with new changes
      this.saveAtsFieldMapping();
    });

    return res;
  }

  /**
   * @param newAtsmappingoption {object}
   */
  async updateAtsMappingOption(newAtsmappingoption) {
    const res = await agent.State.updateAtsMappingOption({
      newAtsmappingoption,
    });

    return res;
  }

  /**
   * @param atsMappingOption {object}
   */
  async updateMappingOptionValue(atsMappingOption) {
    const newAtsmappingoption = {
      id: atsMappingOption?.id,
      atsOptions: atsMappingOption?.atsOptions.map(
        (atsOption) => atsOption?.id
      ),
      value: atsMappingOption?.value,
    };

    this.updateAtsMappingOption(newAtsmappingoption);
  }

  /**
   * @param atsOptionId {string}
   * @param selectedMappingOption {object} this is the targeted MappingOption where we need to delete the atsOption from
   */
  async deleteAtsOption(atsOptionId, selectedMappingOption) {
    await agent.State.deleteAtsOption(atsOptionId);

    // if the type of the atsFieldMapping is FIXED_OPTIONS then we delete the selected atsOptions from atsOptions because it doesn't have mappingOption
    if (!selectedMappingOption) {
      runInAction(() => {
        this.atsOptions = this.atsOptions.filter(
          ({ id }) => id !== atsOptionId
        );
      });
      this.saveAtsFieldMapping();
      // if the type of atsFieldMapping is OPTIONS
    } else {
      await agent.State.deleteAtsOption(atsOptionId);
      // filter the atsOptions and return all atsOptions except the deleted one
      const filteredAtsOptions = selectedMappingOption?.atsOptions?.filter(
        (atsOption) => atsOption?.id !== atsOptionId
      );

      // set the atsOptions to the filtered one
      selectedMappingOption?.setAttr('atsOptions', filteredAtsOptions);
      this.saveAtsFieldMapping();

      // if the atsOptions of the atsMappingOption is empty then we delete the atsMappingOption
      if (selectedMappingOption?.atsOptions?.length === 0) {
        runInAction(() => {
          this.mappingOptions = this.mappingOptions.filter(
            (atsMapping) => atsMapping.id !== selectedMappingOption?.id
          );
          this.deleteAtsMappingOption(selectedMappingOption?.id);
        });
      }
    }
  }

  /**
   * @param id {string} this is the id of mappingOption we need to delete in case we deleted all the atsOptions and become empty so we delete all the mappingOption
   */
  async deleteAtsMappingOption(id) {
    const res = await agent.State.deleteAtsMappingOption(id);
    runInAction(() => {
      this.mappingOptions = this.mappingOptions?.filter(
        (mappingOption) => mappingOption?.id !== id
      );
    });
    return res;
  }

  async saveAtsFieldMapping() {
    const newAtsfieldmapping = {
      id: this.id,
      type: this.type,
      atsOptions: this.atsOptions?.map((atsOption) => atsOption?.id),
      mappingOptions: this.mappingOptions.map(
        (mappingOption) => mappingOption.id
      ),
      isLocked: this.isLocked,
      append: this.append,
      prepend: this.prepend,
      prependAppendSeparator: this.prependAppendSeparator,
      postModifiers: this.postModifiers?.filter((modifier) => modifier !== ''),
      getModifiers: this.getModifiers?.filter((modifier) => modifier !== ''),
    };

    const res = await agent.AllAtsFieldMappings.updateAtsFieldMapping(
      newAtsfieldmapping
    );

    return res;
  }

  async toggleUnlockAtsFieldMapping() {
    this.isLocked = !this.isLocked;
    await this.saveAtsFieldMapping();
    // Computed property to determine the selected value
  }

  get selectedPrependAppendValue() {
    if (this.append) {
      return 'append';
    } else if (this.prepend) {
      return 'prepend';
    } else {
      return 'write';
    }
  }

  // Action to update the atsFieldMapping based on the selected value
  async updateSelectedPrependAppendValue(value) {
    if (value === 'append') {
      this.append = true;
      this.prepend = false;
    } else if (value === 'prepend') {
      this.append = false;
      this.prepend = true;
    } else {
      this.append = false;
      this.prepend = false;
    }
    this.saveAtsFieldMapping();
  }

  async setSeparator(separator) {
    this.prependAppendSeparator = separator;
    this.saveAtsFieldMapping();
  }

  async addModifier(modifier, index, storePropertyName) {
    runInAction(() => {
      this[storePropertyName] = modifier;
    });
    await this.saveAtsFieldMapping();
  }

  async deleteModifier(storePropertyName, filteredModifiers) {
    runInAction(() => {
      this[storePropertyName] = filteredModifiers;
    });
    await this.saveAtsFieldMapping();
  }
}

decorate(AtsFieldMapping, {
  id: observable,
  setup: action,
  atsFields: observable,
  isDefaultMapping: observable,
  translatedHumanReadableName: computed,
  atsPath: computed,
  scalarName: observable,
  value: observable,
  fetchAllAtsFieldMappings: action,
  getAtsFieldOptions: action,
  updateMappingOptionValue: action,
  dataType: observable,
  type: observable,
  atsOptions: observable,
  updateScalarName: action,
  mappingOptions: observable,
  atsFieldOptions: observable,
  states: observable,
  isLocked: observable,
  prepend: observable,
  append: observable,
  prependAppendSeparator: observable,
  createNewAtsOption: action,
  createAtsMappingOption: action,
  updateAtsMappingOption: action,
  deleteAtsMappingOption: action,
  saveAtsFieldMapping: action,
  toggleUnlockAtsFieldMapping: action,
  updateSelectedPrependAppendValue: action,
  setSeparator: action,
  selectedPrependAppendValue: computed,
  selectedFieldName: computed,
  getModifiers: observable,
  postModifiers: observable,
  deleteModifier: action,
});

export default AtsFieldMapping;
