import { decorate, observable, action, runInAction, computed } from 'mobx'
import _ from 'lodash'
import agent from 'agent'
import BaseStore from 'stores/BaseStore'
import Block from 'stores/Block.store'

const GENERIC = 'generic'

class CalendarAvailability extends BaseStore {
  id
  userId
  contactName
  calendarEmail
  appointmentType
  interviewType
  _categories
  blocks
  timeZone
  maxDailyAppointments

  constructor(params) {
    super()
    this.setup(params)
    this.save = _.debounce(this.save, 1300)
  }

  setup({
    id,
    userId,
    contactName,
    calendarEmail,
    appointmentType,
    interviewType,
    categories,
    blocks,
    timeZone,
    maxDailyAppointments,
  }) {
    this.id = id
    this.userId = userId
    this.contactName = contactName
    this.calendarEmail = calendarEmail
    this.appointmentType = appointmentType
    this.interviewType = interviewType
    this.categories = categories || []
    this.blocks = blocks.map(block => new Block(block))
    this.timeZone = timeZone
    this.maxDailyAppointments = maxDailyAppointments
  }

  async save() {
    const data = {
      id: this.id,
      userId: this.userId,
      contactName: this.contactName,
      calendarEmail: this.calendarEmail,
      interviewType: this.interviewType,
      categories: this._categories,
      timeZone: this.timeZone,
      maxDailyAppointments: this.maxDailyAppointments,
      appointmentType: this.appointmentType.id,
    }
    await agent.CalendarAvailability.update(data)
  }

  async addBlock(startTime, endTime, dayOfTheSlot) {
    const dayInUpperCase = dayOfTheSlot.toUpperCase()
    const data = {
      startTime,
      endTime,
      day: dayInUpperCase,
      calendarAvailability: this.id,
    }
    const block = await Block.create(data)
    runInAction(() => {
      this.blocks.push(block)
    })
  }

  async deleteBlock(id) {
    _.remove(this.blocks, { id })
    await Block.delete(id)
  }

  get blocksMonday() {
    return this.blocks.filter(block => block.day === 'MONDAY')
  }

  static async create(data) {
    data.categories = CalendarAvailability.processCategories(
      data.categories || [],
    )

    const calendarAvailability = await agent.CalendarAvailability.create(data)
    return new CalendarAvailability(calendarAvailability)
  }

  static async delete(id) {
    await agent.CalendarAvailability.delete(id)
  }

  set categories(cats) {
    this._categories = CalendarAvailability.processCategories(cats)
  }

  get categories() {
    return this._categories.filter(cat => cat)
  }

  static processCategories(cats) {
    if (cats.length === 0) {
      return [GENERIC]
    }
    if (cats.length >= 2 && _.includes(cats, GENERIC)) {
      return _.remove(cats, elem => elem !== GENERIC)
    }
    return cats
  }
}

decorate(CalendarAvailability, {
  id: observable,
  userId: observable,
  contactName: observable,
  calendarEmail: observable,
  interviewType: observable,
  _categories: observable,
  blocks: observable,
  maxDailyAppointments: observable,
  setup: action,
  addBlock: action,
  deleteBlock: action,
  setAppointmentType: action,
  blocksMonday: computed,
  categories: computed,
})

export default CalendarAvailability
