import { action, decorate, observable, runInAction } from 'mobx';
import agent from '../agent';
import { TEMPLATE_SCHEME } from '../constants';
import AtsFieldMapping from './AtsFieldMapping.store';
import BaseStore from './BaseStore';
import EmailTemplateStore from './EmailTemplateStore';
import MapperStore from './MapperStore';
import OptionStore from './OptionStore';

class Processor extends BaseStore {
  id = '';
  name = '';
  humanReadableName = '';
  processorType = '';
  type = '';
  color = '';
  icon = '';
  category = '';
  priority;
  dependencies = [];
  description = '';
  isImportantGoal;
  googleAnalyticsEvent;
  finalGoalCampaigns = [];
  stateMachines = [];
  options = [];
  states = [];
  processorAtsFieldMappings = [];
  useMapperV2 = false;
  emailTemplate = null;
  mappers = [];
  optionSet;
  onFailProcessor;
  onFinishProcessor;
  onFirstReplyProcessor;

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    this.id = args?.id;
    this.name = args?.name;
    this.humanReadableName = args?.humanReadableName;
    this.processorType = args?.processorType;
    this.color = args?.color;
    this.icon = args?.icon;
    this.category = args?.category;
    this.priority = args?.priority;
    this.dependencies = args?.processorDependencies;
    this.description = args?.description;
    this.isImportantGoal = args?.isImportantGoal;
    this.googleAnalyticsEvent = args?.googleAnalyticsEvent;
    this.type = args?.type;
    this.finalGoalCampaigns = args?.finalGoalCampaigns;
    this.stateMachines = args?.stateMachines;
    this.states = args?.states;
    this.options = args?.options;
    this.useMapperV2 = args?.useMapperV2;
    this.optionSet = args?.optionSet?.map((option) => new OptionStore(option));
    this.onFailProcessor = args?.onFailProcessor;
    this.onFinishProcessor = args?.onFinishProcessor;
    this.onFirstReplyProcessor = args?.onFirstReplyProcessor;

    if (args?.atsFieldMappings) {
      this.processorAtsFieldMappings = args.atsFieldMappings.map(
        (atsFieldMapping) => new AtsFieldMapping(atsFieldMapping)
      );
    }
  }

  async save() {
    const data = {
      id: this.id,
      priority: this.priority,
      isImportantGoal: this.isImportantGoal,
      googleAnalyticsEvent: this.googleAnalyticsEvent
        ? this.googleAnalyticsEvent.id
        : null,
      finalGoalCampaigns: this.finalGoalCampaigns.map(
        (campaign) => campaign.id
      ),
      atsFieldMappings: this.processorAtsFieldMappings?.map(({ id }) => id),
    };

    return await agent.Processor.update(data);
  }

  async editGoogleAnalyticsEvent(value) {
    const data = {
      id: this.id,
      googleAnalyticsEvent: value,
    };

    await agent.Processor.update(data);
  }

  async clone(schemaName) {
    const { data } = await agent.Processor.cloneProcessor(
      this.id,
      schemaName,
      schemaName
    );

    if (data?.ok) return null;

    let clonedProcessor;
    runInAction(() => {
      clonedProcessor = new Processor(data.cloneProcessor.clonedProcessor);
    });

    return clonedProcessor;
  }
  //TODO: this method should be in chatbot store and should be renamed to "copyProcess"
  // also it will always return null!!
  async copy(templateId, schemaName) {
    const { data } = await agent.Processor.copyProcessor({
      processorTemplateIds: [templateId],
      fromSchema: TEMPLATE_SCHEME,
      toSchema: schemaName,
    });

    if (data.ok) return null;

    let copiedProcessor;
    runInAction(() => {
      copiedProcessor = new Processor(data.copyProcessor.copiedProcessor);
    });

    return copiedProcessor;
  }

  async update() {
    const data = {
      id: this.id,
      humanReadableName: this.humanReadableName,
      description: this.description,
    };

    await agent.Processor.update(data);
  }

  async assignAtsFieldMapping(atsFieldToEdit, operation) {
    // update the frontend
    runInAction(() => {
      if (operation === 'add') {
        this.processorAtsFieldMappings.push(atsFieldToEdit);
      } else {
        this.processorAtsFieldMappings = this.processorAtsFieldMappings.filter(
          ({ id }) => id !== atsFieldToEdit.id
        );
      }
    });
    // update the backend
    await this.save();
  }

  /**
   * @param isFixedValueOrFixedOptionType {bool} this param we pass it to check if the atsFieldMapping type is FIXED or REGULAR
   * @param fieldToClone {object}this is the atsFieldMapping we need to clone
   */
  async cloneAtsFieldMapping(isFixedValueOrFixedOptionType, fieldToClone) {
    const {
      data: { cloneAtsFieldMapping },
    } = await agent.AllAtsFieldMappings.cloneAtsFieldMapping(fieldToClone?.id);

    if (cloneAtsFieldMapping.ok) {
      // here we check first if we are configuring from the list of fixed value/options
      // then on cloning we switch the cloned atsFieldMapping to FIXED_VALUE if the type is REGULAR and to FIXED_OPTIONS if the type is OPTIONS
      if (isFixedValueOrFixedOptionType) {
        const {
          data: { updateAtsFieldMapping },
        } = await agent.AllAtsFieldMappings.updateAtsFieldMapping({
          type:
            cloneAtsFieldMapping.atsFieldMapping?.type === 'REGULAR'
              ? 'FIXED_VALUE'
              : 'FIXED_OPTIONS',
          id: cloneAtsFieldMapping.atsFieldMapping?.id,
        });

        runInAction(() => {
          this.processorAtsFieldMappings.push(
            new AtsFieldMapping(updateAtsFieldMapping.atsfieldmapping)
          );
        });
      } else {
        runInAction(() => {
          this.processorAtsFieldMappings.push(
            new AtsFieldMapping(cloneAtsFieldMapping.atsFieldMapping)
          );
        });
      }
      await this.save();
      return true;
    }
    return false;
  }

  async incrementPriority(i) {
    runInAction(() => {
      this.priority += i;
    });
  }

  async setPriority(amount) {
    runInAction(() => {
      this.priority = amount;
    });
  }

  async setFinalGoalCampaigns(campaigns) {
    runInAction(() => {
      this.finalGoalCampaigns = campaigns;
    });
  }

  async fetchEmailTemplate(id) {
    const { data } = await agent.EmailTemplate.get(id);

    runInAction(() => {
      this.emailTemplate = new EmailTemplateStore(data?.emailTemplate);
    });

    return this.emailTemplate;
  }

  async fetchMappers(ids) {
    const { data } = await agent.Mapper.all(ids);

    runInAction(() => {
      this.mappers = data?.allMappers.map((mapper) => new MapperStore(mapper));
    });

    return this.mappers;
  }

  async fetchProcessorMapper(id) {
    const { data } = await agent.Processor.fetchProcessorMapper(id);

    runInAction(() => {
      this.processorAtsFieldMappings =
        data?.allProcessors[0]?.atsFieldMappings?.map(
          (atsFieldMapping) => new AtsFieldMapping(atsFieldMapping)
        );
    });

    return data?.allProcessors[0];
  }

  async fetchProcessorDependencies() {
    if (!this.emailTemplate) {
      const emailTemplateReference = this.dependencies?.find(
        (dependency) =>
          dependency.customProcessor &&
          dependency.customProcessor.modelName === 'EmailTemplate'
      );
      const emailTemplateId = emailTemplateReference?.customProcessor?.id;

      if (emailTemplateId) {
        await this.fetchEmailTemplate(emailTemplateId);
      }
    }

    if (!this.mappers) {
      const mapperIdsArray = this.dependencies
        ?.filter(
          (dependency) =>
            dependency.customProcessor &&
            dependency.customProcessor.modelName === 'Mapper'
        )
        .map((mapper) => mapper.customProcessor.id);

      if (mapperIdsArray?.length) {
        await this.fetchMappers(mapperIdsArray);
      }
    }
  }
}

decorate(Processor, {
  id: observable,
  name: observable,
  humanReadableName: observable,
  processorType: observable,
  icon: observable,
  color: observable,
  priority: observable,
  dependencies: observable,
  description: observable,
  processorAtsFieldMappings: observable,
  category: observable,
  useMapperV2: observable,
  emailTemplate: observable,
  mappers: observable,
  optionSet: observable,
  onFailProcessor: observable,
  onFinishProcessor: observable,
  onFirstReplyProcessor: observable,
  setup: action,
  clone: action,
  save: action,
  incrementPriority: action,
  setPriority: action,
  assignAtsFieldMapping: action,
  cloneAtsFieldMapping: action,
  fetchProcessorMapper: action,
  fetchProcessorDependencies: action,
});

export default Processor;
