import agent from 'agent';
import { action, computed, decorate, observable, runInAction } from 'mobx';
import BaseStore from 'stores/BaseStore';
import { campaignTypes } from '../../../constants/campaign.constants';
import Campaign from '../Campaign.store';
import WhatsAppTemplate from '../WhatsAppTemplate/WhatsAppTemplate.store';

class WhatsAppDefinition extends BaseStore {
  id;
  name;
  isSmartInboxTemplate;
  versionsHistoricallyCreated;
  templateIdentifier;
  activeTemplate;
  availableTemplates;
  campaigns;
  supportsBatchAction;
  batchActionCampaign;
  atsBatchActionEnabled;
  displayTemplate;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.id = params?.id;
    this.name = params?.name;
    this.isSmartInboxTemplate = params?.isSmartInboxTemplate;
    this.versionsHistoricallyCreated = params?.versionsHistoricallyCreated;
    this.templateIdentifier = params?.templateIdentifier;
    this.activeTemplate =
      params?.activeTemplate && new WhatsAppTemplate(params?.activeTemplate);

    // If display template is not fetched we use activeTemplate as displayTemplate
    this.displayTemplate = params?.displayTemplate
      ? new WhatsAppTemplate(params?.displayTemplate)
      : this.activeTemplate;

    this.availableTemplates = params?.availableTemplates?.map(
      (template) => new WhatsAppTemplate(template)
    );
    this.campaigns = params?.campaignSet?.map(
      (campaign) => new Campaign(campaign)
    );

    this.batchActionCampaign = this.campaigns?.find(
      (campaign) => campaign.type === campaignTypes.SMART_INBOX_BATCH_ACTION
    );

    this.supportsBatchAction = params?.supportsBatchAction;
    this.atsBatchActionEnabled =
      !!this.batchActionCampaign?.atsBatchActions?.length;
  }

  async clear() {
    this.id = null;
    this.name = null;
    this.isSmartInboxTemplate = null;
    this.versionsHistoricallyCreated = null;
    this.templateIdentifier = null;
    this.activeTemplate = null;
    this.availableTemplates = null;
    this.campaignSet = null;
    this.atsBatchActions = null;
  }

  async save() {
    const data = {
      id: this.id,
      name: this.name,
      isSmartInboxTemplate: this.isSmartInboxTemplate,
      versionsHistoricallyCreated: this.versionsHistoricallyCreated,
      templateIdentifier: this.templateIdentifier,
    };
    await agent.WhatsAppDefinition.update(data);
  }

  get templateToShow() {
    if (this.activeTemplate?.id) {
      return this.activeTemplate;
    } else if (this.availableTemplates?.length > 0) {
      return this.availableTemplates[0];
    }
    return null;
  }

  async setupBatchAction(campaignId) {
    runInAction(
      () => (this.atsBatchActionEnabled = !this.atsBatchActionEnabled)
    );

    const res = await agent.AtsBatchAction.setup(
      campaignId ? null : this.id,
      campaignId,
      this.atsBatchActionEnabled
    );

    runInAction(() => {
      if (!res.ok) {
        this.atsBatchActionEnabled = !this.atsBatchActionEnabled;
        return;
      }
      const newCampaign = new Campaign(res.campaign);

      // Check of the newCampaign is already in the list of campaigns
      const existingCampaign = this.campaigns.find(
        (campaign) => campaign.id === newCampaign.id
      );

      // Delete the existing campaign if it exists
      if (existingCampaign) {
        this.campaigns = this.campaigns.filter(
          (campaign) => campaign.id !== newCampaign.id
        );
      }

      // Add the new campaign to the list of campaigns
      this.campaigns.push(newCampaign);

      // Set the new campaign to be the batchActionCampaign
      this.batchActionCampaign = newCampaign;
    });
  }
}

decorate(WhatsAppDefinition, {
  id: observable,
  name: observable,
  isSmartInboxTemplate: observable,
  versionsHistoricallyCreated: observable,
  templateIdentifier: observable,
  activeTemplate: observable,
  availableTemplates: observable,
  templateToShow: computed,
  campaigns: observable,
  batchActionCampaign: observable,
  supportsBatchAction: observable,
  atsBatchActionEnabled: observable,
  setup: action,
  clear: action,
  save: action,
  setupBatchAction: action,
});

export default WhatsAppDefinition;
