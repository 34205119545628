import { computed, decorate, observable } from 'mobx';
import { getTranslationFromSelectedLanguageTag } from '../utils/translateFromSelectedLanguageTag.js';
import BaseStore from './BaseStore';
import Translation from './TranslationStore';

class AtsEntityType extends BaseStore {
  id = '';
  humanReadableNames = [];
  name = '';
  systemName = '';

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    this.name = args?.name;
    this.systemName = args?.systemName;
    const targetHumanReadableNames =
      args?.allHumanReadableNames || args?.humanReadableNames;
    this.id = args?.id;
    this.humanReadableNames = targetHumanReadableNames?.map(
      (humanReadableName) => new Translation(humanReadableName)
    );
  }

  get translatedHumanReadableName() {
    return getTranslationFromSelectedLanguageTag(this.humanReadableNames);
  }
}

decorate(AtsEntityType, {
  id: observable,
  humanReadableNames: observable,
  name: observable,
  systemName: observable,
  translatedHumanReadableName: computed,
});

export default AtsEntityType;
