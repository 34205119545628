import { action, decorate, observable, runInAction } from 'mobx';
import moment from 'moment';
import agent from '../../../agent';
import BaseStore from '../../BaseStore';

class WhatsAppChannel extends BaseStore {
  id = '';
  name = '';
  channelId = '';
  whatsappNumber = '';
  createdAt = '';

  profileText = '';
  description = '';
  websites = '';
  profilePicture = '';
  companyName = '';
  address = '';

  isLoading = false;
  isLoaded = false;

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    this.id = args.id;
    this.name = args.name;
    this.channelId = args.channelId;
    this.whatsappNumber = args.whatsappNumber;
    this.createdAt = moment(args.createdAt).format('MMMM Do YYYY, h:mm:ss a');
  }

  async fetchProfile() {
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      const response = await agent.WhatsApp.fetchProfile();

      runInAction(() => {
        this.profileText = response.profileText;
        this.description = response.description;
        this.websites = response.websites;
        this.profilePicture = response.profilePicture;
        this.companyName = response.companyName;
        this.address = response.address;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.isLoaded = true;
      });
    }
  }

  async save() {
    try {
      const response = await agent.WhatsApp.update(
        this.profileText,
        this.description,
        this.websites,
        this.profilePicture,
        this.companyName,
        this.address
      );
      return response.ok;
    } catch (error) {
      console.log(error);
    }

    return false;
  }
}

decorate(WhatsAppChannel, {
  id: observable,
  name: observable,
  channelId: observable,
  whatsappNumber: observable,
  createdAt: observable,
  isLoading: observable,
  isLoaded: observable,

  profileText: observable,
  description: observable,
  websites: observable,
  profilePicture: observable,
  companyName: observable,
  address: observable,
  setup: action,
  fetchProfile: action,
});

export default WhatsAppChannel;
