import { createContext } from 'react';
import AgentsStore from './AgentsStore';
import AnalyticsStore from './AnalyticsStore';
import SmartInboxAnalyticsStore from './AnalyticsStores/SmartInboxAnalyticsStore';
import ChatbotSettingStore from './ChatbotSettingStore/ChatbotSettingStore';
import CommonStore from './CommonStore';
import {
  BlogStore,
  DashboardStore,
  DocumentationStore,
  GettingStartedStore,
  ReleaseNotesStore,
} from './DashboardStores';
import ErrorStore from './ErrorStore';
import Flow from './Flows/Flow.store';
import LanguageStore from './LanguageStore';
import SaveStore from './SaveStore';
import StateGroup from './StateGroup';
import TemplateStores from './TemplatesStore';
import UserStore from './UserStore';
import WidgetSettingsStore from './WidgetSettingsStore';
import WhatsAppTemplate from './campaigns/WhatsAppTemplate/WhatsAppTemplate.store';

export const commonStore = new CommonStore();
export const userStore = new UserStore();
export const analyticsStore = new AnalyticsStore();
export const saveStore = new SaveStore();
export const documentationStore = new DocumentationStore();
export const settingsStore = new WidgetSettingsStore();
export const templateStores = new TemplateStores();
export const errorStore = new ErrorStore();
export const languageStore = new LanguageStore();
export const chatbotSettingStore = new ChatbotSettingStore();
export const dashboardStore = new DashboardStore();
export const blogStore = new BlogStore();
export const gettingStartedStore = new GettingStartedStore();
export const releaseNotesStore = new ReleaseNotesStore();
export const stateGroupStore = new StateGroup();
export const WhatsAppTemplateStore = new WhatsAppTemplate();
export const smartInboxAnalytics = new SmartInboxAnalyticsStore();
export const agentsStore = new AgentsStore();

export const CommonContext = createContext(commonStore);
export const UserContext = createContext(userStore);
export const AnalyticsContext = createContext(analyticsStore);
export const SaveContext = createContext(saveStore);
export const DocumentationContext = createContext(documentationStore);
export const SettingsContext = createContext(settingsStore);
export const TemplatesContext = createContext(templateStores);
export const ErrorContext = createContext(errorStore);
export const LanguageContext = createContext(languageStore);
export const ChatbotSettingContext = createContext(chatbotSettingStore);
export const BlogContext = createContext(blogStore);
export const DashboardContext = createContext(dashboardStore);
export const GettingStartedContext = createContext(gettingStartedStore);
export const ReleaseNotesContext = createContext(releaseNotesStore);
export const StateGroupContext = createContext(stateGroupStore);
export const FlowContext = createContext(Flow);
export const WhatsAppTemplateContext = createContext(WhatsAppTemplateStore);
export const SmartInboxAnalyticsContext = createContext(smartInboxAnalytics);
export const AgentsContext = createContext(agentsStore);
