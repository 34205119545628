export const calendarTypes = {
  GOOGLE_MEET: 'GOOGLE_MEET',
  MICROSOFT_TEAMS: 'MICROSOFT_TEAMS',
  LOCATION: 'LOCATION',
};

export const calendarHeaderLocations = {
  CALENDAR_OVERVIEW: 'calendarOverview',
  LINK_CALENDAR_MODAL: 'linkCalendarModal',
  CONFIGURATION_SIDE_DRAWER: 'configurationSideDrawer',
};

export const configurationTabs = {
  APPOINTMENT_DATA: 'appointmentDataTab',
  TIME_SCHEDULE: 'TimesScheduleTab',
  LINKED_CALENDARS: 'linkedCalendarsTab',
};

export const ScheduleAvailabilityDays = {
  MON: 'A_1',
  TUE: 'A_2',
  WED: 'A_3',
  THU: 'A_4',
  FRI: 'A_5',
  SAT: 'A_6',
  SUN: 'A_7',
};

export const ScheduleAvailabilityWorkDays = {
  MON: 'A_1',
  TUE: 'A_2',
  WED: 'A_3',
  THU: 'A_4',
  FRI: 'A_5',
};

export const CALENDAR_DATES_FORMAT = 'DD-MM-YYYY';

export const NOTIFICATION_TYPES = {
  EMAIL: 'EMAIL',
  PUSH: 'PUSH',
};

export const DATE_RANGE_TYPE = {
  DAY: 'day',
  HOUR: 'hour',
};
