import _ from 'lodash';
import { action, observable, runInAction } from 'mobx';

import agent from '../agent';
import BaseStore from './BaseStore';
import TemplateStore from './TemplateStore';
import { commonStore, userStore } from './index';

class TemplatesStores extends BaseStore {
  @observable templates = new Set();

  @action
  async loadStateGroupTemplates() {
    this.templates = new Set();
    const res = await agent.StateGroupTemplate.all(
      // userStore?.selectedChatbot?.useBetaPortal || false DISABLED FOR NOW
      false
    );
    res?.data?.allTemplates.forEach((template) => {
      runInAction(() => {
        this.templates.add(new TemplateStore(template));
      });
    });
  }

  @action
  async copyTemplate(
    templateId,
    destinationStateMachineId,
    destinationPosition,
    newStateGroupName
  ) {
    const data = {
      destinationStateMachineId,
      destinationPosition,
      destinationProjectId: commonStore.selectedChatbot.name,
      templateIds: [templateId],
      newStateGroupName,
    };
    const res = await agent.StateGroupTemplate.copy(data);
    const ok = _.get(res, 'data.copyStateGroup.ok');
    if (!ok) throw Error('Could not copy Template');
  }
}

export default TemplatesStores;
