import requests from '../requests'

const mutation = `
mutation UpdateTextMessage($data: TextMessageUpdateGenericType!) {
    updateTextMessageMutation(newTextmessage: $data) {
      textmessage {
        id
        content
      }
      ok
    }
  }
`

const TextMessage = {
  update: data => requests.gql.chatserver.mutate(mutation, data),
}

export default TextMessage
