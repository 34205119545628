import { action, decorate, observable, runInAction } from 'mobx';
import {
  listActiveEventConfigurations,
  sortDates,
} from 'utils/calendarOverview.utils';
import { v4 as uuidv4 } from 'uuid';
import agent from '../../agent';
import BaseStore from '../BaseStore';
import EventConfiguration from './EventConfiguration.store';
import EventPreference from './EventPreference.store';
import ScheduleAvailabilitySchema from './ScheduleAvailabilitySchema.store';

class CalendarUser extends BaseStore {
  id;
  userRouterId;
  email;
  firstName;
  lastName;
  isCalendarAuthenticated = false;
  blockedStatuses;
  excludedDays = [];
  calendar;
  timeZone;
  teams;
  eventPreferences = [];
  spokenLanguages = [];
  allUserEventConfigurations = [];
  defaultAvailabilitySchema = null;
  scheduleAvailabilitySchemaToEdit = null;
  availabilitySchemas = [];
  isDefaultSchemaUpdated = false;
  isCreatingNewScheduleAvailabilitySchema = false;
  areActiveConfigsChangedInLinkedCalendarTab = false;

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    this.id = args?.id;
    this.userRouterId = args?.userRouterId;
    this.email = args?.email;
    this.firstName = args?.firstName;
    this.lastName = args?.lastName;
    this.isCalendarAuthenticated = args?.isCalendarAuthenticated;
    this.blockedStatuses = args?.blockedStatuses;
    this.calendar = args?.calendar;
    this.timeZone = args?.timeZone;
    // this.teams = args?.teams?.map((team) => new Team(team));

    if (args?.eventPreferences?.length) {
      this.eventPreferences = args.eventPreferences.map(
        (eventPreference) => new EventPreference(eventPreference)
      );
      this.allUserEventConfigurations = listActiveEventConfigurations(
        args.eventPreferences
      ).map((eventConfiguration) => new EventConfiguration(eventConfiguration));
    }
    this.spokenLanguages = args?.spokenLanguages;
    if (args?.excludedDays) {
      this.excludedDays = args.excludedDays.sort(sortDates);
    }
    if (args?.defaultAvailabilitySchema) {
      this.defaultAvailabilitySchema = new ScheduleAvailabilitySchema(
        args?.defaultAvailabilitySchema
      );
    }
    if (args?.availabilitySchemas.length > 0) {
      this.availabilitySchemas = args?.availabilitySchemas.map(
        (availabilitySchema) =>
          new ScheduleAvailabilitySchema(availabilitySchema)
      );
    }
  }

  async authenticateCalendar(code, id, calendarType, referer = '') {
    const calendar = await agent.Calendar.authenticateCalendar(
      code,
      id,
      calendarType.toLowerCase(),
      referer
    );
    if (calendar.ok) {
      await this.updateCalendarUser();
      runInAction(() => {
        this.isCalendarAuthenticated = true;
      });
    }
    return calendar.ok;
  }

  async updateCalendarUser(fieldsToUpdate) {
    let newCalendaruser = { id: this.id };
    if (fieldsToUpdate) {
      newCalendaruser = { id: this.id, ...fieldsToUpdate };
    }

    const updatedUser = await agent.Calendar.updateCalendarUser(
      newCalendaruser
    );

    runInAction(() => {
      this.setup(updatedUser);
    });
  }

  async createScheduleAvailabilitySchema(values) {
    if (values?.name?.length < 3) return;
    runInAction(() => {
      this.isCreatingNewScheduleAvailabilitySchema = true;
    });
    const defaultValues = {
      name: values?.name,
      timeZone:
        values?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      scheduleAvailabilities:
        values?.scheduleAvailabilities?.length > 0
          ? values?.scheduleAvailabilities
          : [],
      eventpreferencesSet: values?.eventPreferencesSet || [],
    };

    const newSchema = new ScheduleAvailabilitySchema(defaultValues);
    try {
      // create new schema
      if (newSchema.scheduleAvailabilities.length === 0) {
        await newSchema.createScheduleAvailabilitiesDefaultValues();
      }

      await newSchema.createScheduleAvailabilitySchema();
      if (values?.eventPreferencesSet?.length > 0) {
        runInAction(() => {
          newSchema.setAttr('eventPreferencesSet', values?.eventPreferencesSet);
        });

        const eventPreferencesPromisesArr = [];
        values.eventPreferencesSet.map((event) =>
          eventPreferencesPromisesArr.push(
            event.updateEventPreference({
              scheduleAvailabilitySchema: newSchema.id,
            })
          )
        );

        await Promise.all(eventPreferencesPromisesArr);
        await newSchema.updateScheduleAvailabilitySchema();
      }
      runInAction(() => {
        this.availabilitySchemas.push(newSchema);
      });
      // update calendar user
      const newAvailabilitySchemasIds = this.availabilitySchemas.map(
        (schema) => schema.id
      );
      await this.updateCalendarUser({
        availabilitySchemas: newAvailabilitySchemasIds,
      });
      // show new schema in the modal
      runInAction(() => {
        this.scheduleAvailabilitySchemaToEdit = this.availabilitySchemas.find(
          (schema) => schema?.name === newSchema?.name
        );

        this.isCreatingNewScheduleAvailabilitySchema = false;
      });
    } catch (error) {
      console.log(error);
    }
  }

  setSchemaToEditWithEventPreferenceAvailabilities = (eventPreference) => {
    const newSchemaObject = {
      id: uuidv4(),
      scheduleAvailabilities: eventPreference?.scheduleAvailabilities,
      eventpreferencesSet: [{ ...eventPreference }],
      timeZone: this.timeZone,
      isCopyOfEventPreferenceAvailabilities: true,
    };
    runInAction(() => {
      this.scheduleAvailabilitySchemaToEdit = new ScheduleAvailabilitySchema(
        newSchemaObject
      );
    });
  };

  deleteEventConfigAndRelatedEventPreference(
    configurationId,
    relatedEventPreferenceId
  ) {
    if (this?.allUserEventConfigurations?.length > 0) {
      runInAction(() => {
        this.allUserEventConfigurations =
          this.allUserEventConfigurations.filter(
            (config) => config.id !== configurationId
          );
      });
    }

    if (this?.eventPreferences?.length > 0) {
      runInAction(() => {
        this.eventPreferences = this.eventPreferences.filter(
          (event) => event.id !== relatedEventPreferenceId
        );
      });
    }

    if (this?.defaultAvailabilitySchema?.id) {
      runInAction(() => {
        const updatedDefaultSchema =
          this.defaultAvailabilitySchema.eventPreferencesSet.filter(
            (event) => event.id !== relatedEventPreferenceId
          );
        this.defaultAvailabilitySchema.eventPreferencesSet =
          new ScheduleAvailabilitySchema(updatedDefaultSchema);
      });
    }
    if (this?.availabilitySchemas?.length > 0) {
      runInAction(() => {
        this.availabilitySchemas = this.availabilitySchemas.map((schema) => {
          schema.eventPreferencesSet = schema.eventPreferencesSet.filter(
            (event) => event.id !== relatedEventPreferenceId
          );
          return new ScheduleAvailabilitySchema(schema);
        });
      });
    }
  }

  async getEventPreferenceForUser(userId, eventConfigurationsId) {
    try {
      const calendarUserId = userId || this.userRouterId;

      const newEventPreferences =
        await agent.Calendar.getEventPreferenceForUser(calendarUserId, [
          eventConfigurationsId,
        ]);
      if (newEventPreferences.length === 0) return;
      const isEventPreferenceExist = this.eventPreferences.some(
        (eventPreference) => eventPreference.id === newEventPreferences[0]?.id
      );

      if (!isEventPreferenceExist) {
        runInAction(() => {
          this.allUserEventConfigurations = listActiveEventConfigurations([
            ...this.eventPreferences,
            ...newEventPreferences,
          ]).map(
            (eventConfiguration) => new EventConfiguration(eventConfiguration)
          );
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
}

decorate(CalendarUser, {
  id: observable,
  userRouterId: observable,
  email: observable,
  firstName: observable,
  lastName: observable,
  isCalendarAuthenticated: observable,
  blockedStatuses: observable,
  excludedDays: observable,
  calendar: observable,
  timeZone: observable,
  teams: observable,
  eventPreferences: observable,
  defaultAvailabilitySchema: observable,
  availabilitySchemas: observable,
  allUserEventConfigurations: observable,
  spokenLanguages: observable,
  isCreatingNewScheduleAvailabilitySchema: observable,
  scheduleAvailabilitySchemaToEdit: observable,
  isDefaultSchemaUpdated: observable,
  areActiveConfigsChangedInLinkedCalendarTab: observable,
  authenticateCalendar: action,
  updateCalendarUser: action,
  createScheduleAvailabilitySchema: action,
  setSchemaToEditWithEventPreferenceAvailabilities: action,
  deleteEventConfigAndRelatedEventPreference: action,
  getEventPreferenceForUser: action,
});

export default CalendarUser;
