import agent from 'agent';
import { action, decorate, observable, runInAction } from 'mobx';
import { userStore } from 'stores';
import AtsEntityType from 'stores/AtsEntityType.store';
import BaseStore from 'stores/BaseStore';
import { queryOperators } from 'utils/queryBuilder.uitls';
import ConditionStore from './Condition.store';
import QueryStatementStore from './QueryStatement.store';

class QueryStore extends BaseStore {
  id = '';
  queryStatements = [];
  baseAtsEntityType = {};
  targetHandler = '';
  loadingConditions = false;
  allQueryConditions = [];

  constructor(query) {
    super();
    if (query) {
      this.id = query.id;
      this.queryStatements = query.queryStatements?.map(
        (statement) => new QueryStatementStore(statement)
      );
      this.baseAtsEntityType = new AtsEntityType(query.baseAtsEntityType);
      this.targetHandler = query.targetHandler;
    }
  }

  async addNewQueryStatement(targetAtsFieldId, selectedAtsEntityTypeId = null) {
    const { selectedChatbot } = userStore;

    const {
      data: { autoCreateAtsFieldMapping },
    } = await selectedChatbot.createAtsFieldMapping(
      null,
      targetAtsFieldId,
      selectedAtsEntityTypeId || this.baseAtsEntityType.id,
      this.targetHandler
    );

    if (autoCreateAtsFieldMapping.ok) {
      // create query statement object
      const { atsFieldMapping } = autoCreateAtsFieldMapping;
      const targetDataType =
        atsFieldMapping.atsFields[atsFieldMapping.atsFields.length - 1]
          .dataType;

      const queryStatement = {
        order: this.queryStatements.length,
        condition: this.allQueryConditions.find(({ allowedDataTypes }) =>
          allowedDataTypes.includes(targetDataType.toLowerCase())
        )?.id,
        negate: false,
        operator: queryOperators.AND,
        value: '',
        atsFieldMapping: atsFieldMapping.id,
      };
      const {
        ok,
        querystatement,
      } = await agent.Query.createQueryStatement(
        queryStatement
      );

      if (ok) {
        runInAction(() => {
          this.queryStatements.push(new QueryStatementStore(querystatement));
        });
      }
      const success = await this.updateTargetQuery();

      if (!success) {
        this.removeQueryStatement(querystatement.id);
      }

      return success;
    }
  }

  async updateTargetQuery() {
    const QueryObject = {
      id: this.id,
      queryStatements: this.queryStatements,
      baseAtsEntityType: this.baseAtsEntityType,
      targetHandler: this.targetHandler,
    };
    const serializedQuery = this.serializeInstance(QueryObject);

    const { ok } = await agent.Query.updateQuery(serializedQuery);
    return ok;
  }

  async removeQueryStatement(statementId) {
    const { ok } = await agent.Query.deleteQueryStatement(statementId);

    if (ok) {
      runInAction(() => {
        this.queryStatements = this.queryStatements.filter(
          ({ id }) => id !== statementId
        );
      });
    }
    return ok;
  }

  async fetchAllQueryConditions() {
    if (this.allQueryConditions.length && !this.loadingConditions) return;
    runInAction(() => {
      this.loadingConditions = true;
    });
    const conditions = await agent.Broadcast.getAllQueryConditions(
      this.targetHandler.toLowerCase()
    );
    if (conditions.length) {
      runInAction(() => {
        this.allQueryConditions = conditions.map(
          (condition) => new ConditionStore(condition)
        );
        this.loadingConditions = false;
      });
    }
  }
}

decorate(QueryStore, {
  id: observable,
  queryStatements: observable,
  loadingConditions: observable,
  allQueryConditions: observable,
  updateTargetQuery: action,
  fetchAllQueryConditions: action,
  addNewQueryStatement: action,
  removeQueryStatement: action,
});

export default QueryStore;
