import requests from './requests';

const queryWidgetPreview = `
  {
    widgetSettings {
      botAvatar
    }
    widgetPreview {
      text
      autoOpenTime
      widgetIconStartFlow
      buttons {
        flow
        text
      }
      desktopBotAvatar {
        botAvatar
        isRectangle
      }
      mobileBotAvatar {
        botAvatar
        isRectangle
      }
    }
}
`;

const mutateWidgetPreview = `
  mutation UpdateWidgetPreview($text: String, $autoOpenTime: Float,
    $buttons: [ButtonInput], $desktopBotAvatar: BotAvatarInput, $widgetIconStartFlow: String) {
      updateWidgetPreview(text: $text, autoOpenTime: $autoOpenTime, buttons: $buttons, 
      desktopBotAvatar: $desktopBotAvatar, widgetIconStartFlow: $widgetIconStartFlow) {
        ok
      }
  }
`;

const WidgetPreview = {
  get: async () => {
    const res = await requests.gql.chatserver.query(queryWidgetPreview, null);
    return res.data;
  },
  put: async (body) => {
    const variables = {
      buttons: body.buttons,
      text: body.text,
      autoOpenTime: body.autoOpenTime,
      desktopBotAvatar: {
        isRectangle: body.isRectangle,
      },
      widgetIconStartFlow: body.widgetIconStartFlow,
    };
    return requests.gql.chatserver.mutate(mutateWidgetPreview, variables);
  },
};

export default WidgetPreview;
