import { observable, action, decorate } from 'mobx'
import BaseStore from '../BaseStore'

class DashboardStore extends BaseStore {
  isSupportTicketVisible = false

  toggleSupportTicketVisibility() {
    this.isSupportTicketVisible = !this.isSupportTicketVisible
  }
}

decorate(DashboardStore, {
  isSupportTicketVisible: observable,
  toggleSupportTicketVisibility: action,
})
export default DashboardStore
