import agent from 'agent';
import { DEFAULT_ATS_FIELDS_HANDLER } from 'constants/campaign.constants';
import _ from 'lodash';
import {
  action,
  autorun,
  computed,
  decorate,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { languageStore } from 'stores';
import AtsField from 'stores/AtsField.Store';
import GroupAppointmentsStore from 'stores/CalendarAvailability/GroupAppointments.store';
import EmailTemplateStore from 'stores/EmailTemplateStore';
import FacebookLeads from 'stores/FacebookLeads.store';
import GoogleAnalyticsEventStore from 'stores/GoogleAnalyticsEventStore';
import MapperStore from 'stores/MapperStore';
import OptionStore from 'stores/OptionStore';
import { transformOption } from '../utils/builderUtils';
import { LABELS_DEFAULT_COLORS } from '../utils/constants/labelsDefaultColors';
import ActionResults from './ActionResult/ActionResults.store';
import TagStore from './ActionStore/TagStore';
import AnalyticsStore from './AnalyticsStore';
import AtsEntityType from './AtsEntityType.store';
import AtsFieldMapping from './AtsFieldMapping.store';
import BaseStore from './BaseStore';
import ChatbotSettingStore from './ChatbotSettingStore/ChatbotSettingStore';
import CustomAnalyticsStore from './CustomAnalytics/CustomAnalytics.store';
import flows from './Flows';
import FlowStore from './Flows/Flow.store';
import InboxCompany from './InboxCompany.store';
import InboxLabel from './InboxLabelStore';
import Modifier from './ModifierStore';
import NLP from './NLP.store';
import Processor from './ProcessorStore';
import ScalarNameStore from './ScalarNameStore';
import TagsStore from './Tags/TagsStore.store';
import ValidatorStore from './ValidatorStore';
import WidgetSettingsStore from './WidgetSettingsStore';
import WizardStore from './WizardStore';
import Campaigns from './campaigns/Campaigns.store';
import FollowUps from './campaigns/FollowUps.store';
import WhatsAppChannels from './campaigns/WhatsAppChannel/WhatsAppChannels.store';
import WhatsAppDefinitions from './campaigns/WhatsAppDefinition/WhatsAppDefinitions.store';
import WhatsAppButtons from './campaigns/WhatsAppTemplate/Variation/Button/WhatsAppButtons.store';
import WhatsAppParameters from './campaigns/WhatsAppTemplate/Variation/Parameter/WhatsAppParameters.store';
import WhatsAppVariations from './campaigns/WhatsAppTemplate/Variation/WhatsAppVariations.store';
import WhatsAppTemplates from './campaigns/WhatsAppTemplate/WhatsAppTemplates.store';

const MAX_STATE_HISTORY = 5;

class Chatbot extends BaseStore {
  id = '';
  name = '';
  useBetaPortal = false;
  serverAddress = '';
  projectId = '';
  disallowDisableEventPreference = false;
  smartInboxEnabled = false;
  ats = '';
  atsSpecificTemplatesEnvironment = '';
  token = '';
  selectedStateGroupName = '';
  flows = [];
  questionsAndAnswersFlow = null;
  selectedStateID = null;
  isLoading = false;
  allTags = [];
  allAtsFieldMappings = [];
  scalarNames = [];
  allScalarNamesWithAtsFieldMapping = [];
  allScalarNamesWithoutAtsFieldMapping = [];
  allProcessors = [];
  allNormalProcessors = [];
  allAutomationProcessors = [];
  allValidators = [];
  allReplyProcessors = [];
  allowedCategories = [];
  // All the processors which should be displayed in the add processor side drawer
  processorTemplates = [];
  // Used to store all the normal processors which are used in the flow
  normalProcessorTemplates = [];
  // Used to store all the automation processors (on fail / on finish)
  automationProcessorTemplates = [];
  googleAnalyticsEvents = [];
  mappers = [];
  emailTemplates = [];
  defaultLanguageTag = null;
  startFlowStore = null;
  restartFlowStore = null;
  welcomeBackFlowStore = null;
  endFlowStore = null;
  escapeFlowStore = null;
  fallbackFlowStore = null;
  broadcastJobAlertFlowStore = null;
  outOfOfficeFlowStore = null;
  defaultFlowStore = null;
  stateHistory = [];
  flowNames = [];
  deleted;
  selectedStateIsStatic = false;
  nlp = new NLP();
  campaigns = new Campaigns();
  whatsAppChannels = new WhatsAppChannels();
  whatsAppDefinitions = new WhatsAppDefinitions();
  whatsAppTemplates = new WhatsAppTemplates();
  whatsAppVariations = new WhatsAppVariations({});
  whatsAppButtons = new WhatsAppButtons({});
  whatsAppParameters = new WhatsAppParameters({});
  validators = [];
  groupAppointments = new GroupAppointmentsStore();
  facebookLeads = new FacebookLeads();
  mockMapper = null;
  wizardStore = null;
  customAnalytics = new CustomAnalyticsStore();
  flowToRender = null;
  tagsStore = new TagsStore();
  inboxCompanies = [];
  suggestedInboxCompany = null;
  actionResultsStore = new ActionResults();
  processorStore = new Processor();
  followUpsStore = new FollowUps();
  allDivergentOptionTemplates = [];
  allStateGroups = [];
  loadingStateGroups = false;
  loadingProcessors = false;
  allInboxLabels = [];
  creatableAtsEntityTypes = [];
  loadingCreatableAtsFields = false;
  supportsMappingCreation;
  supportsNestedWhereQueries = false;
  loadingProcessorsTemplates = false;
  loadingEditProcessorData = false;
  loadingConditions = false;
  allModifiers = [];
  atsFieldMappingToClone = null;
  whatsappProvider = null;
  isChatbotLoading = false;
  isFlowsLoading = false;

  @observable analyticsStore = null;

  @observable settingsStore = null;

  @observable chatbotSettingStore = null;

  // Widget Settings
  @observable widgetIcon = null;

  constructor(args) {
    super();

    if (!args.id) {
      this.name = args.name;
      return;
    }

    this.id = args.id;
    this.name = args.name;
    this.useBetaPortal = args.useBetaPortal;
    this.serverAddress = args.serverAddress;
    this.projectId = args.projectId;
    this.token = args.token;
    this.analyticsStore = new AnalyticsStore(this.serverAddress, this.name);
    this.settingsStore = new WidgetSettingsStore(this.id, this.name);
    this.chatbotSettingStore = new ChatbotSettingStore();
    this.deleted = args.deleted;

    autorun(() => {
      const state = this.selectedState;
      if (state) {
        state.reset();
      }
    });
  }

  get allFlows() {
    const allStaticFlows = [
      this.startFlowStore,
      this.restartFlowStore,
      this.escapeFlowStore,
      this.endFlowStore,
      this.welcomeBackFlowStore,
      this.fallbackFlowStore,
      this.defaultFlowStore,
      this.broadcastJobAlertFlowStore,
      this.outOfOfficeFlowStore,
    ];
    const filteredFlows = allStaticFlows
      .filter((staticFlow) => staticFlow)
      .map((staticFlow) => staticFlow.flow)
      .filter((flow) => flow);
    return this.flows.concat(filteredFlows);
  }

  get selectedFlow() {
    return this.findFlowFromState(this.selectedStateID);
  }

  get selectedStateGroup() {
    return this.findStateGroupFromState(this.selectedStateID);
  }

  get stateMachineProcessors() {
    return this.allProcessors?.filter(
      (processor) => processor?.processorType !== 'STATE_PROCESSOR'
    );
  }

  async fetchInboxCompanies() {
    const response = await agent.SmartInbox.allInboxCompanies(
      this.name,
      this.ats
    );
    const { inboxCompanies } = response;

    runInAction(() => {
      this.inboxCompanies = inboxCompanies?.map(
        (inboxCompanies) => new InboxCompany(inboxCompanies)
      );
      this.suggestedInboxCompany = response.suggestion;
    });
  }

  selectFlowById(flowId) {
    this.flowToRender = this.allFlows.find((flow) => flow.id === flowId);
  }

  findStateGroupFromState(stateID) {
    const selectedFlow = this.findFlowFromState(stateID);
    if (!selectedFlow) return;
    const stateGroups = selectedFlow.stateGroups.map((f) => toJS(f));
    const stateGroup = _.find(stateGroups, { states: [{ id: stateID }] });

    runInAction(() => {
      this.selectedStateGroupName = stateGroup?.name;
    });
    if (!stateGroup) {
      return _.find(stateGroups, { initialState: [{ id: stateID }] });
    }
    return stateGroup;
  }

  async fetchChatserverChatbot() {
    this.isChatbotLoading = true;
    try {
      const chatserverChatbot = await agent.User.getChatbot();

      // Something went wrong fetching the chatbot; we won't proceed
      if (!chatserverChatbot) return;

      runInAction(() => {
        this.whatsappProvider = chatserverChatbot.mainChatbot.whatsappProvider;
        this.disallowDisableEventPreference =
          chatserverChatbot.mainChatbot.disallowDisableEventPreference;
        this.smartInboxEnabled =
          chatserverChatbot.mainChatbot.smartInboxEnabled;
        this.ats = chatserverChatbot.mainChatbot.ats;
        this.atsSpecificTemplatesEnvironment =
          chatserverChatbot.mainChatbot.atsSpecificTemplatesEnvironment;
        this.defaultLanguageTag =
          chatserverChatbot.mainChatbot.defaultLanguageTag;
        this.allTags = chatserverChatbot.allTags;
        this.allowedCategories =
          chatserverChatbot.mainChatbot.allowedCategories;
        languageStore.setDefaultLanguage(
          chatserverChatbot.mainChatbot.defaultLanguageTag.content
        );
        this.supportsMappingCreation =
          chatserverChatbot.mainChatbot?.supportsMappingCreation;
        this.supportsNestedWhereQueries =
          chatserverChatbot.mainChatbot.supportsNestedWhereQueries;
      });
    } catch (error) {
      console.error('Error fetching chatbot data:', error);
    } finally {
      runInAction(() => {
        this.isChatbotLoading = false;
      });
    }
  }

  findFlowFromState(stateID) {
    const temp = this.allFlows.map((f) => toJS(f));
    let flowObject = _.find(temp, {
      stateGroups: [{ states: [{ id: stateID }] }],
    });
    if (!flowObject) {
      flowObject = _.find(temp, {
        stateGroups: [{ initialState: { id: stateID } }],
      });
    }
    if (flowObject) return _.find(this.allFlows, { id: flowObject.id });
    return null;
  }

  get selectedState() {
    const allFlows = this.questionsAndAnswersFlow
      ? [...this.allFlows, this.questionsAndAnswersFlow]
      : this.allFlows;
    const stateGroups = _.flattenDeep(
      _.map(allFlows, (flow) =>
        _.map(flow.stateGroups, (stateGroup) => stateGroup)
      )
    );
    const states = _.flattenDeep(
      _.map(stateGroups, (stateGroup) =>
        _.map(stateGroup.states, (state) => state)
      )
    );
    const state = states.filter((s) => s.id === this.selectedStateID)[0];
    if (state) {
      return state;
    }
    return undefined;
  }

  get hasFlows() {
    return (
      this.allFlows.length !== 0 && this.allFlows.length > this.flows.length
    );
  }

  selectState(id, isStatic = false) {
    this.selectedStateID = id;
    this.selectedStateIsStatic = isStatic;
    this.addStateToHistory(this.selectedState);
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  async loadAllProcessors(isAutomation = false) {
    if (this.loadingProcessors) return;

    // Check if we already have the processors cached, if so we use these to
    // safe a request to the backend
    if (isAutomation && this.allAutomationProcessors.length) {
      runInAction(() => {
        this.allProcessors = this.allAutomationProcessors;
      });
      return;
    }
    if (!isAutomation && this.allNormalProcessors.length) {
      runInAction(() => {
        this.allProcessors = this.allNormalProcessors;
      });
      return;
    }

    runInAction(() => {
      this.loadingProcessors = true;
    });

    const processorTypeFilter = isAutomation
      ? 'on_finish_processor, on_fail_processor'
      : '';
    const processorTypeExclusionFilter = isAutomation
      ? ''
      : 'on_finish_processor, on_fail_processor';

    const chatbot = await agent.Processor.getAllProcessors(
      processorTypeFilter,
      processorTypeExclusionFilter
    );

    runInAction(() => {
      this.allProcessors = chatbot.data.allProcessors?.map(
        (processor) => new Processor(processor)
      );

      if (isAutomation) {
        this.allAutomationProcessors = this.allProcessors;
      } else {
        this.allNormalProcessors = this.allProcessors;
      }

      this.loadingProcessors = false;
    });
  }

  async fetchOneProcessor(id) {
    const matchedProcessor = this.allProcessors?.find(
      (processor) => processor.id === id
    );

    if (matchedProcessor) return matchedProcessor;

    const { data } = await agent.Processor.fetchOne(id);

    let processor;
    runInAction(() => {
      processor = new Processor(data?.processor);
      this.allProcessors?.push(processor);
    });

    return processor;
  }

  async loadFlows() {
    if (this.hasFlows) return;
    this.isFlowsLoading = true;
    // Get the chatbot
    const chatbot = await agent.Chatbot.get(this.serverAddress);
    runInAction(() => {
      this.flows = chatbot.flows.map((flow) => new FlowStore(flow));
      this.allTags = chatbot.allTags.map((tag) => new TagStore(tag));
      this.allValidators = chatbot.allValidators.map(
        (validator) => new ValidatorStore(validator)
      );
      this.allReplyProcessors = chatbot.allReplyProcessors;

      // Setup the default language tag of this chatbot
      this.defaultLanguageTag = _.find(this.allTags, {
        id: chatbot.defaultLanguageTag.id,
      });
      this.allowedCategories = chatbot.allowedCategories;
      // Setup the static flows
      this.startFlowStore = new flows.StartFlowStore(chatbot.startStateMachine);
      this.restartFlowStore = new flows.RestartFlowStore(
        chatbot.restartStateMachine
      );
      this.escapeFlowStore = new flows.EscapeFlowStore(
        chatbot.escapeStateMachine
      );
      this.endFlowStore = new flows.EndFlowStore(chatbot.endStateMachine);
      this.welcomeBackFlowStore = new flows.WelcomeBackStore(
        chatbot.welcomeBackStateMachine
      );
      this.fallbackFlowStore = new flows.FallbackFlowStore(
        chatbot.fallbackStateMachine
      );
      this.defaultFlowStore = new flows.DefaultFlowStore(
        chatbot.defaultStateMachine
      );
      this.broadcastJobAlertFlowStore = new flows.BroadcastJobAlertFlowStore(
        chatbot.broadcastJobAlert
      );
      this.outOfOfficeFlowStore = new flows.OutOfOfficeFlowStore(
        chatbot.outOfOfficeStateMachine
      );
      // Load widget icon
      this.widgetIcon = chatbot.widgetSettings.widgetIcon;

      if (this.defaultFlowStore.flow) {
        this.setAttr(
          'questionsAndAnswersFlow',
          new FlowStore(this.defaultFlowStore.flow)
        );
      }
      this.isFlowsLoading = false;
    });
  }

  async loadScalarNames() {
    if (this.scalarNames.length) return;
    const chatbot = await agent.Chatbot.getScalarNames(this.serverAddress);

    runInAction(() => {
      this.scalarNames = chatbot?.scalarNames?.map(
        (scalarName) => new ScalarNameStore(scalarName)
      );

      this.allScalarNamesWithAtsFieldMapping = this.scalarNames?.filter(
        (scalarName) =>
          scalarName.atsFieldMapping?.length > 0 && scalarName?.name !== ''
      );

      this.allScalarNamesWithoutAtsFieldMapping = this.scalarNames?.filter(
        (scalarName) =>
          !scalarName.atsFieldMapping?.length && scalarName?.name !== ''
      );
    });
  }

  async loadAllStateGroups() {
    // If data is already loaded, do not re-fetch
    if (this.allStateGroups.length > 0 || this.loadingStateGroups) return;

    this.loadingStateGroups = true;
    try {
      const {
        data: { stateGroups },
      } = await agent.StateGroup.all();
      runInAction(() => {
        this.allStateGroups = stateGroups;
        this.loadingStateGroups = false;
      });
    } catch (error) {
      console.error('couldn\'t fetch state groups', error);
      runInAction(() => {
        this.loadingStateGroups = false;
      });
    }
  }

  fetchScalarNames(isQA = false) {
    this.setIsLoading(true);
    this.loadScalarNames(isQA).then(() => {
      this.setIsLoading(false);
    });
  }

  getProcessorTemplateData(processorTemplates) {
    return processorTemplates.map((template) => ({
      id: template.id,
      name: template.name,
      icon: template.icon,
      description: template.description,
      processor: new Processor(template.processor),
    }));
  }

  async loadTemplates(isAutomation = false) {
    // Check if we already have the templates cached, if so we use these to
    // safe a request to the backend
    if (isAutomation && this.automationProcessorTemplates.length) {
      runInAction(
        () => (this.processorTemplates = this.automationProcessorTemplates)
      );
      return;
    }
    if (!isAutomation && this.normalProcessorTemplates.length) {
      runInAction(
        () => (this.processorTemplates = this.normalProcessorTemplates)
      );
      return;
    }

    if (this.name) {
      runInAction(() => {
        this.loadingProcessorsTemplates = true;
      });

      const processorTypeFilter = isAutomation
        ? 'on_finish_processor, on_fail_processor'
        : '';
      const processorTypeExclusionFilter = isAutomation
        ? ''
        : 'on_finish_processor, on_fail_processor';

      const { data } = await agent.ProcessorTemplate.all(
        processorTypeFilter,
        processorTypeExclusionFilter
      );

      runInAction(() => {
        this.processorTemplates = this.getProcessorTemplateData(
          data.allProcessorTemplates
        );

        if (isAutomation) {
          this.automationProcessorTemplates = this.processorTemplates;
        } else {
          this.normalProcessorTemplates = this.processorTemplates;
        }

        this.loadingProcessorsTemplates = false;
      });
    }
  }

  async loadEmailTemplates() {
    if (this.name) {
      const { data } = await agent.EmailTemplate.all();
      runInAction(() => {
        this.emailTemplates = data.allEmailTemplates.map(
          (template) => new EmailTemplateStore(template)
        );
      });
    }
  }

  async loadGoogleAnalyticsEvents() {
    if (this.name) {
      const { data } = await agent.GoogleAnalyticsEvent.all();
      runInAction(() => {
        this.googleAnalyticsEvents = data.allGoogleAnalyticsEvents.map(
          (event) => new GoogleAnalyticsEventStore(event)
        );
      });
    }
  }

  async loadValidators() {
    if (this.name) {
      const { data } = await agent.Validator.all();
      runInAction(() => {
        this.validators = data.allValidatorSystemTests.map((validator) =>
          JSON.parse(validator)
        );
      });
    }
  }

  async loadMappers() {
    if (this.name) {
      const { data } = await agent.Mapper.all();
      runInAction(() => {
        this.mappers = data.allMappers.map((mapper) => new MapperStore(mapper));
      });
    }
  }

  async loadCampaigns() {
    if (this.name) {
      this.campaigns.fetch().then();
    }
  }

  async loadMockMapper(id) {
    if (this.name) {
      const { data } = await agent.Mapper.fetchBluePrint(id);
      this.mockMapper = data.generateBlueprintForStatemachine;
    }
  }

  async loadWizard() {
    if (this.name) {
      const graphQlServer = `https://${this.serverAddress}/graphql`;
      const { data } = await agent.Wizard.all(this.token, graphQlServer);
      runInAction(() => {
        if (data?.allWizards?.length > 0)
          this.wizardStore = new WizardStore(data.allWizards[0]);
      });
    }
  }

  selectFirstState(isQA) {
    if (isQA && this.defaultFlowStore) {
      if (this.defaultFlowStore) {
        const firstStateGroup = _.first(this.defaultFlowStore.flow.stateGroups);
        const firstState = _.first(firstStateGroup.states);
        return firstState;
      }
    } else if (this.startFlowStore) {
      const firstFlow = this.startFlowStore.flow;
      const firstStateGroup = _.first(firstFlow.stateGroups);
      const firstState = _.first(firstStateGroup.states);
      return firstState;
    }

    return null;
  }

  async loadAllAtsFieldMappings(isDefaultMapping) {
    if (this.allAtsFieldMappings.length) return;
    const { data } = await agent.AllAtsFieldMappings.get(isDefaultMapping);
    if (data?.allAtsFieldMappings?.length) {
      runInAction(() => {
        this.allAtsFieldMappings = data?.allAtsFieldMappings?.map(
          (atsField) => new AtsFieldMapping(atsField)
        );
      });
    }
  }

  async getAllInboxLabels() {
    if (this.allInboxLabels.length) return;
    const response = await agent.Chatbot.getAllLabels();
    if (response.data.allLabels) {
      runInAction(() => {
        this.allInboxLabels = response?.data?.allLabels?.map(
          (label) => new InboxLabel(label)
        );
      });
    }
  }

  async createSmartInboxLabel(labelName) {
    const newLabel = {
      name: labelName,
      color:
        LABELS_DEFAULT_COLORS[
          Math.floor(Math.random() * LABELS_DEFAULT_COLORS.length)
        ],
    };
    const {
      ok,
      inboxlabel,
    } = await agent.InboxLabel.createInboxLabel(
      newLabel
    );
    let newlyCreatedLabel = {};

    if (ok) {
      newlyCreatedLabel = new InboxLabel(inboxlabel);
      runInAction(() => {
        this.allInboxLabels.push(newlyCreatedLabel);
      });
      return newlyCreatedLabel;
    }
  }

  checkIfLabelExistsAlready(labelName) {
    return this.allInboxLabels.find(({ name }) => name === labelName);
  }

  reset() {
    // Clean the previous data
    this.flows = [];
    this.questionsAndAnswersFlow = null;
    this.selectedStateID = null;
    this.stateHistory = [];

    this.startFlowStore = null;
    this.restartFlowStore = null;
    this.welcomeBackFlowStore = null;
    this.endFlowStore = null;
    this.escapeFlowStore = null;
    this.fallbackFlowStore = null;
    this.broadcastJobAlertFlowStore = null;
    this.defaultFlowStore = null;
  }

  async addFlow(flowName) {
    const flow = new FlowStore({
      id: '',
      name: flowName,
    });
    this.flows.push(flow);
    // Send to the backend and get the ID of the new created flow
    const res = await agent.Flow.add(flowName);
    const ok = _.get(res, 'data.createStateMachine.ok');
    if (!ok) throw Error('Could not add flow');
    const flowFromRes = _.get(res, 'data.createStateMachine.stateMachine');
    runInAction(() => {
      flow.id = flowFromRes.id;
    });
  }

  async deleteFlow(flowId) {
    _.remove(this.flows, (flow) => flow.id === flowId);
    // Delete from the backend
    const res = await agent.Flow.delete(flowId);
    const ok = _.get(res, 'data.deleteStateMachine.ok');
    if (!ok) throw Error('Could not delete flow');
  }

  addStateToHistory(state) {
    if (this.stateHistory.length === MAX_STATE_HISTORY) {
      this.stateHistory.pop();
    }
    this.stateHistory.unshift(state);
  }

  dropScalarNameFromList(id) {
    _.remove(this.scalarNames, { id });
  }

  async fixChatBot(projectId) {
    await agent.Chatbot.fix(projectId);
  }

  async recoverChatBot(projectId) {
    await agent.Chatbot.recover(projectId);
  }

  // TODO: We need to check if this function actually does anything
  getUpdatedProcessors(processors) {
    runInAction(() => {
      const { allProcessors } = processors;
      this.allProcessors = allProcessors.map(
        (processor) => new Processor(processor)
      );
    });
    return this.allProcessors;
  }

  async fetchBasicFlowsInfo() {
    if (this.flows.length) return;
    const allFlows = await agent.Chatbot.listFlowsBasicData();

    runInAction(() => {
      this.flows = allFlows.map((flow) => new FlowStore(flow));
    });
  }

  async allFlowNames() {
    const allFlowNames = await agent.Chatbot.flowNames();
    runInAction(() => {
      this.flowNames = allFlowNames.map((flow) => new FlowStore(flow));
    });
  }

  async createGoogleAnalyticsEvent(systemName, eventAction, eventLabel) {
    const { data } = await agent.GoogleAnalyticsEvent.create({
      newGoogleanalyticsevent: {
        systemName,
        eventAction,
        eventLabel,
      },
    });
    await this.loadGoogleAnalyticsEvents();

    return data.createGoogleAnalyticsEvent.googleanalyticsevent;
  }

  async deleteGoogleAnalyticsEvent(id) {
    const { data } = await agent.GoogleAnalyticsEvent.delete(id);
    await this.loadGoogleAnalyticsEvents();
  }

  async runValidatorTests(object) {
    const data = {
      validatorTestFormat: JSON.stringify(object),
    };
    await agent.Validator.run(data);
  }

  async cloneFlow(flowId, originClient, destinationClient, newFlowName) {
    const tempId = Date.now();

    if (originClient === destinationClient) {
      runInAction(() => {
        this.flows.push(
          new FlowStore({
            id: tempId,
            name: newFlowName,
            isCreating: true,
          })
        );
      });
    }

    const data = {
      flowId,
      originClient,
      destinationClient,
      newFlowName,
    };
    const result = await agent.Flow.clone(data);

    const ok = _.get(result, 'data.cloneFlow.ok');

    if (originClient !== destinationClient) return ok;
    if (!ok) {
      const flowIndex = this.flows.findIndex((flow) => flow.id === tempId);
      runInAction(() => this.flows.splice(flowIndex, 1));
      return ok;
    }

    const flowData = _.get(result, 'data.cloneFlow.flow');

    const flowIndex = this.flows.findIndex((flow) => flow.id === tempId);

    runInAction(() => {
      this.flows[flowIndex].setup(flowData);
    });

    return ok;
  }

  async configureInboxCompany(inboxCompanyIdentifier) {
    const res = await agent.SmartInbox.configure(
      this.id,
      inboxCompanyIdentifier,
      this.ats
    );
    return res.ok;
  }

  async autoConfigureSmartInbox(username = '', password = '') {
    const res = await agent.SmartInbox.autoConfigure(username, password);
    return res.ok;
  }

  async syncTemplateVariables() {
    const { data } = await agent.AtsFieldMapping.syncTemplateVariables();
    return data.syncAtsField.ok;
  }

  async loadDivergentOptionTemplates() {
    const { allDivergentOptionTemplates } =
      await agent.Option.getDivergentOptionsTemplate();

    if (allDivergentOptionTemplates.length) {
      runInAction(() => {
        this.allDivergentOptionTemplates = allDivergentOptionTemplates.map(
          (divergentOptionTemplate) =>
            new OptionStore(transformOption(divergentOptionTemplate))
        );
      });
    }
  }

  async fetchAllSupportedEntities(targetHandler = 'ats') {
    const { data } = await agent.AllAtsFieldMappings.getSupportedEntities(
      targetHandler
    );
    runInAction(() => {
      this.creatableAtsEntityTypes = data?.creatableAtsEntities?.map(
        (entity) => new AtsEntityType(entity)
      );
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async fetchCreatableAtsFields({
    entityTypeId,
    search,
    page = 1,
    targetHandler = DEFAULT_ATS_FIELDS_HANDLER,
  }) {
    const res = await agent.AllAtsFieldMappings.getCreatableAtsFields({
      entityTypeId,
      search,
      pageSize: 20,
      page,
      targetHandler,
    });

    const { data } = res;
    if (data.creatableAtsFieldsOptimized) {
      let newAtsFields = [];
      runInAction(() => {
        newAtsFields = data?.creatableAtsFieldsOptimized?.atsFields?.map(
          (atsField) => new AtsField(atsField)
        );
      });
      return {
        hasNextPage: data?.creatableAtsFieldsOptimized?.hasNextPage,
        atsFields: newAtsFields,
        ok: true,
      };
    }
    return { ok: false };
  }

  async deleteScalarName(scalarNameId) {
    agent.ScalarName.delete(scalarNameId);

    runInAction(() => {
      this.scalarNames = this.scalarNames.filter(
        ({ id }) => id !== scalarNameId
      );
      this.allScalarNamesWithAtsFieldMapping =
        this.allScalarNamesWithAtsFieldMapping.filter(
          ({ id }) => id !== scalarNameId
        );
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, class-methods-use-this
  async createAtsFieldMapping(
    scalarNameId,
    atsFieldId,
    atsEntityTypeId,
    targetHandler
  ) {
    const newAtsFieldMappingResponse =
      await agent.AllAtsFieldMappings.autoCreateAtsFieldMapping(
        scalarNameId,
        atsFieldId,
        atsEntityTypeId,
        targetHandler || DEFAULT_ATS_FIELDS_HANDLER
      );
    return newAtsFieldMappingResponse;
  }

  // create new scalarName
  async createAtsFieldMappingForAtsField(data, atsFieldId, atsEntityTypeId) {
    const res = await agent.ScalarName.create(data);
    const {
      ok,
      scalarname,
    } = res.data.createScalarNameMutation;
    if (!ok) throw Error('Can not create scalar name');
    const scalarNameId = scalarname?.id;
    // here we create autoAtsFieldMapping
    const newAtsFieldMappingResponse = await this.createAtsFieldMapping(
      scalarNameId,
      atsFieldId,
      atsEntityTypeId
    );

    runInAction(() => {
      // if went wrong we delete the created scalarName
      if (!newAtsFieldMappingResponse?.data) {
        this.deleteScalarName(scalarname?.id);
      } else {
        // otherwise we push the new scalarName with atsFieldMapping to this.allScalarNamesWithAtsFieldMapping
        const { atsFieldMapping } =
          newAtsFieldMappingResponse?.data?.autoCreateAtsFieldMapping;
        const newScalarName = {
          ...scalarname,
          atsFieldMappings: [atsFieldMapping],
        };
        this.atsFieldMappingToClone = atsFieldMapping;
        this.scalarNames.push(new ScalarNameStore(newScalarName));
        this.allScalarNamesWithAtsFieldMapping.push(
          new ScalarNameStore(newScalarName)
        );
      }
    });
  }

  // create new translation
  async addNewTranslationToAtsField(
    newTranslation,
    scalarName,
    atsFieldId,
    atsEntityTypeId
  ) {
    try {
      const {
        ok,
        translation,
      } = await agent.Translation.createTranslation(
        newTranslation
      );
      if (ok) {
        // we create a new scalarName
        const data = {
          name: scalarName,
          humanReadableNames: [translation?.id],
        };

        // create a new scalarName
        await this.createAtsFieldMappingForAtsField(
          data,
          atsFieldId,
          atsEntityTypeId
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  // this function we call it in the component to auto create atsFieldMapping
  // it calls first the addNewTranslation function to create humanReadableName
  // and then addNewTranslation calls CreateScalarName function
  // and at the end CreateScalarName creates a atsFieldMapping
  async autoCreateAtsFieldMapping(scalarName, atsField, atsEntityTypeId) {
    // here we determine the tag language for creating a new tag
    const selectedTag = this.allTags.find(
      (tag) => tag.content === languageStore.selectedLanguage.substring(0, 2)
    );

    // creating a new tag
    const newTranslation = {
      tags: [selectedTag?.id],
      content: scalarName,
    };
    await this.addNewTranslationToAtsField(
      newTranslation,
      scalarName,
      atsField?.id,
      atsEntityTypeId
    );
  }

  async getAddProcessorSideDrawerData(isAutomation = false) {
    try {
      const promisesArr = [];
      if (this.campaigns.campaigns.length === 0)
        promisesArr.push(this.loadCampaigns());

      // We don't check the length because that's done in the fn itself. We check
      // if we have cached either the normal or automation processors and assign
      // them accordingly
      promisesArr.push(this.loadTemplates(isAutomation));
      promisesArr.push(this.loadAllProcessors(isAutomation));

      await Promise.all(promisesArr);
    } catch (error) {
      console.error(error);
      runInAction(() => {
        this.loadingEditProcessorData = false;
      });
    }
  }

  async getModifiers() {
    const res = await agent.Chatbot.getAllModifiers();
    runInAction(() => {
      this.allModifiers = res?.data?.allModifiers.map(
        (modifier) => new Modifier(modifier)
      );
    });
  }
}

decorate(Chatbot, {
  id: observable,
  name: observable,
  useBetaPortal: observable,
  serverAddress: observable,
  projectId: observable,
  disallowDisableEventPreference: observable,
  smartInboxEnabled: observable,
  ats: observable,
  atsSpecificTemplatesEnvironment: observable,
  token: observable,
  flows: observable,
  questionsAndAnswersFlow: observable,
  selectedStateID: observable,
  isLoading: observable,
  allTags: observable,
  allAtsFieldMappings: observable,
  scalarNames: observable,
  allScalarNamesWithAtsFieldMapping: observable,
  allScalarNamesWithoutAtsFieldMapping: observable,
  processorTemplates: observable,
  defaultLanguageTag: observable,
  startFlowStore: observable,
  restartFlowStore: observable,
  welcomeBackFlowStore: observable,
  endFlowStore: observable,
  escapeFlowStore: observable,
  fallbackFlowStore: observable,
  broadcastJobAlertFlowStore: observable,
  outOfOfficeFlowStore: observable,
  defaultFlowStore: observable,
  whatsAppDefinitions: observable,
  stateHistory: observable,
  nlp: observable,
  selectedStateIsStatic: observable,
  allValidators: observable,
  allProcessors: observable,
  allReplyProcessors: observable,
  deleted: observable,
  groupAppointments: observable,
  facebookLeads: observable,
  allFlows: computed,
  inboxCompanies: observable,
  suggestedInboxCompany: observable,
  allowedCategories: observable,
  selectedStateGroupName: observable,
  selectedFlow: computed,
  selectedStateGroup: computed,
  stateMachineProcessors: computed,
  selectedState: computed,
  hasFlows: computed,
  selectState: action,
  setIsLoading: action,
  loadFlows: action,
  loadScalarNames: action,
  loadAllProcessors: action,
  fetchScalarNames: action,
  reset: action,
  loadData: action,
  addFlow: action,
  deleteFlow: action,
  addStateToHistory: action,
  fetchWhatsAppDefinitionTemplates: action,
  fetchChatserverChatbot: action,
  dropScalarNameFromList: action,
  fetchBasicFlowsInfo: action,
  addClonedProcessor: action,
  allProcessorTemplates: action,
  cloneFlow: action,
  wizardStore: observable,
  customAnalytics: observable,
  flowToRender: observable,
  selectFlowById: action,
  configureInboxCompany: action,
  tagsStore: observable,
  actionResultsStore: observable,
  processorStore: observable,
  followUpsStore: observable,
  flowNames: observable,
  allFlowNames: action,
  loadAllAtsFieldMappings: action,
  syncTemplateVariables: action,
  allDivergentOptionTemplates: observable,
  loadAllStateGroups: action,
  allStateGroups: observable,
  loadingStateGroups: observable,
  loadingProcessors: observable,
  allInboxLabels: observable,
  creatableAtsEntityTypes: observable,
  supportsMappingCreation: observable,
  supportsNestedWhereQueries: observable,
  getAllInboxLabels: action,
  fetchAllSupportedEntities: action,
  fetchCreatableAtsFields: action,
  autoCreateAtsFieldMapping: action,
  createAtsFieldMappingForAtsField: action,
  addNewTranslationToAtsField: action,
  deleteScalarName: action,
  loadingProcessorsTemplates: observable,
  getAddProcessorSideDrawerData: action,
  fetchOneProcessor: action,
  allNormalProcessors: observable,
  allAutomationProcessors: observable,
  loadingEditProcessorData: observable,
  createSmartInboxLabel: action,
  getModifiers: action,
  normalProcessorTemplates: observable,
  automationProcessorTemplates: observable,
  allModifiers: observable,
  googleAnalyticsEvents: observable,
  loadingCreatableAtsFields: observable,
  loadingConditions: observable,
  atsFieldMappingToClone: observable,
  whatsappProvider: observable,
  isChatbotLoading: observable,
  loadCampaigns: action,
  isFlowsLoading: observable,
});

export default Chatbot;
