import React from 'react'
import PropTypes from 'prop-types'
import { DefaultFallback } from './styles'

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    const { children, fallback } = this.props
    const { hasError } = this.state
    if (hasError) {
      // You can render any custom fallback UI
      return fallback
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
}

ErrorBoundary.defaultProps = {
  fallback: <DefaultFallback />,
}

export default ErrorBoundary
