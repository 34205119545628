import {
  atsFieldOrderingFragment,
  mappingOptionFragment,
} from './fragments/builder/atsMappingFragments';
import requests from './requests';

const getFlowQuery = `
  query GetFlow($id: ID!){
    stateMachine(id: $id) {
      id
      name
      isDefaultStateMachine
      fixedAtsFieldMappings{
        id
        type
        dataType
        value
        isDefaultMapping
        atsOptions{
           value
           humanReadableName
           id
          } 
        mappingOptions {
          ...mappingOption  
        }
        atsFieldOrdering: atsfieldorderingSet {
            ...atsFieldOrderingFragment
        }
        scalarName{
          name
          id
        }
     }
      processors: wrapupProcessor {
        id
        icon
        priority
        category
        description
        humanReadableName
        name
        type
        processorType
        isImportantGoal
        processorDependencies {
          customProcessor {
            modelName
            id
          }
        }
        finalGoalCampaigns {
          id
          name
        }
      }
      stateGroups: stategroupSet {
        id
        name
        initialState {
          id
          name
        }
        position
        states {
          id
          name
          draft
          scalarName {
            id
            name
          }
        }
      }
    }
  }
  ${atsFieldOrderingFragment}
  ${mappingOptionFragment}
`;

const updateStateGroupOrder = `
  mutation UpdateStateGroupOrdering($stateMachineId: ID, $stateGroupids: [ID]) {
    updateStateGroupOrdering(stateMachineId: $stateMachineId, stateGroupids: $stateGroupids) {
      ok
    }
  }
`;

const createFlow = `
  mutation CreateStateMachine($name: String) {
    createStateMachine(name: $name) {
      ok
      stateMachine {
        id
        name
        isDefaultStateMachine
        processors: wrapupProcessor {
          id
          icon
          priority
          category
          description
          humanReadableName
          name
          type
          processorType
          isImportantGoal
          processorDependencies {
            customProcessor {
              modelName
              id
            }
          }
          finalGoalCampaigns {
            id
            name
          }
        }
        stateGroups: stategroupSet {
          id
          name
          position
          initialState {
            id
            name
            draft
            isSkippable
            alwaysStore
            scalarName {
              id
              name
            }
          }
          states {
            id
            name
            draft
            isSkippable
            alwaysStore
            scalarName {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const updateFlow = `
  mutation UpdateStateMachine($data: StateMachineUpdateGenericType!) {
    updateStateMachine(newStatemachine: $data) {
      statemachine {
        id
        name
        isDefaultStateMachine
        fixedAtsFieldMappings {
            id
            type
            dataType
            value
            isDefaultMapping
            atsOptions{
               value
               humanReadableName
               id
              } 
            mappingOptions {
              ...mappingOption  
            }
            atsFieldOrdering: atsfieldorderingSet {
                ...atsFieldOrderingFragment
            }
            scalarName{
              name
              id
            }
        }
        processors: wrapupProcessor {
          id
          icon
          priority
          category
          description
          humanReadableName
          name
          type
          processorType
          isImportantGoal
          googleAnalyticsEvent {
            id
            eventLabel
            eventAction
            systemName
          }
          processorDependencies {
            customProcessor {
              modelName
              id
            }
          }
          finalGoalCampaigns {
            id
            name
          }
        }
        stateGroups: stategroupSet {
          id
          name
          position
          initialState {
            id
            name
            draft
            scalarName {
              id
              name
            }
          }
          states {
            id
            name
            draft
            scalarName {
              id
              name
            }
          }
        }
      }
      ok
    }
  }
  ${atsFieldOrderingFragment}
  ${mappingOptionFragment}
`;

const deleteFlow = `
  mutation DeleteStateMAchine($id: ID!) {
    deleteStateMachine(id: $id) {
      ok
    }
  }
`;

const cloneFLow = `
mutation CloneFlow(
  $flowId:ID!, 
  $originClient:String!, 
  $destinationClient:String, 
  $newFlowName:String,
  ) {
    cloneFlow(
      flowId:$flowId,
      originClient:$originClient,
      destinationClient:$destinationClient,
      newFlowName:$newFlowName,
    ) {
      ok
      flow {
        id
        name
        isDefaultStateMachine
        description
        processors: wrapupProcessor {
          id
          icon
          priority
          category
          description
          humanReadableName
          name
          type
          processorType
          isImportantGoal
          googleAnalyticsEvent {
            id
            eventLabel
            eventAction
            systemName
          }
          processorDependencies {
            customProcessor {
              modelName
              id
            }
          }
          finalGoalCampaigns {
            id
            name
          }
        }
      }
    }
  }
`;

const getTreeView = `
query GetTreeView($id: ID!){
  stateMachine(id: $id) {
    basicTreeFlow 
  }
}

`;
const Flow = {
  get: (id) => requests.gql.chatserver.query(getFlowQuery, { id }),
  getTreeFlow: (id) => requests.gql.chatserver.query(getTreeView, { id }),
  add: (name) => requests.gql.chatserver.mutate(createFlow, { name }),
  update: (data) => requests.gql.chatserver.mutate(updateFlow, { data }),
  delete: (id) => requests.gql.chatserver.mutate(deleteFlow, { id }),
  updateStateGroupOrder: (data) =>
    requests.gql.chatserver.mutate(updateStateGroupOrder, data),
  clone: (data) => requests.gql.chatserver.mutate(cloneFLow, data),
};

export default Flow;
