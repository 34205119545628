import requests from './requests';

const TEMPLATE_CHAT_SERVER_LINK =
  process.env.REACT_APP_TEMPLATE_CHAT_SERVER_LINK;
const TEMPLATE_CHAT_SERVER_TOKEN =
  process.env.REACT_APP_TEMPLATE_CHAT_SERVER_TOKEN;

const getAllTemplates = `
query GetAllTemplates($isBetaTemplate: Boolean) {
  allTemplates(isBetaTemplate: $isBetaTemplate) {
    id
    icon
    name
    description
    stateGroup {
      id
    }
  }
}

`;

const copyStateGroup = `
  mutation CopyStateGroup($templateIds: [ID], $destinationStateMachineId: ID, $destinationProjectId: String, $destinationPosition: Int, $newStateGroupName: String) {
    copyStateGroup(templateIds: $templateIds, destinationStateMachineId: $destinationStateMachineId, destinationProjectId: $destinationProjectId, destinationPosition: $destinationPosition, newStateGroupName: $newStateGroupName) {
      ok
    }
  }
`;

const StateGroupTemplate = {
  all: async (isBetaTemplate) =>
    requests.gql.chatserver.query(
      getAllTemplates,
      { isBetaTemplate },
      TEMPLATE_CHAT_SERVER_LINK,
      TEMPLATE_CHAT_SERVER_TOKEN
    ),
  copy: async (data) =>
    requests.gql.chatserver.mutate(
      copyStateGroup,
      data,
      TEMPLATE_CHAT_SERVER_LINK,
      TEMPLATE_CHAT_SERVER_TOKEN
    ),
};

export default StateGroupTemplate;
