import { commonStore, languageStore } from 'stores';

// this function gets the translation compared to the selected state's tag language
export const getTranslationFromSelectedLanguageTag = (variations) => {
  const { selectedStateTags } = commonStore;
  const applicationLanguage = languageStore.selectedLanguage.substring(0, 2);
  const targetedLanguage =
    selectedStateTags?.length === 0
      ? applicationLanguage
      : selectedStateTags[0]?.content;

  const selectedTranslation = variations?.find(
    (variation) =>
      variation?.tags && variation?.tags[0]?.content === targetedLanguage
  )?.content;

  if (selectedTranslation) return selectedTranslation;

  return variations?.find(
    (variation) =>
      (variation?.tags &&
        ['nl', 'en'].includes(variation?.tags?.[0]?.content)) ||
      variation[variations.length === 1 || targetedLanguage === 'nl' ? 0 : 1]
  )?.content;
};
