import { action, computed, decorate, observable, runInAction } from 'mobx';
import agent from '../agent';
import { getTranslationFromSelectedLanguageTag } from '../utils/translateFromSelectedLanguageTag.js';
import AtsFieldMapping from './AtsFieldMapping.store';
import BaseStore from './BaseStore';

class ScalarNameStore extends BaseStore {
  id = '';
  name = '';
  humanReadableName = '';
  humanReadableNames = [];
  atsFieldMapping;
  tags = [];

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    runInAction(() => {
      this.id = args?.id;
      this.name = args?.name;
      this.humanReadableName = args?.humanReadableName;
      this.humanReadableNames = args?.humanReadableNames;
      this.atsFieldMapping = args?.atsFieldMappings?.map(
        (atsField) => new AtsFieldMapping(atsField)
      );
      this.tags = this.humanReadableNames?.map(
        (humanReadableName) => humanReadableName.tags
      );
    });
  }

  setAttr(key, value) {
    this[key] = value;
  }

  async save() {
    const data = {
      id: this.id,
      name: this.name,
      humanReadableName: this.humanReadableName,
      humanReadableNames: this.humanReadableNames,
      atsFieldMapping: this.atsFieldMapping,
      tags: this.tags,
    };
    await agent.ScalarName.update(data);
    // TODO: Implement this
    // const res = await agent.ScalarName.update(data);
    // this.setup(res.data.scalarNameMutation.scalarName);
  }

  // this computed function gets the translated humanReadableName compared to the selected state's tag language
  get translatedHumanReadableName() {
    return getTranslationFromSelectedLanguageTag(this.humanReadableNames);
  }
}

decorate(ScalarNameStore, {
  name: observable,
  humanReadableName: observable,
  humanReadableNames: observable,
  atsFieldMapping: observable,
  tags: observable,
  translatedHumanReadableName: computed,
  setup: action,
});

export default ScalarNameStore;
