import { observable, action } from 'mobx'
import FlowStore from './Flow.store'

class BaseVariationFlowStore {
  @observable id = ''
  @observable flow = null

  constructor(args, flowName) {
    this.setup(args, flowName)
  }

  @action
  setup(args, flowName) {
    if (args) {
      this.id = args.id
      this.flow = new FlowStore(args.flow)
      this.flow.name = flowName
    }
  }
}

class StartFlowStore extends BaseVariationFlowStore {
  constructor(args) {
    super(args, 'Start')
  }
}
class RestartFlowStore extends BaseVariationFlowStore {
  constructor(args) {
    super(args, 'Restart')
  }
}
class WelcomeBackStore extends BaseVariationFlowStore {
  constructor(args) {
    super(args, 'Welcome Back')
  }
}
class EndFlowStore extends BaseVariationFlowStore {
  constructor(args) {
    super(args, 'End')
  }
}
class EscapeFlowStore extends BaseVariationFlowStore {
  constructor(args) {
    super(args, 'Escape')
  }
}
class FallbackFlowStore extends BaseVariationFlowStore {
  constructor(args) {
    super(args, 'Fallbacks')
  }
}
class BroadcastJobAlertFlowStore extends BaseVariationFlowStore {
  constructor(args) {
    super(args, 'Broadcast Job Alert')
  }
}
class OutOfOfficeFlowStore extends BaseVariationFlowStore {
  constructor(args) {
    super(args, args?.flow?.name || 'Out of Office')
  }
}
class DefaultFlowStore extends BaseVariationFlowStore {
  constructor(args) {
    super(args, 'Default')
  }
}

const staticFlows = {
  StartFlowStore,
  RestartFlowStore,
  WelcomeBackStore,
  EndFlowStore,
  EscapeFlowStore,
  FallbackFlowStore,
  BroadcastJobAlertFlowStore,
  OutOfOfficeFlowStore,
  DefaultFlowStore,
}

export default staticFlows
