import { decorate, observable, action } from 'mobx';
import BaseStore from 'stores/BaseStore';

class CampaignAction extends BaseStore {
  processorId;
  successful;
  observable;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.processorId = params?.processorId || '';
    this.successful = params?.successful || 0;
    this.failed = params?.failed || 0;
  }
}

decorate(CampaignAction, {
  processorId: observable,
  successful: observable,
  failed: observable,
  setup: action,
});

export default CampaignAction;
