import { get as _get } from 'lodash'
import logo from 'style/imgs/logo.png'
import { CORS_PROXY } from '../../constants'
import agent from '../third-party-apis/requests'

const RSS_FEED_URL = `${CORS_PROXY}https://www.joboti.com/blog-feed.xml`

const Blog = {
  /**
   * Get a list for blog articles
   * @param {Number} limit the maximum length of the result array (default: 5)
   * @returns {Array} arrayOfObjects where each object is:
   * @returns {String} title
   * @returns {String} link
   * @returns {String} snippet
   * @returns {String} img
   */
  getLatestArticles: async (limit = 5) => {
    const feedStr = await agent.getXML(RSS_FEED_URL)
    try {
      const parseFeedPromise = new Promise((resolve, reject) => {
        // TODO: replace?
      })

      const feed = await parseFeedPromise
      const items = _get(feed, 'rss.channel[0].item')

      // Flatten and clean the returned array
      return items
        .map((art) => ({
          // creator: art.creator,
          snippet: _get(art, 'contentSnippet', ''),
          link: _get(art, 'link', '#'),
          title: _get(art, 'title', 'Untitled'),
          img: _get(art, 'enclosure[0].$.url', logo),
        }))
        .slice(0, limit)
    } catch (e) {
      return null
    }
  },
}

export default Blog
