import BaseStore from 'stores/BaseStore'
import agent from 'agent'
import { decorate, observable } from 'mobx'

class EmailTemplateStore extends BaseStore {
  toEmail = ''
  fromEmail = ''
  isHtml = false
  plainText = ''
  isText = false
  templateKey = ''
  id = ''
  htmlTemplate = ''
  subject = ''
  useNewEditor = false

  constructor(args) {
    super()
    this.toEmail = args.toEmail
    this.fromEmail = args.fromEmail
    this.isHtml = args.isHtml
    this.plainText = args.plainText
    this.isText = args.isText
    this.templateKey = args.templateKey
    this.htmlTemplate = args.htmlTemplate
    this.subject = args.subject
    this.id = args.id
    this.useNewEditor = args.useNewEditor
  }

  async save() {
    const data = {
      newEmailtemplate: {
        id: this.id,
        toEmail: this.toEmail,
      },
    }
    await agent.EmailTemplate.update(data)
  }

  async update() {
    const data = {
      newEmailtemplate: {
        id: this.id,
        htmlTemplate: this.htmlTemplate,
        subject: this.subject,
      },
    }
    await agent.EmailTemplate.update(data)
  }
}

decorate(EmailTemplateStore, {
  toEmail: observable,
  fromEmail: observable,
  isHtml: observable,
  plainText: observable,
  isText: observable,
  templateKey: observable,
  id: observable,
  htmlTemplate: observable,
  subject: observable,
})

export default EmailTemplateStore
