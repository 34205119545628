import { computed, decorate, observable } from 'mobx';
import { getTranslationFromSelectedLanguageTag } from 'utils/translateFromSelectedLanguageTag';
import AtsEntityType from './AtsEntityType.store';
import BaseStore from './BaseStore';

class AtsField extends BaseStore {
  id;
  getPath;
  atsEntityType;
  number;
  humanReadableNames;
  dataType = '';
  postPath = '';
  regex = '';
  required = false;
  targetEntityType = null;
  extractionPath = '';
  maxLength = null;
  excludeFromPost;

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    const { atsField, number, id, humanReadableNames, getPath } = args;
    const targetedEntityType = args?.atsField?.entityType || args?.entityType;
    this.id = atsField?.id || id;
    this.getPath = atsField?.getPath || getPath;
    this.atsEntityType = new AtsEntityType(targetedEntityType);
    this.number = number;
    this.humanReadableNames =
      atsField?.humanReadableNames || humanReadableNames;
    this.dataType = atsField?.dataType;
    this.postPath = atsField?.postPath;
    this.regex = atsField?.regex;
    this.required = atsField?.required;
    this.targetEntityType = atsField?.targetEntityType;
    this.extractionPath = atsField?.extractionPath;
    this.maxLength = atsField?.maxLength;
    this.excludeFromPost = args?.excludeFromPost;
  }

  get atsPath() {
    const atsEntityTypeName = this.atsEntityType?.name;
    return `${atsEntityTypeName}.${this.getPath}`;
  }

  get translatedHumanReadableName() {
    return getTranslationFromSelectedLanguageTag(this.humanReadableNames);
  }
}

decorate(AtsField, {
  id: observable,
  getPath: observable,
  atsEntityType: observable,
  number: observable,
  humanReadableNames: observable,
  dataType: observable,
  postPath: observable,
  regex: observable,
  required: observable,
  targetEntityType: observable,
  extractionPath: observable,
  maxLength: observable,
  atsPath: computed,
  excludeFromPost: observable,
});

export default AtsField;
