import { observable, action, decorate, runInAction, toJS } from 'mobx'
import Documentation from 'agent/dashboard/documentation'
import BaseStore from '../BaseStore'

class GettingStartedStore extends BaseStore {
  articles = []

  get getGettingStartedArticles() {
    return toJS(this.articles)
  }

  async loadDocumentationArticles() {
    const result = await Documentation.getGettingStartedArticles()
    runInAction(() => {
      this.articles = result
    })
  }
}

decorate(GettingStartedStore, {
  articles: observable,
  loadDocumentationArticles: action,
})

export default GettingStartedStore
