import _ from 'lodash'
import requests from './requests'

const modelsQuery = `
query ModelsWithEntities {
  models {
    id
    entities: entitySet {
      id
      listEntity {
        id
        name
      }
    }
  }
}
`

const modelsQueryWithValues = `
query ModelsWithEntities {
  models {
    id
    entities: entitySet {
      id
      listEntity {
        id
        name
        values: listentityvalueSet {
          id
          name
          synonyms: listentitysynonymSet {
            id
            name
          }
        }
      }
    }
  }
}
`

const getEntity = `
query GetEntity($id: ID!) {
  entity(id: $id) {
    id
    listEntity {
      id
      name
      values: listentityvalueSet {
        id
        name
        synonyms: listentitysynonymSet {
          id
          name
        }
      }
    }
  }
}
`

const deleteListEntityMutation = `
  mutation DeleteListEntity($entityId: ID!, $listEntityId: ID!) {
    deleteEntity(id: $entityId) {
      entity {
        id
        listEntity {
          id
          name
        }
      }
      ok
    }
    deleteListEntity(id: $listEntityId) {
      listentity {
        id
        name
      }
      ok
    }
  }
`

const createListEntityMutation = `
mutation CreateListEntity($name: String!) {
  createListEntity(name: $name){
    entity {
      id
      listEntity {
        id
        name
      }
    }
  }
}
`

export default {
  list: async () => {
    return []
  },
  modelsList: async () => {
    const res = await requests.gql.chatserver.query(modelsQuery)
    return _.get(res, 'data.models')
  },
  modelsListWithValues: async () => {
    const res = await requests.gql.chatserver.query(modelsQueryWithValues)
    return _.get(res, 'data.models')
  },
  get: async id => {
    const res = await requests.gql.chatserver.query(getEntity, { id })
    return _.get(res, 'data.entity')
  },
  delete: async (entityId, listEntityId) => {
    await requests.gql.chatserver.mutate(deleteListEntityMutation, {
      entityId,
      listEntityId,
    })
  },
  createListEntity: async name => {
    const res = await requests.gql.chatserver.mutate(createListEntityMutation, {
      name,
    })
    return _.get(res, 'data.createListEntity.entity')
  },
}
