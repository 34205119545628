import { decorate, observable } from 'mobx';
import moment from 'moment';
import BaseStore from 'stores/BaseStore';

class AnalyticsMessageStore extends BaseStore {
  assignedAgentName = '';
  date = '';
  type = '';
  id = '';
  isBot = null;
  isInbound = null;
  phoneNumber = null;
  responseTime = 0;
  senderEntity = '';
  stateMachine = null;
  teamName = '';
  templateName = null;
  statusHistory = null;
  atsEntityId = '';

  constructor(args) {
    super();
    if (args) this.setup(args);
  }

  setup(args) {
    this.date = moment(args.metrics.received).format('DD/MM/YY HH:mm');
    this.assignedAgentName = args.assigned_agent_name;
    this.type = args.human_readable_type;
    this.id = args.id;
    this.isBot = args.is_bot;
    this.phoneNumber = args.phone_number;
    this.isInbound = args.is_inbound;
    this.responseTime = args.response_time;
    this.senderEntity = args.sender_entity;
    this.stateMachine = args.state_machine;
    this.statusHistory = args.status_history;
    this.templateName = args.template_name;
    this.teamNames = args.team_names;
    this.atsEntityId = args.ats_entity_id;
  }
}

// this is not needed, but we have it here for no reason :/
decorate(AnalyticsMessageStore, {
  assignedAgentName: observable,
  date: observable,
  type: observable,
  id: observable,
  isBot: observable,
  isInbound: observable,
  phoneNumber: observable,
  responseTime: observable,
  senderEntity: observable,
  stateMachine: observable,
  teamName: observable,
  templateName: observable,
});

export default AnalyticsMessageStore;
