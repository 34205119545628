import { decorate, observable } from 'mobx';
import BaseStore from 'stores/BaseStore';

class CampaignActionStep extends BaseStore {
  actionStepId = '';
  title = '';
  logs = [];
  successful = false;
  processedAt = '';

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.actionStepId = params.actionStepId;
    this.title = params.title;
    this.logs = params.logs;
    this.successful = params.successful;
    this.processedAt = params.processedAt;
  }
}

decorate(CampaignActionStep, {
  actionStepId: observable,
  title: observable,
  logs: observable,
  successful: observable,
  processedAt: observable,
});

export default CampaignActionStep;
