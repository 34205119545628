import _ from 'lodash'
import requests from './requests'

const updateMutation = `
mutation UpdateListEntity($data: ListEntityUpdateGenericType!) {
  updateListEntity(newListentity: $data) {
    listentity {
      id
      name
    }
  }
}
`

export default {
  update: async data => {
    const res = await requests.gql.chatserver.mutate(updateMutation, { data })
    return _.get(res, 'data.updateListEntity.listentity')
  },
}
