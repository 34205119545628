export const atsFieldOrderingFragment = `
fragment atsFieldOrderingFragment on AtsFieldOrderingNode {
    number
    excludeFromPost
    atsField {
      id
      getPath
      dataType
      entityType {
        id
        name
        humanReadableNames: allHumanReadableNames {
          id
          content
          tags {
            kind
            content
          }
        }
      }
      humanReadableNames: allHumanReadableNames {
        id
        content
        tags {
          kind 
          content
          id
        }
        }
      }
    }
`;

export const mappingOptionFragment = `
fragment mappingOption on AtsMappingOptionNode{
    id
    value
    doStoreValue
    option {
     symbol
     id
     states {
      id
      name
     }
   }
    atsOptions{
     value
     humanReadableName
     id
   }       
  }
`;

export const statesFragment = `
fragment statesFragment on StateNode {
  id
  name
  stategroupSet {
    id
    name
    stateMachine {
      id
      name
    }
  }
 }
`;
