/* eslint-disable consistent-return */
import agent from 'agent';
import { message } from 'antd';
import { fakeFormatMessage as formatMessage } from 'locales/utils';

const formatEntity = (entity) => {
  if (entity[0] !== entity[0].toUpperCase()) {
    return entity[0].toUpperCase() + entity.slice(1);
  }
  return entity;
};

class Utils {
  /**
   * The function `updateGenericType` asynchronously updates an entity instance using a specified agent
   * and returns a boolean indicating success.
   * @param entity - The `entity` parameter in the `updateGenericType` function represents the type of
   * entity that you want to update. It could be a string indicating the entity type such as 'User',
   * 'Product', 'Order', etc.
   * @param instanceToUpdate - `instanceToUpdate` is the object that contains the data you want to
   * update for the specified entity. It could be an instance of a class or an object representing a
   * record in a database that you want to update.
   * @param [agentName=null] - The `agentName` parameter is an optional parameter that allows you to
   * specify the name of the agent to use for updating the entity. you can `agentName` when the name of
   * the entity is same as the name of the agent
   * @returns The function `updateGenericType` returns a boolean value - `true` if the update operation
   * was successful (`ok`), and `false` if there was an error during the update process.
   */
  async updateGenericType(entity, instanceToUpdate, agentName = null) {
    // if the first letter of the entity is not a capital letter, we capitalize it
    const formattedEntity = formatEntity(entity);
    const serializedInstance = this.serializeInstance(instanceToUpdate);
    try {
      const { ok } = await agent[`${agentName || formattedEntity}`][
        `update${formattedEntity}`
      ](serializedInstance);
      return ok;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, class-methods-use-this
  async deleteGenericType(entity, instanceId) {
    const formattedEntity = formatEntity(entity);

    try {
      const { ok } = await agent[formattedEntity][`delete${formattedEntity}`](
        instanceId
      );
      return ok;
    } catch (err) {
      console.error(err);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, class-methods-use-this
  serializeInstance(instanceToSerialize) {
    const instanceToSend = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const property in instanceToSerialize) {
      if (Object.hasOwn(instanceToSerialize, property)) {
        // if the instance is an array then get the ids..
        const currentValue = instanceToSerialize[property];
        if (Array.isArray(currentValue)) {
          instanceToSend[property] = currentValue.map((item) => item.id);
        } else if (typeof currentValue === 'object' && currentValue !== null) {
          instanceToSend[property] = currentValue.id;
        } else if (property !== 'hasChanged') {
          instanceToSend[property] = currentValue;
        }
      }
    }
    return instanceToSend;
  }

  // eslint-disable-next-line class-methods-use-this
  showNotification({ status, id, messageContent }) {
    let defaultMessage;
    const notificationMessage =
      (id || messageContent) &&
      formatMessage({
        id,
        messageContent,
      });

    switch (status) {
      case 'error':
        defaultMessage = formatMessage({
          id: 'notifyFunction/defaultErrorMessage',
        });
        message.error(notificationMessage || defaultMessage);
        break;
      case 'warning':
        defaultMessage = formatMessage({
          id: 'notifyFunction/defaultErrorMessage',
        });
        message.warning(notificationMessage || defaultMessage);
        break;
      default:
        defaultMessage = formatMessage({
          id: 'notifyFunction/defaultSuccessMessage',
        });
        message.success(notificationMessage || defaultMessage);
        break;
    }

    return null;
  }
}

export default Utils;
