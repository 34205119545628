import requests from '../requests';

const addTextVariationQuery = `
mutation AddTextMessageVariation($messageClassId: ID!) {
  addTextMessageMutation(messageClassId: $messageClassId) {
    ok
    variation {
      id
      tags {
        id
        color
        content
        kind
      }
      message {
        id
        textMessage {
          id
          content
        }
      }
    }
  }
}

`;

const addVideoVariationQuery = `
mutation AddVideoMessageVariation($messageClassId: ID!) {
  addVideoMessageMutation(messageClassId: $messageClassId) {
    ok
    variation {
      id
      tags {
        id
        color
        content
        kind
      }
      message {
        id
        videoMessage {
          id
          link
        }
      }
    }
  }
}

`;

const addImageVariationQuery = `
mutation AddImageMessageVariation($messageClassId: ID!) {
  addImageMessageMutation(messageClassId: $messageClassId) {
    ok
    variation {
      id
      tags {
        id
        color
        content
        kind
      }
      message {
        id
        imageMessage {
          id
          link
        }
      }
    }
  }
}
`;

const addLinkVariationQuery = `
mutation AddLinkMessageVariation($messageClassId: ID!, $isDocument: Boolean!) {
  addLinkMessageMutation(messageClassId: $messageClassId, isDocument: $isDocument) {
    ok
    variation {
      id
      tags {
        id
        color
        content
        kind
      }
      message {
        id
        linkMessage {
          id
          placeholder
          link
          isDocument
        }
      }
    }
  }
}
`;

const addGeoLocationVariationQuery = `
mutation AddGeoLocationMessageVariation($messageClassId: ID!) {
  addGeoLocationMutation(messageClassId: $messageClassId) {
    ok
    variation {
      id
      tags {
        id
        color
        content
        kind
      }
      message {
        id
        geoLocationMessage {
          id
          lat
          lng
        }
      }
    }
  }
}
`;

const getMessageQuery = `
query GetMessage($id: ID!) {
    message(id: $id) {
      ...messageClass
    }
  }
  
  fragment messageClass on MessageNode {
    id
    variations {
      id
      tags {
        id
        color
        content
        kind
      }
      message {
        id
        textMessage {
          id
          content
        }
      }
    }
  }
`;

const MessageClass = {
  addTextVariation: (data) => requests.gql.chatserver.mutate(addTextVariationQuery, data),
  addVideoVariation: (data) => requests.gql.chatserver.mutate(addVideoVariationQuery, data),
  addImageVariation: (data) => requests.gql.chatserver.mutate(addImageVariationQuery, data),
  addLinkVariation: (data) => requests.gql.chatserver.mutate(addLinkVariationQuery, data),
  addGeoLocationVariation: (data) => requests.gql.chatserver.mutate(addGeoLocationVariationQuery, data),
  get: (id) => requests.gql.chatserver.query(getMessageQuery, { id }),
};

export default MessageClass;
