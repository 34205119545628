import BaseStore from 'stores/BaseStore';
import agent from 'agent';
import { action, decorate, observable, runInAction } from 'mobx';
import WhatsAppTemplate from './WhatsAppTemplate.store';

class WhatsAppTemplates extends BaseStore {
  templates = [];
  isLoading;

  async fetch() {
    this.isLoading = true;
    const templates = await agent.WhatsAppTemplate.list();
    runInAction(() => {
      this.templates = templates
        ? templates.map((template) => new WhatsAppTemplate(template))
        : [];
      this.isLoading = false;
    });
  }

  async create(newTemplate) {
    this.isLoading = true;

    const prefetchedNewTemplate = {
      id: newTemplate?.id,
      templateName: newTemplate?.templateName,
      description: newTemplate?.description,
      category: newTemplate?.category,
      scalarNames: newTemplate?.scalarNames,
      additionalData: newTemplate?.additionalData,
      attachmentType: newTemplate?.attachmentType,
      version: newTemplate?.version,
      status: newTemplate?.status,
      creatorEmail: newTemplate?.creatorEmail,
      buttonType: newTemplate?.buttonType,
      variations: newTemplate?.variations,
      parameters: newTemplate?.parameters,
    };

    const result = await agent.WhatsAppTemplate.create(prefetchedNewTemplate);
    const whatsAppTemplate = new WhatsAppTemplate(result.whatsapptemplate);
    runInAction(() => {
      this.templates.push(whatsAppTemplate);
      this.isLoading = false;
    });
    return result;
  }

  async update(newTemplate) {
    this.isLoading = true;

    const prefetchedNewTemplate = {
      id: newTemplate?.id,
      templateName: newTemplate?.templateName,
      description: newTemplate?.description,
      category: newTemplate?.category,
      scalarNames: newTemplate?.scalarNames,
      additionalData: newTemplate?.additionalData,
      attachmentType: newTemplate?.attachmentType,
      version: newTemplate?.version,
      status: newTemplate?.status,
      creatorEmail: newTemplate?.creatorEmail,
      buttonType: newTemplate?.buttonType,
      variations: newTemplate?.variations,
      parameters: newTemplate?.parameters,
    };

    const result = await agent.WhatsAppTemplate.update(prefetchedNewTemplate);
    const whatsAppTemplate = new WhatsAppTemplate(result.whatsapptemplate);
    runInAction(() => {
      const templateIdx = this.templates.findIndex(
        (template) => template.id === newTemplate.id
      );
      this.templates[templateIdx] = newTemplate;
      this.isLoading = false;
    });
    return whatsAppTemplate;
  }

  async delete(id) {
    this.isLoading = true;
    await agent.WhatsAppTemplate.delete(id);
    runInAction(() => {
      this.templates = this.templates.filter((template) => template.id !== id);
      this.isLoading = false;
    });
  }

  async providerCreate(id) {
    this.isLoading = true;
    const res = await agent.WhatsAppTemplate.providerCreate(id);

    runInAction(() => {
      this.isLoading = false;
    });

    return res;
  }

  async providerDelete(id) {
    this.isLoading = true;
    const res = await agent.WhatsAppTemplate.providerDelete(id);

    runInAction(() => {
      this.isLoading = false;
    });

    return res;
  }
}

decorate(WhatsAppTemplates, {
  templates: observable,
  isLoading: observable,
  fetch: action,
  create: action,
  update: action,
  delete: action,
  providerCreate: action,
  providerDelete: action,
});

export default WhatsAppTemplates;
