import { AbilityBuilder } from '@casl/ability';
import calendarAuthHandler from 'constants/authHandlerTypes';
import {
  ANALYTICS_CONVERSATIONS_ENTITY,
  ANALYTICS_ENTITY,
  ANALYTICS_EXPORT_RESULTS_ENTITY,
  ANALYTICS_FUNNEL_ENTITY,
  ANALYTICS_LANGUAGE_ENTITY,
  ANALYTICS_SUMMARY_ENTITY,
  BUILDER_ACTION_WITH_OPTIONS,
  BUILDER_ADD_OPTION,
  BUILDER_EDIT_OPTION,
  BUILDER_ENTITY,
  BUILDER_FLOW_ENTITY,
  BUILDER_QA_ENTITY,
  CALENDAR,
  CAMPAIGNS,
  CHATBOTS,
  CHATBOT_SETTINGS,
  CHATBOT_SETTINGS_ATS,
  CHATBOT_SETTINGS_AVAILABILITIES_ENTITY,
  CHATBOT_SETTINGS_CHATBOT_ENTITY,
  CHATBOT_SETTINGS_FACEBOOK_LEADS_ENTITY,
  CHATBOT_SETTINGS_GROUP_APPOINTMENT_ENTITY,
  CHATBOT_SETTINGS_WIDGET_ENTITY,
  CHATBOT_SETTINGS_WIDGET_PREVIEW_ENTITY,
  DASHBOARD_ENTITY,
  NLP_ENTITIES_ENTITY,
  NLP_ENTITY,
  NLP_INTENTS_ENTITY,
  REMINDER,
  SMART_INBOX,
  USERS_MANAGEMENT_ENTITY,
  VALIDATOR,
  VALIDATOR_RESULTS_ENTITY,
  VALIDATOR_TEST_ENTITY,
  WHATSAPP,
  WHATSAPP_TEMPLATE,
} from 'constants/entities.constants';
import {
  ROLE_ADMIN,
  ROLE_EMPLOYEE,
  ROLE_MANAGER,
  ROLE_PARTNER,
  ROLE_TEAM_LEAD,
} from 'constants/roles.constants';
import Cookies from 'js-cookie';
import jsonwebtoken from 'jsonwebtoken';
import _, { debounce } from 'lodash';
import {
  action,
  autorun,
  computed,
  decorate,
  observable,
  runInAction,
  when,
} from 'mobx';
import moment from 'moment';
import CalendarAvailabilities from 'stores/CalendarAvailability/CalendarAvailabilities.store';
import agent from '../agent';
import {
  JWT_EXPIRATION_BUFFER,
  JWT_EXPIRATION_LOCAL_STORAGE_KEY,
  JWT_LOCAL_STORAGE_KEY,
  LoginRequestType,
  REFRESH_TOKEN_LOCAL_STORAGE_KEY,
  SELECTED_CHATBOT_LOCAL_STORAGE_KEY,
  cookiesOption,
} from '../constants';
import { clearCookies } from '../utils/cookies';
import BaseStore from './BaseStore';
import CalendarSettings from './CalendarOverview/CalendarSettings.store';
import ChatbotStore from './ChatbotStore';
import Employee from './Employee.store';
import EmployeesStore from './Employees.store';
import Organization from './Organization.store';
import { commonStore, languageStore } from './index';

function defineAbilitiesFor(user) {
  return AbilityBuilder.define((can, cannot) => {
    if (user.role === ROLE_ADMIN) {
      can('manage', 'all');
    } else if (user.role === ROLE_PARTNER) {
      can('manage', WHATSAPP);
      can('manage', CHATBOT_SETTINGS_FACEBOOK_LEADS_ENTITY);
      can('manage', CHATBOTS);
      can('read', CAMPAIGNS);
      can('manage', DASHBOARD_ENTITY);
      can('manage', BUILDER_ENTITY);
      can('manage', ANALYTICS_ENTITY);
      can('manage', CHATBOT_SETTINGS);
      can('manage', USERS_MANAGEMENT_ENTITY);
      can('manage', VALIDATOR);
      can('create', WHATSAPP_TEMPLATE);
      can('manage', WHATSAPP_TEMPLATE);
      can('update', WHATSAPP_TEMPLATE);
      can('delete', WHATSAPP_TEMPLATE);

      // Sub Sections
      can('manage', CHATBOT_SETTINGS_WIDGET_ENTITY);
      can('manage', CHATBOT_SETTINGS_WIDGET_PREVIEW_ENTITY);
      can('manage', CHATBOT_SETTINGS_AVAILABILITIES_ENTITY);
      can('manage', CHATBOT_SETTINGS_GROUP_APPOINTMENT_ENTITY);
      can('manage', CHATBOT_SETTINGS_CHATBOT_ENTITY);
      can('manage', CHATBOT_SETTINGS_ATS);

      can('manage', BUILDER_FLOW_ENTITY);
      can('manage', BUILDER_QA_ENTITY);
      can('manage', BUILDER_EDIT_OPTION);
      can('manage', BUILDER_ADD_OPTION);
      can('manage', BUILDER_ACTION_WITH_OPTIONS);

      can('manage', ANALYTICS_SUMMARY_ENTITY);
      can('manage', ANALYTICS_CONVERSATIONS_ENTITY);
      can('manage', ANALYTICS_LANGUAGE_ENTITY);
      can('manage', ANALYTICS_FUNNEL_ENTITY);
      can('manage', ANALYTICS_EXPORT_RESULTS_ENTITY);

      can('manage', VALIDATOR_TEST_ENTITY);
      can('manage', VALIDATOR_RESULTS_ENTITY);

      can('manage', NLP_ENTITY);
      can('manage', NLP_INTENTS_ENTITY);
      can('manage', NLP_ENTITIES_ENTITY);
      can('manage', SMART_INBOX);
      can('manage', CALENDAR);
    } else if (user.role === ROLE_MANAGER) {
      can('manage', CHATBOT_SETTINGS_FACEBOOK_LEADS_ENTITY);
      can('manage', CHATBOTS);
      can('read', CAMPAIGNS);
      can('create', CAMPAIGNS);
      can('manage', DASHBOARD_ENTITY);
      can('manage', BUILDER_ENTITY);
      can('manage', ANALYTICS_ENTITY);
      can('manage', CHATBOT_SETTINGS);
      can('manage', USERS_MANAGEMENT_ENTITY);
      can('manage', VALIDATOR);
      can('create', WHATSAPP_TEMPLATE);
      can('manage', WHATSAPP_TEMPLATE);
      can('update', WHATSAPP_TEMPLATE);
      can('delete', WHATSAPP_TEMPLATE);
      can('manage', REMINDER);

      // Sub Sections
      can('manage', CHATBOT_SETTINGS_WIDGET_ENTITY);
      can('manage', CHATBOT_SETTINGS_WIDGET_PREVIEW_ENTITY);
      can('manage', CHATBOT_SETTINGS_AVAILABILITIES_ENTITY);
      can('manage', CHATBOT_SETTINGS_GROUP_APPOINTMENT_ENTITY);
      can('manage', CHATBOT_SETTINGS_CHATBOT_ENTITY);
      can('manage', CHATBOT_SETTINGS_ATS);

      can('manage', BUILDER_FLOW_ENTITY);
      can('manage', BUILDER_QA_ENTITY);
      can('manage', BUILDER_EDIT_OPTION);
      can('manage', BUILDER_ADD_OPTION);
      can('manage', BUILDER_ACTION_WITH_OPTIONS);

      can('manage', ANALYTICS_SUMMARY_ENTITY);
      can('manage', ANALYTICS_CONVERSATIONS_ENTITY);
      can('manage', ANALYTICS_LANGUAGE_ENTITY);
      can('manage', ANALYTICS_FUNNEL_ENTITY);
      can('manage', ANALYTICS_EXPORT_RESULTS_ENTITY);

      can('manage', VALIDATOR_TEST_ENTITY);
      can('manage', VALIDATOR_RESULTS_ENTITY);

      can('manage', NLP_ENTITY);
      can('manage', NLP_INTENTS_ENTITY);
      can('manage', NLP_ENTITIES_ENTITY);
      can('manage', SMART_INBOX);
      can('manage', CALENDAR);
    } else if (user.role === ROLE_TEAM_LEAD) {
      can('manage', DASHBOARD_ENTITY);
      can('manage', CHATBOT_SETTINGS);
      can('manage', SMART_INBOX);
      can('read', CAMPAIGNS);

      can('manage', CHATBOT_SETTINGS_AVAILABILITIES_ENTITY);
      can('manage', CHATBOT_SETTINGS_GROUP_APPOINTMENT_ENTITY);

      can('manage', ANALYTICS_ENTITY);
      can('manage', ANALYTICS_SUMMARY_ENTITY);
      can('manage', ANALYTICS_CONVERSATIONS_ENTITY);
      can('manage', ANALYTICS_LANGUAGE_ENTITY);
      can('manage', ANALYTICS_FUNNEL_ENTITY);
      can('manage', ANALYTICS_EXPORT_RESULTS_ENTITY);
    } else if (user.role === ROLE_EMPLOYEE) {
      if (user.isInTkPortal) {
        // When opened in tk portal we want to show the automations so we need to give access
        can('read', CAMPAIGNS);
        can('create', CAMPAIGNS);
      }

      // Sections
      can('manage', DASHBOARD_ENTITY);
      can('manage', CHATBOT_SETTINGS);

      // Sub Sections
      can('manage', CHATBOT_SETTINGS_AVAILABILITIES_ENTITY);
      can('manage', BUILDER_ADD_OPTION);
      can('manage', SMART_INBOX);
    } else {
      cannot('manage', 'all');
    }
  });
}

class User extends BaseStore {
  @observable id = null;
  @observable email = '';
  @observable language = '';
  @observable username = '';
  @observable firstName = '';
  @observable lastName = '';
  @observable jwtToken = null;
  @observable isLoading = true;
  @observable isLoggedIn = false;
  @observable isRefreshingLogin = false;
  @observable isSuperuser = false;
  @observable ability = null;
  @observable calendarIsAuthenticated = false;
  @observable calendarAuthHandler = calendarAuthHandler.JOBOTI;
  @observable defaultLanguage;

  @observable loginRequestAuthToken = '';
  @observable loginRequestErrorCode = '';
  @observable loginRequestType = '';
  @observable loginCodeTimeout = null;
  @observable allowResendLoginCode = true;
  @observable changingPhoneNumber = false;
  @observable requiresPasswordReset = false;

  @observable chatbots = [];
  @observable selectedChatbotID = '';

  @observable isOnSmallDevice = false;
  @observable navigationCollapsed = false;
  @observable employee = null;
  @observable calendarSettings = new CalendarSettings();
  @observable pagesVisited = {};

  employeesStore = new EmployeesStore();
  organization;

  calendarAvailabilities = new CalendarAvailabilities();
  role;
  isInTkPortal = false;

  constructor() {
    super();
    this.reset();

    autorun(() => {
      const chatbot = this.selectedChatbot;
      if (chatbot && !chatbot.isChatbotLoading && !chatbot.whatsappProvider) {
        chatbot.reset();
        commonStore.setAttr('selectedChatbot', chatbot);
        this.fetchChatbotDebounced(chatbot);
      }
    });
    when(
      () => _.size(this.chatbots) !== 0,
      () => {
        // Check if there is a cached ChatbotID
        const cachedChatbotId = Cookies.get(SELECTED_CHATBOT_LOCAL_STORAGE_KEY);

        // When there is a cached chatbot id load this instead of the first option
        if (
          cachedChatbotId &&
          this.chatbots.filter((chatbot) => chatbot.id === cachedChatbotId)[0]
        ) {
          this.selectChatbot(cachedChatbotId);
        }

        // When there is a valid selected chatbot we won't override it with the default first chatbot
        if (this.selectedChatbot) return;

        // Select the first chatbot from the list
        this.selectChatbot(this.chatbots[0].id);
      }
    );
  }

  @computed
  get selectedChatbot() {
    return this.chatbots.filter(
      (chatbot) => chatbot.id === this.selectedChatbotID
    )[0];
  }

  @computed
  get hasLoadedData() {
    return this.id && this.username;
  }

  @action
  fetchChatbotDebounced = debounce((chatbot) => {
    chatbot.fetchChatserverChatbot();
  }, 500);

  @action
  selectChatbot(id) {
    this.selectedChatbotID = id;
    Cookies.set(SELECTED_CHATBOT_LOCAL_STORAGE_KEY, id, cookiesOption);
    languageStore.setDefaultLanguage(
      this.selectedChatbot?.defaultLanguageTag?.content
    );
  }

  @action
  async login(username, password) {
    let response = await agent.User.login(username, password);

    // If we receive an error then we need to parse it so we can read the error code
    if (response.message) {
      response = JSON.parse(response.message);
    }

    if (response.error) {
      // We received an error show this
      runInAction(() => {
        this.loginRequestErrorCode = response.error;
      });
      throw new Error(response.error);
    }

    if (response.type) {
      // The field type is present in the response, this indicates that 2FA is required
      runInAction(() => {
        this.loginRequestType = response.type;
        this.loginRequestAuthToken = response.auth_token;

        if (response.login_code_timeout) {
          this.loginCodeTimeout = moment(response.login_code_timeout);
        }

        this.allowResendLoginCode = false;

        if (this.loginRequestType === LoginRequestType.CHANGE_PHONE_NUMBER) {
          this.changingPhoneNumber = true;
        }
      });
      return true;
    }

    runInAction(() => {
      this.startAuthHandler();
      this.loadData();
    });

    return true;
  }

  /**
   * Validates if the token is still valid
   */
  async validateToken() {
    try {
      await agent.User.validateToken();
      return true;
    } catch (e) {
      return false;
    }
  }

  async sendPasswordReset(username) {
    const response = await agent.User.sendPasswordReset(username);

    if (response.status !== 200) {
      throw Error('Failed to send password reset.');
    }
  }

  @action
  async changePasswordFromReset(guid, password) {
    const response = await agent.User.changePasswordWithGuid(guid, password);

    if (response.status !== 200) {
      throw Error('Failed to change password.');
    }
  }

  @action
  async changePasswordForFirstTime(password) {
    const response = await agent.User.changePasswordForFirstTime(
      this.id,
      password
    );

    runInAction(() => {
      this.requiresPasswordReset = false;
    });
  }

  async changePassword(oldPassword, newPassword) {
    const response = await agent.User.changePassword(
      this.jwtToken,
      this.id,
      oldPassword,
      newPassword
    );

    return response.status === 200;
  }

  /**
   * THIS FUNCTION IS NOT YET SUPPORTED WITH THE COOKIES
   * WE HAVE TO MIGRATE IT ONCE WE ARE GOING TO USE IT AGAIN!
   *
   * FOR NOW IT'S NOT BEING USED SO NO NEED TO MIGRATE IT!
   */
  @action
  async loginWithCode(code) {
    let response = await agent.User.loginWithCode(
      code,
      this.loginRequestAuthToken
    );

    if (response.message) {
      response = JSON.parse(response.message);
    }

    if (response.error) {
      runInAction(() => {
        this.loginRequestErrorCode = response.error;
      });
      return false;
    }

    if (!response.access) {
      clearCookies();
      throw new Error(
        'Failed to authenticate user with given username and password.'
      );
    }
    // we are not change these because this component is not used!
    localStorage.setItem(JWT_LOCAL_STORAGE_KEY, response.access);
    localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, response.refresh);

    const jwtPayload = jsonwebtoken.decode(response.access);
    const jwtExpiry = jwtPayload.exp;
    // we are not change these because this component is not used!

    localStorage.setItem(JWT_EXPIRATION_LOCAL_STORAGE_KEY, jwtExpiry);

    runInAction(() => {
      this.jwtToken = response.access;
      this.startAuthHandler();
      this.loadData();
    });

    return true;
  }

  @action
  async changePhoneNumber(phoneNumber) {
    let response = await agent.User.changePhoneNumber(
      phoneNumber,
      this.loginRequestAuthToken
    );

    if (response.message) {
      response = JSON.parse(response.message);
    }

    if (response.error) {
      runInAction(() => {
        this.loginRequestErrorCode = response.error;
      });
    }

    runInAction(() => {
      this.loginCodeTimeout = moment(response.login_code_timeout);
      this.allowResendLoginCode = false;
      this.changingPhoneNumber = false;
    });
  }

  @action
  async resendCode() {
    let response = await agent.User.resendLoginCode(this.loginRequestAuthToken);

    if (response.message) {
      response = JSON.parse(response.message);
    }

    if (response.error) {
      runInAction(() => {
        this.loginRequestErrorCode = response.error;
      });
      return;
    }

    runInAction(() => {
      this.loginCodeTimeout = moment(response.login_code_timeout);
      this.allowResendLoginCode = false;
    });
  }

  @action
  async loginRefresh(refreshToken = null) {
    this.isRefreshingLogin = true;

    await agent.User.loginRefresh(refreshToken);
  }

  async oktaLogin(accessToken) {
    const { token } = await agent.User.oktaLogin(accessToken);
    return token;
  }

  async logout() {
    await agent.User.logout();

    clearCookies();
    window.location.href = '/';
    this.isLoading = false;
  }

  @action
  async loadUser() {
    this.isLoading = true;

    // Load the user's information
    const response = await agent.User.get();

    if (!response.data?.user) {
      throw new Error('Failed to retrieve user with this authorization token.');
    }

    const { user } = response.data;
    user.isInTkPortal = this.isInTkPortal;

    runInAction(() => {
      this.isLoggedIn = true;
      this.id = user.id;
      this.language = user.language;
      this.username = user.username;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.email = user.email;
      this.isSuperuser = user.isSuperuser;
      this.organization = new Organization(user.organization);
      this.role = user.role;
      this.calendarAuthHandler = user.calendarAuthHandler;
      this.calendarIsAuthenticated = user.calendarIsAuthenticated;
      this.requiresPasswordReset = user.requiresPasswordReset;
      this.employee = new Employee(user);
      this.agentId = user.agentId;
      this.pagesVisited = JSON.parse(user.pagesVisited || '{}');
    });

    runInAction(() => {
      // Load the chatbots on the user
      this.chatbots = user.chatbots.map((chatbot) => new ChatbotStore(chatbot));

      // Set up permissions (ability)
      this.ability = defineAbilitiesFor(user);
      this.ability.can('manage', 'all');
    });
  }

  @action
  async loadData() {
    this.isLoading = true;
    await this.loadUser();
    this.startAuthHandler();

    runInAction(() => {
      this.isLoading = false;
    });
  }

  whenChatbotIsSelected(callback) {
    when(
      () => this.selectedChatbot,
      () => callback()
    );
  }

  @action
  setAttr(field, value) {
    this[field] = value;
  }

  @action
  reset() {
    this.chatbots = [];
  }

  async addChatBot(projectId, template) {
    runInAction(() => {
      this.chatbots.unshift(new ChatbotStore({ name: projectId }));
    });
    await agent.Chatbot.create({
      projectId,
      template,
    });
  }

  async deleteChatBot(projectId) {
    await agent.Chatbot.remove(projectId);
  }

  async updateCalendarToken(
    token,
    handler,
    userId = null,
    employee = null,
    referer = null
  ) {
    const result = await agent.User.updateCalendarToken(
      token,
      userId || this.id,
      handler,
      referer
    );
    if (result) {
      runInAction(() => {
        if (employee) {
          employee.calendarIsAuthenticated = true;
        } else {
          this.calendarIsAuthenticated = true;
        }
      });
    }
    return result;
  }

  async startAuthHandler() {
    // Load the token from the localStorage
    let jwtExpiry = Cookies.get(JWT_EXPIRATION_LOCAL_STORAGE_KEY);

    if (!jwtExpiry) {
      // No expiry has been stored, we will log the user out
      return;
    }

    // calculate the time left until the token expires (in seconds)
    let jwtExpirationDuration =
      parseInt(jwtExpiry, 10) - Math.floor(Date.now() / 1000);

    // Subtract the expiration buffer from the expiration duration
    jwtExpirationDuration -= JWT_EXPIRATION_BUFFER;

    // If we have a refresh token stored & the jwt is about to expire, then we will refresh the token
    if (jwtExpirationDuration <= 0) {
      try {
        // Refresh the JWT
        await this.loginRefresh();

        // get the new expiry
        jwtExpiry = Cookies.get(JWT_EXPIRATION_LOCAL_STORAGE_KEY);
        jwtExpirationDuration =
          parseInt(jwtExpiry, 10) - Math.floor(Date.now() / 1000);
        jwtExpirationDuration -= JWT_EXPIRATION_BUFFER;
      } catch (error) {
        // Something went wrong refreshing the jwt. We log the user out
        this.logout();
      }
    }

    setTimeout(() => {
      this.startAuthHandler();
    }, jwtExpirationDuration * 1000);
  }
}

decorate(User, {
  employeesStore: observable,
  organization: observable,
  calendarAvailabilities: observable,
  role: observable,
  isInTkPortal: observable,
  addChatBot: action,
  deleteChatBot: action,
  updateCalendarToken: action,
});

export default User;
