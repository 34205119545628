import moment from 'moment';
import { isObject } from 'lodash/lang';

function getRandomInt(min, max) {
  const minValue = Math.ceil(min);
  const maxValue = Math.floor(max);
  return Math.floor(Math.random() * (maxValue - minValue)) + minValue;
}

/**
 * Sort array of objects by date, each element of the **data** array is an object,
 * Each object has count, year, month, day.
 * @param {Array} data
 * @param {Number} data.year
 * @param {Number} data.month
 * @param {Number} data.day
 * @param {Number} data.count
 * @param {Object} body
 *
 * @returns {Object} {
 *  labels: [Strings of sorted dates]
 *  data: [Count of users per day]
 * }
 */
function dataSortedByDate(data, body) {
  const startDate = new Date(body.s_year, body.s_month, body.s_day);
  const endDate = new Date(body.e_year, body.e_month, body.e_day);

  let dataArray = [];
  let labelsArray = [];

  if (data.length > 0) {
    if (data.length > 1) {
      if (data[0].day) {
        data.sort(
          (data1, data2) =>
            new Date(data1.year, data1.month - 1, data1.day) -
            new Date(data2.year, data2.month - 1, data2.day)
        );

        data.forEach((dt) => {
          dataArray.push(dt.count);
          labelsArray.push(
            new Date(dt.year, dt.month - 1, dt.day).toDateString()
          );
        });
      } else {
        data.sort(
          (data1, data2) =>
            new Date(data1.date.year, data1.date.month - 1, data1.date.day) -
            new Date(data2.date.year, data2.date.month - 1, data2.date.day)
        );

        data.forEach((dt) => {
          dataArray.push(dt.count);
          labelsArray.push(
            new Date(
              dt.date.year,
              dt.date.month - 1,
              dt.date.day
            ).toDateString()
          );
        });
      }

      if (labelsArray[0] !== startDate.toDateString()) {
        labelsArray.unshift(startDate.toDateString());
        dataArray.unshift(0);
      }
      if (labelsArray[labelsArray.length - 1] !== endDate.toDateString()) {
        labelsArray.push(endDate.toDateString());
        dataArray.push(0);
      }
    } else {
      // eq 1
      let tempDateObj;
      const dt = data[0];
      if (dt.year && dt.month && dt.day) {
        tempDateObj = new Date(dt.year, dt.month - 1, dt.day);
      } else {
        tempDateObj = new Date(dt.date.year, dt.date.month - 1, dt.date.day);
      }

      if (tempDateObj.getTime() === startDate.getTime()) {
        labelsArray = [tempDateObj.toDateString(), endDate.toDateString()];
        dataArray = [dt.count, 0];
      } else if (tempDateObj.getTime() === endDate.getTime()) {
        labelsArray = [startDate.toDateString(), tempDateObj.toDateString()];
        dataArray = [0, dt.count];
      } else {
        labelsArray = [
          startDate.toDateString(),
          tempDateObj.toDateString(),
          endDate.toDateString(),
        ];
        dataArray = [0, dt.count, 0];
      }
      // end eq 1
    }

    return {
      data: dataArray,
      labels: labelsArray,
    };
  }

  return {
    data: [0, 0],
    labels: [startDate.toDateString(), endDate.toDateString()],
  };
}

/**
 * Parse contact details from resopnse and return pipe separated string
 * @param {Object} cnvObject Conversation object
 * @returns {String} pipe '|' separated string
 */
function getContactDetail(cnvObject) {
  try {
    const contactDetails = [];
    if (!cnvObject) return '-';

    if (cnvObject.user_email) {
      if (
        cnvObject.user_email.modified_text &&
        cnvObject.user_email.modified_text.length
      ) {
        contactDetails.push(cnvObject.user_email.modified_text);
      } else if (cnvObject.user_email.text && cnvObject.user_email.text.length)
        contactDetails.push(cnvObject.user_email.text);
    }

    if (cnvObject.user_phone) {
      if (
        cnvObject.user_phone.modified_text &&
        cnvObject.user_phone.modified_text.length
      ) {
        contactDetails.push(cnvObject.user_phone.modified_text);
      } else if (cnvObject.user_phone.text && cnvObject.user_phone.text.length)
        contactDetails.push(cnvObject.user_phone.text);
    }

    return contactDetails.length ? contactDetails.join(' | ') : '-';
  } catch (error) {
    // console.error(error);
    return '-';
  }
}

/**
 * Convert color names to RGB
 * @param {String} colorName CSS color name (ex: "white")
 * @returns {String} hexColor (ex: #FFFFFF)
 */
function stringToColorHex(colorName) {
  // Create element
  const tempNode = document.createElement('div');
  tempNode.id = 'temp-node';
  document.body.appendChild(tempNode);

  // Set the color
  tempNode.style.color = colorName;
  let rgb = getComputedStyle(tempNode).color;
  rgb = rgb.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  );
  const hexColor =
    rgb && rgb.length === 4
      ? `#${`0${parseInt(rgb[1], 10).toString(16)}`.slice(-2)}${`0${parseInt(
        rgb[2],
        10
      ).toString(16)}`.slice(-2)}${`0${parseInt(rgb[3], 10).toString(
        16
      )}`.slice(-2)}`
      : '';

  // Cleanup: Remove elemnt
  document.body.removeChild(tempNode);
  return hexColor;
}

/**
 * Get the user's browser language, or set English-US by default
 */
const userLocale =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'en-US';

/**
 * Convert nested objects to a DOT[.] separated string
 * @param {Object} nestedMessages Nested messages object
 * @param {String} prefix
 */
function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

/**
 * Print percentage
 * @param {Number} percentage
 * @returns {String} PERCENTAGE%;
 */
function toPercentage(percentage) {
  if (percentage) {
    return `${Number(Number(percentage).toFixed(2))}%`;
  }
  return '0%';
}

/**
 *
 * @param {Array} arr
 * @param {Integer} oldIndex
 * @param {Integer} newIndex
 */
function arrayMove(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

/**
 *
 * @param {any} value
 * @param {Integer} index
 * @param {Array} self
 */
function uniqueArrayValues(value, index, self) {
  return self.indexOf(value) === index;
}

/**
 * Uppercase the first letter of a string (titleCase)
 */
function titleCase(str) {
  if (!str) return '';
  return str
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

const formateSecondsIntoDate = (sec) => {
  const { _data: time } = moment.duration(sec, 'seconds');
  const dayParts = Object.keys(time).reverse();
  let result = '';
  // I am subtracting '1' from the length because we don't want milliseconds
  for (let i = 0; i < dayParts.length - 1; i++) {
    // if the key doesn't have value don't return
    if (time[dayParts[i]]) {
      result += `${time[dayParts[i]]}${dayParts[i][0]} `;
    }
  }
  return result;
};

/**
 * Get the attribute value from the conversation data
 *
 * @param conversationData    the user data
 * @param attribute           the attribute to get the value from
 * @returns {string|*|string} the value of the attribute
 */
const getAttributeValue = (conversationData, attribute) => {
  const dataValue = conversationData[attribute];
  if (dataValue === null || dataValue === undefined) {
    return '';
  }

  if (
    Object.keys(dataValue).indexOf('modified_text') !== -1 &&
    dataValue.modified_text
  ) {
    return dataValue.modified_text;
  }
  if (Object.keys(dataValue).indexOf('text') !== -1 && dataValue.text) {
    return dataValue.text;
  }
  if (
    Object.keys(dataValue).indexOf('original_text') !== -1 &&
    dataValue.original_text
  ) {
    return dataValue.original_text;
  }
  return isObject(dataValue) ? JSON.stringify(dataValue) : dataValue;
};

export {
  getRandomInt,
  dataSortedByDate,
  getContactDetail,
  stringToColorHex,
  userLocale,
  flattenMessages,
  toPercentage,
  arrayMove,
  uniqueArrayValues,
  titleCase,
  formateSecondsIntoDate,
  getAttributeValue
};
