import { action, observable } from 'mobx';
import BaseStore from './BaseStore';

class Error extends BaseStore {
  @observable hasError = false;

  @action
  triggerError() {
    this.hasError = true;
  }
}

export default Error;
