import { decorate, observable, action, runInAction } from 'mobx';
import agent from 'agent';
import BaseStore from 'stores/BaseStore';
import FollowUp from './FollowUp.store';

class FollowUps extends BaseStore {
  followUps = [];
  selectedFollowUp;
  isLoading;

  async fetch() {
    this.isLoading = true;
    const followUps = await agent.FollowUp.list();

    runInAction(() => {
      this.followUps = followUps
        ? followUps.map((followUp) => new FollowUp(followUp))
        : [];
      this.isLoading = false;
    });
  }

  async fetchOne(id) {
    this.isLoading = true;
    const followUp = await agent.FollowUp.get(id);

    runInAction(() => {
      this.selectedFollowUp = followUp ? new FollowUp(followUp) : null;
      this.isLoading = false;
    });
  }

  async create(newFollowUp) {
    this.isLoading = true;
    const result = await agent.FollowUp.create(newFollowUp);
    let followUp;

    runInAction(() => {
      followUp = new FollowUp(result.followup);
      this.followUps.push(followUp);
      this.isLoading = false;
    });

    return followUp;
  }

  async update(newFollowUp) {
    this.isLoading = true;
    const result = await agent.FollowUp.update(newFollowUp);
    const followUp = new FollowUp(result.followup);

    runInAction(() => {
      const followUpIdx = this.followUps.findIndex(
        (item) => item.id === followUp.id
      );
      this.followUps[followUpIdx] = new FollowUp(followUp);
    });
  }

  async delete(id) {
    this.isLoading = true;
    await agent.FollowUp.delete(id);

    runInAction(() => {
      this.followUps = this.followUps.filter((followUp) => followUp.id !== id);
      this.isLoading = false;
    });
  }

  async selectFollowUp(followUp) {
    runInAction(() => {
      if (followUp instanceof FollowUp || !followUp) {
        this.selectedFollowUp = followUp;
      } else {
        this.selectedFollowUp = new FollowUp(followUp);
      }
    });
  }

  async clearSelectedFollowUp() {
    runInAction(() => {
      this.selectedFollowUp = null;
    });
  }
}

decorate(FollowUps, {
  followUps: observable,
  isLoading: observable,
  fetch: action,
  fetchOne: action,
  create: action,
  update: action,
  delete: action,
  selectFollowUp: action,
  clearSelectedFollowUp: action,
});

export default FollowUps;
