import styled from 'styled-components'
import BaseException from 'ant-design-pro/lib/Exception'

export const Exception = styled(BaseException)`
  height: 100vh;
  width: 100vw;
  background-color: white;
  position: fixed;
  z-index: 99;
  right: 0;
  top: 0;
`
