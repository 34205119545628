import { action, computed, observable } from 'mobx';
import BaseStore from './BaseStore';

function add(array, value) {
  if (array.indexOf(value) === -1) {
    array.push(value);
  }
}

class SaveStore extends BaseStore {
  @observable storesToSave = [];

  @action
  async saveStores() {
    const res = this.storesToSave.map(async (store) => {
      const saved = await store.save();
      return saved;
    });
    this.storesToSave = [];
    return res;
  }

  @computed
  get isReadyToSave() {
    return this.storesToSave.length !== 0;
  }

  @action
  addStore(store) {
    add(this.storesToSave, store);
  }
}

export default SaveStore;
