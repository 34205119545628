import BaseStore from 'stores/BaseStore';
import agent from 'agent';
import { action, decorate, observable, runInAction } from 'mobx';
import WhatsAppButton from './WhatsAppButton.store';

class WhatsAppButtons extends BaseStore {
  buttons = [];
  isLoading;

  async fetch() {
    this.isLoading = true;
    const buttons = await agent.WhatsAppButton.list();
    runInAction(() => {
      if (buttons && buttons.length > 0) {
        this.buttons = buttons.map((button) => new WhatsAppButton(button));
      }
      this.isLoading = false;
    });
  }

  async create(newButton) {
    this.isLoading = true;
    const result = await agent.WhatsAppButton.create(newButton);
    const whatsAppButton = new WhatsAppButton(result.whatsappbutton);
    runInAction(() => {
      this.buttons.push(whatsAppButton);
      this.isLoading = false;
    });
    return result;
  }

  async update(newButton) {
    this.isLoading = true;
    const result = await agent.WhatsAppButton.update(newButton);
    runInAction(() => {
      const buttonIdx = this.buttons.findIndex(
        (button) => button.id === newButton.id
      );
      this.buttons[buttonIdx] = newButton;
      this.isLoading = false;
    });
    return result;
  }

  async delete(id) {
    this.isLoading = true;
    await agent.WhatsAppButton.delete(id);
    runInAction(() => {
      this.buttons = this.buttons.filter((button) => button.id !== id);
      this.isLoading = false;
    });
  }
}

decorate(WhatsAppButtons, {
  buttons: observable,
  isLoading: observable,
  fetch: action,
  create: action,
  update: action,
  delete: action,
});

export default WhatsAppButtons;
