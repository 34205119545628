import { observable } from 'mobx';

import BaseStore from '../BaseStore';
import ActionWithOptionsStore from './ActionWithOptionsStore';
import StandardActionStore from './StandardActionStore';

class ActionStore extends BaseStore {
  @observable id = '';
  @observable content = '';
  @observable actionWithOptions = null;
  @observable standardAction = null;
  @observable editable = true;
  @observable postion = null;

  constructor(action) {
    super();
    this.id = action.id;
    this.content = action.content;
    this.editable = action.editable;
    this.postion = action.postion;
   
    if (action.actionWithOptions) {
      this.actionWithOptions = new ActionWithOptionsStore({
        actionId: this.id,
        ...action.actionWithOptions,
      });
    } else if (action.standardAction) {
      this.standardAction = new StandardActionStore({
        optionName: action.optionName,
        actionId: this.id,
        ...action.standardAction,
      });
    }
  }
}

export default ActionStore;
