import requests from './requests';

const query = `
mutation StandardActionUpdate($data: StandardActionUpdateGenericType!) {
    standardActionUpdate(newStandardaction: $data) {
        ok
        standardaction {
            id
            content: message
        }
    }
}
`;

const StandardAction = { update: (data) => requests.gql.chatserver.mutate(query, data) };

export default StandardAction;
