import { decorate, observable } from 'mobx';
import BaseStore from 'stores/BaseStore';
import AtsEntityType from 'stores/AtsEntityType.store';
class AtsBatchAction extends BaseStore {
  id;
  externalId;
  name;
  entityType;
  notificationHeader;
  notificationBody;
  url;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    if (!params) {
      return;
    }

    this.id = params?.id;
    this.externalId = params?.externalId;
    this.name = params?.name;
    this.entityType = new AtsEntityType(params?.entityType);
    this.notificationHeader = params?.notificationHeader;
    this.notificationBody = params?.notificationBody;
    this.url = params?.url;
  }
}

decorate(AtsBatchAction, {
  id: observable,
  externalId: observable,
  name: observable,
  entityType: observable,
  notificationHeader: observable,
  notificationBody: observable,
});

export default AtsBatchAction;
