import {
  CALENDAR_DATES_FORMAT,
  ScheduleAvailabilityWorkDays,
} from 'constants/calendarOverview.constants';
import dayjs from 'dayjs';

export const checkTimeSlotsOverlapping = (timeSegments) => {
  if (timeSegments.length === 1) return true;
  // sort time slots
  const sortedTimeSegments = timeSegments.sort((timeSegment1, timeSegment2) =>
    timeSegment1.startTime.localeCompare(timeSegment2.startTime)
  );
  // we loop through the array till the one before the last element in the array, we compare just the start time of the last element.
  for (let i = 0; i < sortedTimeSegments.length - 1; i++) {
    const currentEndTime = sortedTimeSegments[i].endTime;
    const nextStartTime = sortedTimeSegments[i + 1].startTime;
    if (currentEndTime > nextStartTime) {
      return false;
    }
  }
  return true;
};

export const checkIsDateValid = (date) => {
  const today = dayjs().format(CALENDAR_DATES_FORMAT);
  const newDate = dayjs(date).format(CALENDAR_DATES_FORMAT);
  const isDateNotValid = dayjs(newDate).isBefore(today);
  return isDateNotValid;
};

export const checkIsDateAlreadyExist = (newDate, dates) => {
  const dateToAdd = dayjs(newDate).format(CALENDAR_DATES_FORMAT);
  return dates.some((date) => date === dateToAdd);
};

export const sortScheduleAvailabilities = (availabilitiesArr) => {
  return availabilitiesArr.sort((timeSegment1, timeSegment2) =>
    timeSegment1.startTime.localeCompare(timeSegment2.startTime)
  );
};

/**
 * listActiveEventConfigurations returns one array of all event configurations arrays inside event preferences arrays.
 * it adds the id of the related event Preference to the event configuration
 * we got as a result one array of all event configurations, every one has the id of the event preference object that was inside it.
 *  @param  {Array} eventPreferences  array of eventPreferences.
 *  @returns {Array}
 */
export const listActiveEventConfigurations = (eventPreferences) => {
  if (!eventPreferences) return [];
  return eventPreferences
    .map((eventPreference) =>
      eventPreference.eventConfigurations.map((eventConfiguration) => ({
        ...eventConfiguration,
        relatedEventPreference: {
          ...eventPreference,
        },
      }))
    )
    .flat();
};

/**
 * getWorkDaysValuesArr returns arr of object values
 *  @returns {Array}
 */
export const getWorkDaysValuesArr = () => {
  const daysValues = [];
  for (const [key, value] of Object.entries(ScheduleAvailabilityWorkDays)) {
    daysValues.push(value);
  }
  return daysValues;
};

export const sortDates = (a, b) => {
  const date1 = a.split('-').reverse().join('');
  const date2 = b.split('-').reverse().join('');
  return date1.localeCompare(date2);
};

// if user closed the modal without creating any schema, and he doesn't have any schema we create default availabilities for event preference
const ensureEventPreferenceHasSchema = async (
  calendarUser,
  relatedEventPreference
) => {
  // Check if the relatedEventPreference exists
  if (!relatedEventPreference?.id) return;

  const hasNoSchemas = calendarUser?.availabilitySchemas?.length === 0;
  const hasNoDefaultSchema = !calendarUser?.defaultAvailabilitySchema?.id;

  // If user has no schemas and no default schema, create default availabilities
  if (hasNoSchemas && hasNoDefaultSchema) {
    await relatedEventPreference.createDefaultScheduleAvailabilities(true);
  }
};

/**
 * closeScheduleAvailabilitySchemasModal function runs when we close  scheduleAvailabilitySchemasModal modal, it chicks if any schema is updated,
 *  then it updates the backend
 *  @param  {Object} calendarUser the related calendar user
 *  @param  {Object} relatedEventPreference the related event preference if we open the side drawer from user management, we need it to update the scheduleAvailabilitySchema for a event preference
 *  @param {Function} setIsScheduleAvailabilitySchemaModalOpen function to close the modal
 */
export const closeScheduleAvailabilitySchemasModal = async (
  calendarUser,
  relatedEventPreference,
  setIsScheduleAvailabilitySchemaModalOpen
) => {
  // update schemas
  // 1- we check if defaultAvailabilitySchema for calendar user is updated,
  // ----then we check relatedEventPreference' scheduleAvailabilitySchema is the same we update the ui
  // 2- we loop over all  calendar user's availabilitySchemas and check if any is updated,
  // ----then we check relatedEventPreference' scheduleAvailabilitySchema is the same we update the ui
  if (
    calendarUser?.defaultAvailabilitySchema?.id &&
    calendarUser?.defaultAvailabilitySchema?.arePropsUpdated
  ) {
    await calendarUser?.defaultAvailabilitySchema.updateScheduleAvailabilitySchema();
    calendarUser?.defaultAvailabilitySchema.setAttr('arePropsUpdated', false);
    if (
      relatedEventPreference &&
      relatedEventPreference?.scheduleAvailabilitySchema?.id ===
        calendarUser?.defaultAvailabilitySchema?.id
    ) {
      relatedEventPreference.setAttr(
        'scheduleAvailabilitySchema',
        calendarUser?.defaultAvailabilitySchema
      );
    }
  }
  calendarUser?.availabilitySchemas?.forEach(async (schema) => {
    if (schema.arePropsUpdated) {
      await schema.updateScheduleAvailabilitySchema();
      schema.setAttr('arePropsUpdated', false);
      if (
        relatedEventPreference &&
        relatedEventPreference.scheduleAvailabilitySchema?.id === schema?.id
      ) {
        relatedEventPreference.setAttr('scheduleAvailabilitySchema', schema);
      }
    }
  });
  await ensureEventPreferenceHasSchema(calendarUser, relatedEventPreference);
  setIsScheduleAvailabilitySchemaModalOpen(false);
  calendarUser.setAttr('scheduleAvailabilitySchemaToEdit', null);
};

/**
 * handleCloseEventConfigSideDrawer: function to update event preference when side drawer is closed
 */
export const handleCloseEventConfigSideDrawer = async (
  calendarSettingsStore
) => {
  const { relatedEventPreference } = calendarSettingsStore.configurationToEdit;
  if (
    relatedEventPreference.isAnyEventPreferenceInputUpdated &&
    relatedEventPreference?.id
  ) {
    await relatedEventPreference.updateEventPreference();
  }
  // if user switched to availability schema tab, but he doesn't have one. we recreate the availabilities array foe event preference
  if (
    relatedEventPreference.isSwitchedToUseAvailabilitySchema &&
    relatedEventPreference?.id &&
    relatedEventPreference?.scheduleAvailabilitySchema === null &&
    relatedEventPreference?.scheduleAvailabilities?.length === 0
  ) {
    await relatedEventPreference.createDefaultScheduleAvailabilities(true);
  }
  calendarSettingsStore.setConfigDrawerSettings();
};

// functions for event preference availabilities switch button in event configuration side drawer

// sub functions
const getRelevantSchema = (relatedCalendarUser) => {
  const availabilitySchemas = relatedCalendarUser?.availabilitySchemas;
  const defaultAvailabilitySchema =
    relatedCalendarUser?.defaultAvailabilitySchema;
  return defaultAvailabilitySchema?.id
    ? defaultAvailabilitySchema
    : availabilitySchemas[0];
};

const removeFromEventPreferencesSet = async (
  relatedEventPreference,
  relatedCalendarUser
) => {
  const schema = getRelevantSchema(relatedCalendarUser);
  if (schema) {
    const newEventPreferencesSet = schema.eventPreferencesSet.filter(
      (eventPreference) => eventPreference?.id !== relatedEventPreference?.id
    );
    schema.setAttr('eventPreferencesSet', newEventPreferencesSet);
  }
};

const deleteAllScheduleAvailabilities = async (relatedEventPreference) => {
  const deletePromises = relatedEventPreference.scheduleAvailabilities.map(
    (availability) =>
      relatedEventPreference.deleteScheduleAvailability(availability.id)
  );
  await Promise.all(deletePromises);
};

const addToEventPreferencesSet = async (
  relatedEventPreference,
  relatedCalendarUser,
  changeIsAvailabilityModalOpen
) => {
  const schema = getRelevantSchema(relatedCalendarUser);
  if (schema?.id) {
    schema.setAttr('eventPreferencesSet', [
      ...schema.eventPreferencesSet,
      relatedEventPreference,
    ]);
    relatedEventPreference.setAttr('scheduleAvailabilitySchema', schema);
    await relatedEventPreference.updateEventPreference({
      scheduleAvailabilitySchema: schema.id,
    });
  } else {
    relatedCalendarUser.setSchemaToEditWithEventPreferenceAvailabilities(
      relatedEventPreference
    );
    changeIsAvailabilityModalOpen(true);
  }
};
//

export const switchToCustomTab = async (
  relatedEventPreference,
  relatedCalendarUser
) => {
  const updateEventPreferenceObject = false;
  await relatedEventPreference.createDefaultScheduleAvailabilities(
    updateEventPreferenceObject
  );
  await removeFromEventPreferencesSet(
    relatedEventPreference,
    relatedCalendarUser
  );
  relatedEventPreference.setAttr('scheduleAvailabilitySchema', null);
  await relatedEventPreference.updateEventPreference();
  relatedEventPreference.setAttr('isSwitchedToUseAvailabilitySchema', false);
};

export const switchToSchemaAvailabilitiesTab = async (
  relatedEventPreference,
  relatedCalendarUser,
  changeIsAvailabilityModalOpen
) => {
  relatedEventPreference.setAttr('isUpdatingAvailabilities', true);
  await deleteAllScheduleAvailabilities(relatedEventPreference);
  await addToEventPreferencesSet(
    relatedEventPreference,
    relatedCalendarUser,
    changeIsAvailabilityModalOpen
  );
  relatedEventPreference.setAttr('isUpdatingAvailabilities', false);
  relatedEventPreference.setAttr('isSwitchedToUseAvailabilitySchema', true);
};
