import requests from './requests';
import translationFragment from './translation.Fragment';

const createTranslation = `
mutation CreateTranslation($newTranslation: TranslationCreateGenericType!) {
  createTranslation(newTranslation:$newTranslation) {
    translation {
      ...translationFragment
    }
    ok
  }
}
  ${translationFragment}
`;

const updateTranslation = `
mutation UpdateTranslation($newTranslation: TranslationUpdateGenericType!) {
  updateTranslation(newTranslation:$newTranslation) {
    translation {
      ...translationFragment
    }
    ok
    errors {
      messages
    }
  }
}
  ${translationFragment}
`;

const deleteTranslation = `
mutation DeleteTranslation($id: ID!) {
  deleteTranslation(id: $id) {
    translation {
      ...translationFragment
    }
    ok
    errors {
      messages
    }
  }
}
  ${translationFragment}
`;

const Translation = {
  createTranslation: async (newTranslation) => {
    const { data } = await requests.gql.chatserver.mutate(createTranslation, {
      newTranslation,
    });
    return data.createTranslation;
  },
  updateTranslation: async (newTranslation) => {
    const { data } = await requests.gql.chatserver.mutate(updateTranslation, {
      newTranslation,
    });
    return data.updateTranslation;
  },
  deleteTranslation: async (translationId) => {
    const { data } = await requests.gql.chatserver.mutate(deleteTranslation, {
      id: translationId,
    });
    return data.deleteTranslation;
  },
};

export default Translation;
