import { decorate, observable, action } from 'mobx'
import BaseStore from './BaseStore'

class Organization extends BaseStore {
  id
  name

  constructor(args) {
    super(args)
    this.setup(args)
  }

  setup(args) {
    this.id = args.id
    this.name = args.name
  }
}

decorate(Organization, {
  id: observable,
  name: observable,
  setup: action,
})

export default Organization
