import _ from 'lodash'
import requests from './requests'

const listQuery = `
query Intents {
  intents: intentSet {
    id
    name
  }
}
`

const modelsQuery = `
query ModelsWithIntents {
    models {
      id
      intents: intentSet {
        id
        name
      }
    }
  }
`

const getQuery = `
query Intent($id: ID!) {
    intent(id: $id) {
      id
      name
      sentences: sentenceSet {
        id
        text
      }
      keywords: keywordSet {
        id
        text
      }
    }
  }
`

const createMutation = `
mutation CreateIntent($data: IntentCreateGenericType!) {
  createIntent(newIntent: $data) {
    intent {
      id
      name
    }
  }
}
`

const updateMutation = `
mutation UpdateIntent($data: IntentUpdateGenericType!) {
  updateIntent(newIntent: $data) {
    intent {
      id
      name
    }
  }
}
`

const deleteMutation = `
mutation DeleteIntent($id: ID!) {
  deleteIntent(id: $id) {
    intent {
      id
      name
    }
    ok
  }
}
`

const detectIntent = `
  mutation DetectIntent($text: String!) {
    detectIntent(text: $text) {
      intentName
    }
  }
`

const Intent = {
  list: async () => {
    const res = await requests.gql.chatserver.query(listQuery)
    return _.get(res, 'data.intents')
  },
  modelsList: async () => {
    const res = await requests.gql.chatserver.query(modelsQuery)
    return _.get(res, 'data.models')
  },
  get: async id => {
    const { data } = await requests.gql.chatserver.query(getQuery, { id })
    return data.intent
  },
  create: async data => {
    const res = await requests.gql.chatserver.mutate(createMutation, { data })
    return _.get(res, 'data.createIntent.intent')
  },
  update: async data => {
    const res = await requests.gql.chatserver.mutate(updateMutation, { data })
    return _.get(res, 'data.updateIntent.intent')
  },
  delete: async id => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, { id })
    const ok = _.get(res, 'data.deleteIntent.ok')
    if (!ok) throw new Error('can not delete intent')
  },
  detectIntent: async text => {
    const res = await requests.gql.chatserver.mutate(detectIntent, { text })
    return _.get(res, 'data.detectIntent.intentName')
  },
}

export default Intent
