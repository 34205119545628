/* eslint-disable no-param-reassign */
import { get as _get, has as _has } from 'lodash';

import { fakeFormatMessage as formatMessage } from '../locales/utils';
import { dataSortedByDate } from '../utils/utils';
import { FirebaseApp } from './firebase-config';
import requests from './requests';

const ANALYTICS_URL = '/api/v1/analytics/';
const PORTAL_ANALYTICS_URL = '/api/v1/portal-analytics/';
const CONVERSATIONS_URL = '/api/v1/conversations/';

const getProcessorsQuery = `
  query GetProcessors($ids: [ID!]) {
    processors(ids: $ids) {
      id
      analyticsDescription
    }
  }
`;

const analyticsSummaryStateMachinesLabelsQuery = `
{
  startStateMachine(id: "1") {
    statemachine {
      initialState {
        defaultOption {
          actions {
            actionWithOptions {
              optionlabelSet {
                id
                messageClass {
                  variations {
                    id
                    message {
                      id
                      textMessage {
                        id
                        content
                      }
                      linkMessage {
                        id
                        content: placeholder
                      }
                    }
                  }
                }
                option {
                  nextStateMachine {
                    id
                    name
                  }
                  nextState {
                    id
                    stategroup {
                      id
                      stateMachine {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

const getGoogleLogs = `
  query allGoogleLogs($clientId: String, $schemaName: String, $dateFirstMsg: DateTime, $dateLastMsg: DateTime) {
    allGoogleLogsClient(clientId: $clientId, schemaName: $schemaName, dateFirstMsg: $dateFirstMsg, dateLastMsg: $dateLastMsg)
  }
`;

const deleteClientStateByClientIdQuery = `
  mutation DeleteClientStateByClientId($clientId:ID!) {
  deleteClientStateByClientId(clientId:$clientId) {
    ok
    errorMessage
  }
}
`;

const Analytics = {
  analyticsSummaryStateMachinesLabels: async () => {
    const result = await requests.gql.chatserver.query(
      analyticsSummaryStateMachinesLabelsQuery,
      null
    );
    const { startStateMachine } = result.data;
    if (
      _has(startStateMachine.statemachine, 'initialState.defaultOption.actions')
    ) {
      const flatResult =
        result.data.startStateMachine.statemachine.initialState.defaultOption
          .actions;
      const actions = flatResult
        .filter((act) => act.actionWithOptions)
        .map((act) => act.actionWithOptions.optionlabelSet);
      const stateMachines = actions
        ? actions[0].reduce((acc, smWithOption) => {
            const sm = smWithOption.option;
            const nextStateMachine = _get(sm, 'nextStateMachine.id');
            const nextState = _get(
              sm,
              'nextState.stategroup[0].stateMachine.id'
            );
            const textMessageContent = _get(
              smWithOption,
              'messageClass.variations[0].message.textMessage.content'
            );
            const linkMessageContent = _get(
              smWithOption,
              'messageClass.variations[0].message.linkMessage.content'
            );
            const label = textMessageContent || linkMessageContent;
            const smName = nextStateMachine || nextState;

            if (smName) {
              acc.push({
                label: label || '',
                stateMachine: smName,
              });
              return acc;
            }
            return acc;
          }, [])
        : [];

      return stateMachines;
    }
    return [];
  },
  usersPerPeriod: async (body) => {
    body.analyticsFunction = 'activeUsersPerPeriod';
    const result = await requests.post(PORTAL_ANALYTICS_URL, body);
    return dataSortedByDate(result.docs, body);
  },
  usersPerHour: async (body) => {
    body.analyticsFunction = 'activeUsersPerHour';
    const result = await requests.post(ANALYTICS_URL, body);
    let dataArray = [];
    let labelsArray = [];

    const dataSorted = result.docs;
    if (dataSorted && dataSorted.length > 0) {
      // Sort results per day hours
      dataSorted.sort((data1, data2) => data1.hour - data2.hour);

      dataSorted.forEach((dt) => {
        const tempDate = `${String(dt.hour)}:00`;
        dataArray.push(dt.count);
        labelsArray.push(tempDate);
      });
    } else {
      // if no data is present display a fake empty results
      dataArray = [0, 0, 0, 0];
      labelsArray = ['00:00', '08:00', '16:00', '23:00'];
    }

    return {
      data: dataArray,
      labels: labelsArray,
    };
  },
  usageOutsideOfficeHours: async (body) => {
    body.analyticsFunction = 'usageOutsideOfficeHours';
    const result = await requests.post(ANALYTICS_URL, body);
    let percentage = result.percentage ? result.percentage * 100 : 0;
    percentage = percentage.toFixed(2);
    const percentageArray = [percentage, 100 - percentage];
    return {
      labels: [
        formatMessage({ id: 'agent/analytics/outsideOfficeHour' }),
        formatMessage({ id: 'agent/analytics/officeHour' }),
      ],
      data: percentageArray,
    };
  },
  top10Intents: async (body) => {
    body.analyticsFunction = 'top10Intents';
    const result = await requests.post(ANALYTICS_URL, body);
    return result.docs;
  },
  kpiPerFlow: async (body) => {
    body.analyticsFunction = 'kpiAnalytics';
    const result = await requests.post(PORTAL_ANALYTICS_URL, body);
    const keys = Object.keys(result);
    // Group by KPI ID, sum kpis and convert timestamp to DateString()
    // keys.forEach(sm => {
    //   const kpis = result[sm];
    //   if (Array.isArray(kpis)) {
    //     const newArray = kpis.reduce((acc, k) => {
    //       const timestampStr = new Date(k.timestamp).toDateString();
    //       const elmIndex = acc.findIndex(elm => new Date(elm.timestamp).toDateString() === timestampStr);
    //
    //       if (elmIndex > -1) {
    //         acc[elmIndex].kpi += k.kpi;
    //       } else {
    //         k.timestamp = timestampStr;
    //         acc.push(k);
    //       }
    //       return acc;
    //     }, []);
    //     result[sm] = newArray;
    //   }
    // });

    return result.docs;
  },
  descriptionForKPIs: async (ids = []) => {
    const result = await requests.gql.chatserver.query(getProcessorsQuery, {
      ids,
    });
    const { processors } = result.data;
    const processorsDescription = {};
    processors.forEach((ps) => {
      processorsDescription[ps.id] = ps.analyticsDescription;
    });

    return processorsDescription;
  },
  usersPerStateMachine: async (body) => {
    /**
     * The API call returns an array of objects, each object has one key (the state machine name)
     * In this function will construct a new array of state machines
     *
     * @returns {Array} state machines array [{
     *  - State machine name accessible via a key (state_machine_id)
     *  - Count total user per state machine
     *  - users array of sorted users per date
     * }]
     */
    body.analyticsFunction = 'usersPerFlow';
    const result = await requests.post(ANALYTICS_URL, body);

    // Get State Machines with total users for each state machine
    const tempResultArray = [];
    if (result.docs !== undefined) {
      Object.keys(result.docs).forEach((stateMachineName) => {
        const element = result.docs[stateMachineName];
        // Construct the state machine object
        tempResultArray.push({
          state_machine_id: stateMachineName,
          count: element.reduce((acc, obj) => acc + obj.count, 0),
          users: dataSortedByDate(element, body),
        });
      });
    }

    return tempResultArray;
  },
  funnelPerFlow: async (body) => {
    body.analyticsFunction = 'dropRateFunnel';
    const result = await requests.post(ANALYTICS_URL, body);

    // Get most FAQ per state machine
    const tempResultArray = {};
    const documents = result.docs;

    Object.keys(documents).forEach((stateMachineName) => {
      const element = documents[stateMachineName];
      const elements = element.slice(0, 5);
      tempResultArray[stateMachineName] = elements.map((elm) => ({
        message: elm.message,
        count: elm.count,
      }));
    });
    return tempResultArray;
  },
  /**
   * Get state machine, and active users per state machine for each client
   */
  usersPerFlow: async (body) => {
    body.analyticsFunction = 'usersPerFlow';
    const result = await requests.post(ANALYTICS_URL, body);
    return dataSortedByDate(result.docs, body);
  },
  /**
   * Get the date of the first conversation
   * @param {Object} body
   * @param {String} body.collection
   * @returns { Date }
   */
  getMinDate: async (body) => {
    body.analyticsFunction = 'minDate';
    const result = await requests.post(ANALYTICS_URL, body);
    return result.minDate ? new Date(result.minDate) : new Date();
  },
  /**
   * Get brief information about a chatbot conversations
   * @param {Object} body
   * @param {String} body.project_id
   * @returns {Object} Conversation objects where the key is conversation ID
   */
  conversationsBrief: async (body) => {
    body.analyticsFunction = 'brief';
    const result = await requests.post(CONVERSATIONS_URL, body);
    return result.docs;
  },
  contactInformation: async (conversations, projectId) => {
    const ids = conversations.map((cnv) => cnv.client_id);
    const body = {
      analyticsFunction: 'contacts_info',
      users: ids,
      project_id: projectId,
    };

    const result = await requests.post(CONVERSATIONS_URL, body);
    const resultObject = {};

    // Contruct Object from array of objects
    if (result.docs && result.docs.length) {
      result.docs.forEach((rs) => {
        resultObject[rs.client_id] = rs;
      });
    } else {
      return conversations;
    }

    const conversationsWithContactInfo = conversations.map((cnv) => {
      const contactDetails = resultObject[cnv.client_id];
      if (contactDetails) {
        cnv.contact_details = contactDetails;
      }
      return cnv;
    });
    return conversationsWithContactInfo;
  },
  conversationContent: async (body) => {
    body.analyticsFunction = 'conversation';
    const result = await requests.post(CONVERSATIONS_URL, body);
    return result.docs[body.conversation_id] || [];
  },
  getOpenedConversations: async (clientID) => {
    const result = [];
    const database = FirebaseApp.database();
    const conversationsRef = database.ref(
      `analytics/conversations/${clientID}`
    );
    const conversationsObjectArray = await conversationsRef.once('value');
    const snaps = await conversationsObjectArray.val();
    if (snaps) {
      const snapKeys = Object.keys(snaps);
      snapKeys.forEach((key) => result.push(snaps[key]));
    }
    return result;
  },
  openConversations: async (clientID, id) => {
    FirebaseApp.authenticate(clientID).then(async () => {
      const database = FirebaseApp.database();
      const conversationsRef = database.ref(
        `analytics/conversations/${clientID}`
      );
      await conversationsRef.push(id);
    });
  },
  /**
   * @param clientID {String}
   * @param id {String}
   */
  removeConversation: async (clientID, id) => {
    FirebaseApp.authenticate(clientID).then(async () => {
      const database = FirebaseApp.database();
      const conversationsRef = database.ref(
        `analytics/conversations/${clientID}`
      );
      const conversations = await conversationsRef.once('value');
      // TODO optimize this trick
      const values = conversations.val();
      const conversationValuesKeys = Object.keys(values);
      conversationValuesKeys.forEach(async (key) => {
        if (values[key] === id) {
          await conversationsRef.child(key).remove();
        }
      });
    });
  },
  getConversations: async (url) => {
    return requests.get(url);
  },
  /**
   * @param clientID {String}
   * @param ids {Array}
   */
  bulkRemoveConversation: async (clientID, ids) => {
    if (ids && Array.isArray(ids)) {
      ids = new Set(ids);
      FirebaseApp.authenticate(clientID).then(async () => {
        const database = FirebaseApp.database();
        const conversationsRef = database.ref(
          `analytics/conversations/${clientID}`
        );
        const conversations = await conversationsRef.once('value');
        // TODO optimize this trick
        const values = conversations.val();
        const conversationValuesKeys = Object.keys(values);
        conversationValuesKeys.forEach(async (key) => {
          if (ids.has(values[key])) {
            await conversationsRef.child(key).remove();
          }
        });
      });
    }
  },
  deleteConversationByClientId: async (clientId) => {
    return requests.gql.chatserver.mutate(deleteClientStateByClientIdQuery, {
      clientId,
    });
  },
  googleLogs: async (clientId, schemaName, dateFirstMsg, dateLastMsg) => {
    return requests.gql.chatserver.query(getGoogleLogs, {
      clientId,
      schemaName,
      dateFirstMsg,
      dateLastMsg,
    });
  },
  smartInboxAnalytics: async ({
    projectId,
    pageSize,
    pageNum,
    fetchOptions,
    starDate,
    endDate,
    isInbound,
    isSent,
    isRead,
    isReplied,
    templateNames,
    humanReadableTypes,
    teamNames,
    senderEntities,
    assignedTo,
    candidateId,
    exportCSV,
  }) => {
    return requests.get('/api/v1/client-messages/', {
      project_id: projectId,
      page_size: pageSize,
      page_num: pageNum,
      fetch_options: fetchOptions,
      start_date: starDate,
      end_date: endDate,
      is_inbound: isInbound,
      is_sent: isSent,
      is_read: isRead,
      is_replied: isReplied,
      template_names: templateNames,
      human_readable_types: humanReadableTypes,
      team_names: teamNames,
      assigned_to: assignedTo,
      sender_entities: senderEntities,
      candidate_id: candidateId,
      export: exportCSV,
    });
  },
};

export default Analytics;
