// Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import Cookies from 'js-cookie';
import {
  DEPLOYMENT_CONFIG,
  FIREBASE_CHAT_SERVER_DATA_AUTH_TOKEN_KEY,
  FIREBASE_CONFIG,
  FIREBASE_PORTAL_AUTH_TOKEN_KEY,
  cookiesOption,
} from '../constants';
import requests from './requests';

const FIRE_LOGIN_URL = '/api/v1/fire-login/';
const CONFIG = FIREBASE_CONFIG;

const FirebaseAppType = {
  CHAT_SERVER_DATA: 'chat_server_data',
  PORTAL: 'portal',
};

const publicApp = firebase.initializeApp(CONFIG, 'publicApp');
const storageApp = firebase.initializeApp(CONFIG, 'storageApp');
const deploymentsApp = firebase.initializeApp(DEPLOYMENT_CONFIG, 'deployments');

const FirebaseApp = {
  authenticate: async (name) => {
    const body = { project_id: name };
    const cachedPortalToken =
      Cookies.get(FIREBASE_PORTAL_AUTH_TOKEN_KEY) || null;
    const cachedChatServerDataToken =
      Cookies.get(FIREBASE_CHAT_SERVER_DATA_AUTH_TOKEN_KEY) || null;

    try {
      // Try to authenticate with the cached firebase tokens
      if (!cachedPortalToken || !cachedChatServerDataToken)
        throw Error('Please reconnect');
      await storageApp.auth().signInWithCustomToken(cachedPortalToken);
      await publicApp.auth().signInWithCustomToken(cachedPortalToken);
      await deploymentsApp
        .auth()
        .signInWithCustomToken(cachedChatServerDataToken);
    } catch (error) {
      // No token was cached / the cached token has expired so we will get new ones.
      const chatServerDataResult = await requests.post(FIRE_LOGIN_URL, {
        ...body,
        firebase_app: FirebaseAppType.CHAT_SERVER_DATA,
      });

      // Extract the chatServerData token
      const chatServerDataToken = chatServerDataResult.token;

      // Cache the firebase auth token
      Cookies.set(
        FIREBASE_PORTAL_AUTH_TOKEN_KEY,
        chatServerDataToken,
        cookiesOption
      );
      // Sign in with the token
      await deploymentsApp.auth().signInWithCustomToken(chatServerDataToken);

      // Fetch the portal firebase token
      const portalResult = await requests.post(FIRE_LOGIN_URL, {
        ...body,
        firebase_app: FirebaseAppType.PORTAL,
      });

      // Extract the portal token
      const portalToken = portalResult.token;

      // Cache the firebase auth token
      Cookies.set(FIREBASE_PORTAL_AUTH_TOKEN_KEY, portalToken, cookiesOption);

      // Sign in to both the storageApp and the publicApp
      await storageApp.auth().signInWithCustomToken(portalToken);
      await publicApp.auth().signInWithCustomToken(portalToken);
    }
  },
  storage: () => storageApp.storage(),
  database: () => publicApp.database(),
};

export { FirebaseApp, deploymentsApp };
