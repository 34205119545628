import agent from 'agent';
import { NOTIFICATION_TYPES } from 'constants/calendarOverview.constants';
import { action, computed, decorate, observable, runInAction } from 'mobx';
import { languageStore } from 'stores';
import BaseStore from 'stores/BaseStore';
import Translation from '../TranslationStore';
import EventPreference from './EventPreference.store';
import Notification from './Notification.store';

//this function needs to be imported later from utilities after merging the new builder
const getTranslationFromSelectedLanguageTag = (variations) => {
  if (!variations.length) return;
  const selectedLanguage = languageStore.selectedLanguage.substring(0, 2);

  const translatedContent = variations.find((variation) => {
    variation.tags[0]?.content === selectedLanguage;
  });
  if (translatedContent) return translatedContent?.content;

  const selectDutchContent = variations.find(
    (variation) => variation.tags[0]?.content === 'nl'
  );
  if (selectDutchContent) return selectDutchContent?.content;

  return variations[0]?.content;
};

class EventConfiguration extends BaseStore {
  id;
  name = '';
  color = '';
  duration = '';
  location = '';
  subject = [];
  description = [];
  customLocation = [];
  defaultAttendees = [];
  notifications = [];
  scalarNameSelector = '';
  selectorValues = [];
  isEnabled;
  relatedEventPreference = new EventPreference();
  defaultEventPreference = null;
  isNewCopyOfDefaultEventPreference = false;

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    this.id = args?.id;
    this.color = args?.color;
    this.name = args?.name;
    this.duration = args?.duration;
    this.location = args?.location;
    this.defaultAttendees = args?.defaultAttendees;
    this.selectorValues = args?.selectorValues;
    this.scalarNameSelector = args?.scalarNameSelector?.id;
    this.isEnabled = args?.isEnabled;

    if (args?.subject?.length) {
      this.subject = args.subject.map(
        (subjectItem) => new Translation(subjectItem)
      );
    }
    if (args?.description?.length) {
      this.description = args.description.map(
        (descriptionItem) => new Translation(descriptionItem)
      );
    }
    if (args?.notifications?.length) {
      this.notifications = args.notifications.map(
        (notification) => new Notification(notification)
      );
    }
    if (args?.relatedEventPreference) {
      this.relatedEventPreference = new EventPreference(
        args.relatedEventPreference
      );
    }
    if (args?.defaultEventPreference) {
      this.defaultEventPreference = new EventPreference(
        args?.defaultEventPreference
      );
    }
    if (args?.customLocation?.length) {
      this.customLocation = args.customLocation.map(
        (location) => new Translation(location)
      );
    }
  }

  get translatedSubject() {
    return getTranslationFromSelectedLanguageTag(this.subject);
  }
  get translatedCustomLocation() {
    return getTranslationFromSelectedLanguageTag(this.customLocation);
  }

  async updateEventConfiguration() {
    if (!this.id) return;
    const updatedEventConfigurationData = this.getProperties;

    try {
      const { ok } = await agent.Calendar.updateEventConfiguration(
        updatedEventConfigurationData
      );
      return ok;
    } catch (err) {
      console.error('Error updating event configuration:', err);
    }
  }

  /**
   * This function creates new notifications of different types and adds them to an array, then updates
   * the event configuration.
   */
  async createNewNotification(minutes = 1, save) {
    if (!save) {
      runInAction(() => {
        for (const notificationType in NOTIFICATION_TYPES) {
          this.notifications.push(
            new Notification({
              minutes,
              type: notificationType,
            })
          );
        }
      });
      return;
    }

    try {
      const notificationsCreated =
        await this.createEventNotificationForAllTypes(minutes);

      if (notificationsCreated) await this.updateEventConfiguration();
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * This function creates event notifications for all types and returns a boolean indicating if all
   * notifications were saved successfully.
   * @param minutes - The number of minutes before the event that the notification should be created
   * for.
   * @returns a boolean value indicating whether all notifications were saved successfully or not.
   */
  async createEventNotificationForAllTypes(minutes) {
    const notificationsPromises = Object.values(NOTIFICATION_TYPES).map(
      async (notificationType) => {
        const { ok, eventnotification } =
          await agent.EventNotification.createEventNotification({
            minutes,
            type: notificationType,
          });

        if (ok) {
          const existingNotification = this.notifications.find(
            (notif) =>
              notif.type === notificationType && notif.minutes === minutes
          );
          if (existingNotification) {
            existingNotification.setAttr('id', eventnotification.id);
          } else {
            runInAction(() => {
              this.notifications.push(new Notification(eventnotification));
            });
          }
        }
        return ok;
      }
    );

    let notificationsSaved;
    try {
      notificationsSaved = await Promise.all(notificationsPromises);
    } catch (err) {
      console.error(err);
      return false;
    }
    // check if all notifications were saved successfully.
    return notificationsSaved.length === 2 && notificationsSaved.every(Boolean);
  }

  /**
   * This function adds a new translation to a field and saves it to the backend if specified.
   * @param newTranslation - The new translation to be added to the field.
   * @param field - The name of the field in the object that the new translation will be added to.
   * @param [save=true] - A boolean parameter that determines whether the new translation should be
   * saved to the backend or just added to the frontend.
   * @returns a boolean value
   */
  async addNewTranslationToField(newTranslation, field, save = true) {
    // when save is false just add the translation to the frontend
    if (!save) {
      runInAction(() => this[field].push(new Translation(newTranslation)));
      return;
    }
    try {
      const { ok, translation } = await agent.Translation.createTranslation(
        newTranslation
      );
      if (ok) {
        runInAction(() => this[field].push(new Translation(translation)));
      }
      await this.updateEventConfiguration();
      return ok;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * This function deletes an element from a field in an entity and updates the state accordingly.
   * @param elementIndex - The index of the element to be deleted from the specified field in the entity.
   * @param field - The name of the field in the current object that contains the element to be deleted.
   * @param entity - {string} - it is a string holding the name of the agent that is used to delete the element. (e.g. Translation)
   * @param save - {boolean} - if true, the function will delete the element from the backend and update the state accordingly.
   */
  async deleteElementFromField({ elementIndex, field, entity, save }) {
    if (!save) {
      runInAction(() => {
        this[field].splice(elementIndex, 1);
      });
      return;
    }
    const elementToUpdate = this[field][elementIndex];
    const deleted = await elementToUpdate.deleteGenericType(
      entity,
      elementToUpdate.id
    );

    if (deleted) {
      runInAction(() => {
        this[field].splice(elementIndex, 1);
      });
    }
  }

  /**
   * The function returns an array of notifications with unique minute values.
   * we are using this because we are creating two instances of the same number of minutes in the backend
   * one with type EMAIL and one with type PUSH
   */
  get singledNotifications() {
    const uniqueNotifications = new Map();
    for (const notif of this.notifications) {
      if (!uniqueNotifications.has(notif.minutes)) {
        uniqueNotifications.set(notif.minutes, notif);
      }
    }
    return Array.from(uniqueNotifications.values());
  }

  notificationsWithSameMinutes(minutes) {
    return this.notifications.filter(
      (notification) => notification.minutes === minutes
    );
  }

  /**
   * This function creates translations for multi-language fields and returns a boolean indicating if all
   * translations were saved successfully.
   * @returns a boolean value indicating whether all translations were saved successfully or not. If all
   * translations were saved successfully, then the function returns true, otherwise it returns false.
   */
  async createTranslationsForMultiLanguageFields() {
    const fieldsWithTranslationsToSave = [
      'subject',
      'description',
      'customLocation',
    ];
    let translationsSaved = [];
    const savePromises = fieldsWithTranslationsToSave.flatMap((field) => {
      const translatedFieldToUpdate = this[field];
      // loop through each language and create a translation
      return translatedFieldToUpdate.map((translation, i) => {
        const descriptionFieldIsEmpty =
          field === 'description' && translation.content.trim() === '';
        const customLocationFieldIsEmpty =
          field === 'customLocation' && translation.content.trim() === '';

        if (descriptionFieldIsEmpty || customLocationFieldIsEmpty) {
          return Promise.resolve(true);
        }
        return agent.Translation.createTranslation({
          tags: translation.tags.map((tag) => tag.id),
          content: translation.content,
        })
          .then(({ ok, translation }) => {
            if (ok) {
              // update the field in the configuration to edit
              runInAction(() => {
                translatedFieldToUpdate[i] = new Translation(translation);
              });
            }
            return translation.id;
          })
          .catch((error) => {
            console.error(
              `Failed to save translation for field ${field} and language ${translation.language}: ${error}`
            );
            return false;
          });
      });
    });
    translationsSaved = await Promise.all(savePromises);
    return translationsSaved.every((ok) => ok);
  }

  async deleteAllEventConfigTranslations() {
    const fieldsWithTranslationsToDelete = [
      'subject',
      'description',
      'customLocation',
    ];
    let translationsDeleted = [];
    const deletePromises = fieldsWithTranslationsToDelete.flatMap((field) => {
      const translatedFieldToUpdate = this[field];
      // loop through each language and delete the translation
      return translatedFieldToUpdate.map((translation) => {
        if (!translation.id) {
          // If translation doesn't have an ID, there's nothing to delete
          return Promise.resolve(true);
        }
        return agent.Translation.deleteTranslation(translation.id)
          .then(({ ok }) => {
            return ok;
          })
          .catch((error) => {
            console.error(
              `Failed to delete translation for field ${field} and language ${translation.language}: ${error}`
            );
            return false;
          });
      });
    });
    translationsDeleted = await Promise.all(deletePromises);
    return translationsDeleted.every((ok) => ok);
  }

  /**
   * This function creates new notifications for all types of events based on the specified minutes.
   * @returns a boolean value. Specifically, it returns `true` if all the notifications were
   * successfully created, and `false` otherwise.
   */
  async createNewNotifications() {
    const notificationsToCreate = this.singledNotifications?.map(
      ({ minutes }) => this.createEventNotificationForAllTypes(minutes)
    );
    let notificationsCreated;
    try {
      notificationsCreated = await Promise.all(notificationsToCreate);
    } catch (err) {
      console.log('could not create notifications', err);
    }
    return notificationsCreated.every(Boolean);
  }

  get getProperties() {
    return {
      id: this.id,
      name: this.name,
      color: this.color,
      duration: this.duration,
      location: this.location,
      scalarNameSelector: this.scalarNameSelector,
      selectorValues: this.selectorValues,
      defaultEventPreference: this.defaultEventPreference?.id || null,
      subject: this.subject.map(({ id }) => id),
      description: this.description.map(({ id }) => id),
      customLocation: this.customLocation.map(({ id }) => id),
      notifications: this.notifications.map(({ id }) => id),
      defaultAttendees: this.defaultAttendees?.length
        ? this.defaultAttendees
        : null,
    };
  }

  async getWholeEventConfigToEdit(eventConfigurationId) {
    const eventConfiguration = await agent.Calendar.getEventConfiguration(
      eventConfigurationId
    );
    if (!eventConfiguration?.id) return;

    runInAction(() => {
      this.setup(eventConfiguration);
    });
  }
}

decorate(EventConfiguration, {
  id: observable,
  name: observable,
  color: observable,
  duration: observable,
  defaultAttendees: observable,
  location: observable,
  subject: observable,
  description: observable,
  customLocation: observable,
  notifications: observable,
  isEnabled: observable,
  relatedEventPreference: observable,
  translatedCustomLocation: computed,
  addNewTranslationToField: action,
  singledNotifications: computed,
  deleteElementFromField: action,
  createTranslationsForMultiLanguageFields: observable,
  scalarNameSelector: observable,
  selectorValues: observable,
  updateEventConfiguration: action,
  defaultEventPreference: observable,
  isNewCopyOfDefaultEventPreference: observable,
  translatedSubject: computed,
  getWholeEventConfigToEdit: action,
});

export default EventConfiguration;
