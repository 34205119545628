/* eslint-disable camelcase */
const {
  REACT_APP_FIREBASE_apiKey,
  REACT_APP_FIREBASE_authDomain,
  REACT_APP_FIREBASE_databaseURL,
  REACT_APP_FIREBASE_projectId,
  REACT_APP_FIREBASE_storageBucket,
  REACT_APP_FIREBASE_messagingSenderId,
  REACT_APP_HBSPT_PORTAL_ID,
  REACT_APP_HBSPT_FORM_ID_ID,
  REACT_APP_FIREBASE_DEPLOYMENTS_apiKey,
  REACT_APP_FIREBASE_DEPLOYMENTS_authDomain,
  REACT_APP_FIREBASE_DEPLOYMENTS_databaseURL,
  REACT_APP_FIREBASE_DEPLOYMENTS_projectId,
  REACT_APP_FIREBASE_DEPLOYMENTS_storageBucket,
  REACT_APP_FIREBASE_DEPLOYMENTS_messagingSenderId,
  REACT_APP_FIREBASE_DEPLOYMENTS_appId,
  REACT_APP_MICROSOFT_CALENDAR_CLIENT_ID,
  REACT_APP_SETUP_WHATSAPP_SECRET,
  REACT_APP_RANDSTAD_LOGIN_URL,
} = process.env;

const TEMPLATE_SCHEME = 'jobotitemplates';

const LoginRequestType = {
  CHANGE_PHONE_NUMBER: 'change_phone_number',
  LOGIN_CODE: 'login_code',
};

const AtsType = {
  OTYS: 'OTYS',
  BULLHORN: 'BULLHORN',
};

const JWT_LOCAL_STORAGE_KEY = 'portalJwtToken';
const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'portalRefreshToken';
const JWT_EXPIRATION_LOCAL_STORAGE_KEY = 'access_token_expiry';

// The time before the actual expiry of the JWT at which we will refresh the JWT
const JWT_EXPIRATION_BUFFER = 120;

const SELECTED_CHATBOT_LOCAL_STORAGE_KEY = 'selectedChatbotID';
const AUTH_HANDLER_STORAGE_KEY = 'authHandler';

const LANGUAGE_STORAGE_KEY = 'SelectedLanguage';

const FIREBASE_PORTAL_AUTH_TOKEN_KEY = 'fbasePortalLoginToken';
const FIREBASE_CHAT_SERVER_DATA_AUTH_TOKEN_KEY =
  'fbaseChatServerDataLoginToken';
const ALL_CAMPAIGNS_ADDITIONAL_FIELDS = 'allCampaignsAdditionalFields';

const PORTAL_HOST_NAMES = [
  'portal.joboti.com',
  'portal-staging.joboti.com',
  'portal-beta.joboti.com',
  'portal-beta-staging.joboti.com',
];

export const cookiesOption = {
  Partitioned: true,
  secure: true,
  sameSite: 'None',
  expires: 365,
};

const PORTAL_REFERRER_STORAGE_KEY = 'portalReferrer';

const BETA_PORTAL_HOST_NAME = 'portal-beta.joboti.com';
const BETA_PORTAL_STAGING_HOST_NAME = 'portal-beta-staging.joboti.com';
const PRODUCTION_PORTAL_HOST_NAME = 'portal.joboti.com';

const FIREBASE_CONFIG = {
  apiKey: REACT_APP_FIREBASE_apiKey,
  authDomain: REACT_APP_FIREBASE_authDomain,
  databaseURL: REACT_APP_FIREBASE_databaseURL,
  projectId: REACT_APP_FIREBASE_projectId,
  storageBucket: REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: REACT_APP_FIREBASE_messagingSenderId,
};

// Static URLs for the dashboard section cards
const DOCUMENTATION_URL = 'https://docs.joboti.com/';
const BLOG_URL = 'https://www.joboti.com/blog';
const GETTING_STARTED_URL =
  'https://docs.joboti.com/portal/ik-heb-een-vraag-over.../';

// This prefix is used to define the original uploaded file and its used to define compressed/uncompressed versions
const ORIGINAL_IMAGE_PREFIX = 'joboti_original_image_';

// Used to enable cross-origin requests to anywhere
const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/';

// HubSpot Constants
const HBSPT_PORTALID = REACT_APP_HBSPT_PORTAL_ID;
const HBSPT_FORMID = REACT_APP_HBSPT_FORM_ID_ID;

const TEMPLATE_CHAT_SERVER_LINK =
  process.env.REACT_APP_TEMPLATE_CHAT_SERVER_LINK;

export {
  ALL_CAMPAIGNS_ADDITIONAL_FIELDS,
  AUTH_HANDLER_STORAGE_KEY,
  AtsType,
  BETA_PORTAL_HOST_NAME,
  BETA_PORTAL_STAGING_HOST_NAME,
  BLOG_URL,
  CORS_PROXY,
  DOCUMENTATION_URL,
  FIREBASE_CHAT_SERVER_DATA_AUTH_TOKEN_KEY,
  FIREBASE_CONFIG,
  FIREBASE_PORTAL_AUTH_TOKEN_KEY,
  GETTING_STARTED_URL,
  HBSPT_FORMID,
  HBSPT_PORTALID,
  JWT_EXPIRATION_BUFFER,
  JWT_EXPIRATION_LOCAL_STORAGE_KEY,
  JWT_LOCAL_STORAGE_KEY,
  LANGUAGE_STORAGE_KEY,
  LoginRequestType,
  ORIGINAL_IMAGE_PREFIX,
  PORTAL_HOST_NAMES,
  PORTAL_REFERRER_STORAGE_KEY,
  PRODUCTION_PORTAL_HOST_NAME,
  REACT_APP_MICROSOFT_CALENDAR_CLIENT_ID,
  REACT_APP_RANDSTAD_LOGIN_URL,
  REACT_APP_SETUP_WHATSAPP_SECRET,
  REFRESH_TOKEN_LOCAL_STORAGE_KEY,
  SELECTED_CHATBOT_LOCAL_STORAGE_KEY,
  TEMPLATE_CHAT_SERVER_LINK,
  TEMPLATE_SCHEME,
};

export const INTENTS_COUNT_LIMIT = 175;
export const SENTENCES_COUNT_LIMIT = 75;
export const ENTITIES_COUNT_LIMIT = 175;

export const DEPLOYMENT_CONFIG = {
  apiKey: REACT_APP_FIREBASE_DEPLOYMENTS_apiKey,
  authDomain: REACT_APP_FIREBASE_DEPLOYMENTS_authDomain,
  databaseURL: REACT_APP_FIREBASE_DEPLOYMENTS_databaseURL,
  projectId: REACT_APP_FIREBASE_DEPLOYMENTS_projectId,
  storageBucket: REACT_APP_FIREBASE_DEPLOYMENTS_storageBucket,
  messagingSenderId: REACT_APP_FIREBASE_DEPLOYMENTS_messagingSenderId,
  appId: REACT_APP_FIREBASE_DEPLOYMENTS_appId,
};
