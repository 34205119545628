import { observable, decorate, runInAction, action } from 'mobx'
import agent from 'agent'
import { message } from 'antd'
import {
  MODEL_TRAINING_STATUS_PUBLISHING,
  MODEL_TRAINING_STATUS_PUBLISHED,
} from 'utils/constants/nlp.constant'
import BaseStore from './BaseStore'
import Intents from './Intents.store'
import Entities from './Entities.store'

export default class NLP extends BaseStore {
  intentsStore = new Intents()
  entitiesStore = new Entities()
  modelTrainingStatus
  addIntentDrawerVisible = false
  addListEntityDrawerVisible = false

  async fetchModelTrainingStatus() {
    const trainStatus = await agent.NLP.getModelTrainStatus()

    if (
      trainStatus === MODEL_TRAINING_STATUS_PUBLISHED &&
      this.modelTrainingStatus === MODEL_TRAINING_STATUS_PUBLISHING
    ) {
      message.info(
        'Published 🚀 you can use the new knowledge in the Builder 🛠',
      )
    }

    runInAction(() => {
      this.modelTrainingStatus = trainStatus
    })
  }

  async publishModel() {
    this.modelTrainingStatus = MODEL_TRAINING_STATUS_PUBLISHING
    await agent.NLP.publishModel()
  }
}

decorate(NLP, {
  intentsStore: observable,
  entitiesStore: observable,
  modelTrainingStatus: observable,
  publishModel: action,
  addIntentDrawerVisible: observable,
  addListEntityDrawerVisible: observable,
})
