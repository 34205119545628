import agent from 'agent';
import { toNumber } from 'lodash';
import { action, decorate, observable, runInAction } from 'mobx';
import { userStore } from 'stores';
import BaseStore from './BaseStore';
import Employee from './Employee.store';

class EmployeesStore extends BaseStore {
  employees = [];

  async fetch(chatbotId) {
    const employeesData = await agent.Employee.list(chatbotId);
    runInAction(() => {
      this.employees = employeesData
        ?.filter((employee) => employee.id !== userStore.employee.id)
        .map((employee) => new Employee(employee));
    });
    // add calendar user to employees and employee in userStore
    const allCalendarUsers = await agent.User.fetchAllCalendarUsers();
    if (allCalendarUsers?.length > 0) {
      runInAction(() => {
        userStore.employee = new Employee({
          ...userStore.employee,
          calendarUser: allCalendarUsers.find(
            (calendarUser) =>
              toNumber(calendarUser.userRouterId) ===
              toNumber(userStore.employee.id)
          ),
        });

        this.employees = this.employees.map(
          (employee) =>
            new Employee({
              ...employee,
              calendarUser: allCalendarUsers.find(
                (calendarUser) =>
                  toNumber(calendarUser.userRouterId) === toNumber(employee.id)
              ),
            })
        );
      });
    } else {
      runInAction(() => {
        userStore.employee = new Employee({
          ...userStore.employee,
          calendarUser: null,
        });
      });
    }
  }

  async addEmployee(data) {
    const employee = await agent.Employee.create(data);
    runInAction(() => {
      this.employees.unshift(new Employee(employee));
    });
  }

  async deleteEmployee(id) {
    try {
      const isDeleted = await agent.Employee.delete(id);
      if (isDeleted) {
        runInAction(() => {
          this.employees = this.employees.filter(
            (employee) => employee.id !== id
          );
        });
        return true;
      }
    } catch (e) {
      return false;
    }
  }
}

decorate(EmployeesStore, {
  employees: observable,
  fetch: action,
  addEmployee: action,
  deleteEmployee: action,
});

export default EmployeesStore;
