import styled from 'styled-components';

export const blue = '#0dbebe'; // The official Joboti blue

export const primaryColor = '#FF5722'; // antd's default is "#1890ff"
export const primaryColorHover = '#fff5ee'; // used in tabviews

export const altBlue = '#039BE5';

export const colorDanger = '#db221d';

export const colorWarning = '#da9e20';

export const LIGHT_GRAY = '#707070';
export const BUILDER_RED = '#be0d54';
export const BUILDER_BORDER = `10px solid ${BUILDER_RED}`;
export const dashedBorderLight = '2px dashed #eee';
export const dashedBorder = '2px dashed #e6e6e6';
export const bodyColor = '#333';

// TODO: remove this object and use the variables from the css file
export const THEME = {
  CARROT_ORANGE: '#f88d2b',
  ORANGE: '#d87e2a',
  BLUE: '#0097ce',
  DARK_BLUE: '#182642',
  GREEN: '#6cca98',
  PURPLE: '#8b85ca',
  YELLOW: '#ffb71b',
  GRAY: '#E9E8ED',
  LIGHT_GRAY: '#FAFAFA',
  DARK_GRAY: ' #9a9a9a',
  ERROR: '#db221d',
};

export const dashedTab = `
  top: 2px;
  border-bottom: 0px dashed #000;
  padding-bottom: 6px;
`;

export const PageContainer = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex: 1;
  background-color: #f5f5f5;
`;

export const rtlStyling = `
  direction: rtl;
  text-align: right;`;

// primary button style.
// used in login and documentation button so far
export const primaryButtonStyle = `
  border: none;
  display: block;
  background-color: var(--carrot-orange) !important;
  color: var(--dark-blue) !important;
  font-weight: 800;
  text-transform: capitalize;
  border-radius: 4px !important;
  font-size: 15px !important;
  &:hover{
   background-color : var(--orange) !important;
   color: var(--dark-blue) !important;
  }
`;

// this button design is used in the header
export const secondaryButtonStyle = `
  border-radius: 4px !important;
  text-transform: capitalize !important;
  &:hover{
   border-color : var(--carrot-orange) !important;
  }
   &:focus {
    border-color: var(--carrot-orange) !important;
  }
`;

export const headerStyle = `
  text-transform: uppercase;
  font-family: var(--subTitle-font);
  font-size: var(--Title-fontSize);
  color: var(--carrot-orange);
`;
