import React from 'react';
import agent from 'agent';
import AllAtsFieldMappings from 'agent/ats-mapping.agent';
import {
  campaignStatusType,
  campaignTypes,
  publishStageStatus,
  publishStageStatusTypes,
} from 'constants/campaign.constants';
import { action, computed, decorate, observable, runInAction } from 'mobx';
import AtsFieldMapping from 'stores/AtsFieldMapping.store';
import BaseStore from 'stores/BaseStore';
import InboxLabel from 'stores/InboxLabelStore';
import Broadcast from 'stores/campaigns/BroadCastStore';
import CampaignDetail from 'stores/campaigns/CampaignDetail.store';
import Flow from '../Flows/Flow.store';
import AtsBatchAction from './AtsBatchAction.store';
import AutoMatch from './AutoMatch.store';
import CampaignActions from './CampaignActions.store';
import FollowUp from './FollowUp.store';
import Processor from './Processor.store';
import RequiredDataField from './RequiredData.store';
import WhatsAppDefinition from './WhatsAppDefinition/WhatsAppDefinition.store';

class Campaign extends BaseStore {
  id;
  name;
  isSmartInbox = false;
  description;
  whitelist = [];
  analyticsDocumentId;
  type;
  created;
  broadcast = null;
  status;
  campaignAnalytics;
  campaignConversations;
  filteredCampaignConversations;
  whatsAppDefinition;
  flow = [];
  requiredData = [];
  atsFieldMappings = [];
  campaignDetail;
  campaignActions;
  onFinishProcessor;
  onFailProcessor;
  followUp = [];
  selectedAtsFieldMapping;
  isEditMode = false;
  atsBatchActions = [];
  clientDescription;
  clientDescriptionImages = [];
  atsBatchActionEnabled;
  loading = false;
  hasActiveTemplate = false;
  smartInboxLabels = [];
  removeSmartInboxLabels = [];
  draftAutomatch = {};
  activeAutomatch = {};
  testSearchResults = [];
  testMatchResults = [];
  selectedSearchResults = [];
  engageEnabled = false;
  destinationGetterData;
  isDestinationGetterLoading = false;
  togglingScheduleStatus = false;
  automatchPublishing = false;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    if (!params) {
      return;
    }

    this.id = params?.id;
    this.isSmartInbox = params?.isSmartInbox;
    this.name = params?.name;
    this.description = params?.description;
    this.whitelist = params?.whitelist;
    this.analyticsDocumentId = params?.analyticsDocumentId;
    this.type = params?.type;
    this.status =
      (this.type === campaignTypes.AUTOMATCH_VAC2CAND ||
        this.type === campaignTypes.AUTOMATCH_CAND2VAC) &&
      params?.status === 'A_2'
        ? 'A_0'
        : params?.status;
    this.created = params?.created;
    this.hasActiveTemplate = params?.hasActiveTemplate;
    this.engageEnabled = params?.engageEnabled;
    this.flow = new Flow(params?.flow);
    this.atsFieldMappings = params?.atsFieldMappings?.map(
      (atsField) => new AtsFieldMapping(atsField)
    );
    this.clientDescription = params?.clientDescription;
    this.clientDescriptionImages = params?.clientDescriptionImages;
    this.atsBatchActions =
      params?.atsBatchActions?.map(
        (batchAction) => new AtsBatchAction(batchAction)
      ) || [];
    this.atsBatchActionEnabled = !!params?.atsBatchActions?.length;
    this.smartInboxLabels = params?.smartInboxLabels
      ? params.smartInboxLabels.map((label) => new InboxLabel(label))
      : [];
    this.removeSmartInboxLabels = params?.removeSmartInboxLabels
      ? params.removeSmartInboxLabels.map((label) => new InboxLabel(label))
      : [];
    if (params?.requiredData && params?.requiredData.length > 0) {
      params.requiredData.forEach((requiredDataField) => {
        if (requiredDataField.name) {
          this.requiredData.push(new RequiredDataField(requiredDataField));
        }
      });
    }

    if (params?.type === campaignTypes.BROADCAST) {
      this.broadcast = new Broadcast(params.broadcast || {});
    }

    if (params?.whatsappDefinition) {
      this.whatsAppDefinition = new WhatsAppDefinition(
        params.whatsappDefinition
      );
    }
    // Initialize the campaign detail store. This will be responsible for showing the campaign conversations on the
    // detail page.
    this.campaignDetail = new CampaignDetail(this);
    this.campaignActions = new CampaignActions(params?.campaignActions);

    this.onFinishProcessor = new Processor(params?.onFinishProcessor);
    this.onFailProcessor = new Processor(params?.onFailProcessor);
    this.followUp = new FollowUp(params?.followUp);
    this.draftAutomatch = new AutoMatch(params?.draftAutomatch);
    this.activeAutomatch = new AutoMatch(params?.activeAutomatch);
  }

  async save(showMessage) {
    const data = {
      id: this.id,
      name: this.name,
      description: this.description,
      whitelist: this.whitelist,
      analyticsDocumentId: this.analyticsDocumentId,
      type: this.type,
      status: this.status,
      flow: this.flow.id,
      onFinishProcessor: this.onFinishProcessor.id,
      onFailProcessor: this.onFailProcessor.id,
      whatsappDefinition: this.whatsAppDefinition?.id,
      followUp: this.followUp.id,
      atsFieldMappings: this.atsFieldMappings?.map(({ id }) => id),
      isSmartInbox: this.isSmartInbox,
      clientDescriptionImages: this.clientDescriptionImages,
      clientDescription: this.clientDescription,
      atsBatchActions: this.atsBatchActions?.map(({ id }) => id),
      smartInboxLabels: this.smartInboxLabels.map((label) => label.id),
      removeSmartInboxLabels: this.removeSmartInboxLabels.map(
        (label) => label.id
      ),
      engageEnabled: this.engageEnabled,
    };
    const res = await agent.Campaign.updateCampaign(data);
    try {
      if (!res?.data?.updateCampaign?.ok) {
        throw new Error(res.message);
      }

      // showNotification takes object as argument {status,id,messageContent}
      // status is representing the status of the operation ex: successful,error,..etc
      // messageContent is the text we want to display in the notification message if we want to add it by ourself (optional)
      // id is the message id of the translation

      if (showMessage) {
        this.showNotification({
          status: 'success',
          id: 'components/campaigns/CampaignOverview/campaign/CampaignEditForm/updateCampaign',
        });
      }
    } catch (error) {
      console.log(error.message);
      if (showMessage) {
        this.showNotification('error');
      }
    }
  }

  async updateAtsFieldMapping(newAtsFieldMapping) {
    const atsFieldMappingToEdit = this.atsFieldMappings.findIndex(
      ({ id }) => id === this.selectedAtsFieldMapping?.id
    );
    this.atsFieldMappings[atsFieldMappingToEdit] = newAtsFieldMapping;
    await this.save();
  }

  async addAtsFieldMapping(newAtsFieldMapping) {
    const res = await AllAtsFieldMappings.cloneAtsFieldMapping(
      newAtsFieldMapping?.id
    );
    const clonedAtsFieldMapping =
      res.data.cloneAtsFieldMapping?.atsFieldMapping;
    runInAction(() => {
      this.atsFieldMappings.push(new AtsFieldMapping(clonedAtsFieldMapping));
    });
    if (this.id) {
      await this.save();
    }
  }

  async deleteAtsFieldMapping(selectedAtsFieldMapping) {
    this.atsFieldMappings = this.atsFieldMappings.filter(
      (atsFieldMapping) => atsFieldMapping?.id !== selectedAtsFieldMapping?.id
    );
    await this.save();
  }

  async syncRequiredData() {
    const result = await agent.Campaign.syncAutomationRequiredDataMutation(
      this.id
    );
    const requiredData = [];
    runInAction(() => {
      result.forEach((requiredDataField) => {
        if (requiredDataField.name) {
          requiredData.push(new RequiredDataField(requiredDataField));
        }
      });

      this.requiredData = requiredData;
    });
  }

  async setupBatchAction(campaignId) {
    runInAction(() => {
      this.atsBatchActionEnabled = !this.atsBatchActionEnabled;
      this.loading = true;
    });

    const res = await agent.AtsBatchAction.setup(
      null,
      campaignId,
      this.atsBatchActionEnabled
    );

    runInAction(() => {
      if (!res.ok) {
        this.atsBatchActionEnabled = !this.atsBatchActionEnabled;
      } else {
        this.loading = false;
        this.atsBatchActions = [...res.campaign.atsBatchActions];
      }
    });
    await this.save();
  }

  /**
   * The function fetchActiveTemplate fetches the active template for a campaign and assigns it to the
   * WhatsAppDefinition property of the current object.
   */
  async fetchActiveTemplate() {
    const result = await agent.Campaign.getTriggerCampaignData(this.id);
    runInAction(() => {
      this.whatsAppDefinition = new WhatsAppDefinition(
        result?.whatsappDefinition
      );
      if (!this.broadcast?.id && result.broadcast) {
        // We set the broadcast id if it's missing so the trigger campaign
        // sidedrawer works properly
        this.broadcast = new Broadcast(result?.broadcast);
      }
    });
  }

  async publishDraftAutomatch() {
    try {
      this.automatchPublishing = true;
      const res = await agent.Campaign.cloneConfiguration(this.id);

      if (!res.ok) {
        throw new Error(res.message);
      }
      runInAction(() => {
        this.activeAutomatch = new AutoMatch(res?.campaign?.activeAutomatch);
        this.draftAutomatch = new AutoMatch(res?.campaign?.draftAutomatch);
        this.automatchPublishing = false;
      });
    } catch (error) {
      console.error(error);
    }
  }

  async toggleConfigurationSchedule(enabled) {
    try {
      this.togglingScheduleStatus = true;
      const { ok, campaign, errorMessage } =
        await agent.Campaign.toggleConfigurationSchedule(this.id, enabled);

      if (!ok) {
        throw new Error(errorMessage);
      }

      runInAction(() => {
        this.activeAutomatch = new AutoMatch(campaign?.activeAutomatch);
        this.togglingScheduleStatus = false;
      });

      return ok;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async fetchTestSearchResults(campaignId, matchPageLimit) {
    try {
      this.testSearchResults = [];
      const { testSearch } = await agent.Query.testSearchQueryStatement(
        campaignId,
        matchPageLimit
      );

      if (testSearch.success) {
        runInAction(() => {
          this.testSearchResults = testSearch.documents.map(
            ({ fields, id }) => {
              const parsedFields = JSON.parse(fields);
              const result = {
                id,
                name: parsedFields?.vacancytitle || parsedFields?.jobtitle,
                ...Object.fromEntries(
                  Object.entries(parsedFields).map(([key, value]) => [
                    key,
                    value[0] || '',
                  ])
                ),
              };

              return result;
            }
          );
        });
      }
    } catch (error) {
      console.error('Error fetching test search:', error);
    }
  }

  async fetchTestMatchResults(campaignId, documentIds, matchPageLimit) {
    try {
      const { testMatch } = await agent.Query.testMatchQueryStatement(
        campaignId,
        documentIds,
        matchPageLimit
      );

      if (!testMatch?.success) {
        throw new Error(testMatch?.message);
      }

      runInAction(() => {
        this.testMatchResults = testMatch.documents.map(
          ({ fields, id, vacancyId }) => {
            const parsedFields = JSON.parse(fields);

            const vacancyName = this.testSearchResults.find(
              (testRes) => testRes?.id === vacancyId
            )?.name;

            const results = {
              id,
              vacancyName,
              vacancyId,
              name: parsedFields.name?.[0],
              phone: parsedFields.phone?.join(', '),
              email: parsedFields.email?.join(', '),
            };

            return results;
          }
        );
      });
    } catch (error) {
      console.log('Error fetching test match:', error);
    }
  }

  get groupedTestMatchResults() {
    if (this.selectedSearchResults?.length) {
      return this.testMatchResults.reduce((acc, current) => {
        const { vacancyName } = current;
        if (!acc[vacancyName]) {
          acc[vacancyName] = [];
        }
        acc[vacancyName].push(current);
        return acc;
      }, {});
    }
    return {};
  }

  async executeDestinationGetter() {
    this.isDestinationGetterLoading = true;
    this.destinationGetterData = null;
    try {
      const res = await agent.Broadcast.executeDestinationGetter(
        this.broadcast.id
      );
      runInAction(() => {
        this.destinationGetterData = JSON.parse(res.targets);
      });

      return res;
    } catch (e) {
      this.showNotification({
        status: 'error',
        id: 'components/campaigns/CampaignOverview/SendMessageSideDrawer/SendThroughMapper/GetRecipientsError',
      });
      console.log(e);
    } finally {
      runInAction(() => {
        this.isDestinationGetterLoading = false;
      });
    }
  }

  // eslint-disable-next-line getter-return
  get automatchStatus() {
    const {
      status,
      activeAutomatch,
      draftAutomatch,
      togglingScheduleStatus,
      automatchPublishing,
    } = this;
    const isActive = status === campaignStatusType.ACTIVE;
    const publishStage = activeAutomatch?.publishStage;
    const isToggleSchedule =
      publishStage === publishStageStatusTypes.TOGGLE_SCHEDULE;
    const isPublishing =
      publishStage === publishStageStatusTypes.CLONE_CONNECTOR_SETTINGS;
    const activeAutomatchDate = new Date(activeAutomatch?.modified);
    const draftAutomatchDate = new Date(draftAutomatch?.modified);
    const isPublishDisabled =
      activeAutomatchDate > draftAutomatchDate ||
      isToggleSchedule ||
      isPublishing;
    const isPublished = publishStage === 'PUBLISHED';
    const isAutomationRunning = status === 'A_1' && isPublishDisabled;
    const needsPublishing = status === 'A_1' && !isPublishDisabled;
    const automationNotRunning = status === 'A_0' && isPublishDisabled;
    const hasFailed = publishStage === 'FAILED';
    let statusMessageID;

    if (status === 'A_0' && isPublished) {
      statusMessageID = 'AUTOMATION_NOT_RUNNING';
    } else if (isPublished && isAutomationRunning) {
      statusMessageID = 'AUTOMATION_RUNNING';
    } else if (needsPublishing) {
      statusMessageID = 'AUTOMATION_NEED_PUBLISH';
    } else if (automationNotRunning) {
      statusMessageID = 'PREVIOUS_AUTOMATION_NOT_RUNNING';
    } else if (hasFailed) {
      statusMessageID = 'FAILED';
    }

    if (isToggleSchedule || togglingScheduleStatus) {
      return isActive ? 'Disabling schedule' : 'Enabling schedule';
    }

    if (isPublishing || automatchPublishing) {
      return publishStageStatus.CLONE_CONNECTOR_SETTINGS;
    }

    return publishStageStatus[statusMessageID];
  }

  async pollAutomationPublish() {
    let pollResult = null;

    try {
      const res = await agent.Campaign.PollAutomatchPublishStatus(this.id);
      const {
        ok,
        campaign,
        pollResult: result,
        // eslint-disable-next-line no-unsafe-optional-chaining
      } = res?.data?.pollAutomatchPublishStatus;
      pollResult = result;

      if (!ok) {
        throw new Error(res.message);
      }

      if (pollResult !== null) {
        runInAction(() => {
          this.status = campaign?.status;
          this.activeAutomatch = new AutoMatch(campaign?.activeAutomatch);
        });
      }
    } catch (error) {
      console.log('Error:', error.message);
    } finally {
      setTimeout(() => {
        if (pollResult === null) {
          this.pollAutomationPublish();
        }
      }, 5000);
    }
  }
}

decorate(Campaign, {
  id: observable,
  name: observable,
  description: observable,
  analytics_document_id: observable,
  type: observable,
  broadcast: observable,
  status: observable,
  isSmartInbox: observable,
  campaignAnalytics: observable,
  whitelist: observable,
  flow: observable,
  whatsAppDefinition: observable,
  requiredData: observable,
  atsFieldMappings: observable,
  campaignActions: observable,
  onFinishProcessor: observable,
  onFailProcessor: observable,
  followUp: observable,
  saveToLocalStorage: action,
  selectedAtsFieldMapping: observable,
  isEditMode: observable,
  clientDescription: observable,
  clientDescriptionImages: observable,
  atsBatchActionEnabled: observable,
  atsBatchActions: observable,
  loading: observable,
  save: action,
  syncRequiredData: action,
  addAtsFieldMapping: action,
  updateAtsFieldMapping: action,
  deleteAtsFieldMapping: action,
  setupBatchAction: action,
  fetchActiveTemplate: action,
  hasActiveTemplate: observable,
  smartInboxLabels: observable,
  removeSmartInboxLabels: observable,
  draftAutomatch: observable,
  activeAutomatch: observable,
  publishDraftAutomatch: action,
  toggleConfigurationSchedule: action,
  fetchTestSearchResults: action,
  testSearchResults: observable,
  testMatchResults: observable,
  selectedSearchResults: observable,
  groupedTestMatchResults: computed,
  engageEnabled: observable,
  executeDestinationGetter: action,
  destinationGetterData: observable,
  isDestinationGetterLoading: observable,
  automatchStatus: computed,
  pollAutomationPublish: action,
  togglingScheduleStatus: observable,
  automatchPublishing: observable,
});

export default Campaign;
