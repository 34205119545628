import _ from 'lodash'
import requests from './requests'

const createMutation = `
mutation CreateListEntityValue($data: ListEntityValueCreateGenericType!) {
  createListEntityValue(newListentityvalue: $data) {
    listentityvalue {
      id
      name
      synonyms: listentitysynonymSet {
        id
        name
      }
    }
  }
}
`

const updateMutation = `
mutation UpdateListEntityValue($data: ListEntityValueUpdateGenericType!) {
  updateListEntityValue(newListentityvalue: $data) {
    listentityvalue {
      id
      name
      synonyms: listentitysynonymSet {
        id
        name
      }
    }
  }
}

`

const deleteMutation = `
mutation DeleteListEntityValue($id: ID!) {
  deleteListEntityValue(id: $id) {
    listentityvalue {
      id
      name
      synonyms: listentitysynonymSet {
        id
        name
      }
    }
    ok
  }
}
`

export default {
  create: async data => {
    const res = await requests.gql.chatserver.mutate(createMutation, { data })
    return _.get(res, 'data.createListEntityValue.listentityvalue')
  },
  update: async data => {
    const res = await requests.gql.chatserver.mutate(updateMutation, { data })
    return _.get(res, 'data.updateListEntityValue.listentityvalue')
  },
  delete: async id => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, { id })
    const ok = _.get(res, 'data.deleteListEntityValue.ok')
    if (!ok) throw new Error('can not delete list entity value')
  },
}
