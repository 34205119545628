import { action, decorate, observable, runInAction } from 'mobx';
import BaseStore from 'stores/BaseStore';
import agent from 'agent';
import Report from './Report.store';

class CustomAnalytics extends BaseStore {
  reports = []
  isLoading

  async fetch() {
    this.isLoading = true
    const reports = await agent.CustomAnalytics.all()
    runInAction(() => {
      this.reports = reports.map(report => new Report(report))
      this.isLoading = false
    })
  }
}

decorate(CustomAnalytics, {
  reports: observable,
  isLoading: observable,
  fetch: action,
})

export default CustomAnalytics;
