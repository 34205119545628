import agent from 'agent';
import { message } from 'antd/lib';
import { fakeFormatMessage } from 'locales/utils';
import { action, decorate, observable, runInAction } from 'mobx';
import BaseStore from 'stores/BaseStore';
import { userStore } from 'stores/index';
import { CampaignConversationSource } from '../../constants/campaign.constants';
import CampaignActionStep from './CampaignActionStep.store';
import CampaignTargetEntity from './CampaignTargetEntity.store';

class CampaignConversation extends BaseStore {
  id;
  // Whether the conversation is from a Engage (Campaign Conversation) or ASHL (Automatch Conversation)
  source = CampaignConversationSource.ENGAGE.value;
  campaignEventId;
  campaignId;
  clientId;
  conversationData;
  conversationStart;
  conversationEnd;
  kpis;
  status;
  statusHistory;
  messages;
  userPhone = '';
  automationName = '';
  actionType = '';
  targetEntityId = '';
  allActionTargetEntities = [];
  selectedActionSteps = [];
  isLoadingActionStep = false;
  isLoadingTargetEntityActions = false;
  showSelectedActionStepDrawer = false;
  selectedActionStep = '';

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.id = params.id;
    this.source = params.source || CampaignConversationSource.ENGAGE;
    this.campaignEventId = params.campaign_event_id;
    this.campaignId = params.campaign_id;
    this.conversationData = params.conversation_data;
    this.clientId = params.client_id || this.conversationData?.client_id;
    this.conversationStart = params.conversation_start;
    this.conversationEnd = params.conversation_end;
    this.kpis = params.kpis;
    this.status = params.status;
    this.statusHistory = params.status_history;
    this.errorReason = params.error_reason;
    this.automationName = this.conversationData.automation_name;
    this.actionType = this.conversationData.actionType;
    this.targetEntityId = this.conversationData.targetEntityId;
    if (Object.keys(this.conversationData).indexOf('user_phone') !== -1) {
      this.userPhone =
        this.conversationData.user_phone.modified_text ||
        this.conversationData.phone;
    }
  }

  async fetchMessages() {
    const tenant = userStore.selectedChatbot.name;

    let campaignConversationId;
    if (this.source === CampaignConversationSource.ASHL) {
      campaignConversationId = this.conversationData?.campaign_conversation_id;
    } else {
      campaignConversationId = this.id;
    }

    if (!campaignConversationId) {
      // No campaign conversation id, nothing to fetch
      return;
    }

    const messages = await agent.CampaignConversations.fetchMessages(
      tenant,
      this.conversationStart,
      this.conversationEnd,
      this.clientId,
      campaignConversationId
    );
    runInAction(() => {
      this.messages = messages;
    });
  }

  async fetchAllAshlTargetEntityActions() {
    try {
      runInAction(() => {
        this.isLoadingTargetEntityActions = true;
      });
      const targetEntityActions =
        await agent.CampaignConversations.getAllAshlTargetEntityActions({
          automationId: this.campaignId,
          targetEntityId: this.targetEntityId,
        });
      runInAction(() => {
        const sortedTargetEntityActionsByDate = targetEntityActions.sort(
          (a, b) => new Date(a.startedAt) - new Date(b.startedAt)
        );
        this.allActionTargetEntities = sortedTargetEntityActionsByDate?.map(
          (EntityAction) => new CampaignTargetEntity(EntityAction)
        );
        this.isLoadingTargetEntityActions = false;
      });
    } catch (error) {
      console.error('error fetch all Ashl targetEntity actions ', error);
      runInAction(() => {
        this.isLoadingTargetEntityActions = false;
      });
    }
  }

  async fetchAshlActionStep(actionId, step) {
    try {
      runInAction(() => {
        this.isLoadingActionStep = true;
        this.selectedActionStep = step;
      });
      const ashlActionSteps =
        await agent.CampaignConversations.getAshlActionSteps({
          actionId,
          automationId: this.campaignId,
        });
      runInAction(() => {
        this.selectedActionSteps = ashlActionSteps.map(
          (actionStep) => new CampaignActionStep(actionStep)
        );
        this.isLoadingActionStep = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoadingActionStep = false;
        message.error(fakeFormatMessage({ id: 'generic.Failure' }));
      });
    }
  }
}

decorate(CampaignConversation, {
  id: observable,
  source: observable,
  campaignEventId: observable,
  campaignId: observable,
  clientId: observable,
  conversationData: observable,
  conversationStart: observable,
  conversationEnd: observable,
  kpis: observable,
  status: observable,
  statusHistory: observable,
  messages: observable,
  userPhone: observable,
  errorReason: observable,
  automationName: observable,
  fetchMessages: action,
  actionType: observable,
  targetEntityId: observable,
  fetchAllAshlTargetEntityActions: action,
  allActionTargetEntities: observable,
  fetchAshlActionStep: action,
  selectedActionSteps: observable,
  isLoadingActionStep: observable,
  showSelectedActionStepDrawer: observable,
  selectedActionStep: observable,
  isLoadingTargetEntityActions: observable,
});

export default CampaignConversation;
