import { processorFragment } from './fragments/builder';
import {
  atsFieldOrderingFragment,
  mappingOptionFragment,
  statesFragment,
} from './fragments/builder/atsMappingFragments';
import requests from './requests';

const updateProcessorMutation = `
  mutation UpdateProcessor ($data: ProcessorUpdateGenericType!) {
    updateProcessor( newProcessor: $data) {
      ok
    }
  }
`;

const cloneProcessorMutation = `
mutation CloneProcessor( $id: ID!, $fromSchema: String, $toSchema: String! ){
  cloneProcessor(processorId: $id, fromSchema: $fromSchema, toSchema: $toSchema) {
    ok
    clonedProcessor {
      ...processorFragment
    }
  }
}
${processorFragment}
`;

const copyProcessorMutation = `
mutation CopyProcessor($processorTemplateIds:[ID], $fromSchema: String, $toSchema: String!){
  copyProcessor(processorTemplateIds:$processorTemplateIds, fromSchema:$fromSchema, toSchema:$toSchema) {
    ok
    copiedProcessor {
      ...processorFragment
    }
  }
}
${processorFragment}
`;

const fetchProcessorMapper = `
query FetchProcessorMapper($id: UUID!) {
  allProcessors(id: $id) {
    id
    humanReadableName
    atsFieldMappings {
      id
      type
      dataType
      isLocked
      value
      isDefaultMapping
      prepend
      append
      postModifiers
      prependAppendSeparator
      states {
        ...statesFragment
      }
      atsOptions{
          value
          humanReadableName
          id
          }
      mappingOptions {
          ...mappingOption
          }
      atsFieldOrdering: atsfieldorderingSet {
          ...atsFieldOrderingFragment
      }
      scalarName {
          id
          name
      }
    }
  }
}
${mappingOptionFragment}
${atsFieldOrderingFragment}
${statesFragment}
`;

const fetchOne = `
query FetchOneProcessor($id: ID!) {
  processor(id: $id) {
    ...processorFragment
  }
}

${processorFragment}
`;

const allProcessors = /* GraphQL */ `
  query FetchProcessors(
    $processorType_In: String
    $processorType_NotIn: String
  ) {
    allProcessors(
      processorType_In: $processorType_In
      processorType_NotIn: $processorType_NotIn
    ) {
      ...processorFragment
    }
  }
  ${processorFragment}
`;

const Processor = {
  update: async (data) =>
    requests.gql.chatserver.mutate(updateProcessorMutation, {
      data,
    }),
  cloneProcessor: async (id, fromSchema, toSchema) =>
    requests.gql.chatserver.mutate(cloneProcessorMutation, {
      id,
      fromSchema,
      toSchema,
    }),
  copyProcessor: async (data) =>
    requests.gql.chatserver.mutate(copyProcessorMutation, data),
  fetchOne: async (id) => requests.gql.chatserver.query(fetchOne, { id }),
  fetchProcessorMapper: async (id) =>
    requests.gql.chatserver.query(fetchProcessorMapper, { id }),
  getAllProcessors: async (processorIdIn) =>
    requests.gql.chatserver.query(allProcessors, {
      processorId_In: processorIdIn,
    }),
};

export default Processor;
