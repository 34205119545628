export const LABELS_DEFAULT_COLORS = [
  '#ff00ff',
  '#9900ff',
  '#0000ff',
  '#46bec6',
  '#30cd41',
  '#f9d900',
  '#f5a622',
  '#ff0000',
  '#af7e2e',
  '#e65100',
  '#b5bcc2',
  '#667684',
  '#bf55ec',
  '#ff7fab',
  '#02bcd4',
  '#1bbc9c',
];
