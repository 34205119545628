import BaseStore from 'stores/BaseStore';
import TagStore from 'stores/ActionStore/TagStore';
import agent from 'agent';
import { action, decorate, observable, runInAction } from 'mobx';

class TagsStore extends BaseStore {
  tags = [];
  isLoading;

  async create(newTag) {
    this.isLoading = true;
    const result = await agent.Tag.create(newTag);
    let tag = null;
    runInAction(() => {
      tag = new TagStore(result.tag);
      this.tags.push(tag);
      this.isLoading = false;
    });
    return tag;
  }

  async update(newTag) {
    this.isLoading = true;
    await agent.Tag.update(newTag);
    runInAction(() => {
      const tagIdx = this.tags.findIndex((tag) => tag.id === newTag.id);
      this.tags[tagIdx] = newTag;
      this.isLoading = false;
    });
  }

  async delete(id) {
    this.isLoading = true;
    await agent.Tag.delete(id);
    runInAction(() => {
      this.tags = this.tags.filter((tag) => tag.id !== id);
      this.isLoading = false;
    });
  }
}

decorate(TagsStore, {
  tags: observable,
  isLoading: observable,
  create: action,
  update: action,
  delete: action,
});

export default TagsStore;
