import { decorate, observable, action } from 'mobx'
import _ from 'lodash'
import agent from 'agent'
import BaseStore from './BaseStore'

class Sentence extends BaseStore {
  id
  text
  constructor(params) {
    super()
    this.setup(params)
    this.save = _.debounce(this.save, 1500)
  }

  setup({ id, text }) {
    this.id = id
    this.text = text
  }

  async save() {
    const data = {
      id: this.id,
      text: this.text,
    }
    await agent.Sentence.update(data)
  }

  static async create(text, intentId) {
    const sentence = await agent.Sentence.create({ text, intent: intentId })
    return new Sentence(sentence)
  }

  static async init(text1, text2, text3, intentId) {
    const sentences = await agent.Sentence.init({
      text1,
      text2,
      text3,
      intent: intentId,
    })
    return sentences.map(sentence => new Sentence(sentence))
  }

  static async delete(id) {
    await agent.Sentence.delete(id)
  }
}

decorate(Sentence, {
  id: observable,
  text: observable,
  setup: action,
  save: action,
})

export default Sentence
