import requests from './requests';

function getUrl(chatbotID) {
  return `/api/v1/chatbots/${chatbotID}/config/`;
}

const WidgetSettings = {
  getSettings: async (chatbotID) => {
    const result = await requests.get(getUrl(chatbotID));
    return result;
  },
  saveChanges: async (chatbotID, body) => {
    const updatedBody = {
      widgetIcon: body.widgetIcon,
      botAvatar: body.botAvatar,
      widgetName: body.widgetName,
      autoOpen: body.autoOpen,
      showExtraBackButton: body.showExtraBackButton,
      hideTextInput: body.hideTextInput,
      settingsTemplate: body.settingsTemplate,
      widgetChatName: body.widgetChatName,
      headerText: body.headerText,
      introAppText: body.introAppText,
      introductionText: body.introductionText,
      introductionTextTranslations: body.introductionTextTranslations,
      welcomebackMessage: body.welcomebackMessage,
      messageIndicatorTitleSingular: body.messageIndicatorTitleSingular,
      messageIndicatorTitlePlural: body.messageIndicatorTitlePlural,
      inputPlaceholder: body.inputPlaceholder,
      sendButtonText: body.sendButtonText,
      MESSAGE_DELAY_DEFAULT: body.MESSAGE_DELAY_DEFAULT,
      MESSAGE_DELAY_MAX: body.MESSAGE_DELAY_MAX,
      MESSAGE_DELAY_SIZE: body.MESSAGE_DELAY_SIZE,
      calendar: body.calendar,
      style: {
        coreColor: body.coreColor,
        coreTextColor: body.coreTextColor,
        secondaryColor: body.secondaryColor,
        secondaryTextColor: body.secondaryTextColor,
      },
    };
    const result = await requests.put(getUrl(chatbotID), updatedBody);
    return result;
  },
};

export default WidgetSettings;
