import { observable, decorate, action, toJS, computed, runInAction } from 'mobx'
import { EMPTY_STRING } from 'constants/regex'
import Chatbot from 'agent/chatbot'
import { PreviewWidgetStyles } from 'pages/SettingsSection/subsection/WidgetPreviewEditor/Preview/styles'
import WidgetPreview from 'agent/widget-preview'
import BaseStore from '../BaseStore'
import { ButtonModel } from './models'
import {
  renderButtons,
  renderTimedCall,
  renderIconShape,
  renderUserData,
} from './utils'

class WidgetPreviewStore extends BaseStore {
  text = 'Hello Wolrd 😊!'
  buttons = {}
  allFlows = []
  autoOpenTime = 0
  isRectangle = false
  clientName = null
  isLoading = false
  botAvatar =
    'https://joboti-widget.azurewebsites.net/public/assets/images/mindy_rounded_small.png'
  startStateMachine = ''
  widgetIconStartFlow = ''

  constructor(clientName) {
    super()
    this.clientName = clientName
  }

  addButton() {
    const buttons = toJS(this.buttons)
    const newKey = `_${Date.now()}`
    buttons[newKey] = new ButtonModel('', this.startStateMachine || '', newKey)
    this.buttons = buttons
  }

  updateBtnText(btnId, text) {
    this.buttons[btnId].setAttr('text', text)
  }

  updateBtnFlow(btnId, dataId) {
    this.buttons[btnId].setAttr('dataId', dataId)
  }

  deleteButton(btnId) {
    const buttons = toJS(this.buttons)
    delete buttons[btnId]
    this.buttons = buttons
  }

  get getButtons() {
    const buttons = toJS(this.buttons)
    const keys = Object.keys(buttons)
    return keys.map(k => buttons[k])
  }

  get getButtonsAsObject() {
    return this.buttons
  }

  get generatedCode() {
    return `<style>
      ${PreviewWidgetStyles}
      </style>
      
      <script src="https://widget-provider.joboti.com/joboti-widget.min.js"></script>
      <script type="text/javascript">
        var jobotiCustomConfig = {
          ${renderUserData(this.widgetIconStartFlow)}
          previewWidgetHTML: '<div class="previewHTML-content"><div class="message-wrapper"><div>${
            this.text
          }</div></div>${renderButtons(this.getButtons)}</div>',
          ${renderIconShape(this.isRectangle)}
        };
        ${renderTimedCall(
          this.clientName,
          this.autoOpenTime,
          'jobotiCustomConfig',
        )}

        function loadConversationForButton(node) {
          var configCopy = Object.assign({}, jobotiCustomConfig);
          configCopy.userData.start_flow = node.dataset.id;
          JobotiBot.loadChatWidget('${this.clientName}', configCopy);
        }

      </script>`.replace(/"/g, '\\"')
  }

  fetchFlows = async () => {
    if (toJS(this.allFlows).length) return // IF the flows are already loaded exit the function
    const result = await Chatbot.get()

    const { flows, startStateMachine } = result

    const startMachineObject = startStateMachine ? startStateMachine.flow : null
    const filtredFlows = [startMachineObject, ...flows].filter(sm => sm)
    runInAction(() => {
      this.allFlows = filtredFlows
      this.startStateMachine = filtredFlows.length ? filtredFlows[0].id : ''
    })
  }

  fetchData = async () => {
    this.isLoading = true
    await this.fetchFlows()
    let resultButtonsObject = {}
    const {
      widgetPreview,
      widgetSettings: { botAvatar },
    } = await WidgetPreview.get()
    if (widgetPreview) {
      const {
        text,
        buttons,
        autoOpenTime,
        desktopBotAvatar,
        widgetIconStartFlow,
      } = widgetPreview
      runInAction(() => {
        if (buttons && Array.isArray(buttons)) {
          let count = 0
          resultButtonsObject = buttons.reduce((acc, btn) => {
            const btnID = count++
            acc[btnID] = new ButtonModel(btn.text, btn.flow, btnID)
            return acc
          }, {})
        }

        this.text = text
        this.autoOpenTime = autoOpenTime
        this.buttons = resultButtonsObject || {}
        this.botAvatar = botAvatar
        this.isRectangle = desktopBotAvatar.isRectangle || false
        this.isLoading = false
        this.widgetIconStartFlow = widgetIconStartFlow || ''
      })
    } else {
      runInAction(() => {
        this.text = ''
        this.buttons = {}
        this.autoOpenTime = 0
        this.isRectangle = false
        this.botAvatar = botAvatar
        this.isLoading = false
        this.widgetIconStartFlow = ''
      })
    }
  }

  saveData = async () => {
    const buttons = this.getButtons.map(btn => {
      return {
        flow: btn.dataId,
        text: btn.text,
      }
    })

    const body = {
      text: this.text,
      autoOpenTime: this.autoOpenTime,
      isRectangle: this.isRectangle,
      widgetIconStartFlow: this.widgetIconStartFlow || '',
      buttons,
    }

    await WidgetPreview.put(body)
    // const ok = await WidgetPreview.put(body);
    // TODO: show  message.error() on ok=false
  }

  /**
   * @returns true if all texts of all buttons are not empty
   */
  get canAddBtn() {
    const buttons = toJS(this.buttons)
    const keys = Object.keys(buttons)
    if (keys.length > 0) {
      return keys.filter(k => EMPTY_STRING.test(buttons[k].text)).length === 0
    }
    return true
  }
}

decorate(WidgetPreviewStore, {
  text: observable,
  buttons: observable,
  allFlows: observable,
  startStateMachine: observable,
  widgetIconStartFlow: observable,
  isRectangle: observable,
  autoOpenTime: observable,
  botAvatar: observable,
  isLoading: observable,
  fetchFlows: action,
  fetchData: action,
  addButton: action,
  updateBtnText: action,
  updateBtnFlow: action,
  saveData: action,
  deleteButton: action,
  getButtons: computed,
  getButtonsAsObject: computed,
  canAddBtn: computed,
  generatedCode: computed,
})

export default WidgetPreviewStore
