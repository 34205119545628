import { action, decorate, observable } from 'mobx';
import BaseStore from 'stores/BaseStore';
import FlowStore from 'stores/Flows/Flow.store';
import WhatsAppParameter from './Variation/Parameter/WhatsAppParameter.store';
import WhatsAppVariation from './Variation/WhatsAppVariation.store';

class WhatsAppTemplate extends BaseStore {
  id;
  templateName;
  facebookName;
  description;
  category;
  scalarNames;
  additionalData;
  attachmentType;
  version;
  status;
  creatorEmail;
  buttonType;
  created;
  modified;
  flow;
  parameters;
  variations;
  selectedVariation;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.id = params?.id;
    this.templateName = params?.templateName;
    this.facebookName = params?.facebookName || params?.templateName;
    this.description = params?.description;
    this.category = params?.category;
    this.scalarNames = params?.scalarNames;
    this.additionalData = params?.additionalData;
    this.attachmentType = params?.attachmentType;
    this.version = params?.version;
    this.status = params?.status;
    this.creatorEmail = params?.creatorEmail;
    this.buttonType = params?.buttonType;
    this.created = params?.created;
    this.modified = params?.modified;
    this.flow = new FlowStore(params?.flow || {});
    this.parameters = Array.isArray(params?.parameters)
      ? params?.parameters.map((parameter) => new WhatsAppParameter(parameter))
      : [];
    this.variations = Array.isArray(params?.variations)
      ? params?.variations.map((variation) => new WhatsAppVariation(variation))
      : [];

    // by default we select the first variation
    this.selectedVariation = this.variations && this.variations[0];
  }
}

decorate(WhatsAppTemplate, {
  id: observable,
  templateName: observable,
  facebookName: observable,
  description: observable,
  category: observable,
  scalarNames: observable,
  additionalData: observable,
  attachmentType: observable,
  version: observable,
  status: observable,
  creatorEmail: observable,
  buttonType: observable,
  created: observable,
  modified: observable,
  flow: observable,
  parameters: observable,
  variations: observable,
  selectedVariation: observable,
  setup: action,
});

export default WhatsAppTemplate;
