import agent from 'agent';
import { action, decorate, observable, runInAction } from 'mobx';
import BaseStore from 'stores/BaseStore';
import Processor from 'stores/ProcessorStore';
import CampaignAction from 'stores/campaigns/CampaignAction.store';

class CampaignActions extends BaseStore {
  isLoading = false;
  processorsLoading = false;
  actions;
  allProcessors;

  constructor(actions) {
    super();
    this.setup(actions);
  }

  setup(actions) {
    this.actions = actions || [];
  }

  async fetch(chatbot, campaignId) {
    this.isLoading = true;
    try {
      const response = await agent.Campaign.getCampaignActions(
        chatbot,
        campaignId
      );

      if (!response?.results) {
        throw new Error(response.message);
      }

      const allProcessorIds = response.results
        .map(({ processorId }) => processorId)
        .join(', ');

      await this.fetchAllProcessors(allProcessorIds);
      runInAction(() => {
        this.actions = response.results.map(
          (result) =>
            new CampaignAction({
              processorId: result?.processor_id,
              successful: result?.successful,
              failed: result?.failed,
            })
        );
        this.isLoading = false;
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchAllProcessors(allProcessorIds) {
    this.isLoading = true;
    try {
      const res = await agent.Processor.getAllProcessors(allProcessorIds);
      if (!res?.data) {
        throw new Error(res.message);
      }
      runInAction(() => {
        this.allProcessors = res.data.allProcessors.map(
          (processor) => new Processor(processor)
        );
        this.isLoading = false;
      });
    } catch (error) {
      console.log(error);
    }
  }
}

decorate(CampaignActions, {
  isLoading: observable,
  actions: observable,
  setup: action,
  fetch: action,
  fetchAllProcessors: action,
  allProcessors: observable,
});

export default CampaignActions;
