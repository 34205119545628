/**
 * The function transforms an option object by mapping its actions array and adding a position property
 * to each action object.
 * @param option - an option object
 * @returns The function `transformOption` is returning the `transformedOption` object after modifying
 * the `actions` array by adding a `position` property to each action object.
 */
export const transformOption = (option) => {
  const transformedOption = option;
  transformedOption.actions = option.actions.map((act) => {
    const actionToTransform = act.action;
    if (actionToTransform) {
      actionToTransform.position = act.postion;
    }
    return actionToTransform;
  });

  return transformedOption;
};

export const getOptionLabel = (currentOption, selectedStateTags) => {
  if (!currentOption.optionLabel) return currentOption.name;
  const optionLabelVariations =
    currentOption.optionLabel.messageClass.variations;
  const selectedTagsContent = selectedStateTags.map((tag) => tag.content);
  let optionLabelVariation = null;
  let matchingTags = false;
  optionLabelVariations.forEach((variation) => {
    if (variation.tags.length === selectedStateTags.length) {
      variation.tags.forEach((tag) => {
        matchingTags = selectedTagsContent.includes(tag.content);
      });
      if (matchingTags) optionLabelVariation = variation;
    }
  });

  if (optionLabelVariation && optionLabelVariation.message.textMessage) {
    return <span>{optionLabelVariation.message.textMessage.content}</span>;
  }
  if (optionLabelVariation && optionLabelVariation.message.linkMessage) {
    return <span>{optionLabelVariation.message.linkMessage.placeholder}</span>;
  }

  return '';
};

/**
 *
 * this function takes a text content and returns an array of words that can be used
 * in TextMessageFormatted component
 * */
export const preFormatText = (textContent) => {
  let content = textContent;

  if (textContent.includes('{{mongo_data')) {
    content = content.replaceAll('mongo_data.', '');
  }

  let extraAction;

  if (content.includes('#AP#')) {
    // eslint-disable-next-line prefer-destructuring
    extraAction = textContent
      .replace('#AP#', '')
      .split(' ')
      .filter((word) => word !== '')[0];
  }

  return { words: content.split(' '), extraAction };
};

/**
 *formatProcessorTemplates  return formatted templated to display after filter on allowed categories and selected category and input search value
 *
 * @param {Array.<Object>} templates - The array of selectedChatbot.processorTemplates to be filtered.
 * @param {String || null} category - selected category by user to filter by.
 * @param {String || null} searchValue - search value that filter templates by name
 * @param {String || null} allowedCategories - the allowedCategories on chatbot to  filter the templates by
 *
 * @returns {Array.<Object>} An array of processorTemplates after filter.
 *
 */
export const formatProcessorTemplates = (
  templates,
  category,
  searchValue,
  allowedCategories
) => {
  let processorTemplates = templates.filter((processorTemplate) =>
    allowedCategories.includes(processorTemplate.processor.category)
  );

  if (category !== null) {
    processorTemplates = processorTemplates.filter(
      (processorTemplate) => processorTemplate.processor.category === category
    );
  }
  if (searchValue !== null) {
    processorTemplates = processorTemplates.filter((processorTemplate) =>
      processorTemplate.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  return processorTemplates.map((template, index) => ({
    key: index + 1,
    icon: template.icon,
    name: template.name,
    description: template.description,
    id: template.id,
    value: template.id,
    processor: template.processor,
  }));
};

/**
 * Filters and returns variations that have matching tags with the provided selected tags.
 *
 * @param {Array.<Object>} variationsSource - The array of variations to be filtered.
 *   Each variation should have a `tags` property which is an array of tag objects.
 * @param {Array.<Object>} selectedTags - The array of selected tags to filter by.
 *   Each tag should have a `content` property.
 *
 * @returns {Array.<Object>} An array of variations that match the selected tags.
 *
 */
export const variationsToRender = (variationsSource, selectedTags) => {
  const selectedTagsContent = selectedTags.map((tag) => tag.content);

  return variationsSource?.filter((variation) => {
    const variationTagsContent = variation.tags.map((tag) => tag.content);

    // Check if both tag sets have the same length
    if (variationTagsContent.length !== selectedTagsContent.length) {
      return false;
    }

    // Ensure every selected tag is in variation tags
    return selectedTagsContent.every((tag) =>
      variationTagsContent.includes(tag)
    );
  });
};

// function target the add processor side drawer, after search or filter we scroll to top
export const scrollTop = () => {
  const scrollBody = document.querySelector('.scroll-body .ant-drawer-body');
  if (scrollBody) {
    scrollBody.scrollTo({
      top: 0,
    });
  }
};
