import requests from './requests';

const getAgent = `
query getAgent($agentId: ID!) {
  agent(id: $agentId) {
    id
    devices {
      id
      type
      pushNotificationToken
    }
    isAdmin
    email
    externalUid
    isActive
    name
    phone
    teams {
      id
      name
      hasAccess
    }
  }
}
`;

const updateAgent = `
mutation updateAgent($newAgent: AgentUpdateGenericType!) {
    updateAgent(newAgent: $newAgent) {
      agent {
        id
        isAdmin
        teams {
          id
          name
          hasAccess
        }
      }
      ok
    }
  }
`;

const getAllTeams = `
{
    allTeams {
      id
      name
      email
      externalId
    }
 }
`;

const Agents = {
  get: (agentId) => requests.gql.chatserver.query(getAgent, { agentId }),
  getAllTeams: (allTeams) =>
    requests.gql.chatserver.query(getAllTeams, { allTeams }),
  update: (data) =>
    requests.gql.chatserver.mutate(updateAgent, {
      newAgent: data,
    }),
};

export default Agents;
