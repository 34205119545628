import { action, decorate, observable } from 'mobx';
import Utils from './Utils.store';

class BaseStore extends Utils {
  hasChanged;
  setAttr(name, value) {
    this.hasChanged = true;
    this[name] = value;
  }

  constructor() {
    super();
  }
}

decorate(BaseStore, {
  hasChanged: observable,
  setAttr: action,
});

export default BaseStore;
