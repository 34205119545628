import _ from 'lodash';
import requests from './requests';

const listQuery = `
query User($chatbotId:String) {
  user {
    id
    chatbots(chatbotId:$chatbotId) {
      id
      serverId
      name
      projectName
      users {
        agentId
        language
        id
        role
        email
        name
        firstName
        lastName
        calendarIsAuthenticated
        chatbots {
          id: serverId
          name: projectName
        }
      }
    }
  }
}
`;

const createMutation = `
mutation CreateUser($data: CreateUserInput!) {
  createUser(input: $data) {
    ok
    user {
      agentId
      id
      language
      email
      name
      calendarIsAuthenticated
      role
      chatbots {
        id: serverId
        name: projectName
      }
    }
  }
}
`;

const updateMutation = `
mutation UpdateUser($data: UpdateUserInput!) {
  updateUser(input: $data) {
    ok
    user {
      agentId
      id
      language
      email
      name
      calendarIsAuthenticated
      role
      pagesVisited
      chatbots {
        id: serverId
        name: projectName
      }
    }
  }
}
`;

const deleteMutation = `
mutation DeleteUser($id: ID!) {
  deleteUser(id: $id) {
    ok
  }
}
`;

const Employee = {
  list: async (chatbotId) => {
    const res = await requests.gql.router.query(listQuery, { chatbotId });
    return _.get(res, 'data.user.chatbots.0.users');
  },
  create: async (data) => {
    const res = await requests.gql.router.mutate(createMutation, { data });
    const { ok, user } = _.get(res, 'data.createUser');
    if (!ok) throw new Error('Failed to create user');
    return user;
  },
  update: async (data) => {
    const res = await requests.gql.router.mutate(updateMutation, { data });
    const updatedUser = _.get(res, 'data.updateUser');
    if (!updatedUser?.ok) {
      throw new Error('Failed to update user');
    }
    return updatedUser.user;
  },
  delete: async (id) => {
    const res = await requests.gql.router.mutate(deleteMutation, { id });
    if (!res.data?.deleteUser?.ok) {
      throw new Error('Failed to remove user');
    }
    return true;
  },
};

export default Employee;
