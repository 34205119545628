import { decorate, observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import agent from 'agent'
import BaseStore from './BaseStore'
import Sentence from './Sentence.store'
import Keyword from './Keyword.store'

class Intent extends BaseStore {
  id
  name
  sentences
  keywords

  constructor(params) {
    super()
    this.setup(params)
    this.save = _.debounce(this.save, 1300)
  }

  setup({ id, name, sentences, keywords }) {
    this.id = id
    this.name = name
    this.sentences = []
    this.keywords = []
    if (sentences) {
      sentences.forEach(sentence => {
        this.sentences.push(new Sentence(sentence))
      })
    }
    if (keywords) {
      keywords.forEach(keyword => {
        this.keywords.push(new Keyword(keyword))
      })
    }
  }

  async save() {
    const data = {
      id: this.id,
      name: this.name,
    }
    await agent.Intent.update(data)
  }

  static async create(data) {
    const intent = await agent.Intent.create(data)
    return new Intent(intent)
  }

  static async delete(id) {
    await agent.Intent.delete(id)
  }

  static async get(id) {
    const intent = await agent.Intent.get(id)
    return new Intent(intent)
  }

  async addSentence(text) {
    const sentence = await Sentence.create(text, this.id)
    runInAction(() => {
      this.sentences.unshift(sentence)
    })
  }

  async initSentences(text1, text2, text3) {
    const sentences = await Sentence.init(text1, text2, text3, this.id)
    runInAction(() => {
      this.sentences = sentences
    })
  }

  async removeSentence(id) {
    _.remove(this.sentences, { id })
    await Sentence.delete(id)
  }

  async addKeyword(text) {
    const keyword = await Keyword.create(text, this.id)
    runInAction(() => {
      this.keywords.unshift(keyword)
    })
  }

  async removeKeyword(id) {
    _.remove(this.keywords, { id })
    await agent.Keyword.delete(id)
  }
}

decorate(Intent, {
  id: observable,
  name: observable,
  sentences: observable,
  keywords: observable,
  save: action,
  addSentence: action,
  removeSentence: action,
  addKeyword: action,
  removeKeyword: action,
  setup: action,
  get: action,
})

export default Intent
