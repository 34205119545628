import { message } from 'antd';
import { ORIGINAL_IMAGE_PREFIX } from '../constants';
import { fakeFormatMessage as formatMessage } from '../locales/utils';

const {
  REACT_APP_CLOUDINARY_CLOUD_NAME,
  REACT_APP_CLOUDINARY_PRESET_NAME,
  REACT_APP_CLOUDINARY_BASE_URL,
} = process.env;

const ChatbotAvatar = {
  uploadAvatar: async (clientName, file, property) => {
    const uploadForm = new FormData();
    uploadForm.append('file', file);
    uploadForm.append('upload_preset', REACT_APP_CLOUDINARY_PRESET_NAME);
    uploadForm.append('cloud_name', REACT_APP_CLOUDINARY_CLOUD_NAME);
    uploadForm.append('folder', `${clientName}/images`);

    const res = await fetch(REACT_APP_CLOUDINARY_BASE_URL, {
      method: 'POST',
      body: uploadForm,
      use_filename: true,
      filename_override: `${ORIGINAL_IMAGE_PREFIX}${property}-${file.name}`,
      unique_filename: false,
    });
    const avatarUrl = await res.json();

    if (avatarUrl.hasOwnProperty('secure_url')) return avatarUrl['secure_url'];
    message.error(
      formatMessage({ id: 'agent/chatbot-settings/anErrorHasOccured' })
    );
  },
};

export default ChatbotAvatar;
