import requests from './requests';

const eventNotificationFields = `
  fragment eventNotificationFields on EventNotificationGenericType {
    id
    type
    minutes
  }
`;

const createEventNotification = `
mutation CreateEventNotification($newEventNotification: EventNotificationCreateGenericType!) {
  createEventNotification(newEventnotification:$newEventNotification) {
    eventnotification {
      ...eventNotificationFields
    }
    ok
  }
}
  ${eventNotificationFields}
`;

const updateEventNotification = `
mutation UpdateEventNotification($newEventNotification: EventNotificationUpdateGenericType!) {
  updateEventNotification(newEventnotification:$newEventNotification) {
    eventnotification {
      ...eventNotificationFields
    }
    ok
    errors {
      messages
    }
  }
}
  ${eventNotificationFields}
`;

const deleteEventNotification = `
mutation DeleteEventNotification($id: ID!) {
  deleteEventNotification(id: $id) {
    eventnotification {
      ...eventNotificationFields
    }
    ok
    errors {
      messages
    }
  }
}
  ${eventNotificationFields}
`;

const EventNotification = {
  createEventNotification: async (newEventNotification) => {
    const { data } = await requests.gql.chatserver.mutate(
      createEventNotification,
      {
        newEventNotification,
      }
    );
    return data.createEventNotification;
  },
  updateEventNotification: async (newEventNotification) => {
    const { data } = await requests.gql.chatserver.mutate(
      updateEventNotification,
      {
        newEventNotification,
      }
    );
    return data.updateEventNotification;
  },
  deleteEventNotification: async (eventNotificationId) => {
    const { data } = await requests.gql.chatserver.mutate(
      deleteEventNotification,
      {
        id: eventNotificationId,
      }
    );
    return data.deleteEventNotification;
  },
};

export default EventNotification;
