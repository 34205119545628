import _ from 'lodash';
import requests from './requests';

const updateChatbotSetting = `
mutation UpdateSettings($settings: [SettingArgument!]!) {
	chatbotSettingsUpdate(settings: $settings) {
	  ok
	}
}
`;

const getAllChatbotSettings = `
query GetAtsChatbotSettings($section_Iexact: String){
  allChatbotSettings(section_Iexact: $section_Iexact) {
    adminOnly
    chatbot {
      id
    }
    id
    key
    settingType
    value
    helpText
    section
  }
}
`;

const ChatbotSetting = {
  update: (settings) =>
    requests.gql.chatserver.mutate(updateChatbotSetting, { settings }),
  all: (section) =>
    requests.gql.chatserver.query(getAllChatbotSettings, {
      section_Iexact: section,
    }),
};

export default ChatbotSetting;
