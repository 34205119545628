import agent from 'agent';
import {
  DEFAULT_END_EXECUTION_TIME,
  DEFAULT_START_EXECUTION_TIME,
} from 'constants/campaign.constants';
import { action, decorate, observable, runInAction } from 'mobx';
import BaseStore from 'stores/BaseStore';
import {
  getWorkDaysValuesArr,
  sortScheduleAvailabilities,
} from 'utils/calendarOverview.utils';
import EventPreference from './EventPreference.store';
import ScheduleAvailability from './ScheduleAvailability.store';

class ScheduleAvailabilitySchema extends BaseStore {
  id;
  name;
  timeZone;
  scheduleAvailabilities = [];
  isLoading = false;
  dayNameForNewAvailability = [];
  arePropsUpdated = false;
  eventPreferencesSet = [];
  isCopyOfEventPreferenceAvailabilities = false;

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    this.id = args?.id;
    this.name = args?.name;
    this.timeZone = args?.timeZone;
    if (args?.scheduleAvailabilities?.length > 0) {
      const sortedTimeSegments = sortScheduleAvailabilities(
        args.scheduleAvailabilities
      );
      this.scheduleAvailabilities = sortedTimeSegments.map(
        (scheduleAvailability) => new ScheduleAvailability(scheduleAvailability)
      );
    }
    if (args?.eventpreferencesSet?.length > 0) {
      this.eventPreferencesSet = args.eventpreferencesSet?.map(
        (eventPreference) => new EventPreference(eventPreference)
      );
    }
    this.isCopyOfEventPreferenceAvailabilities =
      args?.isCopyOfEventPreferenceAvailabilities || false;
  }

  get getProperties() {
    return {
      name: this.name,
      timeZone: this.timeZone,
      scheduleAvailabilities: this.scheduleAvailabilities.map(
        (availability) => availability.id
      ),
    };
  }

  resetStore(values) {
    this.id = values?.id ? values?.id : null;
    this.name = values?.name ? values?.name : null;
    this.timeZone = values?.timeZone
      ? values?.timeZone
      : Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (values?.scheduleAvailabilities?.length > 0) {
      const sortedTimeSegments = sortScheduleAvailabilities(
        values.scheduleAvailabilities
      );
      this.scheduleAvailabilities = sortedTimeSegments.map(
        (scheduleAvailability) => new ScheduleAvailability(scheduleAvailability)
      );
    } else {
      this.scheduleAvailabilities = [];
    }
    this.arePropsUpdated = false;
    this.eventPreferencesSet =
      values?.eventPreferencesSet.length > 0
        ? values?.eventPreferencesSet?.map(
            (eventPreference) => new EventPreference(eventPreference)
          )
        : [];
  }

  async createScheduleAvailabilitySchema() {
    const newSchemaValues = this.getProperties;
    if (newSchemaValues?.name?.length < 3) return;

    try {
      const createScheduleAvailabilitySchema =
        await agent.Calendar.createScheduleAvailabilitySchema(newSchemaValues);

      runInAction(() => {
        this.setup(createScheduleAvailabilitySchema);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async updateScheduleAvailabilitySchema(fieldsToUpdate) {
    if (!this.id || (fieldsToUpdate?.name && fieldsToUpdate?.name?.length < 3))
      return;
    try {
      let newScheduleAvailabilitySchema;
      if (fieldsToUpdate) {
        newScheduleAvailabilitySchema = {
          id: this.id,
          ...fieldsToUpdate,
        };
      } else {
        newScheduleAvailabilitySchema = {
          id: this.id,
          ...this.getProperties,
        };
      }
      const updateScheduleAvailabilitySchema =
        await agent.Calendar.updateScheduleAvailabilitySchema(
          newScheduleAvailabilitySchema
        );
      if (updateScheduleAvailabilitySchema?.id) {
        runInAction(() => {
          this.setup(updateScheduleAvailabilitySchema);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getScheduleAvailabilitySchema(id) {
    const schemaId = id || this.id;
    try {
      const getScheduleAvailabilitySchema =
        await agent.Calendar.getScheduleAvailabilitySchema(schemaId);
      if (getScheduleAvailabilitySchema?.id) {
        runInAction(() => {
          this.setup(getScheduleAvailabilitySchema);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async createScheduleAvailability(newScheduleavailability) {
    try {
      runInAction(() => {
        this.dayNameForNewAvailability = newScheduleavailability.day;
      });
      // create availability
      const newAvailability = await agent.Calendar.createScheduleAvailability(
        newScheduleavailability
      );
      if (newAvailability?.ok) {
        runInAction(() => {
          const sortedTimeSegments = sortScheduleAvailabilities([
            new ScheduleAvailability(newAvailability.scheduleavailability),
            ...this.scheduleAvailabilities,
          ]);
          this.scheduleAvailabilities = sortedTimeSegments;
          this.dayNameForNewAvailability = null;
        });

        // update schema
        await this.updateScheduleAvailabilitySchema();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async updateScheduleAvailability(newScheduleavailability) {
    const updateScheduleAvailability =
      await agent.Calendar.updateScheduleAvailability(newScheduleavailability);
    if (updateScheduleAvailability.ok) {
      runInAction(() => {
        const sortedTimeSegments = sortScheduleAvailabilities([
          new ScheduleAvailability(
            updateScheduleAvailability.scheduleavailability
          ),
          ...this.scheduleAvailabilities.filter(
            (scheduleAvailability) =>
              scheduleAvailability.id !==
              updateScheduleAvailability.scheduleavailability.id
          ),
        ]);
        this.scheduleAvailabilities = sortedTimeSegments;
      });
    }
  }

  async deleteScheduleAvailability(scheduleAvailabilityId) {
    const deleteScheduleAvailability =
      await agent.Calendar.deleteScheduleAvailability(scheduleAvailabilityId);
    if (deleteScheduleAvailability.ok) {
      runInAction(() => {
        this.scheduleAvailabilities = this.scheduleAvailabilities.filter(
          (scheduleAvailability) =>
            scheduleAvailability.id !== scheduleAvailabilityId
        );
      });
    }
  }

  async createScheduleAvailabilitiesDefaultValues() {
    try {
      const daysValues = getWorkDaysValuesArr();
      runInAction(() => {
        this.isLoading = true;
      });
      const scheduleAvailabilities = await Promise.all(
        daysValues.map(async (day) => {
          const newScheduleavailability = {
            day,
            startTime: DEFAULT_START_EXECUTION_TIME,
            endTime: DEFAULT_END_EXECUTION_TIME,
          };
          const newAvailability =
            await agent.Calendar.createScheduleAvailability(
              newScheduleavailability
            );

          return newAvailability.scheduleavailability;
        })
      );
      runInAction(() => {
        this.scheduleAvailabilities = scheduleAvailabilities.map(
          (scheduleAvailability) =>
            new ScheduleAvailability(scheduleAvailability)
        );
        this.isLoading = false;
      });
    } catch (error) {
      console.log(error);
    }
  }
}

decorate(ScheduleAvailabilitySchema, {
  id: observable,
  name: observable,
  timeZone: observable,
  scheduleAvailabilities: observable,
  dayNameForNewAvailability: observable,
  isLoading: observable,
  arePropsUpdated: observable,
  eventPreferencesSet: observable,
  isCopyOfEventPreferenceAvailabilities: observable,
  resetStore: action,
  createScheduleAvailabilitiesDefaultValues: action,
  createScheduleAvailabilitySchema: action,
  updateScheduleAvailabilitySchema: action,
  getScheduleAvailabilitySchema: action,
  createScheduleAvailability: action,
  updateScheduleAvailability: action,
  deleteScheduleAvailability: action,
});

export default ScheduleAvailabilitySchema;
