import dayjs from 'dayjs';
import { THEME } from 'globalStyles';
import { find, groupBy, isEmpty, uniqBy } from 'lodash';
import { action, computed, observable, runInAction, toJS } from 'mobx';
import Analytics from '../agent/analytics';
import Chatbot from '../agent/chatbot';
import { fakeFormatMessage as formatMessage } from '../locales/utils';
import ConversationsStore from './AnalyticsStores/ConversationsStore';
import BaseStore from './BaseStore';

const today = dayjs()
  .set('hours', 23)
  .set('minutes', 59)
  .set('seconds', 59)
  .set('milliseconds', 999);
const lastMonth = dayjs().subtract(30, 'day');

class AnalyticsStore extends BaseStore {
  @observable isLoading = false;

  @observable usersPerPeriod = [];

  @observable usersPerHour = [];

  @observable usersOutsideOfficeHours = [];

  @observable stateMachineArray = [];

  @observable top10IntentsArray = [];

  @observable kpiPerFlow = [];

  @observable kpiKeys = {};

  // Flows
  @observable funnelPerFlow = {};

  @observable flows = [];

  @observable selectedFlow = null;

  @observable stateMachineSummaryLabels = [];

  @observable conversations = null;

  // Min date
  @observable minDateYear = lastMonth.year();

  @observable minDateMonth = lastMonth.month();

  @observable minDateDay = lastMonth.date();

  // Start date
  @observable startYear = lastMonth.year();

  @observable startMonth = lastMonth.month();

  @observable startDate = lastMonth.date();

  // End date
  @observable endYear = today.year();

  @observable endMonth = today.month();

  @observable endDay = today.date();

  @observable clientID = '';

  @observable serverAddress = '';

  constructor(serverAddress, name) {
    super();
    this.serverAddress = serverAddress;
    this.clientID = name;
    this.conversations = new ConversationsStore(name);
  }

  /**
   * Get the selected period (default to last 30 days)
   * @returns {Array} range
   * @returns {Object} range[0] - Date object (start date)
   * @returns {Object} range[1] - Date object (End date)
   */
  get dateRange() {
    return [
      dayjs(new Date(this.startYear, this.startMonth, this.startDate)),
      dayjs(new Date(this.endYear, this.endMonth, this.endDay))
        .set('hours', 23)
        .set('minutes', 59)
        .set('seconds', 59)
        .set('milliseconds', 999),
    ];
  }

  @action
  fetchMinDate = async () => {
    // TODO: If the time is set don"t fetch
    const result = await Analytics.getMinDate({ project_id: this.clientID });

    runInAction(() => {
      // If the start of date range earlier than the minDate update the startDate
      const minDate = new Date(this.startYear, this.startMonth, this.startDate);
      if (minDate >= result) {
        this.minDateYear = result.getFullYear();
        this.minDateMonth = result.getMonth();
        this.minDateDay = result.getDate();
      } else {
        this.minDateYear = result.getFullYear();
        this.startYear = this.minDateYear;
        this.minDateMonth = result.getMonth();
        this.startMonth = this.minDateMonth;
        this.minDateDay = result.getDate();
        this.startDate = this.minDateDay;
      }
    });
  };

  /**
   * Force API call on date picker change
   */
  @action
  forceReload = () => {
    this.loadInitialData();
    this.loadSummaryData();
    this.loadConversations();
    this.loadTop10Intents();
    this.loadUsersPerStateMachine();
    this.loadFunnelPerFlow();
  };

  /**
   * Set a new start and end date
   * @param {Number} startYear Start date year
   * @param {Number} startMonth Start date  month
   * @param {Number} startDay Start date  day
   * @param {Number} endYear End date year
   * @param {Number} endMonth End month
   * @param {Number} endDay Day month
   */
  @action
  updateDate = (startYear, startMonth, startDay, endYear, endMonth, endDay) => {
    this.startYear = startYear;
    this.startMonth = startMonth;
    this.startDate = startDay;
    this.endYear = endYear;
    this.endMonth = endMonth;
    this.endDay = endDay;

    // Hack: Force actions to reload new data
    this.forceReload();
  };

  @action
  loadFlows = async () => {
    if (toJS(this.flows).length) return; // IF the flows are already loaded exit the function

    const result = await Chatbot.getStateMachinesForAnalytics(
      this.serverAddress
    );
    const flows = [
      ...result.flows,
      // result.startStateMachine ? result.startStateMachine.flow: null,
      // result.restartStateMachine ? result.restartStateMachine.flow: null,
      // result.escapeStateMachine ? result.escapeStateMachine.flow: null,
      // result.endStateMachine ? result.endStateMachine.flow: null,
      // result.welcomeBackStateMachine ? result.welcomeBackStateMachine.flow: null,
      // result.fallbackStateMachinea ? result.fallbackStateMachinea.flow: null,
      // result.defaultStateMachine ? result.defaultStateMachine.flow: null,
    ].filter((sm) => sm);

    runInAction(() => {
      this.flows = uniqBy(flows, 'id');
      this.selectedFlow = flows.length ? flows[0].id : null;
    });
  };

  @action
  selectFlow(flow) {
    this.selectedFlow = flow;
  }

  @action
  loadUsersPerPeriod = async () => {
    if (this.usersPerPeriod.length) return; // Exit if the data is already loaded
    await this.loadFlows();
    const flows = toJS(this.flows).map((flow) => flow.id);
    const body = {
      s_day: this.startDate,
      s_month: this.startMonth,
      s_year: this.startYear,
      e_day: this.endDay,
      e_month: this.endMonth,
      e_year: this.endYear,
      project_id: this.clientID,
      flows,
    };

    const result = await Analytics.usersPerPeriod(body);
    runInAction(() => {
      this.usersPerPeriod = result;
    });
  };

  @action
  // eslint-disable-next-line consistent-return
  loadUsersPerHour = async () => {
    if (this.usersPerHour.length) return;

    await this.loadFlows();
    const flows = toJS(this.flows).map((flow) => flow.id);
    const body = {
      s_day: this.startDate,
      s_month: this.startMonth,
      s_year: this.startYear,
      e_day: this.endDay,
      e_month: this.endMonth,
      e_year: this.endYear,
      project_id: this.clientID,
      flows,
    };

    const result = await Analytics.usersPerHour(body);
    runInAction(() => {
      this.usersPerHour = result;
    });
  };

  @action
  loadUsersUsersOutsideOfficeHours = async () => {
    if (this.usersOutsideOfficeHours.length) return;
    await this.loadFlows();
    const flows = toJS(this.flows).map((flow) => flow.id);
    const body = {
      s_day: this.startDate,
      s_month: this.startMonth,
      s_year: this.startYear,
      e_day: this.endDay,
      e_month: this.endMonth,
      e_year: this.endYear,
      project_id: this.clientID,
      flows,
    };

    const result = await Analytics.usageOutsideOfficeHours(body);
    runInAction(() => {
      this.usersOutsideOfficeHours = result;
    });
  };

  @action
  loadKpiPerFlow = async () => {
    if (this.kpiPerFlow.length) return;

    const body = {
      s_day: this.startDate,
      s_month: this.startMonth,
      s_year: this.startYear,
      e_day: this.endDay,
      e_month: this.endMonth,
      e_year: this.endYear,
      project_id: this.clientID,
    };

    const result = await Analytics.kpiPerFlow(body);
    const smKeys = Object.keys(result);
    // // Get kpiKeys
    let kpiKeys = new Set();
    let kpiDescriptionResult;
    smKeys.forEach((sk) =>
      Object.keys(result[sk]).map((kpiKey) => kpiKeys.add(kpiKey))
    );
    kpiKeys = Array.from(kpiKeys);
    try {
      kpiDescriptionResult = await Analytics.descriptionForKPIs(kpiKeys);
    } catch (e) {
      kpiDescriptionResult = {};
    }

    runInAction(() => {
      this.kpiPerFlow = result;
      this.kpiKeys = kpiDescriptionResult;
    });
  };

  @action
  loadTop10Intents = async () => {
    if (this.top10IntentsArray.length) return;

    const body = {
      s_day: this.startDate,
      s_month: this.startMonth,
      s_year: this.startYear,
      e_day: this.endDay,
      e_month: this.endMonth,
      e_year: this.endYear,
      project_id: this.clientID,
    };

    const result = await Analytics.top10Intents(body);
    runInAction(() => {
      this.top10IntentsArray = result;
    });
  };

  @action
  loadUsersPerStateMachine = async () => {
    if (this.stateMachineArray.length) return;

    await this.loadFlows();
    const flows = toJS(this.flows).map((flow) => flow.id);
    const body = {
      s_day: this.startDate,
      s_month: this.startMonth,
      s_year: this.startYear,
      e_day: this.endDay,
      e_month: this.endMonth,
      e_year: this.endYear,
      project_id: this.clientID,
      flows,
    };

    const result = await Analytics.usersPerStateMachine(body);
    runInAction(() => {
      this.stateMachineArray = result;
    });
  };

  @action
  loadFunnelPerFlow = async () => {
    if (!isEmpty(this.funnelPerFlow)) return;
    await this.loadFlows();
    const flows = toJS(this.flows).map((flow) => flow.id);
    const body = {
      s_day: this.startDate,
      s_month: this.startMonth,
      s_year: this.startYear,
      e_day: this.endDay,
      e_month: this.endMonth,
      e_year: this.endYear,
      project_id: this.clientID,
      flows,
    };
    const result = await Analytics.funnelPerFlow(body);
    runInAction(() => {
      this.funnelPerFlow = result;
    });
  };

  /**
   * Get State machine labels, used for analytics summary section
   * - Get all client state machines
   * - Get defaultOption/actions/actionWithOptions/optionlabelSet/option/state machines
   * - Filter out static state machines
   */
  @action
  fetchStateMachineSummaryLabels = async () => {
    await this.loadFlows();
    const userStateMachines = (toJS(this.flows) || []).map((sm) => sm.id);
    const rawResult = await Analytics.analyticsSummaryStateMachinesLabels();
    // Filter out static state machines
    const result = rawResult.filter((rs) =>
      userStateMachines.includes(rs.stateMachine)
    );
    const flows = result.map((sm) => sm.stateMachine);

    const body = {
      s_day: this.startDate,
      s_month: this.startMonth,
      s_year: this.startYear,
      e_day: this.endDay,
      e_month: this.endMonth,
      e_year: this.endYear,
      project_id: this.clientID,
      flows,
    };

    const smResult = await Analytics.usersPerStateMachine(body);

    const stateMachineSummary = result.reduce((acc, sm) => {
      const stateMachine = find(
        smResult,
        (tempSm) => sm.stateMachine === tempSm.state_machine_id
      );
      if (stateMachine) {
        sm.smData = stateMachine;
      } else {
        sm.smData = {
          state_machine_id: sm.stateMachine,
          count: 0,
          users: {
            data: [0],
            labels: [''],
          },
        };
      }
      acc.push(sm);
      return acc;
    }, []);

    runInAction(() => {
      this.stateMachineSummaryLabels = stateMachineSummary;
    });
  };

  @computed
  get firstConversationDate() {
    return {
      year: this.minDateYear,
      month: this.minDateMonth,
      day: this.minDateDay,
    };
  }

  @computed
  get getUsersPerPeriod() {
    return toJS({
      labels: toJS(this.usersPerPeriod.labels),
      datasets: [
        {
          label: formatMessage({
            id: 'stores/AnalyticsStore/activeUsersPerPeriod',
          }),
          data: toJS(this.usersPerPeriod.data),
          borderColor: THEME.BLUE,
          pointHoverBackgroundColor: THEME.BLUE,
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          fill: false,
        },
      ],
    });
  }

  @computed
  get getUsersPerHour() {
    return toJS({
      labels: toJS(this.usersPerHour.labels),
      datasets: [
        {
          label: formatMessage({
            id: 'stores/AnalyticsStore/activeUsersPerHour',
          }),
          data: toJS(this.usersPerHour.data),
          backgroundColor: 'rgba(240, 122, 133,.7)',
          pointHoverBackgroundColor: 'rgba(240, 122, 133,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          fill: false,
        },
      ],
    });
  }

  @computed
  get getUsersOutsideOfficeHours() {
    return toJS({
      labels: toJS(this.usersOutsideOfficeHours.labels),
      datasets: [
        {
          label: formatMessage({
            id: 'stores/AnalyticsStore/activeUsersOutsideOfficeHours',
          }),
          data: toJS(this.usersOutsideOfficeHours.data),
          backgroundColor: ['rgba(61,163,232,1)', 'rgba(254,205,95,1)'],
          hoverBackgroundColor: ['rgba(61,163,232,.7)', 'rgba(254,205,95,.7)'],
        },
      ],
    });
  }

  @computed
  get getAllKpi() {
    return toJS(this.kpiPerFlow);
  }

  @computed
  get getSpecificKpi() {
    const kpiPerFlow = toJS(this.kpiPerFlow);

    if (this.selectedFlow && kpiPerFlow && kpiPerFlow[this.selectedFlow]) {
      const kpiGroups = groupBy(kpiPerFlow[this.selectedFlow], 'kpiKey');
      return kpiGroups;
    }
    return {};
  }

  @computed
  get descriptionForKPI() {
    return toJS(this.kpiKeys);
  }

  @computed
  get getTop10Intents() {
    return toJS(this.top10IntentsArray);
  }

  // Hnadle State Machines

  /**
   * Get ALL State machines data
   */
  @computed
  get getStateMachines() {
    return toJS(this.stateMachineArray);
  }

  /**
   * Get an array of the selected state machines names
   */
  @computed
  get getFlows() {
    return toJS(this.flows);
  }

  @computed
  get getFlowsAsObject() {
    const flows = toJS(this.flows);
    return flows.reduce((acc, flow) => {
      if (flow.id && flow.name) acc[flow.id] = flow.name;
      return acc;
    }, {});
  }

  @computed
  get getSelectedFlow() {
    return toJS(this.selectedFlow);
  }

  @computed
  get totalConversations() {
    const conversationsCount = toJS(this.usersPerPeriod.data) || [];
    return conversationsCount.reduce((acc, nr) => acc + nr, 0);
  }

  @computed
  get getFunnelPerFlow() {
    return toJS(this.funnelPerFlow);
  }

  @computed
  get getStateMachineSummaryLabels() {
    return toJS(this.stateMachineSummaryLabels);
  }

  @computed
  get hasData() {
    return this.usersPerPeriod.length > 0;
  }

  @action
  resetStores() {
    // Empty the sotrs
    this.usersPerPeriod = [];
    this.usersPerHour = [];
    this.usersOutsideOfficeHours = [];
    this.stateMachineArray = [];
    this.top5MessageArray = [];
    this.funnelPerFlow = {};
    this.top10IntentsArray = [];
    this.stateMachineSummaryLabels = [];
    this.flows = [];
    this.selectedFlow = null;
    this.kpiPerFlow = [];
    this.kpiKeys = {};
    this.conversations = new ConversationsStore(this.clientID);
  }

  @action
  loadInitialData() {
    this.resetStores();
    this.fetchMinDate();
    this.loadFlows();
  }

  @action
  loadSummaryData() {
    // Reset summary flows
    this.usersPerPeriod = [];
    this.usersPerHour = [];
    this.usersOutsideOfficeHours = [];
    this.stateMachineArray = [];
    this.funnelPerFlow = {};
    this.stateMachineSummaryLabels = [];
    // Load summary flows
    this.loadUsersPerPeriod();
    this.loadUsersPerHour();
    this.loadUsersUsersOutsideOfficeHours();
    this.fetchStateMachineSummaryLabels();
    this.loadKpiPerFlow();
  }

  @action
  loadAnalyticsPerFlowData() {
    this.loadUsersPerPeriod();
    this.loadUsersPerStateMachine();
    this.loadFunnelPerFlow();
    this.loadKpiPerFlow();
  }

  @action
  loadConversations() {
    const params = [
      this.startDate,
      this.startMonth,
      this.startYear,
      this.endDay,
      this.endMonth,
      this.endYear,
    ];
    this.loadFlows();
    this.conversations.setDateInterval(...params);
    this.conversations.loadConversationsBrief();
  }
}

export default AnalyticsStore;
