import BaseStore from 'stores/BaseStore';
import { decorate, observable, action } from 'mobx';

class WhatsAppButton extends BaseStore {
  id;
  actionType;
  text;
  phoneNumber;
  url;
  urlType;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.id = params?.id;
    this.actionType = params?.actionType;
    this.text = params?.text;
    this.phoneNumber = params?.phoneNumber;
    this.url = params?.url;
    this.urlType = params?.urlType;
  }
}

decorate(WhatsAppButton, {
  id: observable,
  actionType: observable,
  text: observable,
  phoneNumber: observable,
  url: observable,
  urlType: observable,
  setup: action,
});

export default WhatsAppButton;
