import _ from 'lodash';
import { action, decorate, observable, runInAction, toJS } from 'mobx';
import Processor from 'stores/ProcessorStore';
import agent from '../agent';
import { getTranslationFromSelectedLanguageTag } from '../utils/translateFromSelectedLanguageTag.js';
import ActionStore from './ActionStore';
import AtsFieldMapping from './AtsFieldMapping.store';
import BaseStore from './BaseStore';
import InboxLabel from './InboxLabelStore';
import Translation from './TranslationStore';

class OptionStore extends BaseStore {
  id = '';
  name = '';
  actions = [];
  isRequired = false;
  processors = [];
  nextState = null;
  nextStateMachine = null;
  flow = null;
  optionLabel = null;
  customData = {};
  position = 0;
  symbol;
  type;
  optionHeight = 0;
  smartInboxLabels = [];
  removeSmartInboxLabels = [];
  allowNlp = false;
  description = [];
  descriptionTitle = [];
  routingInstruction = '';
  fixedAtsFieldMappings;
  cancelFollowUp;
  states = [];

  constructor(option) {
    super();
    this.setup(option);
    this.saveActionOrdering = _.debounce(this.saveActionOrdering, 2000);
    this.save = _.debounce(this.save, 2000);
  }

  setup(option) {
    this.id = option.id;
    this.name = option.name;
    this.symbol = option.symbol;
    this.isRequired = option.isRequired;
    this.type = option.type;
    this.routingInstruction = option.routingInstruction;
    this.allowNlp = option.allowNlp;
    this.cancelFollowUp = option.cancelFollowUp;
    this.fixedAtsFieldMappings = option?.fixedAtsFieldMappings?.map(
      (atsFieldMapping) => new AtsFieldMapping(atsFieldMapping)
    );
    this.states = option?.states;

    if (option.processors) {
      this.processors = option.processors.map(
        (processor) => new Processor(processor)
      );
    }
    // check if nextStateMachine and nextState aren't null
    if (option.nextStateMachine) {
      this.nextStateMachine = { ...option.nextStateMachine };
    }
    if (option.nextState) {
      this.nextState = { ...option.nextState };
      this.nextState.flow = { ..._.first(option.nextState.flow) };
    }
    if (option.actions) {
      const actions = _.orderBy(option.actions, ['postion'], ['desc']);
      actions.map((actionItem) => {
        actionItem &&
          this.actions.push(
            new ActionStore({
              ...actionItem,
              optionName: option.name,
            })
          );
      });
    }
    if (option.customData) {
      try {
        this.customData = toJS(JSON.parse(option.customData));
      } catch (e) {
        this.customData = toJS(option.customData);
      }
    }
    if (option.smartInboxLabels) {
      this.smartInboxLabels = option.smartInboxLabels.map(
        (label) => new InboxLabel(label)
      );
    }
    if (option.removeSmartInboxLabels) {
      this.removeSmartInboxLabels = option.removeSmartInboxLabels.map(
        (label) => new InboxLabel(label)
      );
    }

    if (option.description) {
      this.description = option.description.map(
        (description) => new Translation(description)
      );
    }
    if (option.descriptionTitle) {
      this.descriptionTitle = option.descriptionTitle.map(
        (description) => new Translation(description)
      );
    }
  }

  moveAction(actionToMove, delta) {
    const actionsCopy = toJS(this.actions);
    const index = _.findIndex(actionsCopy, (a) => a.id === actionToMove.id);
    const newIndex = index + delta;
    if (newIndex < 0 || newIndex === this.actions.length) return; // Already at the top or bottom.
    const indexes = [index, newIndex].sort(); // Sort the indixes
    this.actions.splice(
      indexes[0],
      2,
      this.actions[indexes[1]],
      this.actions[indexes[0]]
    );
  }

  moveUpAction(actionToMove) {
    this.moveAction(actionToMove, -1);
    this.saveActionOrdering();
  }

  moveDownAction(actionToMove) {
    this.moveAction(actionToMove, 1);
    this.saveActionOrdering();
  }

  addAction(actionObject) {
    const actionStore = new ActionStore(actionObject);
    this.actions.push(actionStore);
    return actionStore;
  }

  addVariable(key, value) {
    runInAction(() => {
      try {
        value = JSON.parse(value);
      } catch (e) {}
      this.customData[key] = value;
    });
  }

  deleteVariable(key) {
    runInAction(() => {
      delete this.customData[key];
    });
  }

  async editVariable(editedKey, editedValue, oldKey, oldValue) {
    runInAction(() => {
      let key = oldKey;
      if (editedKey !== oldKey) {
        delete Object.assign(this.customData, {
          [editedKey]: this.customData[oldKey],
        })[oldKey];
        key = editedKey;
      }
      if (editedValue !== oldValue) {
        try {
          editedValue = JSON.parse(editedValue);
        } catch (e) {}

        this.customData[key] = editedValue;
      }
    });
  }

  async saveActionOrdering() {
    const data = {
      optionId: this.id,
      actionIDs: this.actions.map((a) => a.id),
    };
    const res = await agent.Option.updateActionOrder(data);
    const ok = _.get(res, 'data.updateActionOrdering.ok');
    if (!ok) throw Error('Could not update action ordering');
    return res;
  }

  async save(variable) {
    const params = {
      data: {
        id: this.id,
        name: this.name,
        symbol: this.symbol,
        symbolType: this.type,
        routingInstruction: this.routingInstruction,
        cancelFollowUp: this.cancelFollowUp,
        removeSmartInboxLabels: this.removeSmartInboxLabels.map(
          (label) => label.id
        ),
        smartInboxLabels: this.smartInboxLabels.map((label) => {
          if (typeof label === 'object') {
            return label.id;
          } 
          return label;
          
        }),
        customData:
          typeof variable !== 'undefined'
            ? JSON.stringify(variable)
            : JSON.stringify(this.customData || {}),
        processors: this.processors.map((processors) => processors.id),
      },
    };
    if (this.nextStateMachine) {
      params.data.nextStateMachine = this.nextStateMachine.id;
    }
    if (this.nextState) {
      params.data.nextState = this.nextState.id;
    }
    const { data } = await agent.Option.update(params);
    const { option, ok } = data?.optionUpdate;
    if (!ok) throw Error('Could not update Option');
    if (option.smartInboxLabels) {
      runInAction(() => {
        this.smartInboxLabels = option.smartInboxLabels.map(
          (label) => new InboxLabel(label)
        );
      });
    }
    return ok;
  }

  async addStandardAction() {
    const res = await agent.Option.addStandardAction(this.id);
    const data = res?.data?.standardActionCreate;
    if (!data || !data.ok) {
      throw Error('Can\'t add standard action');
    }
    const actionObject = data.action;
    return this.addAction(actionObject);
  }

  async deleteAction(actionId) {
    _.remove(this.actions, (actionObject) => actionObject.id === actionId);
    const res = await agent.Action.delete(actionId);
    const ok = _.get(res, 'data.actionDelete.ok');
    if (!ok) {
      throw Error('Could not delete actions');
    }
  }

  chooseNextRoute(route, id) {
    this.routingInstruction = route;

    if (!id) return;

    if (route === 'STATE') {
      this.nextState = { id };
    } else if (route === 'FLOW') {
      this.nextStateMachine = { id };
    }
  }

  async updateNextState(state) {
    this.nextState = state;
    this.nextStateMachine = null;
    const args = {
      optionId: this.id,
      stateId: state ? state.id : null,
    };
    const res = await agent.Option.updateNextState(args);
    const ok = _.get(res, 'data.updateNextState.ok');
    if (!ok) throw Error('Could not update next state');
    return ok;
  }

  async updateNextStateMachine(stateMachine) {
    this.nextStateMachine = stateMachine;
    this.nextState = null;
    const args = {
      optionId: this.id,
      stateMachineId: stateMachine ? stateMachine.id : null,
    };
    const res = await agent.Option.updateNextStateMachine(args);
    const ok = _.get(res, 'data.updateNextStateMachine.ok');
    if (!ok) throw Error('Could not update next state machine');
    return ok;
  }

  genericTranslationGetter(key) {
    if (this[key]?.length) {
      return getTranslationFromSelectedLanguageTag(this[key]);
    }
  }

  async addOptionFixedAtsFieldMappings(atsFieldMapping) {
    this.fixedAtsFieldMappings.push(atsFieldMapping);
  }
}

decorate(OptionStore, {
  id: observable,
  name: observable,
  symbol: observable,
  isRequired: observable,
  processors: observable,
  type: observable,
  customData: observable,
  actions: observable,
  nextState: observable,
  nextStateMachine: observable,
  flow: observable,
  cancelFollowUp: observable,
  optionLabel: observable,
  position: observable,
  states: observable,
  setup: action,
  moveAction: action,
  addAction: action,
  addStandardAction: action,
  deleteAction: action,
  updateNextState: action,
  updateNextStateMachine: action,
  chooseNextRoute: action,
  optionHeight: observable,
  smartInboxLabels: observable,
  getAllInboxLabels: action,
  allowNlp: observable,
  description: observable,
  descriptionTitle: observable,
  routingInstruction: observable,
  fixedAtsFieldMappings: observable,
  removeSmartInboxLabels: observable,
  addOptionFixedAtsFieldMappings: action,
  save: action,
});

export default OptionStore;
