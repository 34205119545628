/* eslint-disable import/no-import-module-exports */
import 'ant-design-pro/dist/ant-design-pro.css';
import { ConfigProvider } from 'antd';
import 'bootstrap/dist/css/bootstrap.css';
import ErrorBoundary from 'components/ErrorBoundary';
import Page500 from 'components/Page500';
import { THEME, blue } from 'globalStyles';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import ReactDOM from 'react-dom';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import nl from 'react-intl/locale-data/nl';
import 'react-phone-number-input/style.css';
import ConnectedIntlProvider from './ConnectedIntlProvider';
import Main from './components/Main';
import { unregister } from './registerServiceWorker';
import * as stores from './stores';
import './style/index.css';

configure({
  enforceActions: 'always',
});

addLocaleData([...en, ...nl]);

const render = (Component) => {
  return ReactDOM.render(
    <Provider {...stores}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: `'IBM Plex Normal', 'Helvetica Neue', 'Segoe UI', sans-serif`,
            colorPrimary: THEME.BLUE,
            colorText: THEME.DARK_BLUE,
            Layout: {
              siderBg: THEME.GRAY,
              footerBg: THEME.GRAY,
              triggerBg: THEME.GRAY,
              lightTriggerColor: THEME.GRAY,
            },
          },
          components: {
            Select: {
              optionActiveBg: blue,
              optionSelectedBg: '#0dbebe59',
            },
          },
          components: {
            Select: {
              optionActiveBg: blue,
              optionSelectedBg: '#0dbebe59',
            },
          },
        }}
      >
        <ConnectedIntlProvider>
          <ErrorBoundary fallback={<Page500 />}>
            <Component />
          </ErrorBoundary>
        </ConnectedIntlProvider>
      </ConfigProvider>
    </Provider>,
    document.getElementById('root')
  );
};

// registerServiceWorker();
unregister(); // Unregistering service worker, disabling and opting out caching
// This should prevent the issue where users would get a white screen after deployment.

render(Main);

if (module.hot) {
  module.hot.accept('./components/Main/index.js', () => {
    const NextApp = require('./components/Main/index.js').default;
    render(NextApp);
  });
}
