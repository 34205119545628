import _ from 'lodash';
import requests from 'agent/requests';
import { parameterFragment, parameterMutationFragment } from './fragments';

const listQuery = `
query GetAllWhatsAppParameters {
  allWhatsAppParameters {
    ...parameterFragment
  }
}
${parameterFragment}
`;

const createMutation = `
mutation CreateWhatsAppParameter($newWhatsAppParameter: WhatsAppParameterCreateGenericType!) {
  createWhatsAppParameter(newWhatsappparameter: $newWhatsAppParameter) {
    ...parameterMutationFragment
  }
}
${parameterMutationFragment}
`;

const updateMutation = `
mutation UpdateWhatsAppParameter($newWhatsAppParameter: WhatsAppParameterUpdateGenericType!) {
  updateWhatsAppParameter(newWhatsappparameter: $newWhatsAppParameter) {
    ...parameterMutationFragment
  }
}
${parameterMutationFragment}
`;

const deleteMutation = `
mutation DeleteWhatsAppParameter($id: ID!) {
  deleteWhatsAppParameter(id: $id) {
    ...parameterMutationFragment
  }
}
${parameterMutationFragment}
`;

const deleteParametersMutation = (ids) => {
  const interpolatedParameterFields = ids.map((id) => {
    return `
      parameterDelete${id}: deleteWhatsAppParameter(id: ${id}) {
        ...parameterMutationFragment
      }
    `;
  });

  return `
    mutation DeleteWhatsAppParametersByIds {
      ${interpolatedParameterFields}
    }
    ${parameterMutationFragment}
  `;
};

const WhatsAppParameterAgent = {
  list: async () => {
    const res = await requests.gql.chatserver.query(listQuery);
    return _.get(res, 'data.allWhatsAppParameters');
  },
  create: async (newWhatsAppParameter) => {
    const res = await requests.gql.chatserver.mutate(createMutation, {
      newWhatsAppParameter,
    });
    return _.get(res, 'data.createWhatsAppParameter');
  },
  update: async (newWhatsAppParameter) => {
    const res = await requests.gql.chatserver.mutate(updateMutation, {
      newWhatsAppParameter,
    });
    return _.get(res, 'data.updateWhatsAppParameter');
  },
  delete: async (id) => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, {
      id,
    });
    return _.get(res, 'data.deleteWhatsAppParameter');
  },
  deleteParameters: async (ids) => {
    const res = await requests.gql.chatserver.mutate(
      deleteParametersMutation(ids)
    );
    return _.get(res, 'data.deleteWhatsAppParameter');
  },
};

export default WhatsAppParameterAgent;
