import requests from './requests'

const queryGetModelTrainingStatus = `
query GetModelTrainingStatus {
  models {
    trainStatus
  }
  }  
`

const mutationTrainModel = `
mutation TrainModel{
    trainModel {
      ok
    }
  }
`

const mutationPublishModel = `
mutation PublishModel {
    publishModel {
      ok
    }
  }
`

const NLP = {
  getModelTrainStatus: async () => {
    const {
      data: { models },
    } = await requests.gql.chatserver.query(queryGetModelTrainingStatus)
    return models[0].trainStatus
  },
  trainModel: async () => {
    const { data: ok } = await requests.gql.chatserver.mutate(
      mutationTrainModel,
    )
    if (!ok) throw new Error('Could not train model')
  },
  publishModel: async () => {
    const { data: ok } = await requests.gql.chatserver.mutate(
      mutationPublishModel,
    )
    if (!ok) throw new Error('Could not publish model')
  },
}

export default NLP
