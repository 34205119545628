import requests from 'agent/requests';

const updateAutomatch = /* GraphQL */ `
  mutation UpdateAutomatch($newAutomatch: AutomatchUpdateGenericType!) {
    updateAutomatch(newAutomatch: $newAutomatch) {
      ok
      automatch {
        id
        modified
      }
    }
  }
`;

const Automatch = {
  updateAutomatch: async (newAutomatch) => {
    const { data } = await requests.gql.chatserver.mutate(updateAutomatch, {
      newAutomatch,
    });
    return data.updateAutomatch;
  },
};

export default Automatch;
