import _ from 'lodash';
import requests from 'agent/requests';
import { variationFragment, variationMutationFragment } from './fragments';

const listQuery = `
query GetAllWhatsAppVariations {
  allWhatsAppVariations {
    ...variationFragment
  }
}
${variationFragment}
`;

const createMutation = `
mutation CreateWhatsAppVariation($newWhatsAppVariation: WhatsAppVariationCreateGenericType!) {
  createWhatsAppVariation(newWhatsappvariation: $newWhatsAppVariation) {
    ...variationMutationFragment
  }
}
${variationMutationFragment}
`;

const updateMutation = `
mutation UpdateWhatsAppVariation($newWhatsAppVariation: WhatsAppVariationUpdateGenericType!) {
  updateWhatsAppVariation(newWhatsappvariation: $newWhatsAppVariation) {
    ...variationMutationFragment
  }
}
${variationMutationFragment}
`;

const deleteMutation = `
mutation DeleteWhatsAppVariation($id: ID!) {
  deleteWhatsAppVariation(id: $id) {
    ...variationMutationFragment
  }
}
${variationMutationFragment}
`;

const deleteVariationsMutation = (ids) => {
  const interpolatedVariationFields = ids.map((id) => {
    return `
      variationDelete${id}: deleteWhatsAppVariation(id: ${id}) {
        ...variationMutationFragment
      }
    `;
  });

  return `
    mutation DeleteWhatsAppVariationsByIds {
      ${interpolatedVariationFields}
    }
    ${variationMutationFragment}
  `;
};

const WhatsAppVariationAgent = {
  list: async () => {
    const res = await requests.gql.chatserver.query(listQuery);
    return _.get(res, 'data.allWhatsappVariations');
  },
  create: async (newWhatsAppVariation) => {
    const res = await requests.gql.chatserver.mutate(createMutation, {
      newWhatsAppVariation,
    });
    return _.get(res, 'data.createWhatsAppVariation');
  },
  update: async (newWhatsAppVariation) => {
    const res = await requests.gql.chatserver.mutate(updateMutation, {
      newWhatsAppVariation,
    });
    return _.get(res, 'data.updateWhatsAppVariation');
  },
  delete: async (id) => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, {
      id,
    });
    return _.get(res, 'data.deleteWhatsAppVariation');
  },
  deleteVariations: async (ids) => {
    const res = await requests.gql.chatserver.mutate(
      deleteVariationsMutation(ids)
    );
    return _.get(res, 'data.deleteWhatsAppVariation');
  },
};

export default WhatsAppVariationAgent;
