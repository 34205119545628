import requests from 'agent/requests';
import _ from 'lodash';
import { TEMPLATE_CHAT_SERVER_LINK } from '../../constants';
import { definitionFragment, definitionMutationFragment } from './fragments';

const listQuery = `
query GetWhatsAppDefinitions {
  allWhatsAppDefinitions {
    ...definitionFragment
  }
}
${definitionFragment()}
`;
// we are using this query to fetch only the data we need when opening the template page
const initListQuery = `
query GetWhatsAppDefinitions {
  allWhatsAppDefinitions {
    ...definitionFragment
  }
}
fragment definitionFragment on WhatsAppDefinitionNode {
  id
  name
  supportsBatchAction
  versionsHistoricallyCreated
  isSmartInboxTemplate
  templateIdentifier
  availableTemplates {
    id
    status
    version
  }
  displayTemplate {
    ...templateFragment
  }
  activeTemplate {
    id
  }
  campaignSet {
    id
    name
    type
    atsBatchActions {
      id
      name
      entityType {
        id
        name
        allHumanReadableNames {
          id
          content
          tags {
            kind
            content
          }
        }
      }
    }
  }
}

fragment templateFragment on WhatsAppTemplateNode {
  id
  templateName
  description
  version
  status
  variations {
    ...variationFragment
  }
  parameters {
    ...parameterFragment
  }
  scalarNames {
    ...scalarNameFragment
  }
}

fragment scalarNameFragment on ScalarNameNode {
  id
  name
  humanReadableName
}

fragment variationFragment on WhatsAppVariationNode {
  id
  content
  status
  language
  languageTag {
    ...languageTagFragment
  }
}

fragment languageTagFragment on TagNode {
  id
  kind
  content
}

fragment parameterFragment on WhatsAppParameterNode {
  id
  name
  index
  atsFieldMapping{
    id
    atsFieldOrdering: atsfieldorderingSet {
      atsField {
        id
        humanReadableNames {
          id
          content
        }
      }
    }
  }
}
`;
// I am not using the fragments here because I am fetching less data than the query
const listActiveQuery = `
query GetActiveWhatsAppDefinitions {
  allWhatsAppDefinitions(activeTemplate_Isnull: false) {
    id
    name
    activeTemplate {
      ...templateFragment
    }
    displayTemplate {
      ...templateFragment
    }
  }
}

fragment templateFragment on WhatsAppTemplateNode {
  id
  templateName
  version
  status
  variations {
    ...variationFragment
  }
  parameters {
    ...parameterFragment
  }
  scalarNames {
    ...scalarNameFragment
  }
}

fragment scalarNameFragment on ScalarNameNode {
  id
  name
  humanReadableName
}

fragment variationFragment on WhatsAppVariationNode {
  id
  content
  status
  language
  languageTag {
    ...languageTagFragment
  }
  buttons {
    ...buttonFragment
  }
  parameters {
    ...parameterFragment
  }
}

fragment languageTagFragment on TagNode {
  id
  kind
  content
}


fragment buttonFragment on WhatsAppButtonNode {
  id
  actionType
  text
  phoneNumber
  url
  urlType
}


fragment parameterFragment on WhatsAppParameterNode {
  id
  name
  index
  atsFieldMapping{
    scalarName{
        id
        name
        humanReadableNames {
            id
            content
            tags {
              kind
              content
            }
          }
    }
    id
    atsFieldOrdering: atsfieldorderingSet {
      atsField {
        id
        getPath
        humanReadableNames {
            id
            content
            tags {
              kind
              content
            }
        }
        entityType {
          id
          name
          humanReadableNames {
            id
            content
            tags {
              kind
              content
            }
          }
        }
      }
      number
    }
  }
}
`;

const getQuery = `
query GetWhatsAppDefinition($id: ID!) {
  whatsAppDefinition(id: $id) {
    ...definitionFragment
  }
}
${definitionFragment()}
`;

const createMutation = `
mutation CreateWhatsAppDefinition($newWhatsAppDefinition: WhatsAppDefinitionCreateGenericType!) {
  createWhatsAppDefinition(newWhatsappdefinition: $newWhatsAppDefinition) {
    ...definitionMutationFragment
  }
}
${definitionMutationFragment}
`;

const updateMutation = `
mutation UpdateWhatsAppDefinition($newWhatsAppDefinition: WhatsAppDefinitionUpdateGenericType!) {
  updateWhatsAppDefinition(newWhatsappdefinition: $newWhatsAppDefinition) {
    ...definitionMutationFragment
  }
}
${definitionMutationFragment}
`;

const deleteMutation = `
mutation DeleteWhatsAppDefinition($id: ID!) {
  deleteWhatsAppDefinition(id: $id) {
    ...definitionMutationFragment
  }
}
${definitionMutationFragment}
`;

const deleteAllWhatsappDefinitionsTemplates = `
mutation DeleteAllWhatsAppDefinitionTemplates($id: ID!) {
  deleteAllWhatsAppDefinitionTemplates(whatsAppDefinitionId: $id) {
    ok
    errorMessage
  }
}
`;

const syncWhatsappTemplates = `
mutation SyncWhatsappTemplates {
  syncWhatsappTemplates {
    ok
    errorMessage
    whatsappDefinitions {
      ...definitionFragment
    }
  }
}
${definitionFragment()}
`;

const cloneMutation = `
mutation CloneWhatsAppDefinition($whatsappDefinitionId: ID!, $newDefinitionName: String) {
  cloneWhatsappDefinition(whatsappDefinitionId: $whatsappDefinitionId, newDefinitionName: $newDefinitionName) {
    ok
    errorMessage
    whatsappDefinition {
      ...definitionFragment
    }
  }
}
${definitionFragment()}
`;

const WhatsAppDefinitionAgent = {
  list: async () => {
    const res = await requests.gql.chatserver.query(initListQuery);
    return _.get(res, 'data.allWhatsAppDefinitions');
  },
  listActive: async () => {
    const res = await requests.gql.chatserver.query(listActiveQuery);
    return _.get(res, 'data.allWhatsAppDefinitions');
  },
  sync: async () => {
    const response = await requests.gql.chatserver.mutate(
      syncWhatsappTemplates
    );
    return response.data.syncWhatsappTemplates;
  },
  listTemplates: async () => {
    const res = await requests.gql.chatserver.query(
      listQuery,
      {},
      TEMPLATE_CHAT_SERVER_LINK
    );
    return _.get(res, 'data.allWhatsAppDefinitions');
  },
  get: async (id) => {
    const res = await requests.gql.chatserver.query(getQuery, {
      id,
    });
    return _.get(res, 'data.whatsAppDefinition');
  },
  create: async (newWhatsAppDefinition) => {
    const res = await requests.gql.chatserver.mutate(createMutation, {
      newWhatsAppDefinition,
    });
    return _.get(res, 'data.createWhatsAppDefinition');
  },
  update: async (newWhatsAppDefinition) => {
    const res = await requests.gql.chatserver.mutate(updateMutation, {
      newWhatsAppDefinition,
    });
    return _.get(res, 'data.updateWhatsAppDefinition');
  },
  delete: async (id) => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, {
      id,
    });
    return _.get(res, 'data.deleteWhatsAppDefinition');
  },
  deleteAllTemplatesAndDefinition: async (id) => {
    const res = await requests.gql.chatserver.mutate(
      deleteAllWhatsappDefinitionsTemplates,
      {
        id,
      }
    );
    return _.get(res, 'data.deleteAllWhatsAppDefinitionTemplates');
  },
  clone: async (id, newName) => {
    const res = await requests.gql.chatserver.mutate(cloneMutation, {
      whatsappDefinitionId: id,
      newDefinitionName: newName,
    });
    return _.get(res, 'data.cloneWhatsappDefinition');
  },
};

export default WhatsAppDefinitionAgent;
