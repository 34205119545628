import BaseStore from 'stores/BaseStore'
import agent from 'agent'
import { decorate, observable } from 'mobx'

class GoogleAnalyticsEventStore extends BaseStore {
  id = ''
  eventLabel = ''
  eventAction = ''
  systemName = ''

  constructor(args) {
    super()
    this.id = args.id
    this.eventLabel = args.eventLabel
    this.eventAction = args.eventAction
    this.systemName = args.systemName
  }

  async save() {
    const data = {
      newGoogleanalyticsevent: {
        id: this.id,
        systemName: this.systemName,
        eventLabel: this.eventLabel,
        eventAction: this.eventAction,
      },
    }
    await agent.GoogleAnalyticsEvent.update(data)
  }
}

decorate(GoogleAnalyticsEventStore, {
  id: observable,
  eventLabel: observable,
  eventAction: observable,
  systemName: observable,
})

export default GoogleAnalyticsEventStore
