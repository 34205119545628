import _ from 'lodash';
import { action, computed, decorate, observable, runInAction } from 'mobx';
import { userStore } from 'stores/index';
import agent from '../agent';
import { transformOption } from '../utils/builderUtils';
import { VIEW_MODES } from '../utils/constants/state.constants';
import AtsFieldMapping from './AtsFieldMapping.store';
import AtsOptionStore from './AtsOptionStore';
import BaseStore from './BaseStore';
import OptionStore from './OptionStore';
import Processor from './ProcessorStore';
import ScalarNameStore from './ScalarNameStore';
import ValidatorStore from './ValidatorStore';
import WhatsAppDefinitionStore from './campaigns/WhatsAppDefinition/WhatsAppDefinition.store';
import { saveStore } from './index';

class State extends BaseStore {
  id = '';
  name = '';
  options = [];
  optionlabelSet = [];
  defaultOption = null;
  draft = false;
  scalarName = null;
  isSkippable = false;
  alwaysStore = false;
  isLoading = false;
  isLoadingOptions = false;
  answerRequired = true;
  validator = null;
  replyProcessorEnabled = false;
  replyProcessor = null;
  processors = [];
  responses = [];
  extended = false;
  treeViewPositionX = 0;
  treeViewPositionY = 0;
  stateWithButtons = false;
  whatsAppDefinitions = [];
  viewMode = VIEW_MODES.COMPACT;
  divergentOptions = [];
  selectedDivergentOption = null;
  atsFieldMappings = [];
  optionAtsFieldMappings = [];
  atsFieldMappingContext = [];
  cancelFollowUp;

  // TODO: Store the whatsapp_definitions here!
  constructor(args) {
    super();
    this.setup(args);
    this.save = _.debounce(this.save, 2000);
  }

  setup(args) {
    this.id = args.id;
    this.name = args.name;
    this.draft = args.draft;
    this.isSkippable = args.isSkippable;
    this.alwaysStore = args.alwaysStore;
    this.answerRequired = !!args.answerRequired;
    this.treeViewPositionX = args.treeViewPositionX;
    this.treeViewPositionY = args.treeViewPositionY;
    this.cancelFollowUp = args.cancelFollowUp;
    if (args.scalarName) {
      this.scalarName = new ScalarNameStore(args.scalarName);
    }
    if (args.validator) {
      this.validator = new ValidatorStore(args.validator);
    }
    if (args.replyProcessorKey) {
      this.replyProcessor = args.replyProcessorKey;
    }
    if (args.processors) {
      this.processors = args.processors.map(
        (processor) => new Processor(processor)
      );
    }
    if (args.whatsAppDefinitions) {
      this.whatsAppDefinitions = args?.whatsAppDefinitions.map(
        (definition) => new WhatsAppDefinitionStore(definition)
      );
    }
    if (args.atsFieldMappings) {
      this.atsFieldMappings = args?.atsFieldMappings.map(
        (atsFieldMapping) => new AtsFieldMapping(atsFieldMapping)
      );
    }
    if (args.optionAtsFieldMappings) {
      this.optionAtsFieldMappings = args?.optionAtsFieldMappings.map(
        (optionAtsFieldMapping) => new AtsFieldMapping(optionAtsFieldMapping)
      );
    }

    this.replyProcessorEnabled = args.replyType !== 'NONE';
  }

  toggleExtended() {
    this.extended = !this.extended;
  }

  async setNodePosition(x, y) {
    if (x === this.treeViewPositionX && y === this.treeViewPositionY) return;
    this.treeViewPositionX = x;
    this.treeViewPositionY = y;
    await this.updateStateTreePosition();
  }

  get hasOptions() {
    return this.options.length !== 0 || this.defaultOption;
  }

  get sortedOptions() {
    return this.options.sort((a, b) => {
      if (a.isButton) {
        return -1;
      }
      if (a.type === 'INTENT' && !b.isButton) {
        return -1;
      }
      if (a.type !== 'INTENT' && b.isButton) {
        return 1;
      }
      return 0;
    });
  }

  setIsLoading(value) {
    this.isLoading = value;
  }
  reset() {
    this.defaultOption = null;
    this.options = [];
  }

  addOption(option) {
    this.options.push(new OptionStore(option));
  }

  updateDraft(draft) {
    this.draft = draft;
    saveStore.addStore(this);
  }

  async loadOptions() {
    const state = await agent.State.get(this.id);
    runInAction(() => {
      this.atsFieldMappings = state?.atsFieldMappings.map(
        (atsField) => new AtsFieldMapping(atsField)
      );
      this.cancelFollowUp = state?.cancelFollowUp;
      this.optionAtsFieldMappings = state?.optionAtsFieldMappings.map(
        (optionAtsField) => new AtsFieldMapping(optionAtsField)
      );
      if (state.defaultOption) {
        this.setAttr(
          'defaultOption',
          new OptionStore(transformOption(state.defaultOption))
        );
        this.setAttr('optionlabelSet', this.selectOptionLabels());
      }
      state.options.map((option) => this.addOption(transformOption(option)));
      if (state.defaultOption) {
        this.linkButtonsWithOptions();
      }
      if (state.divergentOptions) {
        this.divergentOptions = state.divergentOptions.map(
          (option) => new OptionStore(transformOption(option))
        );
      }
      if (state.selectedDivergentOption) {
        this.selectedDivergentOption = new OptionStore(
          transformOption(state.selectedDivergentOption)
        );
      }
    });
  }

  async updateStateTreePosition() {
    const data = {
      id: this.id,
      treeViewPositionX: this.treeViewPositionX,
      treeViewPositionY: this.treeViewPositionY,
    };

    await agent.State.update({ data });
  }

  async refreshOptions() {
    // Sync the options on a state
    const res = await agent.State.syncOptions(this.id);
    // Fetch the data from the current state
    const { options, defaultOption } = res.data.syncOptions.state;
    runInAction(() => {
      // Clear the options
      this.options = [];

      const defaultOptionId = defaultOption.id;

      const parsedOptions = options.filter(
        (option) => option.id !== defaultOptionId
      );

      // Reset the options
      parsedOptions.map((option) => this.addOption(transformOption(option)));

      if (this.defaultOption) {
        this.linkButtonsWithOptions();
      }
    });
  }

  async loadData() {
    if (!this.isLoading) {
      this.reset();
      this.setIsLoading(true);
      await this.loadOptions();
      this.setIsLoading(false);
    }
  }

  /**
   * Save the state
   *
   * @param refreshOptions  Indicates if the options should be refreshed after saving
   */
  async save(refreshOptions = false) {
    if (refreshOptions) {
      runInAction(() => {
        this.isLoadingOptions = true;
      });
    }

    const data = {
      data: {
        id: this.id,
        name: this.name,
        draft: this.draft,
        isSkippable: this.isSkippable,
        alwaysStore: this.alwaysStore,
        answerRequired: this.answerRequired,
        scalarName: this.scalarName ? this.scalarName.id : null,
        validator: this.validator ? this.validator.id : null,
        replyType: this.replyProcessorEnabled ? 'EXISTING' : 'NONE',
        replyProcessorKey: this.replyProcessor,
        selectedDivergentOption: this.selectedDivergentOption?.id,
        stateProcessor: this.processors
          ? this.processors.map(({ id }) => id)
          : null,
        optionAtsFieldMappings: this.optionAtsFieldMappings.map(({ id }) => id),
        atsFieldMappings: this.atsFieldMappings.map(({ id }) => id),
        cancelFollowUp: this.cancelFollowUp,
      },
    };

    if (this.scalarName) {
      data.data.scalarName = this.scalarName.id;
    }

    const res = await agent.State.update(data);
    const { state } = res.data.stateMutation;
    if (state) {
      this.setup(state);
    }

    if (refreshOptions) {
      await this.refreshOptions();
      runInAction(() => {
        this.isLoadingOptions = false;
      });
    }
  }

  selectOptionLabels = () =>
    _.first(
      this.defaultOption.actions
        .filter((a) => _.get(a, 'actionWithOptions.optionlabelSet'))
        .map((a) => a.actionWithOptions.optionlabelSet)
    );

  setScalarName(id, scalarNames) {
    const scalarName = _.find(scalarNames, { id });
    this.scalarName = scalarName;
    this.save();
  }

  async createNewScalarName(name) {
    const res = await agent.ScalarName.create({ name });
    const { ok } = res.data.createScalarNameMutation;
    if (!ok) throw Error('Can not create scalar name');
    let scalar;
    runInAction(() => {
      scalar = new ScalarNameStore(
        res.data.createScalarNameMutation.scalarname
      );
    });
    return scalar;
  }

  deleteScalarName(id) {
    if (id === _.get(this, 'scalarName.id')) {
      this.scalarName = null;
    }

    this.deleteFromBackend(id);
  }

  async deleteFromBackend(id) {
    const res = await agent.ScalarName.delete(id);
    const ok = _.get(res, 'data.deleteScalarNameMutation.ok');
    if (!ok) throw Error('Can not delete scalar name');
  }

  async createOption() {
    const params = {
      data: {
        name: 'new option',
        symbol: 'new symbol',
        stateId: this.id,
      },
    };

    const { data } = await agent.Option.create(params);
    const { option, ok } = data.optionCreate;
    if (!ok) throw Error('Could not create an option');
    this.addOption(transformOption(option));
    return option;
  }

  async deleteOption(id) {
    // Remove from the options list
    _.remove(this.options, { id });
    // If the option was related to an option label then
    // Find and set to null the option label that is linked with this option
    const optionLabel = _.find(this.optionlabelSet, { optionID: id });
    if (optionLabel) {
      optionLabel.option = null;
    }
    const { data } = await agent.Option.delete(id);
    const { ok } = data.optionDelete;
    if (!ok) throw Error('Could not delete option');
  }

  linkButtonsWithOptions() {
    this.options.forEach((option) => {
      if (option.optionLabel) {
        option.optionLabel = null;
      }
    });
    if (this.optionlabelSet) {
      this.optionlabelSet.forEach(
        action((optionLabel) => {
          const option = _.find(this.options, { id: optionLabel.optionID });
          if (option) {
            option.isButton = true;
            option.optionLabel = optionLabel;
            option.position = optionLabel.position;
          }
        })
      );
    }
  }

  async fetchResponses() {
    const tenant = userStore.selectedChatbot.name;
    const response = await agent.State.getResponses(this.id, tenant);

    runInAction(() => {
      this.responses = response;
    });
  }

  async cloneAtsFieldMapping(fieldToCloneId) {
    const {
      data: { cloneAtsFieldMapping },
    } = await agent.AllAtsFieldMappings.cloneAtsFieldMapping(fieldToCloneId);
    if (cloneAtsFieldMapping.ok) {
      // check what type it is and assign it accordingly
      if (cloneAtsFieldMapping.atsFieldMapping.type === 'OPTIONS') {
        await this.handleUpdateAtsFieldMapping(
          this.atsFieldMappings,
          cloneAtsFieldMapping.atsFieldMapping
        );
      } else {
        // on the state to be equal to the scalar_name configured in the ats_field_mapping you just copied.
        await this.handleUpdateAtsFieldMapping(
          this.atsFieldMappings,
          cloneAtsFieldMapping.atsFieldMapping
        );
      }

      // after updating the ats field mapping we need to update the scalar_name
      runInAction(() => {
        this.scalarName = new ScalarNameStore(
          this.atsFieldMappings[0].scalarName
        );
      });
      await this.save();
      return true;
    }
    return false;
  }

  async deleteAtsFieldMapping(fieldToDelete) {
    const res = await agent.AllAtsFieldMappings.deleteAtsFieldMapping(
      fieldToDelete.id
    );
    runInAction(() => {
      // here we delete all ats field mapping in all processor once we delete it from state
      for (const processor of this?.processors) {
        const selectedProcessorFromChatbot =
          userStore.selectedChatbot?.allProcessors?.filter(
            ({ id }) => id === processor.id
          );

        for (const selectedProcessor of selectedProcessorFromChatbot) {
          selectedProcessor?.assignAtsFieldMapping(fieldToDelete, 'remove');
        }
      }
      this.atsFieldMappingContext = this.atsFieldMappingContext.filter(
        ({ id }) => id !== fieldToDelete?.id
      );
      if (fieldToDelete?.type === 'OPTIONS') {
        this.optionAtsFieldMappings = this.optionAtsFieldMappings.filter(
          ({ id }) => id !== fieldToDelete?.id
        );
        this.atsFieldMappings = this.atsFieldMappings.filter(
          ({ id }) => id !== fieldToDelete?.id
        );
      } else {
        this.atsFieldMappings = this.atsFieldMappings.filter(
          ({ id }) => id !== fieldToDelete?.id
        );
      }
    });

    return res;
  }

  async handleUpdateAtsFieldMapping(mappingFieldToUpdate, newField) {
    // first check if we have an atsFieldMapping according to the type
    if (mappingFieldToUpdate.length) {
      // remove it from the backend..
      await this.deleteAtsFieldMapping(mappingFieldToUpdate[0]);
    }
    runInAction(() => {
      // here we update all the processors to have the cloned ats filed mapping configured
      // so we loop through all the processors from selectedchatbot
      for (const processor of this.processors) {
        const selectedProcessorFromChatbot =
          userStore.selectedChatbot?.allProcessors?.filter(
            ({ id }) => id === processor.id
          );

        for (const selectedProcessor of selectedProcessorFromChatbot) {
          const atsFieldMappingToUpdate =
            selectedProcessor?.processorAtsFieldMappings.find(
              ({ id }) =>
                id === mappingFieldToUpdate[0]?.id ||
                id === this.optionAtsFieldMappings[0]?.id
            );

          if (atsFieldMappingToUpdate) {
            selectedProcessor.assignAtsFieldMapping(
              new AtsFieldMapping(newField),
              'add'
            );

            selectedProcessor.assignAtsFieldMapping(
              atsFieldMappingToUpdate,
              'remove'
            );
          } else {
            selectedProcessor.assignAtsFieldMapping(
              new AtsFieldMapping(newField),
              'add'
            );
          }
        }
      }

      // update the frontend
      if (newField.type === 'OPTIONS') {
        this.atsFieldMappings[0] = new AtsFieldMapping(newField);
        this.optionAtsFieldMappings[0] = new AtsFieldMapping(newField);
      } else {
        this.atsFieldMappings[0] = new AtsFieldMapping(newField);
      }
    });
  }

  // render all the atsFieldOptions
  async getAtsFieldOptions(atsFieldMappingId) {
    const res = await agent.State.getAtsFieldOptions(atsFieldMappingId);
    runInAction(() => {
      this.atsFieldMappings[0].atsFieldOptions =
        res?.data?.atsFieldOptions?.map(
          (fieldOption) => new AtsOptionStore(fieldOption)
        );
    });
  }

  async getStateAtsFieldMapping() {
    const { data: currentAtsFieldMappingContext } =
      await agent.AllAtsFieldMappings?.getStateAtsFieldMapping(this.id);

    if (currentAtsFieldMappingContext?.currentAtsFieldMappingContext?.length) {
      runInAction(() => {
        this.isLoading = true;
        this.atsFieldMappingContext =
          currentAtsFieldMappingContext?.currentAtsFieldMappingContext?.map(
            (fieldMapping) => new AtsFieldMapping(fieldMapping)
          );
        this.isLoading = false;
      });
    }
  }

  async assignDivergentOption(optionId) {
    if (!optionId || optionId === this.selectedDivergentOption.id) return;

    if (this.selectedDivergentOption.id) {
      await agent.Option.delete(this.selectedDivergentOption.id);
    }
    const {
      data: { copyDivergentOption },
    } = await agent.Option.copyDivergentOptionsTemplate(optionId, this.id);
    const { divergentOption, ok } = copyDivergentOption;

    if (ok) {
      runInAction(
        () =>
          (this.selectedDivergentOption = new OptionStore(
            transformOption(divergentOption)
          ))
      );
    }
  }
}

decorate(State, {
  id: observable,
  name: observable,
  options: observable,
  optionlabelSet: observable,
  defaultOption: observable,
  draft: observable,
  scalarName: observable,
  isSkippable: observable,
  alwaysStore: observable,
  isLoading: observable,
  isLoadingOptions: observable,
  answerRequired: observable,
  validator: observable,
  replyProcessorEnabled: observable,
  replyProcessor: observable,
  processors: observable,
  responses: observable,
  whatsAppDefinitions: observable,
  atsFieldMappings: observable,
  optionAtsFieldMappings: observable,
  hasOptions: computed,
  setup: action,
  setIsLoading: action,
  reset: action,
  addOption: action,
  updateDraft: action,
  loadOptions: action,
  refreshOptions: action,
  loadData: action,
  save: action,
  selectOptionLabels: action,
  setScalarName: action,
  deleteScalarName: action,
  createOption: action.bound,
  deleteOption: action,
  linkButtonsWithOptions: action,
  fetchResponses: action,
  extended: observable,
  toggleExtended: action,
  sortedOptions: computed,
  setNodePosition: action,
  createNewScalarName: action,
  viewMode: observable,
  divergentOptions: observable,
  selectedDivergentOption: observable,
  cloneAtsFieldMapping: action,
  deleteAtsFieldMapping: action,
  updateAtsFieldMapping: action,
  getAtsFieldOptions: action,
  getStateAtsFieldMapping: action,
  handleUpdateAtsFieldMapping: action,
  atsFieldMappingContext: observable,
  cancelFollowUp: observable,
});

export default State;
