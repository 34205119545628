import { action, decorate, observable } from 'mobx';
import BaseStore from './BaseStore';

class Team extends BaseStore {
  id = '';
  name = '';
  hasAccess = true;

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    this.id = args.id;
    this.name = args.name;
    this.hasAccess = args.hasAccess;
  }
}

decorate(Team, {
  id: observable,
  name: observable,
  hasAccess: observable,
  setup: action,
});

export default Team;
