import _ from 'lodash';
import requests from 'agent/requests';

const setupAtsBatchActions = `mutation SetupAtsBatchAction($whatsappDefinitionId: ID, $campaignId: ID, $enabled: Boolean) {
  setupAtsBatchAction(whatsappDefinitionId:$whatsappDefinitionId, campaignId:$campaignId, enabled: $enabled) {
    ok
    errorMessage
    campaign {
      id
      name
      type
      atsBatchActions {
        id
        externalId
        name
        entityType {
          id
          name
          allHumanReadableNames {
            id
            content
            tags {
              kind
              content
            }
          }
        }
        notificationHeader
        notificationBody
        url
      }
    }
  }
}
`;

const AtsBatchAction = {
  setup: async (whatsappDefinitionId, campaignId, enabled) => {
    const res = await requests.gql.chatserver.mutate(setupAtsBatchActions, {
      whatsappDefinitionId,
      campaignId,
      enabled,
    });
    return _.get(res, 'data.setupAtsBatchAction');
  },
};

export default AtsBatchAction;
