import requests from 'agent/requests';
import _ from 'lodash';

const getAllCustomReports = `
  query AllCustomReports {
  allCustomReports {
    id
    title
    description
    url
  }
}
`;

const CustomAnalyticsAgent = {
  all: async () => {
    const res = await requests.gql.chatserver.query(getAllCustomReports);
    return _.get(res, 'data.allCustomReports');
  },

  generateReport: async (url, body) => {
    return requests.manualPost(url, body);
  },
};

export default CustomAnalyticsAgent;
