import agent from 'agent';
import { action, decorate, observable } from 'mobx';
import moment from 'moment';
import BaseStore from 'stores/BaseStore';
import Processor from 'stores/ProcessorStore';
import QueryStore from './Query.store';

class Broadcast extends BaseStore {
  id;
  name;
  type;
  destinationGetter;
  nextBroadcast;
  interval;
  allowedDays;
  minExecutionTime;
  maxExecutionTime;
  approachLimit;
  targetQuery = {};

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.id = params.id;
    this.name = params.name;
    this.type = params.type;
    this.destinationGetter = new Processor(params.destinationGetter || {});
    this.nextBroadcast = params.nextBroadcast
      ? moment(params.nextBroadcast)
      : null;
    this.interval = params.interval;
    this.allowedDays = params.allowedDays;
    this.minExecutionTime = params.minExecutionTime;
    this.maxExecutionTime = params.maxExecutionTime;
    this.approachLimit = params.approachLimit;
    this.targetQuery = new QueryStore(params.targetQuery || {});
  }

  async updateExecutionTime(value, key) {
    this[key] = value;
  }

  async save() {
    const data = {
      id: this.id,
      interval: this.interval,
      allowedDays: this.allowedDays,
      minExecutionTime: this.minExecutionTime,
      maxExecutionTime: this.maxExecutionTime,
      approachLimit: this.approachLimit,
    };
    await agent.Broadcast.update(data);
  }
}

decorate(Broadcast, {
  id: observable,
  name: observable,
  destinationGetter: observable,
  nextBroadcast: observable,
  interval: observable,
  allowedDays: observable,
  minExecutionTime: observable,
  maxExecutionTime: observable,
  approachLimit: observable,
  setup: action,
  conditions: observable,
  updateExecutionTime: action,
});

export default Broadcast;
