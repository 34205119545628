import { action, computed, decorate, observable, runInAction } from 'mobx';

import _ from 'lodash';
import { applyEdgeChanges } from 'react-flow-renderer';
import AtsFieldMapping from 'stores/AtsFieldMapping.store';
import { userStore } from 'stores/index';
import { arrayMove } from 'utils/utils';
import agent from '../../agent';
import BaseStore from '../BaseStore';
import Processor from '../ProcessorStore';
import StateGroup from '../StateGroup';
import WhatsAppDefinitionStore from '../campaigns/WhatsAppDefinition/WhatsAppDefinition.store';

class Flow extends BaseStore {
  id = '';
  name = '';
  description = '';
  stateGroups = [];
  isLoading = false;
  isLoaded = false;
  isCreating = false;
  processors = [];
  totalHeight = 0;
  statesRelations = [];
  stateEdges = [];
  statesToRender = [];
  edgeStyle = '';
  betweenStatesEdgeStyle = 'default';
  showValidator = true;
  showIntentions = true;
  showMetaData = true;
  showActions = true;
  showData = true;
  whatsAppDefinitions = [];
  fixedAtsFieldMappings = [];

  constructor(args) {
    super();
    this.setup(args);
  }
  setup(args) {
    this.id = args?.id;
    this.name = args?.name;
    this.description = args?.description;
    this.stateGroups = [];
    this.isLoading = false;
    this.isCreating = args?.isCreating || false;

    if (!this.isCreating) {
      this.save = _.debounce(this.save, 2000);
      this.saveStateGroupOrdering = _.debounce(
        this.saveStateGroupOrdering,
        2000
      );
    }

    if (args?.processors) {
      this.processors = args?.processors.map(
        (processor) => new Processor(processor)
      );
    }

    if (args?.campaigns) {
      this.whatsAppDefinitions = args?.campaigns.map(
        (campaign) => new WhatsAppDefinitionStore(campaign.whatsappDefinition)
      );
    }
    if (args?.fixedAtsFieldMappings) {
      this.fixedAtsFieldMappings = args?.fixedAtsFieldMappings.map(
        (fixedAtsFieldMapping) => new AtsFieldMapping(fixedAtsFieldMapping)
      );
    }
  }

  async load() {
    this.isLoading = true;
    const res = await agent.Flow.get(this.id);
    this.setup(res.data.stateMachine);
  }

  async deleteStateGroup(id) {
    // Delete from the backend
    const res = await agent.StateGroup.delete(id);
    const ok = _.get(res, 'data.deleteStateGroupMutation.ok');
    if (!ok) throw Error(`Can not delete state group ${id}`);

    // Delete from the UI
    runInAction(() => {
      _.remove(this.stateGroups, (stateGroup) => stateGroup.id === id);
    });
  }

  moveStateGroup(stateGroupToMove, delta) {
    const stateGroupsCopy = this.stateGroups;
    const index = _.findIndex(
      stateGroupsCopy,
      (a) => a.id === stateGroupToMove.id
    );
    const newIndex = index + delta;
    if (newIndex < 0 || newIndex === stateGroupsCopy.length) return; // Already at the top or bottom.
    const orderedStateGroups = arrayMove(stateGroupsCopy, index, newIndex);
    runInAction(() => {
      this.stateGroups = orderedStateGroups;
    });
  }

  moveUpStateGroup(stateGroupToMove) {
    this.moveStateGroup(stateGroupToMove, -1);
    this.saveStateFGroupOrdering();
  }

  moveDownStateGroup(stateGroupToMove) {
    this.moveStateGroup(stateGroupToMove, 1);
    this.saveStateGroupOrdering();
  }

  async saveStateGroupOrdering() {
    const updateStateGroupOrderdata = {
      stateMachineId: this.id,
      stateGroupids: this.stateGroups.map((stateGroup) => stateGroup.id),
    };
    const res = await agent.Flow.updateStateGroupOrder(
      updateStateGroupOrderdata
    );
    const ok = _.get(res, 'data.updateStateGroupOrdering.ok');
    if (!ok) {
      throw Error('Could not update state group ordering');
    }
    return res;
  }

  async save() {
    const data = {
      id: this.id,
      name: this.name,
      wrapupProcessor: this.processors
        ? this.processors.map((processor) => processor.id)
        : null,
      fixedAtsFieldMappings: this.fixedAtsFieldMappings.map(({ id }) => id),
    };
    const res = await agent.Flow.update(data);
    const ok = _.get(res, 'data.updateStateMachine.ok');
    if (!ok) {
      throw Error('Could not update flow');
    }
    return res;
  }

  async fetchStateGroups(chatbot) {
    const res = await agent.StateGroup.getListByFlowId(this.id);
    const stateGroups = _.orderBy(
      res?.data?.stateMachine?.stateGroups,
      ['postion'],
      ['desc']
    );
    runInAction(() => {
      this.isLoaded = true;
      let initialLoad = false;
      // do not open collapse if it is initial  load
      if (this.stateGroups.length === 0) {
        initialLoad = true;
      }

      stateGroups.forEach((stateGroup, index) => {
        if (index === 0) {
          stateGroup.whatsAppDefinitions = this.whatsAppDefinitions;
        }
        // Add only the new items that where not in the array before
        if (!_.find(this.stateGroups, { id: stateGroup.id })) {
          const sg = new StateGroup(stateGroup);
          this.stateGroups.push(sg);

          if (!initialLoad) {
            sg.isCollapseOpen = true;
            if (stateGroup.initialState) {
              chatbot.selectState(sg.initialState.id);
            } else if (sg.states.length > 0) {
              chatbot.selectState(sg.states[0].id);
            }
          }
        }
      });
    });
    return this.stateGroups;
  }

  get allStates() {
    const stateGroups = _.flattenDeep(
      _.map(this.stateGroups, (stateGroup) => stateGroup)
    );
    return _.flattenDeep(
      _.map(stateGroups, (stateGroup) =>
        _.map(stateGroup.states, (state) => state)
      )
    );
  }

  async calcStateNodesWithSubFlows() {
    const statesNodes = [];
    const edges = [];
    this.isLoading = true;
    // keep track of the num of states to be able to render the states in the right x axis
    let numOfStates = 0;
    for (const stateGroupIndex in this.stateGroups) {
      // first we render all the initial states.. then the rest of the states (mostly we have only initial States)
      const stateGroup = this.stateGroups[stateGroupIndex];
      const { initialState } = stateGroup;
      const [initialStateNodes, initialStateEdges] =
        await this.generateStateNodes(
          initialState,
          stateGroupIndex,
          numOfStates === 1
        );
      statesNodes.push(...initialStateNodes);
      edges.push(...initialStateEdges);
      numOfStates++;
      const initialStateOptions = initialState.options.length;
      const initialStateWidth = initialStateOptions * 550 + 100;
      let totalStateGroupWidth = initialStateWidth + 100;
      // if the states are more than one.. this means that we need to render other states next to the initial state
      if (stateGroup.states.length > 1) {
        for (const stateIndex in stateGroup.states) {
          const state = stateGroup.states[stateIndex];
          if (state.id === initialState.id) continue;
          const [stateNodes, stateEdges] = await this.generateStateNodes(
            state,
            stateGroupIndex,
            numOfStates === 1,
            totalStateGroupWidth
          );
          statesNodes.push(...stateNodes);
          edges.push(...stateEdges);
          const statesOptions = state.options.length;
          const statesOptionsWidth = statesOptions * 550 + 100;
          // for each state we are incrementing the total width by the width of the state.. so that the states move to the right
          totalStateGroupWidth =
            totalStateGroupWidth + statesOptionsWidth + 100;
          numOfStates++;
        }
      }
    }
    runInAction(() => {
      this.statesToRender = statesNodes;
      this.stateEdges = edges;
      this.isLoading = false;
    });
  }
  // TODO: refactor this function to calculate the edges and update them in the store (for the edge changes)
  calcStateEdges(option, state, stateGroupIndex) {
    const optionId = option.id;
    // check the nextState on the option
    const assignedNextStateId = option.nextState
      ? option.nextState.id
      : option.nextStateMachine?.id;
    // when there is no next state.. the next state is the initial state of the next state group
    const nextStateId =
      assignedNextStateId ||
      this.stateGroups[parseInt(stateGroupIndex) + 1]?.initialState.id;

    // the relationship between default option and other options
    const withinStateRelation = {
      id: `within-state-${state.id}-${optionId}`,
      source: `${state.id}_default`,
      target: optionId,
      sourceHandle: `${state.id}_default`,
      targetHandle: optionId,
      type: this.edgeStyle,
      betweenStates: false,
      style: {
        stroke: '#bc0c55',
        strokeWidth: 10,
      },
    };
    // the relations between states
    // when it's the same state we don't want to show a line
    const betweenStatesRelations = nextStateId !== state.id && {
      id: `between-states-${nextStateId}-${optionId}`,
      source: optionId,
      target: `${nextStateId}_default`,
      sourceHandle: optionId,
      animated: !assignedNextStateId, // show dotted lines when the next state is a flow
      type: this.betweenStatesEdgeStyle.includes('Smart')
        ? 'smart'
        : this.betweenStatesEdgeStyle,
      style: {
        stroke: '#bc0c55',
        strokeWidth: 12,
      },
    };
    return [withinStateRelation, betweenStatesRelations];
  }

  generateOptionNodes(option, state, optionIndex) {
    // this the options nodes
    const optionNodeX = optionIndex ? 520 * optionIndex + 180 : 180;
    return {
      id: option.id,
      type: 'stateNode',
      data: {
        option,
        state,
        settings: this.treeViewSettings,
      },
      position: { x: optionNodeX, y: 800 },
      parentNode: state.id,
      extent: 'parent',
      draggable: false,
    };
  }

  async generateStateNodes(state, stateGroupIndex, firstState, xPosition = 0) {
    const stateEdges = [];
    // load the state only if it hasn't been loaded
    if (!state?.defaultOption) {
      await state?.loadData();
    }
    const numOfOptions = state.options.length;
    // the width is 400 per option and 50 is the margin
    const initialStateWidth = numOfOptions * 520 + 180;
    const y = state.treeViewPositionY || stateGroupIndex * 1700;
    const x = state.treeViewPositionX || xPosition || 0;
    const parentNode = {
      id: state.id,
      type: 'group',
      position: { x, y },
      style: {
        width: initialStateWidth,
        height: 1500,
        borderRadius: '15px',
        borderColor: 'transparent',
        backgroundColor: 'white',
        opacity: '0.6',
        zIndex: '-1',
      },
    };
    // the option that has the question
    const defaultOptionNode = {
      id: `${state.id}_default`,
      type: 'stateNode',
      data: {
        option: state.defaultOption,
        state: state,
        extraData: { defaultOption: true, firstState },
        settings: this.treeViewSettings,
      },
      position: { x: initialStateWidth / 2 - 175, y: 30 },
      parentNode: state.id,
      extent: 'parent',
      draggable: false,
    };

    // here we are looping through the options and creating 1) the option nodes, 2) the relations between nodes
    const options = state.options.map((option, optionIndex) => {
      // using the data in the options we are calculating the edges
      const [withinStateRelation, betweenStatesRelations] = this.calcStateEdges(
        option,
        state,
        stateGroupIndex
      );
      stateEdges.push(withinStateRelation, betweenStatesRelations);
      return this.generateOptionNodes(option, state, optionIndex);
    });
    return [[parentNode, defaultOptionNode, ...options], stateEdges];
  }

  onNodesChange = (changes) => {
    // find the node that has changes and update it's position
    const draggedNode = changes.find((change) => change.dragging);
    if (draggedNode) {
      const draggedState = this.allStates.find(
        (state) => state.id === draggedNode.id
      );
      // when it's not a state that moved, we don't want to update the position
      if (draggedState) {
        draggedState.treeViewPositionX = draggedNode.position.x;
        draggedState.treeViewPositionY = draggedNode.position.y;
        draggedState.updateStateTreePosition();
      }
    }
    return;
  };

  onEdgesChange = (changes) => {
    this.stateEdges = applyEdgeChanges(changes, this.stateEdges);
  };

  get treeViewSettings() {
    return {
      validator: this.showValidator,
      intentions: this.showIntentions,
      metaData: this.showMetaData,
      actions: this.showActions,
      data: this.showData,
    };
  }
  setStateGroups(stateGroups) {
    this.stateGroups = stateGroups;
  }

  /**
   * @param fieldToClone {object}this is the atsFieldMapping we need to clone
   * @param option {object}this is the option   where the mappingOption should be in relation with
   */
  async cloneAtsFieldMapping(fieldToClone, option, selectedState) {
    const {
      data: { cloneAtsFieldMapping },
    } = await agent.AllAtsFieldMappings.cloneAtsFieldMapping(fieldToClone?.id);

    if (cloneAtsFieldMapping.ok) {
      // first we create new atsMappingOptions
      const newAtsmappingoption = {
        option: option?.id,
        doStoreValue: true,
      };
      const res = await agent.State.createAtsMappingOption({
        newAtsmappingoption,
      });
      const { createAtsMappingOption } = res.data;

      // here we check first if we are configuring from the list of fixed value/options
      // then on cloning we switch the cloned atsFieldMapping to FIXED_CUSTOM_DATA_VALUE if the type is REGULAR and to FIXED_CUSTOM_DATA_OPTIONS if the type is OPTIONS
      const {
        data: { updateAtsFieldMapping },
      } = await agent.AllAtsFieldMappings?.updateAtsFieldMapping({
        type:
          cloneAtsFieldMapping.atsFieldMapping?.type === 'REGULAR'
            ? 'FIXED_CUSTOM_DATA_VALUE'
            : 'FIXED_CUSTOM_DATA_OPTIONS',
        id: cloneAtsFieldMapping.atsFieldMapping?.id,
        mappingOptions: createAtsMappingOption?.atsmappingoption?.id,
      });

      runInAction(() => {
        for (const processor of selectedState?.processors) {
          const selectedProcessorFromChatbot =
            userStore.selectedChatbot?.allProcessors?.filter(
              ({ id }) => id === processor.id
            );

          for (const selectedProcessor of selectedProcessorFromChatbot) {
            const atsFieldMappingToUpdate =
              selectedProcessor?.processorAtsFieldMappings.find(
                ({ id }) => id === updateAtsFieldMapping.atsfieldmapping?.id
              );
            // here we check if is atsfieldmapping to update
            if (atsFieldMappingToUpdate) {
              // 1- we add the new one into the processors of the states
              selectedProcessor.assignAtsFieldMapping(
                new AtsFieldMapping(updateAtsFieldMapping.atsfieldmapping),
                'add'
              );
              //2- and we delete the one should be updated of the states
              selectedProcessor.assignAtsFieldMapping(
                atsFieldMappingToUpdate,
                'remove'
              );
            }
            // otherwise we just create ats fieldfieldmapping and we push it in the processors of the states
            else {
              selectedProcessor.assignAtsFieldMapping(
                new AtsFieldMapping(updateAtsFieldMapping.atsfieldmapping),
                'add'
              );
            }
          }
        }
        option?.addOptionFixedAtsFieldMappings(
          new AtsFieldMapping(updateAtsFieldMapping.atsfieldmapping)
        );
        this.fixedAtsFieldMappings.push(
          new AtsFieldMapping(updateAtsFieldMapping.atsfieldmapping)
        );
      });

      await this.save();
      return true;
    }
    return false;
  }

  async deleteAtsFieldMapping(atsFieldMappingId, selectedState) {
    const res = await agent?.AllAtsFieldMappings?.deleteAtsFieldMapping(
      atsFieldMappingId
    );

    runInAction(() => {
      const selectedAtsFieldMappingToDelete = this.fixedAtsFieldMappings.find(
        ({ id }) => id === atsFieldMappingId
      );
      for (const processor of selectedState?.processors) {
        const selectedProcessorFromChatbot =
          userStore.selectedChatbot?.allProcessors?.filter(
            ({ id }) => id === processor.id
          );

        for (const selectedProcessor of selectedProcessorFromChatbot) {
          selectedProcessor?.assignAtsFieldMapping(
            selectedAtsFieldMappingToDelete,
            'remove'
          );
        }
      }

      this.fixedAtsFieldMappings = this.fixedAtsFieldMappings.filter(
        ({ id }) => id !== atsFieldMappingId
      );
    });
    return res;
  }
}
decorate(Flow, {
  id: observable,
  name: observable,
  stateGroups: observable,
  isLoading: observable,
  isLoaded: observable,
  description: observable,
  processors: observable,
  isCreating: observable,
  setup: action,
  load: action,
  deleteStateGroup: action,
  moveStateGroup: action,
  moveUpStateGroup: action,
  moveDownStateGroup: action,
  fetchStateGroups: action,
  setStateGroups: action,
  allStates: computed,
  totalHeight: observable,
  loadTreeView: action,
  stateRelations: observable,
  edgeStyle: observable,
  statesToRender: observable,
  stateEdges: observable,
  calcStateEdges: action,
  calcStateNodes: action,
  calcStateNodesWithSubFlows: action,
  createAtsMappingOption: action,
  onEdgesChange: action,
  onNodesChange: action,
  deleteAtsFieldMapping: action,
  cloneAtsFieldMapping: action,
  showValidator: observable,
  showIntentions: observable,
  showMetaData: observable,
  showActions: observable,
  showData: observable,
  betweenStatesEdgeStyle: observable,
  campaigns: observable,
  fixedAtsFieldMappings: observable,
});

export default Flow;
