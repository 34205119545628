import {
  calendarTypes,
  configurationTabs,
} from 'constants/calendarOverview.constants';
import _ from 'lodash';
import { action, decorate, observable, runInAction } from 'mobx';
import { listActiveEventConfigurations } from 'utils/calendarOverview.utils';
import agent from '../../agent';
import BaseStore from '../BaseStore';
import Translation from '../TranslationStore';
import { userStore } from '../index';
import EventConfiguration from './EventConfiguration.store';
import EventPreference from './EventPreference.store';

class CalendarSettings extends BaseStore {
  allEventConfigurations = [];
  loggedInUserEventPreferences = [];
  configurationToEdit = null;
  isLoading = false;
  configurationDrawerOpen = false;
  configurationDrawerSelectedTab = configurationTabs.TIME_SCHEDULE;
  creatingNewEventConfiguration = false;
  dayNameForNewAvailability = null;

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    this.loggedInUserEventPreferences = args?.loggedInUserEventPreferences?.map(
      (eventPreference) => new EventPreference(eventPreference)
    );
  }

  async listAllEventConfigurations(selectedCalendarUserPreferences) {
    // fetch all the events
    const allEventConfigurations =
      await agent.Calendar.listEventConfigurations();

    if (!allEventConfigurations) return;
    const sortedEventConfigs = _.orderBy(
      allEventConfigurations,
      [(config) => config.name.toLowerCase()],
      ['asc']
    );
    // then we check each event configuration if it has an event preference related to it,
    // if so we add  "relatedEventPreference: {id: eventPreference.id, enabled: eventPreference.enabled}" to the event configuration
    const userEventPreferences =
      selectedCalendarUserPreferences || this.loggedInUserEventPreferences;
    const activeEventConfigurations =
      listActiveEventConfigurations(userEventPreferences);
    runInAction(() => {
      this.allEventConfigurations = sortedEventConfigs.map(
        (eventConfiguration) => {
          const activeEventConfiguration = activeEventConfigurations.find(
            (activeEvent) => activeEvent.id === eventConfiguration.id
          );
          return new EventConfiguration({
            ...eventConfiguration,
            relatedEventPreference: !!activeEventConfiguration
              ? activeEventConfiguration.relatedEventPreference
              : null,
          });
        }
      );
    });
  }

  async cloneEventConfiguration(eventConfigurationId, newName) {
    const { ok, eventConfiguration } =
      await agent.Calendar.cloneEventConfiguration(
        eventConfigurationId,
        newName
      );

    if (ok) {
      runInAction(() => {
        this.allEventConfigurations = _.orderBy(
          [
            ...this.allEventConfigurations,
            new EventConfiguration(eventConfiguration),
          ],
          [(config) => config.name.toLowerCase()],
          ['asc']
        );
      });
    }
    return ok;
  }

  async deleteEventConfiguration(eventConfigurationId) {
    const deleteEvent = await agent.Calendar.deleteEventConfiguration(
      eventConfigurationId
    );
    if (deleteEvent.ok) {
      // find the event config and delete all the translations from the backend
      const deletedEventConfigIndex = this.allEventConfigurations.findIndex(
        (eventConfiguration) => eventConfiguration.id === eventConfigurationId
      );
      await this.allEventConfigurations[
        deletedEventConfigIndex
      ].deleteAllEventConfigTranslations();

      // delete the event Preference linked to event config
      const eventPreferenceToDelete = this.loggedInUserEventPreferences.find(
        (eventPreference) =>
          eventPreference.eventConfigurations.some(
            (config) => config.id === eventConfigurationId
          )
      );
      if (eventPreferenceToDelete?.id) {
        const isEventPreferenceDeleted =
          await eventPreferenceToDelete.deleteEventPreference();

        if (isEventPreferenceDeleted) {
          runInAction(() => {
            this.loggedInUserEventPreferences =
              this.loggedInUserEventPreferences.filter(
                (loggedInUserEventPreference) =>
                  loggedInUserEventPreference.id !== eventPreferenceToDelete.id
              );
          });
        }
      }

      // TODO: implement deleting the notification when we use them
      runInAction(() => {
        this.allEventConfigurations = this.allEventConfigurations.filter(
          (eventConfiguration) => eventConfiguration.id !== eventConfigurationId
        );
      });
    }
  }

  async getEventConfiguration(eventConfigurationId) {
    runInAction(() => {
      this.isLoading = true;
    });

    const eventConfiguration = await agent.Calendar.getEventConfiguration(
      eventConfigurationId
    );
    if (!eventConfiguration) return;

    // check if event configuration has an event preference
    const activeEventConfiguration = listActiveEventConfigurations(
      this.loggedInUserEventPreferences
    ).find(
      (activeEventConfiguration) =>
        activeEventConfiguration.id === eventConfigurationId
    );

    runInAction(() => {
      this.allEventConfigurations.map((event) => {
        if (event.id === eventConfiguration.id) {
          event = {
            ...eventConfiguration,
            relatedEventPreference: !!activeEventConfiguration
              ? activeEventConfiguration.relatedEventPreference
              : null,
          };
        }
        return new EventConfiguration(event);
      });

      this.configurationToEdit = new EventConfiguration({
        ...eventConfiguration,
        relatedEventPreference: !!activeEventConfiguration
          ? activeEventConfiguration.relatedEventPreference
          : null,
      });
      this.isLoading = false;
    });
  }

  setConfigDrawerSettings(tabToShow, creatingNewEventConfiguration) {
    runInAction(() => {
      this.configurationDrawerOpen = !!tabToShow;
      this.configurationDrawerSelectedTab = tabToShow;
      this.creatingNewEventConfiguration = creatingNewEventConfiguration;
    });
  }

  /**
   * This function creates a new event configuration object in the store
   *
   */
  async createNewEventConfigurationToEdit() {
    const {
      selectedChatbot: { defaultLanguageTag },
      employee,
    } = userStore;

    const newTranslation = new Translation({
      tags: [defaultLanguageTag],
      content: '',
    });

    runInAction(() => {
      this.configurationToEdit = new EventConfiguration({
        color: '#4b0082',
        duration: 30,
        location:
          Object.values(calendarTypes).find((calendarType) =>
            calendarType.includes(employee?.calendarUser?.calendar)
          ) || calendarTypes.GOOGLE_MEET,
        subject: newTranslation,
        description: newTranslation,
        customLocation: newTranslation,
      });
    });
  }

  async saveNewConfiguration() {
    // commenting out notifications because we are not using it
    let translationsCreated;
    // notificationsCreated
    try {
      // notificationsCreated =
      //   await this.configurationToEdit.createNewNotifications();
      translationsCreated =
        await this.configurationToEdit.createTranslationsForMultiLanguageFields();
    } catch (err) {
      console.error('Error creating notifications:', err);
    }
    // if all the translations and notifications were created successfully.. create the event config
    if (
      translationsCreated
      // && notificationsCreated
    ) {
      try {
        const { ok, eventconfiguration } =
          await agent.Calendar.createEventConfiguration(
            this.configurationToEdit.getProperties
          );
        if (ok) {
          runInAction(() => {
            this.configurationToEdit = new EventConfiguration(
              eventconfiguration
            );
            this.allEventConfigurations = _.orderBy(
              [...this.allEventConfigurations, this.configurationToEdit],
              [(config) => config.name.toLowerCase()],
              ['asc']
            );
          });
          return true;
        } else {
          throw new Error('Failed to save event configuration');
        }
      } catch (error) {
        console.error(`Failed to save event configuration: ${error}`);
        // when the creation of the event fails we need to delete all the translations that were created
        await this.configurationToEdit.deleteTranslationsForMultiLanguageFields();
        // TODO: remove notifications when we use them
      }
    }
    return false;
  }
}

decorate(CalendarSettings, {
  allEventConfigurations: observable,
  loggedInUserEventPreferences: observable,
  configurationToEdit: observable,
  isLoading: observable,
  listAllEventConfigurations: action,
  deleteEventConfiguration: action,
  getEventConfiguration: action,
  configurationDrawerOpen: observable,
  configurationDrawerSelectedTab: observable,
  scheduleAvailabilitySchemas: observable,
  creatingNewEventConfiguration: observable,
  setConfigDrawerSettings: action,
  createNewEventConfigurationToEdit: action,
  saveNewConfiguration: action,
  dayNameForNewAvailability: observable,
});

export default CalendarSettings;
