import requests from 'agent/requests';
import _ from 'lodash';
import { followUpFragment, followUpMutationFragment } from './fragments';

const listQuery = `
query GetFollowUps {
  allFollowUps {
    ...followUpFragment
  }
}
${followUpFragment}
`;

const getQuery = `
query GetFollowUp($id: ID!) {
  followUp(id: $id) {
    ...followUpFragment
  }
}
${followUpFragment}
`;

const createMutation = `
mutation CreateFollowUp($newFollowUp: FollowUpCreateGenericType!) {
  createFollowUp(newFollowup: $newFollowUp) {
    ...followUpMutationFragment
  }
}
${followUpMutationFragment}
`;

const updateMutation = `
mutation UpdateFollowUp($newFollowUp: FollowUpUpdateGenericType!) {
  updateFollowUp(newFollowup: $newFollowUp) {
    ...followUpMutationFragment
  }
}
${followUpMutationFragment}
`;

const deleteMutation = `
mutation DeleteFollowUp($id: ID!) {
  deleteFollowUp(id: $id) {
    ...followUpMutationFragment
  }
}
${followUpMutationFragment}
`;

const FollowUpAgent = {
  list: async () => {
    const res = await requests.gql.chatserver.query(listQuery);
    return _.get(res, 'data.allFollowUps');
  },
  get: async (id) => {
    const res = await requests.gql.chatserver.query(getQuery, { id });
    return _.get(res, 'data.followUp');
  },
  create: async (newFollowUp) => {
    const res = await requests.gql.chatserver.mutate(createMutation, {
      newFollowUp,
    });
    return _.get(res, 'data.createFollowUp');
  },
  update: async (newFollowUp) => {
    const res = await requests.gql.chatserver.mutate(updateMutation, {
      newFollowUp,
    });
    return _.get(res, 'data.updateFollowUp');
  },
  delete: async (id) => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, { id });
    return _.get(res, 'data.deleteFollowUp');
  },
};

export default FollowUpAgent;
