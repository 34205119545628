import _ from 'lodash'
import requests from './requests'

const createMutation = `
mutation CreateBlock($data: BlockCreateGenericType!) {
    createBlock(newBlock: $data) {
      ok
      block {
        id
        startTime
        endTime
        day
      }
    }
  }
`

const updateMutation = `
mutation UpdateBlock($data: BlockUpdateGenericType!) {
    updateBlock(newBlock: $data) {
      ok
      block {
        id
        startTime
        endTime
        day
      }
    }
  }
`

const deleteMutation = `
mutation DeleteMutation($id: ID!) {
    deleteBlock(id: $id) {
      ok
    }
  }
`

const Block = {
  create: async data => {
    const res = await requests.gql.chatserver.mutate(createMutation, { data })
    return _.get(res, 'data.createBlock.block')
  },
  update: async data => {
    const res = await requests.gql.chatserver.mutate(updateMutation, { data })
    return _.get(res, 'data.updateBlock.block')
  },
  delete: async id => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, { id })
    const ok = _.get(res, 'data.deleteBlock.ok')
    if (!ok) throw new Error('can not delete block')
  },
}

export default Block
