import requests from './requests';

const allWizards = `
query allWizards {
  allWizards {
    id
    title
    finishedTimestamp
    chatbot {
      id
    }
    processes {
      id
      order
      title
      description
      isFinished
      finishedTimestamp
      wizardSteps {
        id
        order
        title
        description
        isFinished
        finishedTimestamp
        infoUrl
        note
        settings {
          id
          chatbot {
            id
          }
          helpText
          key
          value
          settingType
          section
          dataValidator{
            id
            humanReadableName
            name
            icon
            color
            processorType
            priority
            category
            type
            scalarName {
              name
              createdAt
              updatedAt
            }
            isImportantGoal
            description
            analyticsDescription
            googleAnalyticsEvent {
              id
              systemName
              eventLabel
              eventAction
            }
            inlineProcessor
            processorDependencies {
              id
              customProcessorId
            }
          }
          adminOnly
        }
        media {
          type
          url
        }
      }
    }
  }
}
`;

const updateWizard = `
mutation updateWizard($newWizard: WizardUpdateGenericType!) {
  updateWizard(newWizard: $newWizard) {
    ok
  }
}
`;

const updateProcess = `
mutation updateProcess($newProcess: ProcessUpdateGenericType!) {
  updateProcess(newProcess: $newProcess) {
    ok
  }
}
`;

const updateWizardStep = `
mutation updateWizardStep($newWizardstep: WizardStepUpdateGenericType!) {
  updateWizardStep(newWizardstep: $newWizardstep) {
    ok
  }
}
`;

const copyWizardProcess = `
mutation cloneWizardProcess($destinationClient: String!, $destinationWizardId: ID!, $originClient: String!, $originProcessIdentifier: ID!) {
  cloneWizardProcess(destinationClient: $destinationClient, originClient: $originClient, originProcessIdentifier: $originProcessIdentifier, destinationWizardId: $destinationWizardId) {
    ok
    message
    process {
      id
      order
      title
      isFinished
      finishedTimestamp
      wizardSteps {
        id
        order
        title
        description
        isFinished
        finishedTimestamp
        infoUrl
        settings {
          id
          chatbot {
            id
          }
          helpText
          key
          value
          settingType
          section
          dataValidator {
            id
            humanReadableName
            name
            icon
            color
            processorType
            priority
            category
            type
            scalarName {
              name
              createdAt
              updatedAt
            }
            isImportantGoal
            description
            analyticsDescription
            googleAnalyticsEvent {
              id
              systemName
              eventLabel
              eventAction
            }
            inlineProcessor
            processorDependencies {
              id
              customProcessorId
            }
          }
          adminOnly
        }
        media {
          type
          url
        }
      }
    }
  }
}`;

const Wizard = {
  all: (token, graphQlServer) =>
    requests.gql.chatserver.query(allWizards, {}, graphQlServer, token),
  updateWizard: (data) => requests.gql.chatserver.mutate(updateWizard, data),
  updateProcess: (data) => requests.gql.chatserver.mutate(updateProcess, data),
  updateWizardStep: (data) =>
    requests.gql.chatserver.mutate(updateWizardStep, data),
  copyWizardProcess: (data) =>
    requests.gql.chatserver.mutate(copyWizardProcess, data),
};

export default Wizard;
