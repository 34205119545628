import requests from "./requests";
import _ from "lodash";

const allInboxCompanies = `
query AllInboxCompanies($tenant: String!, $ats: String!) {
  allInboxCompanies(tenant: $tenant, ats: $ats) {
    suggestion
    inboxCompanies {
      id
      identifier
      companyName
    }
  }
}
`;

const configureInboxCompany = `
mutation ConfigureSmartInboxCompany($chatbotId: ID!, $inboxCompanyIdentifier: String!, $ats:String!) {
  configureSmartInboxCompany(chatbotId: $chatbotId, inboxCompanyIdentifier: $inboxCompanyIdentifier, ats:$ats) {
    ok
    errorMessage
    inboxCompanies {
      id
      ats
      externalId
    }
  }
}
`;

const createSmartInbox = `
mutation CreateSmartInbox($username: String, $password: String) {
  createSmartInbox(username: $username, password: $password) {
    ok
    errorMessage
  }
}`;

const SmartInbox = {
  allInboxCompanies: async (tenant, ats) => {
    const res = await requests.gql.router.query(allInboxCompanies, { tenant, ats });
    return _.get(res, 'data.allInboxCompanies');
  },
  configure: async (chatbotId, inboxCompanyIdentifier, ats) => {
    const res = await requests.gql.router.mutate(configureInboxCompany, {
      chatbotId, inboxCompanyIdentifier, ats
    });
    return _.get(res, 'data.configureSmartInboxCompany');
  },
  autoConfigure: async (username='', password='') => {
    const res = await requests.gql.chatserver.mutate(createSmartInbox, { username, password });
    return _.get(res, 'data.createSmartInbox');
  }
};

export default SmartInbox;
