import { merge } from 'lodash'
import { flattenMessages } from '../utils/utils'
import enMessages from './en.json'
import nlMessages from './nl.json'

const flatEnMessages = flattenMessages(enMessages)
const nlOutputMessages = merge({}, flatEnMessages, nlMessages)

export default {
  'en-US': flatEnMessages,
  'nl-NL': nlOutputMessages,
}
