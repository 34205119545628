import { observable, action } from 'mobx'
import _ from 'lodash'

import agent from '../../agent'
import BaseStore from '../BaseStore'

class TextMessageStore extends BaseStore {
  @observable id = ''
  @observable content = ''

  constructor(args) {
    super()
    this.setup(args)
  }

  @action
  setup(args) {
    this.id = args.id
    this.content = args.content
  }

  @action
  async update(data) {
    const res = await agent.TextMessage.update(data)
    this.setup(res.data.updateTextMessageMutation.textmessage)
    const ok = _.get(res, 'data.updateTextMessageMutation.ok')
    if (!ok) throw Error('Text message not updated')
  }
}

export default TextMessageStore
