import BaseStore from 'stores/BaseStore'
import { decorate, observable, runInAction, action, toJS, computed } from 'mobx'
import Documentation from 'agent/dashboard/documentation'

class ReleaseNotesStore extends BaseStore {
  notes = {
    release: '',
    notes: [],
  }

  async fetch() {
    const result = await Documentation.getRealeaseNotes()
    runInAction(() => {
      this.notes = result
    })
  }

  get getNotes() {
    return toJS(this.notes)
  }
}

decorate(ReleaseNotesStore, {
  notes: observable,
  fetch: action,
  getNotes: computed,
})
export default ReleaseNotesStore
