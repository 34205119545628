import { observable, action, toJS, computed } from 'mobx'
import _ from 'lodash'

import agent from '../../agent'
import { commonStore } from '../index'
import BaseStore from '../BaseStore'
import MessageStore from './MessageStore'

class OptionLabelStore extends BaseStore {
  @observable id = ''

  // @observable label = '';
  @observable optionID = ''

  @observable enabled = null

  @observable messageClass = null

  @observable position = null

  @observable isHovering = false

  constructor(args) {
    super()
    this.setup(args)
  }

  @action
  setup(args) {
    this.id = args.id
    // this.label = args.label;
    this.optionID = _.get(args, 'option.id')
    this.position = args.position
    this.enabled = args.enabled
    this.messageClass = new MessageStore(args.messageClass)
  }

  @action
  async save() {
    const params = {
      data: {
        id: this.id,
        enabled: this.enabled,
        option: this.optionID,
      },
    }
    const res = await agent.OptionLabel.update(params)
    const ok = _.get(res, 'data.optionLabelUpdate.ok')
    if (!ok) throw Error('Option label not updated')
    this.setup(_.get(res, 'data.optionLabelUpdate.optionlabel'))
  }

  @computed
  get label() {
    // const { commonStore, variations } = this.props;
    const { variations } = this.messageClass
    let selectedTags = toJS(commonStore.selectedStateTags)
    selectedTags = _.orderBy(selectedTags, ['content'], ['asc'])

    const result = variations
      .map(variation => {
        let tags = toJS(variation.tags)
        tags = _.orderBy(tags, ['content'], ['asc'])
        if (_.isEqual(selectedTags, tags)) {
          return variation
        }
        return null
      })
      .filter(variation => variation !== null)
    const selectedVariation = _.first(result)
    if (selectedVariation) {
      if (selectedVariation.message.textMessage)
        return selectedVariation.message.textMessage.content
      if (selectedVariation.message.linkMessage)
        return selectedVariation.message.linkMessage.placeholder
      return null
    }
    return null
  }
}

export default OptionLabelStore
