import { decorate, observable, action } from 'mobx'
import agent from 'agent'
import BaseStore from './BaseStore'

class ListEntitySynonym extends BaseStore {
  id
  name

  constructor(args) {
    super(args)
    this.setup(args)
  }

  setup({ id, name }) {
    this.id = id
    this.name = name
  }
}

decorate(ListEntitySynonym, {
  id: observable,
  name: observable,
  save: action,
  setup: action,
})

export default ListEntitySynonym
