import requests from '../requests'

const mutation = `
mutation UpdateImageMessage($data: ImageMessageUpdateGenericType!) {
    updateImageMessageMutation(newImagemessage: $data) {
        imagemessage {
            id
            link
        }
      ok
    }
}  
`

const ImageMessage = {
  update: data => requests.gql.chatserver.mutate(mutation, data),
}

export default ImageMessage
