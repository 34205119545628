import Loader from 'components/Loader';
import NoInternetAlert from 'components/NoInternetAlert';
import Page404 from 'components/Page404';
import PageLoader from 'components/PageLoader';
import { observer } from 'mobx-react-lite';
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useInjectStore } from 'utils/hooks';
import { Container } from './styles';

const Index = lazy(() => import('components/SubMain'));
const Login = lazy(() => import('pages/Login'));
const OAuthTakeawayLogin = lazy(() =>
  import('pages/Login/Okta/OAuth/OAuthLogin')
);

// Login Callback for Okta OAuth
const LoginCallback = lazy(() =>
  import('pages/Login/Okta/OAuth/OAuthCallback')
);
const SAMLCallback = lazy(() => import('pages/Login/Okta/SAML/SAMLCallback'));
const SAMLLogin = lazy(() => import('pages/Login/Okta/SAML/SAMLLogin'));
const TkPortalLogin = lazy(() => import('pages/Login/Auth/TkPortalLogin'));

// Login Callback for Joboti Auth where we redirect with a Joboti Refresh Token
const JobotiAuthCallback = lazy(() =>
  import('pages/Login/Auth/JobotiAuthCallback')
);

// Redirects the user to the Randstad Login page
const RandstadLogin = lazy(() => import('pages/Login/Auth/RandstadLogin'));

function Main() {
  // Uncomment this if you want okta OAuth enabled.
  // const config = {
  //   issuer: 'iss',
  //   redirectUri: `${window.location.origin}/takeaway/callback`,
  //   clientId: 'clientId',
  //   pkce: true
  // };

  const userStore = useInjectStore('userStore');

  if (!userStore.isLoggedIn || userStore.requiresPasswordReset) {
    return (
      <Router>
        <NoInternetAlert />
        <Suspense fallback={<PageLoader />}>
          <Switch>
            {/* <Security {...config}> Uncomment these lines to enable Okta OAuth SSO */}
            {/* <Route exact path="/takeaway/login" component={OAuthTakeawayLogin} /> */}
            {/* <Route path='/takeaway/callback' component={LoginCallback} /> */}
            <Route
              path="/okta/SAML/callback/:refreshToken"
              component={SAMLCallback}
            />
            <Route path="/okta/SAML/login/:client" component={SAMLLogin} />
            <Route path="/auth/callback" component={JobotiAuthCallback} />
            <Route path="/randstad/login" component={RandstadLogin} />
            <Route path="/auth/tk-portal" component={TkPortalLogin} />
            <Route component={Login} />
            {/* </Security> */}
          </Switch>
        </Suspense>
      </Router>
    );
  }

  if (!userStore.isLoading) {
    return (
      <Router>
        <NoInternetAlert />
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/chatbots" />} />
            <Route path="/chatbots" component={Index} />
            <Route path="/login" component={Login} />
            <Route path="/auth/callback" component={JobotiAuthCallback} />
            <Route component={Page404} />
          </Switch>
        </Suspense>
      </Router>
    );
  }

  return (
    <React.Fragment>
      <NoInternetAlert />
      <Container>
        <Loader />
      </Container>
    </React.Fragment>
  );
}

export default observer(Main);
