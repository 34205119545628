import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { IntlProvider } from 'react-intl';
import { LanguageContext } from 'stores';
import locales from './locales';

function ConnectedIntlProvider({ children }) {
  const { selectedLanguage } = useContext(LanguageContext);

  if (selectedLanguage.includes(undefined)) return;

  return (
    <IntlProvider
      locale={selectedLanguage}
      messages={locales[selectedLanguage]}
    >
      {children}
    </IntlProvider>
  );
}

export default observer(ConnectedIntlProvider);
