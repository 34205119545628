import agent from 'agent';
import AllAtsFieldMappings from 'agent/ats-mapping.agent';
import { action, decorate, observable, runInAction } from 'mobx';
import AtsFieldMapping from 'stores/AtsFieldMapping.store';
import BaseStore from 'stores/BaseStore';
import WhatsAppParameter from './WhatsAppParameter.store';

class WhatsAppParameters extends BaseStore {
  parameters = [];
  isLoading;
  selectedParameter;
  isEditMode = false;
  clonedAtsFieldMapping = [];

  setSelectedParameter(selectedParameter) {
    this.selectedParameter = selectedParameter;
  }

  setEditMode() {
    runInAction(() => {
      this.isEditMode = !this.isEditMode;
    });
  }

  async fetch() {
    this.isLoading = true;
    const parameters = await agent.WhatsAppParameter.list();
    runInAction(() => {
      if (parameters && parameters.length > 0) {
        this.parameters = parameters.map(
          (parameter) => new WhatsAppParameter(parameter)
        );
      }
      this.isLoading = false;
    });
  }

  async create(newParameter) {
    this.isLoading = true;
    const result = await agent.WhatsAppParameter.create(newParameter);
    runInAction(() => {
      this.parameters;
      this.isLoading = false;
    });

    return result;
  }

  async update(newParameter) {
    this.isLoading = true;
    const data = {
      id: this.selectedParameter?.id,
      name: newParameter?.name,
      index: this.selectedParameter?.index,
      atsFieldMapping: newParameter?.atsFieldMapping?.id || null,
    };
    const result = await agent.WhatsAppParameter.update(data);
    runInAction(() => {
      this.isLoading = false;
    });
    return result;
  }

  async delete(id) {
    this.isLoading = true;
    await agent.WhatsAppParameter.delete(id);
    runInAction(() => {
      this.parameters = this.parameters.filter(
        (parameter) => parameter.id !== id
      );
      this.isLoading = false;
    });
  }

  async cloneAtsFieldMapping(newAtsFieldMapping) {
    const res = await AllAtsFieldMappings.cloneAtsFieldMapping(
      newAtsFieldMapping
    );
    const clonedAtsFieldMapping =
      res.data.cloneAtsFieldMapping?.atsFieldMapping;

    runInAction(() => {
      this.clonedAtsFieldMapping = new AtsFieldMapping(clonedAtsFieldMapping);
    });
  }
}

decorate(WhatsAppParameters, {
  parameters: observable,
  isLoading: observable,
  clonedAtsFieldMapping: observable,
  fetch: action,
  create: action,
  update: action,
  delete: action,
  selectedParameter: observable,
  setSelectedParameter: action,
  isEditMode: observable,
  setEditMode: action,
  cloneAtsFieldMapping: action,
});

export default WhatsAppParameters;
