import {
  atsFieldOrderingFragment,
  mappingOptionFragment,
  statesFragment,
} from './atsMappingFragments';

export const messageClassFragment = `
fragment messageClass on MessageNode {
  id
  variations {
    id
    tags {
      id
      color
      content
      kind
    }
    message {
      id
      textMessage {
        id
        content
      }
      videoMessage {
        id
        link
      }
      imageMessage {
        id
        link
      }
      documentMessage {
        id
        link
      }
      linkMessage {
        id
        placeholder
        link
        isDocument
      }
      geoLocationMessage {
        id
        lat
        lng
      }
    }
  }
}`;

export const actionFragment = `
fragment action on ActionNode {
  id
  editable
  actionWithOptions {
    id
    content: message
    messageClass {
      ...messageClass
    }
    optionlabelSet {
      id
      label
      enabled
      position: number
      messageClass {
        ...messageClass
      }
      option {
        id
        symbol
        type: symbolType
      }
    }
  }
  standardAction {
    id
    content: message
    messageClass {
      ...messageClass
    }
  }
}
${messageClassFragment}`;

export const optionProcessorFragment = `
fragment optionProcessor on ProcessorNode {
  id
  icon
  priority
  category
  description
  humanReadableName
  name
  type
  processorType
  isImportantGoal
  googleAnalyticsEvent {
    id
    eventLabel
    eventAction
    systemName
  }
  processorDependencies {
    customProcessor {
      modelName
      id
    }
  }
  finalGoalCampaigns {
    id
    name
  }
  atsFieldMappings {
    id
    type
    dataType
    atsFieldOrdering: atsfieldorderingSet {
      ...atsFieldOrderingFragment
    }
    scalarName {
      id
      name
    }
  }
}
${atsFieldOrderingFragment}`;

export const processorFragment = `
fragment processorFragment on ProcessorNode {  
  id
  icon
  priority
  category
  description
  humanReadableName
  name
  type
  useMapperV2
  processorType
   optionSet {
    id
    name
    symbol
    states {
      id
      name
    }
  }
  onFailProcessor {
    id
    name
  }
  onFinishProcessor {
    id
    name
  }
  onFirstReplyProcessor {
    id
    name
  }
  isImportantGoal
  atsFieldMappings {
    id
    type
    dataType
    value
    isDefaultMapping
    postModifiers
    isLocked
    states {
      ...statesFragment
    }
    atsOptions{
      value
      humanReadableName
      id
     }
    mappingOptions {
      ...mappingOption
     }
    atsFieldOrdering: atsfieldorderingSet {
      ...atsFieldOrderingFragment
    }
    scalarName {
      id
      name
     }
  }
  googleAnalyticsEvent {
    id
    eventLabel
    eventAction
    systemName
  }
  processorDependencies {
    customProcessor {
      modelName
      id
    }
  }
  finalGoalCampaigns {
    id
    name
  }
  stateMachines: statemachineSet  {
    name
  }
  states: stateSet {
    name
    stateMachines: statemachineSet {
      name
    }
  }
  options: optionSet {
    id
    name
    symbol
    states {
      id
      name
      statemachineSet {
        id
        name
      }
    }
  }
}
${atsFieldOrderingFragment}
${mappingOptionFragment}
${statesFragment}
`;

export const optionFragment = `
fragment option on OptionNode {
  id
  name
  symbol
  type: symbolType
  customData
  cancelFollowUp
  allowNlp
  isRequired
  fixedAtsFieldMappings {
      id
      dataType
      type
      id
      dataType
      type
      isDefaultMapping
      append
      prepend
      prependAppendSeparator
      value
      postModifiers
      atsFieldOrdering: atsfieldorderingSet {
        ...atsFieldOrderingFragment
        }
          mappingOptions {
            id
            value
            atsOptions {
              humanReadableName
              value
              id
            }
            option {
              symbol
              id
            }
          }
      scalarName{
        id
        name
       }
      }
  descriptionTitle {
    content
    tags {
      id
      kind
      content
    }
  }
  description {
    content
    tags {
      id
      kind
      content
    }
  }
  routingInstruction
  actions: actionorderingSet {
    position: number
    action {
      ...action
    }
  }
  processors {
    ...optionProcessor
  }
  nextState {
    id
    name
    flow: statemachineSet {
      id
      name
    }
  }
  nextStateMachine {
    id
    name
    flow: statemachineSet {
      id
      name
    }
  }
  smartInboxLabels {
    id
    color
    name
  }
  removeSmartInboxLabels {
    id
    color
    name
  }
}
${actionFragment}
${optionProcessorFragment}
`;
