import _ from 'lodash';
import requests from 'agent/requests';
import { buttonFragment, buttonMutationFragment } from './fragments';

const listQuery = `
query GetAllWhatsAppButtons {
  allWhatsAppButtons {
    ...buttonFragment
  }
}
${buttonFragment}
`;

const createMutation = `
mutation CreateWhatsAppButton($newWhatsAppButton: WhatsAppButtonCreateGenericType!) {
  createWhatsAppButton(newWhatsappbutton: $newWhatsAppButton) {
    ...buttonMutationFragment
  }
}
${buttonMutationFragment}
`;

const updateMutation = `
mutation UpdateWhatsAppButton($newWhatsAppButton: WhatsAppButtonUpdateGenericType!) {
  updateWhatsAppButton(newWhatsappbutton: $newWhatsAppButton) {
    ...buttonMutationFragment
  }
}
${buttonMutationFragment}
`;

const deleteMutation = `
mutation DeleteWhatsAppButton($id: ID!) {
  deleteWhatsAppButton(id: $id) {
    ...buttonMutationFragment
  }
}
${buttonMutationFragment}
`;

const deleteButtonsMutation = (ids) => {
  const interpolatedButtonFields = ids.map((id) => {
    return `
      buttonDelete${id}: deleteWhatsAppButton(id: ${id}) {
        ...buttonMutationFragment
      }
    `;
  });

  return `
    mutation DeleteWhatsAppButtonsByIds {
      ${interpolatedButtonFields}
    }
    ${buttonMutationFragment}
  `;
};

const WhatsAppButtonAgent = {
  list: async () => {
    const res = await requests.gql.chatserver.query(listQuery);
    return _.get(res, 'data.allWhatsappButtons');
  },
  create: async (newWhatsAppButton) => {
    const res = await requests.gql.chatserver.mutate(createMutation, {
      newWhatsAppButton,
    });
    return _.get(res, 'data.createWhatsAppButton');
  },
  update: async (newWhatsAppButton) => {
    const res = await requests.gql.chatserver.mutate(updateMutation, {
      newWhatsAppButton,
    });
    return _.get(res, 'data.updateWhatsAppButton');
  },
  delete: async (id) => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, {
      id,
    });
    return _.get(res, 'data.deleteWhatsAppButton');
  },
  deleteButtons: async (ids) => {
    const res = await requests.gql.chatserver.mutate(
      deleteButtonsMutation(ids)
    );
    return _.get(res, 'data.deleteWhatsAppButton');
  },
};

export default WhatsAppButtonAgent;
