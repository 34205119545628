import requests from './requests'

const allValidatorTests = `
  query AllValidatorTests {
    allValidatorSystemTests
  }
`

const runValidatorTests = `
  mutation RunValidatorTests($validatorTestFormat: JSONString!) {
    runValidatorTests(validatorTestFormat: $validatorTestFormat) {
      ok
    }
  }
`

const Validator = {
  all: async () => {
    return await requests.gql.chatserver.query(allValidatorTests)
  },
  run: async data => {
    return await requests.gql.chatserver.mutate(runValidatorTests, data)
  },
}

export default Validator
