import { observable, action } from 'mobx'
import _ from 'lodash'

import agent from '../../agent'
import BaseStore from '../BaseStore'

class ImageMessageStore extends BaseStore {
  @observable id = ''
  @observable link = ''

  constructor(args) {
    super()
    this.setup(args)
  }

  @action
  setup(args) {
    this.id = args.id
    this.link = args.link
  }

  @action
  async update(data) {
    const res = await agent.ImageMessage.update(data)
    this.setup(_.get(res, 'data.updateImageMessageMutation.imagemessage'))
    const ok = _.get(res, 'data.updateImageMessageMutation.ok')
    if (!ok) throw Error('Image message not updated')
  }
}

export default ImageMessageStore
