import { decorate, observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import agent from 'agent'
import BaseStore from 'stores/BaseStore'
import CalendarAvailability from 'stores/CalendarAvailability/CalendarAvailability.store'

class CalendarAvailabilities extends BaseStore {
  availabilities = []
  allAppointmentTypes = []
  allCategories = []
  allInterviewTypes = []
  isLoading

  async fetch(userId) {
    this.isLoading = true
    const data = await agent.CalendarAvailability.data(userId)
    runInAction(() => {
      this.availabilities = data.calendarAvailabilities.map(
        av => new CalendarAvailability(av),
      )
      this.allAppointmentTypes = data.allAppointmentTypes
      this.allCategories = data.allCategories
      this.allInterviewTypes = data.allInterviewTypes
      this.isLoading = false
    })
  }

  async deleteAvailabilities(id) {
    _.remove(this.availabilities, { id })
    await CalendarAvailability.delete(id)
  }

  async addAvailability({
    userId,
    contactName,
    calendarEmail,
    interviewType,
    categories,
    maxDailyAppointments,
    appointmentType,
  }) {
    const data = {
      userId,
      contactName,
      calendarEmail,
      interviewType,
      categories,
      maxDailyAppointments,
      appointmentType,
    }
    const calendarAvailability = await CalendarAvailability.create(data)
    runInAction(() => {
      this.availabilities.unshift(calendarAvailability)
    })
  }
}

decorate(CalendarAvailabilities, {
  availabilities: observable,
  allCategories: observable,
  allInterviewTypes: observable,
  isLoading: observable,
  fetch: action,
  deleteAvailabilities: action,
  addAvailability: action,
})

export default CalendarAvailabilities
