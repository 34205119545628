import { decorate, observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import agent from 'agent'
import BaseStore from 'stores/BaseStore'
import GroupAppointment from 'stores/CalendarAvailability/GroupAppointment.store'

class GroupAppointmentsStore extends BaseStore {
  appointments = []
  isLoading

  async fetch() {
    this.isLoading = true
    const data = await agent.GroupAppointment.data()
    runInAction(() => {
      this.appointments = data.groupAppointments.map(
        ga => new GroupAppointment(ga),
      )
      this.isLoading = false
    })
  }

  async deleteGroupAppointment(id) {
    _.remove(this.appointments, { id })
    await GroupAppointment.delete(id)
  }

  async addGroupAppointment({
    id,
    type,
    maxNrAttendees,
    planRange,
    offsetDays,
    maxNrEventToShow,
    calendarAuthHandler,
    calendarAuthToken,
  }) {
    const data = {
      id,
      type,
      maxNrAttendees,
      planRange,
      offsetDays,
      maxNrEventToShow,
      calendarAuthHandler,
      calendarAuthToken,
    }
    const groupAppointment = await GroupAppointment.create(data)
    runInAction(() => {
      this.appointments.unshift(groupAppointment)
    })
  }
}

decorate(GroupAppointmentsStore, {
  appointments: observable,
  isLoading: observable,
  fetch: action,
  deleteGroupAppointment: action,
  addGroupAppointment: action,
})

export default GroupAppointmentsStore
