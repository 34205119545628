import styled from 'styled-components'

export const PreviewWidgetStyles = `.previewHTML-content {
  background-color: transparent;
  color: black;
  width: 250px;
}

.previewHTML-content .message-wrapper {
  padding: 10px 20px;
  border-radius: 15px 15px 0px 15px;
  background-color: white;
  color: black;
  margin-bottom: 5px;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-box-shadow: 2px 2px 2px 2px rgba(235,235,235,0.25);
  -moz-box-shadow: 2px 2px 2px 2px rgba(235,235,235,0.25);
  box-shadow: 2px 2px 2px 2px rgba(235,235,235,0.25);
}

.previewHTML-content .buttons-wrapper {
  text-align: right;
}

.previewHTML-content .buttons-wrapper button {
  cursor: pointer;
  color: #17487F;
  outline: none;
  border: 1px solid #17487F;
  border-radius: 6px;
  background-color: white;
  padding: 8px;
  margin: 5px 0px 5px 5px;
  font-family: Arial, Helvetica, sans-serif;
}

#exit-joboti-preview-widget {
  fill: rgb(197, 216, 210) !important;
}
`

export const StyledMockPreviewWidget = styled.div`
  margin-right: 50px;
  ${PreviewWidgetStyles}
`

export const StyledBotAvatar = styled.div`
  text-align: right;
  img {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.26) 0px 2px 5px 0px;
    ${({ isRectangle }) => (isRectangle ? '' : 'border-radius: 50%;')}
    position: relative;
    height: 60px;
    width: auto;
  }
`
