import { decorate, observable } from 'mobx';
import TagStore from './ActionStore/TagStore';
import BaseStore from './BaseStore';

// this store uses generic types
// if you want to check the delete and update methods, check the UtilsStore
class Translation extends BaseStore {
  id = '';
  tags = [];
  content = '';

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    this.id = args?.id;
    this.tags = args?.tags?.map((tag) => new TagStore(tag));
    this.content = args?.content;
  }
}

decorate(Translation, {
  id: observable,
  tags: observable,
  content: observable,
});

export default Translation;
