import React from 'react'
import Exception from 'ant-design-pro/lib/Exception'

export default function Page500() {
  return (
    <Exception
      type="500"
      desc="Opps something went wrong in our system"
      redirect="/"
    />
  )
}
