import agent from 'agent';
import { action, decorate, observable, runInAction } from 'mobx';
import BaseStore from 'stores/BaseStore';
import { TEMPLATE_SCHEME } from '../../constants';
import Processor from '../ProcessorStore';
import QueryStore from './BroadCastStore/Query.store';

const DEFAULT_AUTOMATCH_TEMPLATE_ID = '4122fcc4-850d-4fef-bfa0-1b3afbe62212';

class AutoMatch extends BaseStore {
  id = '';
  connectorUuid = '';
  matchPageLimit = 0;
  onReceiveProcessors = [];
  useEngageConnector = false;
  version = null;
  searchQuery = null;
  matchQuery = null;
  facetsQuery = null;
  modified = null;
  publishStage = '';
  publishJobId = '';

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.id = params?.id;
    this.connectorUuid = params?.connectorUuid;
    this.matchPageLimit = params?.matchPageLimit;
    this.onReceiveProcessors = params?.onReceiveProcessors.map(
      (processor) => new Processor(processor)
    );
    this.useEngageConnector = params?.useEngageConnector;
    this.version = params?.version;
    if (params?.searchQuery) {
      this.searchQuery = new QueryStore(params.searchQuery);
    }
    if (params?.matchQuery) {
      this.matchQuery = new QueryStore(params.matchQuery);
    }
    if (params?.facetsQuery) {
      this.facetsQuery = new QueryStore(params.facetsQuery);
    }
    this.modified = params?.modified;
    this.publishStage = params?.publishStage;
    this.publishJobId = params?.publishJobId;
  }

  async updateModifiedDate() {
    const instanceToUpdate = this.serializeInstance(this);
    delete instanceToUpdate.modified;
    try {
      const { ok, automatch } = await agent.Automatch.updateAutomatch(
        instanceToUpdate
      );
      if (ok) {
        runInAction(() => {
          this.modified = automatch.modified;
        });
      }
      return ok;
    } catch (err) {
      console.error('err', err);
    }
    return false;
  }

  async addOnReceiveProcessors(schemaName) {
    const { data } = await agent.Processor.copyProcessor({
      processorTemplateIds: [DEFAULT_AUTOMATCH_TEMPLATE_ID],
      fromSchema: TEMPLATE_SCHEME,
      toSchema: schemaName,
    });
    if (data.copyProcessor.ok) {
      runInAction(() => {
        const newProcessor = new Processor(data.copyProcessor.copiedProcessor);
        this.onReceiveProcessors.push(newProcessor);
      });
    }
  }
}
decorate(AutoMatch, {
  id: observable,
  connectorUuid: observable,
  created: observable,
  matchPageLimit: observable,
  modified: observable,
  onReceiveProcessors: observable,
  useEngageConnector: observable,
  version: observable,
  searchQuery: observable,
  matchQuery: observable,
  facetsQuery: observable,
  publishStage: observable,
  publishJobId: observable,
  addOnReceiveProcessors: action,
});

export default AutoMatch;
