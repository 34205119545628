import requests from 'agent/requests';
import _ from 'lodash';
import { humanReadableNameOnTypeTranslation } from './fragments';

const updateMutation = /* GraphQL */ `
  mutation UpdateBroadcast($newBroadcast: BroadcastUpdateGenericType!) {
    updateBroadcast(newBroadcast: $newBroadcast) {
      broadcast {
        id
        name
        type
        allowedDays
        interval
        minExecutionTime
        maxExecutionTime
        approachLimit
      }
    }
  }
`;

const executeDestinationGetterQuery = /* GraphQL */ `
  query ExecuteDestinationGetter($broadcastId: ID!) {
    executeDestinationGetter(broadcastId: $broadcastId) {
      ok
      errorMessage
      targets
    }
  }
`;

const allQueryConditions = /* GraphQL */ `
  query allConditions($supportedHandler: String) {
    allConditions(supportedHandler: $supportedHandler) {
      id
      name
      value
      allowedDataTypes
      humanReadableNames {
        ...HumanReadableNamesFragment
      }
    }
  }
  ${humanReadableNameOnTypeTranslation}
`;

const BroadcastAgent = {
  getAllQueryConditions: async (supportedHandler) => {
    const { data } = await requests.gql.chatserver.query(allQueryConditions, {
      supportedHandler,
    });
    return data.allConditions;
  },
  update: async (newBroadcast) => {
    const res = await requests.gql.chatserver.mutate(updateMutation, {
      newBroadcast,
    });
    return _.get(res, 'data.updateBroadcast.broadcast');
  },
  executeDestinationGetter: async (broadcastId) => {
    try {
      const res = await requests.gql.chatserver.query(
        executeDestinationGetterQuery,
        { broadcastId }
      );
      return res.data.executeDestinationGetter;
    } catch (e) {
      return { errorMessage: 'Something went wrong' };
    }
  },
};

export default BroadcastAgent;
