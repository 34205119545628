import BaseStore from 'stores/BaseStore'
import { decorate, observable } from 'mobx'
import agent from '../agent'

class MapperStore extends BaseStore {
  id = ''
  name = ''
  data = ''

  constructor(args) {
    super()
    this.id = args.id
    this.name = args.name
    this.data = args.data
  }

  async save() {
    const data = {
      newMapper: {
        id: this.id,
        name: this.name,
        data: this.data,
      },
    }
    await agent.Mapper.update(data)
  }
}

decorate(MapperStore, {
  id: observable,
  name: observable,
  data: observable,
})

export default MapperStore
