// Sections
export const BUILDER_ENTITY = 'BUILDER';
export const DASHBOARD_ENTITY = 'DASHBOARD';
export const ANALYTICS_ENTITY = 'ANALYTICS';
export const CHATBOT_SETTINGS = 'CHATBOT_SETTINGS';
export const NLP_ENTITY = 'NLP';
export const USERS_MANAGEMENT_ENTITY = 'USERS_MANAGEMENT';
export const CHATBOTS = 'CHATBOTS';
export const WHATSAPP = 'WHATSAPP';
export const DEPLOYMENTS = 'DEPLOYMENTS';
export const CAMPAIGNS = 'CAMPAIGNS';
export const VALIDATOR = 'VALIDATOR';
export const WHATSAPP_TEMPLATE = 'WHATSAPP_TEMPLATE';
export const SMART_INBOX = 'SMART_INBOX';
export const CALENDAR = 'CALENDAR';
export const REMINDER = 'REMINDER';

// Sub Sections
export const CHATBOT_SETTINGS_WIDGET_ENTITY = 'CHATBOT_SETTINGS_WIDGET';
export const CHATBOT_SETTINGS_WIDGET_PREVIEW_ENTITY =
  'CHATBOT_SETTINGS_WIDGET_PREVIEW';
export const CHATBOT_SETTINGS_AVAILABILITIES_ENTITY =
  'CHATBOT_SETTINGS_AVAILABILITIES';
export const CHATBOT_SETTINGS_CHATBOT_ENTITY = 'CHATBOT_SETTINGS_CHATBOT';
export const CHATBOT_SETTINGS_GROUP_APPOINTMENT_ENTITY =
  'CHATBOT_SETTINGS_GROUP_APPOINTMENT';
export const CHATBOT_SETTINGS_FACEBOOK_LEADS_ENTITY =
  'CHATBOT_SETTINGS_FACEBOOK_LEADS';
export const CHATBOT_SETTINGS_ATS = 'CHATBOT_SETTINGS_ATS';

export const BUILDER_FLOW_ENTITY = 'BUILDER_FLOW';
export const BUILDER_TREE_FLOW_ENTITY = 'BUILDER_TREE_FLOW';
export const BUILDER_QA_ENTITY = 'BUILDER_QA';
export const BUILDER_EDIT_OPTION = 'Builder';
export const BUILDER_ADD_OPTION = 'BUILD_ADD_OPTION';
export const BUILDER_ACTION_WITH_OPTIONS = 'BUILDER_ACTION_WITH_OPTIONS';

export const NLP_INTENTS_ENTITY = 'NLP_INTENTS';
export const NLP_ENTITIES_ENTITY = 'NLP_ENTITIES';

export const ANALYTICS_SUMMARY_ENTITY = 'ANALYTICS_SUMMARY';
export const ANALYTICS_CONVERSATIONS_ENTITY = 'ANALYTICS_CONVERSATIONS';
export const ANALYTICS_LANGUAGE_ENTITY = 'ANALYTICS_LANGUAGE';
export const ANALYTICS_FUNNEL_ENTITY = 'ANALYTICS_FUNNEL';
export const ANALYTICS_EXPORT_RESULTS_ENTITY = 'ANALYTICS_EXPORT_RESULTS';

export const VALIDATOR_TEST_ENTITY = 'VALIDATOR_TEST';
export const VALIDATOR_RESULTS_ENTITY = 'VALIDATOR_RESULTS';

export const Tabs = {
  UPLOAD_CSV: 'UPLOAD_CSV',
  FILL_IN_FORM: 'FILL_IN_FORM',
  USE_MAPPER: 'USE_MAPPER',
};

export const portalSections = {
  BUILDER_ENTITY,
  DASHBOARD_ENTITY,
  ANALYTICS_ENTITY,
  CHATBOT_SETTINGS,
  NLP_ENTITY,
  USERS_MANAGEMENT_ENTITY,
  CHATBOTS,
  WHATSAPP,
  DEPLOYMENTS,
  CAMPAIGNS,
  VALIDATOR,
  WHATSAPP_TEMPLATE,
  SMART_INBOX,
  CALENDAR,
  REMINDER,
};
