import agent from 'agent';
import { action, decorate, observable } from 'mobx';
import BaseStore from 'stores/BaseStore';
import Flow from 'stores/Flows/Flow.store';
import InboxLabel from 'stores/InboxLabelStore';
import Campaign from './Campaign.store';
import Processor from './Processor.store';
import WhatsAppDefinition from './WhatsAppDefinition/WhatsAppDefinition.store';
import WhatsAppTemplate from './WhatsAppTemplate/WhatsAppTemplate.store';

class FollowUp extends BaseStore {
  id;
  name;
  description;
  interval;
  minInterval;
  allowedDays;
  minExecutionTime;
  maxExecutionTime;
  timeZone;
  onFinishProcessor;
  onFailProcessor;
  flow;
  whatsAppDefinition;
  whatsAppTemplate;
  qualifier;
  campaign;
  smartInboxLabels = [];
  labelToUpdate = null;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.id = params?.id;
    this.name = params?.name;
    this.descripiton = params?.descripiton;
    this.interval = params?.interval;
    this.minInterval = params?.minInterval;
    this.allowedDays = params?.allowedDays;
    this.minExecutionTime = params?.minExecutionTime;
    this.maxExecutionTime = params?.maxExecutionTime;
    this.timeZone = params?.timeZone;
    this.onFinishProcessor = new Processor(params?.onFinishProcessor);
    this.onFailProcessor = new Processor(params?.onFailProcessor);
    this.flow = new Flow(params?.flow);
    this.whatsAppDefinition = new WhatsAppDefinition(
      params?.whatsappDefinition
    );
    this.whatsAppTemplate = new WhatsAppTemplate(params?.whatsappTemplate);
    this.qualifier = new Processor(params?.qualifier);
    this.campaign = new Campaign(params?.campaign);
    this.smartInboxLabels = params?.smartInboxLabels
      ? params.smartInboxLabels.map((label) => new InboxLabel(label))
      : [];
    this.removeSmartInboxLabels = params?.removeSmartInboxLabels
      ? params.removeSmartInboxLabels.map((label) => new InboxLabel(label))
      : [];
  }

  async save() {
    const data = {
      id: this.id,
      name: this.name,
      description: this.description,
      interval: this.interval,
      minInterval: this.minInterval,
      allowedDays: this.allowedDays,
      minExecutionTime: this.minExecutionTime,
      maxExecutionTime: this.maxExecutionTime,
      timeZone: this.timeZone,
      onFinishProcessor: this.onFinishProcessor.id,
      onFailProcessor: this.onFailProcessor.id,
      flow: this.flow.id,
      whatsappDefinition: this.whatsAppDefinition.id || '',
      whatsappTemplate: this.whatsAppTemplate.id || '',
      qualifier: this.qualifier.id,
      campaign: this.campaign.id,
      smartInboxLabels: this.smartInboxLabels.map((label) => label.id),
      removeSmartInboxLabels: this.removeSmartInboxLabels.map(
        (label) => label.id
      ),
    };

    await agent.FollowUp.update(data);
  }

  async clear() {
    this.id = null;
    this.name = null;
    this.description = null;
    this.interval = null;
    this.minInterval = null;
    this.allowedDays = null;
    this.minExecutionTime = null;
    this.maxExecutionTime = null;
    this.timeZone = null;
    this.onFinishProcessor = null;
    this.onFailProcessor = null;
    this.flow = null;
    this.whatsAppDefinition = null;
    this.whatsAppTemplate = null;
    this.qualifier = null;
    this.campaign = null;
  }
}

decorate(FollowUp, {
  id: observable,
  name: observable,
  description: observable,
  interval: observable,
  minInterval: observable,
  allowedDays: observable,
  minExecutionTime: observable,
  maxExecutionTime: observable,
  onFinishProcessor: observable,
  onFailProcessor: observable,
  flow: observable,
  whatsAppDefinition: observable,
  whatsAppTemplate: observable,
  qualifier: observable,
  campaign: observable,
  timeZone: observable,
  smartInboxLabels: observable,
  labelToUpdate: observable,
  removeSmartInboxLabels: observable,
  save: action,
  clear: action,
});

export default FollowUp;
