import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledIcon = styled(LoadingOutlined)`
  font-size: 28px;
`;

const Loading = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Tip = styled.p`
  color: #2697ff;
`;

const Loader = ({ tip, ...props }) => (
  <Loading {...props}>
    <StyledIcon />
    {tip && <Tip>{tip}</Tip>}
  </Loading>
);

Loader.propTypes = { tip: PropTypes.string };

Loader.defaultProps = { tip: null };

export default Loader;
