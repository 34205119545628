import { computed, decorate, observable } from 'mobx';
import BaseStore from 'stores/BaseStore';
import { getTranslationFromSelectedLanguageTag } from 'utils/translateFromSelectedLanguageTag';
import Translation from '../TranslationStore';
import AutomationStep from './AutomationStep.store';
import Campaign from './Campaign.store';

class AutomationTemplate extends BaseStore {
  id;
  name;
  description;
  steps;
  optionalSteps;
  tags = [];
  campaign;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    if (!params) {
      return;
    }
    this.id = params.id;
    this.name = params.name.map((name) => new Translation(name));
    this.description = params.description?.map(
      (description) => new Translation(description)
    );
    this.campaign = new Campaign(params.campaign);
    this.tags = params.tags;
    this.steps = params.steps
      ?.sort((step1, step2) => step1.order - step2.order)
      ?.map((step) => new AutomationStep(step));
    if (params.optionalSteps) {
      this.optionalSteps = params.optionalSteps
        ?.sort((step1, step2) => step1.order - step2.order)
        ?.map((step) => new AutomationStep(step));
    }
  }
  get automationNameTranslation() {
    return getTranslationFromSelectedLanguageTag(this.name);
  }
  get automationDescriptionTranslation() {
    return getTranslationFromSelectedLanguageTag(this.description);
  }
}

decorate(AutomationTemplate, {
  id: observable,
  name: observable,
  description: observable,
  steps: observable,
  optionalSteps: observable,
  tags: observable,
  campaign: observable,
  automationNameTranslation: computed,
  automationDescriptionTranslation: computed,
});

export default AutomationTemplate;
