import { THEME } from 'globalStyles';
import { FormattedMessage } from 'react-intl';

export const DEFAULT_ATS_FIELDS_HANDLER = 'ats';

export const campaignStatusType = {
  ALL: 'A_0_A_1_A_2',
  ON_HOLD: 'A_0',
  ACTIVE: 'A_1',
  TESTING: 'A_2',
};

export const TABS = {
  AUTOMATCH: 'AUTOMATCH',
  ENGAGE: 'ENGAGE',
  REMINDER: 'REMINDER',
};

export const campaignStatuses = {
  [campaignStatusType.ON_HOLD || campaignStatusType.TESTING]: {
    textId: 'components/campaigns/CampaignOverview/status/on_hold',
    color: THEME.CARROT_ORANGE,
  },
  [campaignStatusType.ACTIVE]: {
    textId: 'components/campaigns/CampaignOverview/status/active',
    color: THEME.GREEN,
  },
  [campaignStatusType.TESTING]: {
    textId: 'components/campaigns/CampaignOverview/status/testing',
    color: THEME.YELLOW,
  },
};

export const configurationScheduleStatuses = {
  [campaignStatusType.ON_HOLD]: {
    textId:
      'components/campaigns/CampaignOverview/CampaignEditSideDrawer/CampaignEditForm/configurationScheduleStatus/stopped',
    color: THEME.CARROT_ORANGE,
  },
  [campaignStatusType.ACTIVE]: {
    textId:
      'components/campaigns/CampaignOverview/CampaignEditSideDrawer/CampaignEditForm/configurationScheduleStatus/running',
    color: THEME.GREEN,
  },
};

export const campaignTypes = {
  All: '',
  BROADCAST: 'BROADCAST',
  EMAIL_TO_WHATSAPP: 'EMAIL_TO_WHATSAPP',
  FLOW: 'FLOW',
  WEB: 'WEB',
  SMART_INBOX_BATCH_ACTION: 'SMART_INBOX_BATCH_ACTION',
  AUTOMATCH_VAC2CAND: 'AUTOMATCH_VAC2CAND',
  AUTOMATCH_CAND2VAC: 'AUTOMATCH_CAND2VAC',
  AUTOMATCH: 'automatch',
};

export const publishStageStatusTypes = {
  CLONE_CONNECTOR_SETTINGS: 'CLONE_CONNECTOR_SETTINGS',
  TOGGLE_SCHEDULE: 'TOGGLE_SCHEDULE',
};

export const publishStageStatus = {
  CLONE_CONNECTOR_SETTINGS:
    'components/campaigns/CampaignOverview/campaign/CampaignEditForm/coleConnectorSettings',
  PUBLISHED:
    'components/campaigns/CampaignOverview/campaign/CampaignEditForm/published',
  FAILED:
    'components/campaigns/CampaignOverview/campaign/CampaignEditForm/failedPublished',
  AUTOMATION_RUNNING:
    'components/campaigns/CampaignOverview/campaign/CampaignEditForm/automationRunning',
  AUTOMATION_NOT_RUNNING:
    'components/campaigns/CampaignOverview/campaign/CampaignEditForm/automationNotRunning',
  AUTOMATION_NEED_PUBLISH:
    'components/campaigns/CampaignOverview/campaign/CampaignEditForm/automationNeedPublishing',
  PREVIOUS_AUTOMATION_NOT_RUNNING:
    'components/campaigns/CampaignOverview/campaign/CampaignEditForm/previousAutomationNotRunning',
};

export const campaignConversationStatusType = {
  DELIVERED: 'delivered',
  SENT: 'sent',
  READ: 'read',
  REPLIED: 'replied',
  FINISHED: 'finished',
  ERROR: 'error',
  IGNORED: 'ignored',
  FAILED: 'failed',
};

export const broadcastType = {
  WHATSAPP: 'WHATSAPP',
  FACEBOOK: 'FACEBOOK',
};

export const weekdayType = {
  MONDAY: (
    <FormattedMessage id="components/campaigns/CampaignEditSideDrawer/WeekDaySelect/MONDAY" />
  ),
  TUESDAY: (
    <FormattedMessage id="components/campaigns/CampaignEditSideDrawer/WeekDaySelect/TUESDAY" />
  ),
  WEDNESDAY: (
    <FormattedMessage id="components/campaigns/CampaignEditSideDrawer/WeekDaySelect/WEDNESDAY" />
  ),
  THURSDAY: (
    <FormattedMessage id="components/campaigns/CampaignEditSideDrawer/WeekDaySelect/THURSDAY" />
  ),
  FRIDAY: (
    <FormattedMessage id="components/campaigns/CampaignEditSideDrawer/WeekDaySelect/FRIDAY" />
  ),
  SATURDAY: (
    <FormattedMessage id="components/campaigns/CampaignEditSideDrawer/WeekDaySelect/SATURDAY" />
  ),
  SUNDAY: (
    <FormattedMessage id="components/campaigns/CampaignEditSideDrawer/WeekDaySelect/SUNDAY" />
  ),
};

export const DEFAULT_START_EXECUTION_TIME = '09:00';
export const DEFAULT_END_EXECUTION_TIME = '17:00';
export const EXECUTION_TIME_MIN = '00:00'; // lowest possible time value
export const EXECUTION_TIME_MAX = '23:59'; // highest possible time value
export const EXECUTION_TIME_FORMAT = 'HH:mm'; // format that should be used to display time in the form
export const MIN_INTERVAL = '5'; // minimum time an interval should have
export const FOLLOW_UP_DEFAULT_INTERVAL = 6 * 60; // 360 minutes
export const BROADCAST_DAYS_DEFAULT = [1, 2, 3, 4, 5]; // Mon, Tue, Wed, Thu, Fri

export const TIME_INTERVAL_TYPES = {
  BROADCAST: 'BROADCAST',
  FOLLOW_UP: 'FOLLOW_UP',
};

export const NEXT_BROADCAST_FORMAT = 'DD/MM/YY HH:mm';

export const FOLLOW_UP_DEFAULT = {
  interval: FOLLOW_UP_DEFAULT_INTERVAL,
  minExecutionTime: DEFAULT_START_EXECUTION_TIME,
  maxExecutionTime: DEFAULT_END_EXECUTION_TIME,
  allowedDays: BROADCAST_DAYS_DEFAULT,
};

export const ATS_TYPES = ['OTYS', 'CARERIX', 'SALESFORCE', 'BULLHORN'];

export const CampaignConversationSource = {
  ASHL: 'ASHL',
  ENGAGE: 'ENGAGE',
};
