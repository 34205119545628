import { decorate, observable } from 'mobx';
import BaseStore from '../BaseStore';

class TagStore extends BaseStore {
  id = '';
  kind = '';
  content = '';
  color = '';

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    this.id = args?.id;
    this.kind = args?.kind;
    this.content = args?.content;
    this.color = args?.color;
  }
}

decorate(TagStore, {
  id: observable,
  kind: observable,
  content: observable,
  color: observable,
});

export default TagStore;
