import { includes as _includes } from 'lodash';
import { campaignsAtsFieldOrderingFragment } from './campaigns/fragments';
import {
  atsFieldOrderingFragment,
  mappingOptionFragment,
} from './fragments/builder/atsMappingFragments';
import requests from './requests';

const whatsappDefinitionBuilderFragment = `
fragment definitionFragment on WhatsAppDefinitionNode {
  id
  name
  supportsBatchAction
  versionsHistoricallyCreated
  isSmartInboxTemplate
  templateIdentifier
  activeTemplate {
     ...templateFragment
   }
  campaignSet {
    id
    name
    type
    atsBatchActions {
      id
      externalId
      name
      entityType {
        id
        name
        allHumanReadableNames {
          id
          content
          tags {
            kind
            content
          }
        }
      }
      notificationHeader
      notificationBody
      url
    }
  }
}

fragment templateFragment on WhatsAppTemplateNode {
  id
  variations {
    ...variationFragment
  }
  parameters {
    ...parameterFragment
  }
  scalarNames {
    ...scalarNameFragment
  }
}

fragment scalarNameFragment on ScalarNameNode {
  id
  name
  humanReadableName
}

fragment variationFragment on WhatsAppVariationNode {
  id
  content
  status
  language
  buttons {
    ...buttonFragment
  }
  languageTag {
    ...languageTagFragment
  }
}

fragment languageTagFragment on TagNode {
  id
  kind
  content
}

fragment buttonFragment on WhatsAppButtonNode {
  id
  actionType
  text
  phoneNumber
  url
  urlType
}

fragment parameterFragment on WhatsAppParameterNode {
  id
  name
  index
}
`;

const queryGetChatbot = `
{
    widgetSettings {
      botAvatar
      widgetIcon
    }
    allValidators {
      id
      name
    }
    allReplyProcessors
    allTags {
      id
      content
      kind
      color
    }
    chatbot(id: "fec5a7e4-2bd8-4706-bbd9-cd9ba5a0e804") {
      id
      defaultLanguageTag {
        id
      }
      allowedCategories
    }
    flows: allStateMachines(limit: 9999) {
      ...stateMachine
    }
    startStateMachine(id: "1") {
      id
      flow: statemachine {
        ...stateMachine
      }
    }
    restartStateMachine(id: "1") {
      id
      flow: statemachine {
        ...stateMachine
      }
    }
    escapeStateMachine(id: "1") {
      id
      flow: statemachine {
        ...stateMachine
      }
    }
    endStateMachine(id: "1") {
      id
      flow: statemachine {
        ...stateMachine
      }
    }
    welcomeBackStateMachine(id: "1") {
      id
      flow: statemachine {
        ...stateMachine
      }
    }
    fallbackStateMachine(id: "1") {
      id
      flow: statemachine {
        ...stateMachine
      }
    }
    defaultStateMachine(id: "1") {
      id
      flow: statemachine {
        ...stateMachine
      }
    }
    broadcastJobAlert(id: "1") {
      id
      flow: statemachine {
        ...stateMachine
      }
    }
    outOfOfficeStateMachine(id: "1") {
      id
      flow: statemachine {
        ...stateMachine
      }
    }
  }

  fragment stateMachine on StateMachineNode {
    id
    name
    isDefaultStateMachine
    fixedAtsFieldMappings{
        id
        type
        dataType
        value
        isDefaultMapping
        atsOptions{
           value
           humanReadableName
           id
          }
        mappingOptions {
          ...mappingOption
        }
        atsFieldOrdering: atsfieldorderingSet {
            ...atsFieldOrderingFragment
        }
        scalarName{
          name
          id
        }
  }
    description
    campaigns {
    whatsappDefinition {
      ...definitionFragment
    }
  }
  processors: wrapupProcessor {
    id
    icon
    priority
    category
    description
    humanReadableName
    name
    type
    processorType
    isImportantGoal
    googleAnalyticsEvent {
      id
      eventLabel
      eventAction
      systemName
    }
    processorDependencies {
      customProcessor {
        modelName
        id
      }
    }
    finalGoalCampaigns {
      id
      name
     }
    }
  } 
  ${whatsappDefinitionBuilderFragment}
  ${atsFieldOrderingFragment}
  ${mappingOptionFragment}
`;

const queryGetScalarNames = `
{
    allScalarnames {
        id
        name
        humanReadableName
        humanReadableNames {
          id
          content
          tags {
            id
            kind
            content
          }
        }
        atsFieldMappings (isDefaultMapping:true) {
          id
          isDefaultMapping
          postModifiers
          getModifiers
          scalarName{
            id
            name
          }
          atsFieldOrdering: atsfieldorderingSet {
            ...atsFieldOrderingFragment
          }
        }
      }
    }
    ${campaignsAtsFieldOrderingFragment}
`;

// I am using this one just to fetch the id and the name of the flow
// to reduce the loading time when we are using the flows in a select element e.g.
const basicFlowData = `
{
  flows: allStateMachines(limit: 9999) {
    ...stateMachine
  }
  startStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  restartStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  escapeStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  endStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  welcomeBackStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  fallbackStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  defaultStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  broadcastJobAlert(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  outOfOfficeStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
}

fragment stateMachine on StateMachineNode {
  id
  name
  description
}
`;

const queryGetStateMachinesForAnalytics = `
{
  flows: allStateMachines(limit: 9999) {
    ...stateMachine
  }
  startStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  restartStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  escapeStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  endStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  welcomeBackStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  fallbackStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  defaultStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  broadcastJobAlert(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
  outOfOfficeStateMachine(id: "1") {
    id
    flow: statemachine {
      ...stateMachine
    }
  }
}

fragment stateMachine on StateMachineNode {
  id
  name
  description
  campaigns {
    whatsappDefinition {
      ...definitionFragment
    }
  }
  processors: wrapupProcessor {
    id
    icon
    priority
    category
    description
    humanReadableName
    name
    type
    processorType
    isImportantGoal
    googleAnalyticsEvent {
      id
      eventLabel
      eventAction
      systemName
    }
    processorDependencies {
      customProcessor {
        modelName
        id
      }
    }
    finalGoalCampaigns {
      id
      name
    }
  }
}
 ${whatsappDefinitionBuilderFragment}
`;

const allFlowNames = `
{
  flows: allStateMachines(limit: 9999) {
  id 
  name
 }
}
`;

const allLabels = `{
  allLabels {
    id
    color
    name
  }
}`;

const allModifiers = `
query {
	allModifiers {
    name
    humanReadableNames {
	  tag
      content
    }
  }
}
`;

const filterNonStaticFlows = ({ data }) => {
  const staticFlows = [
    data.startStateMachine,
    data.restartStateMachine,
    data.escapeStateMachine,
    data.endStateMachine,
    data.welcomeBackStateMachine,
    data.fallbackStateMachine,
    data.defaultStateMachine,
    data.broadcastJobAlert,
    data.outOfOfficeStateMachine,
  ];
  const staticFlowIDs = staticFlows
    .filter((flow) => flow || null)
    .map((flow) => flow.flow.id);

  const allFlows = data.flows;
  return allFlows.filter((flow) => !_includes(staticFlowIDs, flow.id));
};

async function get() {
  const res = await requests.gql.chatserver.query(queryGetChatbot, null);
  const constructedChatbot = {
    flows: filterNonStaticFlows(res),
    allTags: res.data.allTags,
    startStateMachine: res.data.startStateMachine,
    restartStateMachine: res.data.restartStateMachine,
    escapeStateMachine: res.data.escapeStateMachine,
    endStateMachine: res.data.endStateMachine,
    welcomeBackStateMachine: res.data.welcomeBackStateMachine,
    fallbackStateMachine: res.data.fallbackStateMachine,
    defaultStateMachine: res.data.defaultStateMachine,
    broadcastJobAlert: res.data.broadcastJobAlert,
    outOfOfficeStateMachine: res.data.outOfOfficeStateMachine,
    allValidators: res.data.allValidators,
    allReplyProcessors: res.data.allReplyProcessors,
    widgetSettings: res.data.widgetSettings,
  };

  const { chatbot } = res.data;
  if (chatbot) {
    constructedChatbot.defaultLanguageTag = chatbot.defaultLanguageTag;
    constructedChatbot.allowedCategories = chatbot.allowedCategories;
  }
  return constructedChatbot;
}

async function getScalarNames() {
  const res = await requests.gql.chatserver.query(queryGetScalarNames, null);
  const constructedScalarName = { scalarNames: res?.data?.allScalarnames };
  return constructedScalarName;
}

async function getStateMachinesForAnalytics(serverAddress) {
  const graphQlServer = `https://${serverAddress}/graphql`;
  const res = await requests.gql.chatserver.query(
    queryGetStateMachinesForAnalytics,
    null,
    graphQlServer
  );
  const constructedChatbot = {
    flows: filterNonStaticFlows(res),
    startStateMachine: res.data.startStateMachine,
    restartStateMachine: res.data.restartStateMachine,
    escapeStateMachine: res.data.escapeStateMachine,
    endStateMachine: res.data.endStateMachine,
    welcomeBackStateMachine: res.data.welcomeBackStateMachine,
    fallbackStateMachine: res.data.fallbackStateMachine,
    defaultStateMachine: res.data.defaultStateMachine,
    broadcastJobAlert: res.data.broadcastJobAlert,
  };
  return constructedChatbot;
}

async function create(data) {
  await requests.chatbotManagement.post(
    '/chatbot/create',
    data,
    process.env.REACT_APP_CHATBOT_SETUP_API_TOKEN
  );
}

async function remove(projectId) {
  await requests.chatbotManagement.post(
    '/chatbot/delete',
    { projectId },
    process.env.REACT_APP_CHATBOT_SETUP_API_TOKEN
  );
}

async function fix(projectId) {
  await requests.chatbotManagement.post(
    '/chatbot/fix',
    { projectId },
    process.env.REACT_APP_CHATBOT_SETUP_API_TOKEN
  );
}

async function recover(projectId) {
  await requests.chatbotManagement.post(
    '/chatbot/recover',
    { projectId },
    process.env.REACT_APP_CHATBOT_SETUP_API_TOKEN
  );
}

async function listFlowsBasicData() {
  const flows = await requests.gql.chatserver.query(basicFlowData);
  return filterNonStaticFlows(flows);
}

async function flowNames() {
  const flows = await requests.gql.chatserver.query(allFlowNames);
  return filterNonStaticFlows(flows);
}

const getAllLabels = () => requests.gql.chatserver.query(allLabels);

const getAllModifiers = () => requests.gql.chatserver.query(allModifiers);

const Chatbot = {
  get,
  getScalarNames,
  getStateMachinesForAnalytics,
  create,
  remove,
  fix,
  recover,
  flowNames,
  listFlowsBasicData,
  getAllLabels,
  getAllModifiers,
};

export default Chatbot;
