import { decorate, observable, action, computed } from 'mobx'
import agent from 'agent'
import BaseStore from './BaseStore'
import ListEntity from './ListEntity.store'

class Entity extends BaseStore {
  id
  listEntity

  constructor(args) {
    super(args)
    this.setup(args)
  }

  setup({ id, listEntity }) {
    this.id = id
    this.listEntity = new ListEntity(listEntity)
  }

  get object() {
    if (this.listEntity) {
      return this.listEntity
    }
    return null
  }

  static async delete(id, listEntityId) {
    await agent.Entity.delete(id, listEntityId)
  }

  static async get(id) {
    const entity = await agent.Entity.get(id)
    return new Entity(entity)
  }

  static async createListEntity(name) {
    const entity = await agent.Entity.createListEntity(name)
    return new Entity(entity)
  }
}

decorate(Entity, {
  id: observable,
  listEntity: observable,
  setup: action,
  object: computed,
})

export default Entity
