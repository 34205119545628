import _ from 'lodash';
import { action, observable } from 'mobx';

import agent from '../../agent';
import BaseStore from '../BaseStore';
import MessageStore from './MessageStore';

class StandardActionStore extends BaseStore {
  @observable id = '';
  @observable content = '';
  @observable messageClass = null;

  constructor(args) {
    super();
    this.setup(args);
  }

  @action
  setup(args) {
    this.id = args.id;
    this.content = args.content;
    this.messageClass = new MessageStore(args.messageClass);
  }

  @action
  async update(data) {
    const res = await agent.StandardAction.update(data);
    this.setup(res.data.standardActionUpdate.standardaction);
    const ok = _.get(res, 'data.standardActionUpdate.ok');
    if (!ok) throw Error('Action not updated');
  }
}

export default StandardActionStore;
