import requests from '../requests'

const mutation = `
mutation UpdateLinkMessage($data: LinkMessageUpdateGenericType!) {
    updateLinkMessageMutation(newLinkmessage: $data) {
        linkmessage {
            id
            placeholder
            link
            isDocument
        }
      ok
    }
}  
`

const LinkMessage = {
  update: data => requests.gql.chatserver.mutate(mutation, data),
}

export default LinkMessage
