import { action, decorate, observable } from 'mobx';
import AtsFieldMapping from 'stores/AtsFieldMapping.store';
import BaseStore from 'stores/BaseStore';

class WhatsAppParameter extends BaseStore {
  id;
  name;
  index;
  atsFieldMapping;
  parameterNameToPreview = '';

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.id = params?.id;
    this.name = params?.name;
    this.index = params?.index;
    this.atsFieldMapping = new AtsFieldMapping(params?.atsFieldMapping);

    if (params?.atsFieldMapping?.id) {
      this.parameterNameToPreview =
        this.atsFieldMapping?.translatedHumanReadableName || params?.name;
    } else {
      this.parameterNameToPreview = params?.name;
    }
  }
}

decorate(WhatsAppParameter, {
  id: observable,
  name: observable,
  index: observable,
  atsFieldMapping: observable,
  setup: action,
});

export default WhatsAppParameter;
