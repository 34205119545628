import { decorate, observable, action } from 'mobx'
import BaseStore from './BaseStore'

class ValidatorStore extends BaseStore {
  id = ''
  name = ''

  constructor(args) {
    super()
    this.setup(args)
  }

  setup(args) {
    this.id = args.id
    this.name = args.name
  }
}

decorate(ValidatorStore, {
  id: observable,
  name: observable,
  setup: action,
})

export default ValidatorStore
