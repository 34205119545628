import requests from './requests'

const updateTagsMutation = `
mutation UpdateVariationTags($tagsId: [ID], $variationId: ID) {
    updateVariationTags(tagsId: $tagsId, variationId: $variationId) {
      ok
    }
  }
`

const deleteMutation = `
  mutation DeleteVariation($id: ID!) {
    deleteVariationMutation(id: $id) {
      ok
    }
  }
`

const Variation = {
  updateTags: data => requests.gql.chatserver.mutate(updateTagsMutation, data),
  delete: id => requests.gql.chatserver.mutate(deleteMutation, { id }),
}

export default Variation
