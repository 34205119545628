import _superagent from 'superagent'
import superagentPromise from 'superagent-promise'

const superagent = superagentPromise(_superagent, global.Promise)

const responseBody = res => res.text

const handleErrors = err => {
  if (err && err.response && err.response.status === 401) {
    console.error(err)
  }
  return err
}

const agent = {
  get: (url, headers = {}) =>
    superagent
      .get(url)
      .set(headers)
      .end(handleErrors)
      .then(responseBody),
  getXML: (url, headers = {}) =>
    superagent
      .get(url)
      .unset('content-type')
      .set(headers)
      .end(handleErrors)
      .then(responseBody),
}

export default agent
