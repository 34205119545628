import { useContext, createContext } from 'react';
import { action } from 'mobx';
import _ from 'lodash';
import * as stores from 'stores';

export default function useInjectStore(store, path) {
  const selectedStore = useContext(createContext(stores[store]));
  if (path) {
    const setter = action((value) => {
      _.set(selectedStore, path, value);
    });
    return [_.get(selectedStore, path), setter];
  }
  return selectedStore;
}
