import { observable, action, decorate, runInAction } from 'mobx'
import Blog from 'agent/dashboard/blog'
import BaseStore from '../BaseStore'

class BlogStore extends BaseStore {
  articles = []

  async fetch() {
    const articles = await Blog.getLatestArticles()
    runInAction(() => {
      this.articles = articles || []
    })
  }
}

decorate(BlogStore, {
  articles: observable,
  fetch: action,
})

export default BlogStore
