import Analytics from 'agent/analytics';
import { sortBy, unionBy } from 'lodash';
import {
  action,
  computed,
  decorate,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import moment from 'moment';
import BaseStore from 'stores/BaseStore';
import { getContactDetail } from '../../utils/utils';

class Conversations extends BaseStore {
  conversations = [];

  conversationsPage = 1;

  conversationsPageSize = 100;

  mayHaveMoreConversations = true;

  loadingConversations = true;

  loadingGoogleLogs = true;

  readConversationArray = [];

  constructor(clientID) {
    super();
    this.conversations = [];
    this.conversationsPage = 1;
    this.conversationsPageSize = 100;
    this.mayHaveMoreConversations = true;
    this.loadingConversations = true;
    this.clientID = clientID;
  }

  setDateInterval(startDate, startMonth, startYear, endDay, endMonth, endYear) {
    this.startDate = startDate;
    this.startMonth = startMonth;
    this.startYear = startYear;
    this.endDay = endDay;
    this.endMonth = endMonth;
    this.endYear = endYear;
  }

  /**
   * Load conversation array
   * TODO: Cleanup and optimize this function
   */
  loadConversationsBrief = async () => {
    this.loadingConversations = true;
    this.fetchOpenedConversation();
    const conversations = toJS(this.conversations);
    let resultSet = [];
    let result;
    const body = {
      s_day: this.startDate,
      s_month: this.startMonth,
      s_year: this.startYear,
      e_day: this.endDay,
      e_month: this.endMonth,
      e_year: this.endYear,
      page: this.conversationsPage,
      page_size: this.conversationsPageSize,
      project_id: this.clientID,
    };

    // Paginate conversations
    do {
      result = await Analytics.conversationsBrief(body);
      resultSet = [...resultSet, ...result];
      body.page += 1;
    } while (
      result.length &&
      result.length >= this.conversationsPageSize &&
      body.page <= 5
    );

    const conversationsWithContactInfo = await Analytics.contactInformation(
      resultSet,
      body.project_id
    );

    runInAction(() => {
      if (resultSet.length) {
        const mergedArray = unionBy(
          conversationsWithContactInfo,
          conversations,
          'client_id'
        );
        const sortedArray = sortBy(mergedArray, 'startedAt');
        this.conversations = sortedArray;
        this.mayHaveMoreConversations =
          result.length && result.length % this.conversationsPageSize === 0;
        this.conversationsPage = body.page;
      }
      this.loadingConversations = false;
    });
  };

  conversationPaginationSetter = (page, pageSize) => {
    this.conversationsPage = page || this.conversationsPage;
    this.conversationsPageSize = pageSize || this.conversationsPageSize;
  };

  nextConversationPage = () => {
    this.conversationsPage += 1;
  };

  fetchConversation = async (rowKey) => {
    const conversations = toJS(this.conversations);
    const index = conversations.findIndex((cnv) => cnv.client_id === rowKey);

    if (index > -1) {
      const body = { conversation_id: rowKey, project_id: this.clientID };
      const result = await Analytics.conversationContent(body);
      conversations[index].messages = result;
      runInAction(() => {
        this.conversations = conversations;
      });
    }
  };

  fetchOpenedConversation = async () => {
    const result = await Analytics.getOpenedConversations(this.clientID);
    runInAction(() => {
      this.readConversationArray = result;
    });
  };

  openConversations = async (id) => {
    const array = new Set(toJS(this.readConversationArray));
    if (array.has(id)) return;
    await Analytics.openConversations(this.clientID, id);
    runInAction(() => {
      this.readConversationArray = [...array, id];
    });
  };

  /**
   * Mark a list of conversations as read/unread
   * @param ids {Array}
   * @param isRead {Boolean}
   */
  bulkChangeReadStatus = async (ids, isRead) => {
    const array = toJS(this.readConversationArray);
    const idsSet = new Set(ids);
    if (isRead) {
      // Delete from firebase
      await Analytics.bulkRemoveConversation(this.clientID, [...idsSet]);
      const readConversations = array.filter((elm) => !idsSet.has(elm));

      runInAction(() => {
        this.readConversationArray = readConversations;
      });
    }
    // else {
    // Work on this to add mark conversations as read
    //   // Add to firebase

    //   // await Analytics.bulkAddConversation(this.clientID, ids);
    //   // runInAction(() => {
    //   //   this.readConversationArray = [...result];
    //   // });
    // }
  };

  markAsUnread = async (id) => {
    const array = toJS(this.readConversationArray);
    await Analytics.removeConversation(this.clientID, id);
    const newArray = array.filter((elm) => elm !== id);

    runInAction(() => {
      this.readConversationArray = newArray;
    });
  };

  get getReadConversationArray() {
    return toJS(this.readConversationArray);
  }

  get getConversationsBrief() {
    const conversations = toJS(this.conversations);
    const result = conversations
      ? conversations.reduce(
          (arr, cnv, index) =>
            arr.concat({
              nr: index + 1,
              date: moment(cnv.startedAt).format('DD-MM-YY HH:mm'),
              joboti_id: cnv.client_id,
              step: cnv.steps,
              state_machines: cnv.state_machines,
              contact_details: getContactDetail(cnv.contact_details),
              rowKey: cnv.client_id,
              rawDate: cnv.startedAt,
            }),
          []
        )
      : [];
    return result;
  }

  get conversationsArray() {
    return toJS(this.conversations);
  }

  getGoogleLogs = async (clientId, schemaName, dateFirstMsg, dateLastMsg) => {
    const data = await Analytics.googleLogs(
      clientId,
      schemaName,
      dateFirstMsg,
      dateLastMsg
    );

    return data;
  };

  deleteConversationByClientId = async (clientId) => {
    this.loadingConversations = true;
    const res = await Analytics.deleteConversationByClientId(clientId);
    runInAction(() => {
      this.loadingConversations = false;
    });
    if (res.data.deleteClientStateByClientId.ok) {
      const deletedConversationIndex = this.conversations.findIndex(
        (conversation) => conversation.client_id === clientId
      );
      runInAction(() => {
        this.conversations.splice(deletedConversationIndex, 1);
      });
      return true;
    }
    return false;
  };
}

decorate(Conversations, {
  conversations: observable,
  conversationsPage: observable,
  conversationsPageSize: observable,
  mayHaveMoreConversations: observable,
  loadingConversations: observable,
  loadingGoogleLogs: observable,
  readConversationArray: observable,

  conversationsArray: computed,
  getConversationsBrief: computed,
  getReadConversationArray: computed,

  loadConversationsBrief: action,
  fetchConversation: action,
  nextConversationPage: action,
  conversationPaginationSetter: action,
  fetchOpenedConversation: action,
  openConversations: action,
  markAsUnread: action,
  bulkChangeReadStatus: action,
  deleteConversationByClientId: action,
});

export default Conversations;
