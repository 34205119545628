import _ from 'lodash'
import requests from './requests'

const updateMutation = `
mutation UpdateListEntityValuesSynonyms($id: ID!, $synonyms: [String!]!) {
  updateListEntitySynonyms(listEntityValueId: $id, synonyms: $synonyms) {
    ok
  }
}
`

export default {
  update: async data => {
    const res = await requests.gql.chatserver.mutate(updateMutation, data)
    const ok = _.get(res, 'data.updateListEntitySynonyms.ok')
    if (!ok) throw new Error('can not update list entity synonyms')
  },
}
