import { calendarUserFragment } from 'agent/fragments/Calendar';
import _ from 'lodash';
import calendarAuthHandler from '../constants/authHandlerTypes';
import requests from './requests';

const getUserQuery = `
{
  user {
    agentId
    id
    language
    email
    isSuperuser
    role
    name
    firstName
    lastName
    requiresPasswordReset
    pagesVisited
    chatbots {
      id: serverId
      name: projectName
      serverAddress: name
      useBetaPortal
      token
      projectId
      deleted
    }
    organization {
      id
      name
    }
    calendarAuthHandler
    calendarIsAuthenticated
  }
}
`;

const getChatbot = `
{
  mainChatbot {
    supportsNestedWhereQueries
    whatsappProvider
    disallowDisableEventPreference
    atsSpecificTemplatesEnvironment
    supportsMappingCreation
    allowedCategories
    ats
    smartInboxEnabled
    defaultLanguageTag {
      id
      kind
      content
      color
    }
  }
  allTags {
    id
    kind
    content
    color
  }
}
`;

const fetchAllCalendarUsers = `
{
  allCalendarUsers {
    ...calendarUserFragment
  }
}
${calendarUserFragment}
`;

const mutationUpdateCalendarToken = `
mutation UpdateCalendarToken($token:String!,$userId:ID!, $referer: String!){
  updateCalendarToken(token:$token, userId: $userId, referer: $referer) {
    ok
  }
}`;

const mutationUpdateMicrosoftCalendarToken = `
mutation UpdateMicrosoftCalendarToken($token:String!,$userId:ID!, $referer: String!){
  updateMicrosoftCalendarToken(token:$token, userId: $userId, referer: $referer) {
    ok
  }
}`;

const BASE_URL = process.env.REACT_APP_API_HOST_NAME;

const User = {
  get: async () => requests.gql.router.query(getUserQuery),
  login: (username, password) =>
    requests.post('/api/cookie-login/', {
      username,
      password,
    }),
  loginWithCode: (loginCode, authToken) =>
    requests.post(
      '/api/login-with-code/',
      {
        login_code: loginCode,
      },
      `Token ${authToken}`
    ),
  validateToken: async () => requests.post('/api/validate-token/'),
  changePhoneNumber: (phoneNumber, authToken) =>
    requests.post(
      '/api/change-phone/',
      {
        phone_number: phoneNumber,
      },
      `Token ${authToken}`
    ),
  resendLoginCode: (authToken) =>
    requests.post('/api/resend-login-code/', {}, `Token ${authToken}`),
  logout: () => requests.post('/api/logout/', {}),
  sendPasswordReset: async (username) =>
    // For some reason, superagent makes this non-async, therefore we use fetch here.
    fetch(`${BASE_URL}/api/v1/forgot-password/`, {
      method: 'POST',
      body: JSON.stringify({ email: username }),
    }),
  changePasswordWithGuid: (password, guid) =>
    fetch(`${BASE_URL}/api/v1/reset-password/`, {
      method: 'POST',
      body: JSON.stringify({
        GUID: guid,
        password,
      }),
    }),
  changePasswordForFirstTime: async (id, password) => {
    return requests.post('/api/v1/reset-password-first-time-login/', {
      id,
      password,
    });
  },
  changePassword: (authToken, userId, oldPassword, newPassword) =>
    fetch(`${BASE_URL}/api/v1/change-password/`, {
      method: 'POST',
      body: JSON.stringify({
        id: userId,
        old_password: oldPassword,
        new_password: newPassword,
      }),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }),
  getChatbot: async () => {
    const res = await requests.gql.chatserver.query(getChatbot);
    return _.get(res, 'data');
  },
  fetchAllCalendarUsers: async () => {
    const res = await requests.gql.chatserver.query(fetchAllCalendarUsers);
    return _.get(res, 'data.allCalendarUsers');
  },
  loginRefresh: (refresh) => {
    const payload = refresh
      ? {
        refresh,
        useCookies: true,
      }
      : {};

    return requests.post('/api/login-refresh/', payload);
  },
  oktaLogin: (accessToken) =>
    requests.plainPost('/api/v1/okta-login/', { accessToken }),
  updateCalendarToken: async (token, userId, handler, referer) => {
    const mutation =
      handler === calendarAuthHandler.GOOGLE
        ? mutationUpdateCalendarToken
        : mutationUpdateMicrosoftCalendarToken;
    const response = await requests.gql.chatserver.mutate(mutation, {
      token,
      userId,
      referer,
    });

    const { ok } =
      handler === calendarAuthHandler.GOOGLE
        ? response.data.updateCalendarToken
        : response.data.updateMicrosoftCalendarToken;
    if (ok) {
      await requests.post('/api/v1/user/is-calendar-authenticated/', {
        calendarIsAuthenticated: true,
        userId,
      });
    }
    return ok;
  },
};

export default User;
