import { computed, decorate, observable } from 'mobx';
import BaseStore from 'stores/BaseStore';
import { getTranslationFromSelectedLanguageTag } from 'utils/translateFromSelectedLanguageTag';
import Translation from '../TranslationStore';

class AutomationStep extends BaseStore {
  id;
  icon;
  order;
  translation;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    if (!params) {
      return;
    }
    this.id = params?.id;
    this.order = params?.order;
    this.icon = params?.icon;
    this.translation = params?.translation.map(
      (content) => new Translation(content)
    );
  }
  get automationStepTranslation() {
    return getTranslationFromSelectedLanguageTag(this.translation);
  }
}

decorate(AutomationStep, {
  id: observable,
  order: observable,
  icon: observable,
  translation: observable,
  automationStepTranslation: computed,
});

export default AutomationStep;
