import { observable, action, computed, decorate, runInAction } from 'mobx';
import { getSelectedLanguage, setSelectedLanguage } from 'locales/utils';
import BaseStore from './BaseStore';

class Language extends BaseStore {
  language = getSelectedLanguage();
  defaultLanguage = '';

  setLanguage(lang) {
    setSelectedLanguage(lang);
    this.language = lang;
  }

  setDefaultLanguage(lang) {
    runInAction(() => {
      this.defaultLanguage = lang;
    });
  }

  get selectedLanguage() {
    let defaultChatbotLanguage;

    if (this.defaultLanguage === 'en') {
      defaultChatbotLanguage = `${this.defaultLanguage}-US`;
    } else {
      defaultChatbotLanguage = 'nl-NL';
    }

    return this.language || defaultChatbotLanguage;
  }

  // eslint-disable-next-line class-methods-use-this
  get supportedLanguages() {
    return [
      {
        name: 'Dutch (Netherlands)',
        code: 'nl-NL',
      },
      {
        name: 'English (US)',
        code: 'en-US',
      },
    ];
  }
}

decorate(Language, {
  language: observable,
  defaultLanguage: observable,
  setLanguage: action,
  setDefaultLanguage: action,
  selectedLanguage: computed,
});
export default Language;
