import _ from 'lodash'
import requests from './requests'

const createMutation = `
mutation CreateSentence($data: SentenceCreateGenericType!) {
  createSentence(newSentence: $data) {
    sentence {
      id
      text
    }
  }
}
`

const updateMutation = `
mutation UpdateSentence($data: SentenceUpdateGenericType!) {
  updateSentence(newSentence: $data) {
    sentence {
      id
      text
    }
  }
}
`

const deleteMutation = `
mutation DeleteSentence($id: ID!) {
  deleteSentence(id: $id) {
    sentence {
      id
      text
    }
    ok
  }
}
`

const initializeSentences = `
mutation CreateSentence1($intent: ID!, $text1: String!, $text2: String!, $text3: String!) {
  createSentence1: createSentence(newSentence: {intent: $intent, text: $text1}) {
    sentence {
      id
      text
    }
  }
  createSentence2: createSentence(newSentence: {intent: $intent, text: $text2}) {
    sentence {
      id
      text
    }
  }
  createSentence3: createSentence(newSentence: {intent: $intent, text: $text3}) {
    sentence {
      id
      text
    }
  }
}`

const Sentence = {
  create: async data => {
    const res = await requests.gql.chatserver.mutate(createMutation, { data })
    return _.get(res, 'data.createSentence.sentence')
  },
  update: async data => {
    const res = await requests.gql.chatserver.mutate(updateMutation, { data })
    return _.get(res, 'data.updateSentence.sentence')
  },
  delete: async id => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, { id })
    const ok = _.get(res, 'data.deleteSentence.ok')
    if (!ok) throw new Error('can not delete sentence')
  },
  init: async data => {
    const res = await requests.gql.chatserver.mutate(initializeSentences, data)
    return [
      _.get(res, 'data.createSentence1.sentence'),
      _.get(res, 'data.createSentence2.sentence'),
      _.get(res, 'data.createSentence3.sentence'),
    ]
  },
}

export default Sentence
