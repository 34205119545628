import { observable, computed, action, decorate, runInAction } from 'mobx';
import _ from 'lodash';

import agent from '../agent';
import StateStore from './StateStore';
import BaseStore from './BaseStore';
import WhatsAppDefinitionStore from './campaigns/WhatsAppDefinition/WhatsAppDefinition.store';

class StateGroup extends BaseStore {
  id = '';
  name = '';
  position = null;
  initialState = null;
  states = [];
  isCollapseOpen = false;
  actionsIds = [];
  whatsAppDefinitions = [];

  constructor(args) {
    super(args);
    this.setup(args);
    this.save = _.debounce(this.save, 2000);
  }

  setup(args) {
    if (args) {
      // check if args is not null (if we don't do this we cannot create a context for this store)
      this.id = args.id;
      this.name = args.name;
      this.position = args.position;

      if (args?.whatsAppDefinitions) {
        this.whatsAppDefinitions = args?.whatsAppDefinitions.map(
          (definition) => new WhatsAppDefinitionStore(definition)
        );
      }
      if (args.initialState) {
        // TODO: Add the wa definitions to this state store
        args.initialState.whatsAppDefinitions = args?.whatsAppDefinitions;
        this.initialState = new StateStore(args.initialState);
      }
      this.states = [];
      args.states.forEach((state) => {
        // TODO: Check if the state over which we are looping is the initial state
        // If so then you want to add state.whatsapp_definitions = wa_definitions

        if (state.id === args?.initialState?.id) {
          state.whatsAppDefinitions = args.whatsAppDefinitions;
        }
        this.states.push(new StateStore(state));
      });
    }
  }

  async cloneStateGroup(
    stateGroupIdentifier,
    originClient,
    originStateMachine,
    destinationStateMachine,
    newStateGroupName
  ) {
    const data = {
      stateGroupIdentifier,
      originClient,
      originStateMachine,
      destinationStateMachine,
      newStateGroupName,
    };
    const res = await agent.StateGroup.clone(data);

    runInAction(() => {
      const ok = _.get(res, 'data.cloneStateGroup.ok');
      if (!ok) throw Error('Could not clone Template');
    });
  }

  get statesWithoutInitialState() {
    if (this.initialState)
      return _.filter(
        this.states,
        (state) => state.id !== this.initialState.id
      );
    return this.states;
  }

  async save() {
    const data = {
      data: {
        id: this.id,
        name: this.name,
        initialState: _.get(this, 'initialState.id'),
      },
    };
    const res = await agent.StateGroup.update(data);
    this.setup(res.data.updateStateGroupMutation.stategroup);
  }
}

decorate(StateGroup, {
  id: observable,
  name: observable,
  position: observable,
  initialState: observable,
  states: observable,
  isCollapseOpen: observable,
  whatsAppDefinitions: observable,
  setup: action,
  cloneStateGroup: action,
  statesWithoutInitialState: computed,
});

export default StateGroup;
