import { decorate, observable, action, runInAction } from 'mobx';
import BaseStore from 'stores/BaseStore';

class Processor extends BaseStore {
  id;
  name;
  humanReadableName;
  processorType;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.id = params?.id;
    this.name = params?.name;
    this.humanReadableName = params?.humanReadableName;
    this.processorType = params?.processorType;
  }

  async clear() {
    runInAction(() => {
      this.id = null;
      this.name = null;
      this.humanReadableName = null;
      this.processorType = null;
    });
  }
}

decorate(Processor, {
  id: observable,
  name: observable,
  humanReadableName: observable,
  setup: action,
  clear: action,
});

export default Processor;
