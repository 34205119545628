import { decorate, observable } from 'mobx'
import BaseStore from '../BaseStore'

export class ButtonModel extends BaseStore {
  text = ''
  dataId = ''
  btnId = 1

  constructor(text, dataId, btnId) {
    super()
    this.text = text
    this.dataId = dataId
    this.btnId = btnId
  }
}

decorate(ButtonModel, {
  text: observable,
  dataId: observable,
  btnId: observable,
})
