import agent from 'agent';
import dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import Cookies from 'js-cookie';
import { action, decorate, observable, runInAction } from 'mobx';
import 'moment-timezone';
import BaseStore from 'stores/BaseStore';
import {
  ALL_CAMPAIGNS_ADDITIONAL_FIELDS,
  cookiesOption,
} from '../../constants';
import { CampaignConversationSource } from '../../constants/campaign.constants';
import { userStore } from '../index';
import CampaignConversation from './CampaignConversation.store';

const PAGE = 1;
const PAGE_SIZE = 20;

dayjs.extend(utc);
dayjs.extend(timezone);
const today = dayjs();
const lastMonth = dayjs().subtract(30, 'day');

class CampaignDetail extends BaseStore {
  isLoading = false;
  conversationsPerPage = {};
  currentConversations = null;
  conversationCount = 0;
  currentPageNumber = 1;
  pageSize = 20;
  selectedAdditionalFields = [];
  campaign = null;
  selectedCampaignIds = [];

  userDataOptions = [];
  kpiFilterOptions = [];
  statusFilterOptions = [];
  automationNameFilter = '';

  phoneNumberFilter = '';
  statusFilter = null;
  kpiFilter = null;
  userDataFilter = null;

  // Sort on the conversation_start by default
  sortField = 'conversation_start';
  sortDirection = 0;

  // Min date
  minYear = null;
  minMonth = null;
  minDay = null;

  // Start date
  startYear = lastMonth.year();
  startMonth = lastMonth.month();
  startDay = lastMonth.date();

  // End date
  endYear = today.year();
  endMonth = today.month();
  endDay = today.date();
  timezone = dayjs.tz.guess();
  campaignFieldsStorageKey;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(campaign) {
    // Add a relation back to the campaign
    this.campaign = campaign;
    this.selectedAdditionalFields = JSON.parse(
      decodeURIComponent(
        Cookies.get(
          campaign?.id ||
            `${ALL_CAMPAIGNS_ADDITIONAL_FIELDS}${userStore?.selectedChatbot?.name}`
        ) || '[]'
      )
    );

    this.selectedCampaignIds = [this.campaign?.id];
  }

  /**
   * Fetch the campaignConversations for the selected campaign.
   *
   * @returns {Promise<void>}
   */
  async fetchCampaignConversations({
    campaignIds = [this.campaign?.id],
    exportCSV = false,
  } = {}) {
    const tenant = userStore.selectedChatbot.name;
    this.campaignFieldsStorageKey = `${ALL_CAMPAIGNS_ADDITIONAL_FIELDS}${userStore?.selectedChatbot?.name}`;
    const firstFetch = !this.currentConversations;
    const additionalFields =
      this.selectedAdditionalFields?.length > 0
        ? this.selectedAdditionalFields.join(', ')
        : this.selectedAdditionalFields;

    runInAction(() => {
      this.isLoading = true;
    });
    const fetchingConfigs = {
      tenant,
      campaignIds,
      pageNum: firstFetch ? PAGE : this.currentPageNumber,
      pageSize: PAGE_SIZE,
      startDate: dayjs(this.startDate).tz(this.timezone).format(),
      endDate: dayjs(this.endDate).tz(this.timezone).format(),
      phoneNumber: this.phoneNumberFilter,
      statusFilter: this.statusFilter,
      kpiFilter: this.kpiFilter,
      userDataFilter: this.userDataFilter,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      conversationDataFields: additionalFields,
      exportCSV,
    };
    // if we are fetching first page add additional properties
    if (firstFetch) {
      fetchingConfigs.startDate = null;
      fetchingConfigs.endDate = null;
      fetchingConfigs.fetchOptions = true;
      fetchingConfigs.exportCSV = exportCSV;
      fetchingConfigs.conversationDataFields = additionalFields;
    }

    const campaignConversations = await agent.CampaignConversations.get(
      fetchingConfigs
    );

    runInAction(() => {
      if (campaignConversations?.conversations) {
        this.currentConversations = campaignConversations?.conversations.map(
          (conversation) => new CampaignConversation(conversation)
        );
        this.conversationCount = campaignConversations.conversation_count;
        // Cache the conversations, so we don't have to fetch again when visiting the same page
        this.conversationsPerPage[this.currentPageNumber] =
          this.currentConversations;

        if (firstFetch) {
          this.kpiFilterOptions = campaignConversations.kpi_options;
          this.statusFilterOptions = campaignConversations.status_options;
          this.userDataOptions = campaignConversations.user_data_options.filter(
            (option) => option !== 'vacancy_title'
          );
          if (!campaignConversations.min_date) {
            campaignConversations.min_date = lastMonth;
          }
          const minDateRange = dayjs(campaignConversations?.min_date);
          [this.minDay, this.minMonth, this.minYear] =
            CampaignDetail.extractDate(minDateRange);
          [this.startYear, this.startMonth, this.startDay] =
            CampaignDetail.extractDate(minDateRange);

          this.selectedAdditionalFields = JSON.parse(
            decodeURIComponent(
              Cookies.get(this.campaign?.id || this.campaignFieldsStorageKey) ||
                '[]'
            )
          );
        }
      }
      this.isLoading = false;
    });
    return campaignConversations;
  }

  async getAutomatchConversations() {
    runInAction(() => {
      this.isLoading = true;
    });
    const firstFetch = !this.currentConversations;

    const automatchConverstaion =
      await agent.CampaignConversations.getAutomatchConversations({
        automationId: this.campaign?.id,
        // Counting starts at 0 for ASHL
        page: firstFetch ? 0 : this.currentPageNumber - 1,
        pageSize: this.pageSize,
      });

    if (automatchConverstaion?.targetEntities?.length) {
      runInAction(() => {
        this.userDataOptions = automatchConverstaion?.userDataOptions.filter(
          (option) => option !== 'vacancy_title'
        );
        this.currentConversations = automatchConverstaion?.targetEntities?.map(
          ({
            invocationId,
            actionStatus,
            displayInfo,
            lastActivityAt,
            targetEntityId,
            actionType,
          }) => {
            const parsedDisplayInfo = JSON.parse(displayInfo);

            const conversationData = {
              status: actionStatus,
              type: 'automatch',
              actionType,
              userFullName: parsedDisplayInfo.name,
              targetEntityId,
            };
            Object.keys(parsedDisplayInfo).forEach((key) => {
              conversationData[key] = parsedDisplayInfo[key];
            });

            const newCampaignConversationData = {
              id: invocationId,
              status: actionStatus,
              conversation_start: lastActivityAt,
              kpis: [],
              conversation_data: conversationData,
              campaign_id: this.campaign?.id,
            };

            return new CampaignConversation({
              ...newCampaignConversationData,
              source: CampaignConversationSource.ASHL,
            });
          }
        );

        this.conversationCount = automatchConverstaion.totalNumberOfResults;
      });
    }
    runInAction(() => {
      this.isLoading = false;
    });
  }

  /**
   * Handle changes in the filter.
   *
   * @param filters Object containing all the filter values
   * @param sorter
   */
  handleFilterChange(filters, sorter, selectedCampaign) {
    const { statusFilter, kpiFilter, phoneNumberFilter, campaignIds } = filters;
    const { order, columnKey } = sorter;

    // Remove the normal filter values from the filters object.
    // This way we are left with the user data filters
    delete filters.statusFilter;
    delete filters.kpiFilter;
    delete filters.phoneNumberFilter;
    delete filters.campaignIds;

    let campaignIdsFilter = campaignIds;

    if (!campaignIdsFilter) {
      campaignIdsFilter = [this.campaign?.id];
    }

    runInAction(() => {
      const encodedValue = Cookies.get(
        this.campaign?.id || this.campaignFieldsStorageKey
      );
      const decodedValue = decodeURIComponent(encodedValue);

      // Set the kpiFilter and statusFilter
      this.kpiFilter = kpiFilter;
      this.statusFilter = statusFilter;

      // Make sure the filter values are present in the options. This way we avoid 'silent' filters. Where the filter,
      // value is not visible but it is still being used.
      if (statusFilter) {
        this.statusFilter = statusFilter.filter((status) =>
          Object.values(this.statusFilterOptions).includes(status)
        );
      }
      if (kpiFilter) {
        this.kpiFilter = kpiFilter.filter((kpi) =>
          this.kpiFilterOptions.includes(kpi)
        );
      }

      this.phoneNumberFilter = phoneNumberFilter ? phoneNumberFilter[0] : null;
      this.sortDirection = order === 'ascend' ? 1 : 0;
      this.sortField = columnKey;
      this.userDataFilter = filters || null;
      this.automationNameFilter = campaignIdsFilter
        ? selectedCampaign?.name
        : '';
      this.selectedCampaignIds = campaignIdsFilter;
      this.selectedAdditionalFields = JSON.parse(
        decodedValue === 'undefined' ? '[]' : decodedValue
      );
      // Reset the cached pages
      this.conversationsPerPage = {};

      // Jump back to page 1
      this.currentPageNumber = 1;
    });

    // Fetch based on the filters.
    this.fetchCampaignConversations({ campaignIds: campaignIdsFilter });
  }

  /**
   * Handle changes in the pagination
   *
   * @param page      The new page number
   * @param pageSize  The new page size
   */
  handlePaginationChange(page, pageSize, isCampaignTypeAutomation) {
    runInAction(() => {
      if (pageSize !== this.pageSize) {
        // Page size change, therefore we need to change the cache
        this.conversationsPerPage = {};

        // Move back to page one after page size changed.
        this.currentPageNumber = 1;
      }

      // Update the pageSize and pageNumber
      this.pageSize = pageSize;
      this.currentPageNumber = page;
    });

    if (
      Object.keys(this.conversationsPerPage).includes(
        this.currentPageNumber.toString()
      )
    ) {
      runInAction(() => {
        this.currentConversations =
          this.conversationsPerPage[this.currentPageNumber];
      });
      return;
    }

    if (isCampaignTypeAutomation) {
      this.getAutomatchConversations();
    } else {
      // Fetch the selected page with the selected pageSize
      this.fetchCampaignConversations({
        campaignIds: this.selectedCampaignIds,
      });
    }
  }

  async deleteCampaignConversation(conversationId) {
    this.isLoading = true;
    const response = await agent.CampaignConversations.delete(conversationId);
    if (response.data.deleteCampaignConversation.ok) {
      await this.fetchCampaignConversations({
        campaignIds: this.selectedCampaignIds,
      });
    }
  }

  /**
   * Get the selected period (default to last 30 days)
   * @returns {Array} range
   * @returns {Object} range[0] - Date object (start date)
   * @returns {Object} range[1] - Date object (End date)
   */
  get defaultRange() {
    return [this.startDate, dayjs()];
  }

  /**
   * Getter for the startDate. This will combine the startYear, startMonth and startDay to one date.
   *
   * @returns {dayjs.Date} The full start date.
   */
  get startDate() {
    return CampaignDetail.compressDate(
      this.startYear,
      this.startMonth,
      this.startDay
    );
  }

  /**
   * Getter for the endDate. This will combine the endYear, endMonth and endDay to one date.
   *
   * @returns {dayjs.Date} The full end date.
   */
  get endDate() {
    return CampaignDetail.compressDate(
      this.endYear,
      this.endMonth,
      this.endDay,
      23,
      59,
      59
    );
  }

  /**
   * Getter for the minDate. This will combine the minYear, minMonth and minDay to one date.
   *
   * @returns {dayjs.Date} The full min date.
   */
  get minDate() {
    return CampaignDetail.compressDate(
      this.minYear,
      this.minMonth,
      this.minDay
    );
  }

  /**
   * Extracts the year, month and date (day in the month) from a dayjs object
   *
   * @param date The date from which we want to extract the year, month and date.
   * @returns {[int, int, int]} The numeric year, month and date (day in the month).
   */
  static extractDate(date) {
    return [date.year(), date.month(), date.date()];
  }

  /**
   * Compress a year, month, day ect.. to one date object.
   *
   * @param year
   * @param month
   * @param day
   * @param hour
   * @param minute
   * @param second
   * @returns {dayjs.Date} Moment object of the provided values.
   */
  static compressDate(year, month, day, hour = 0, minute = 0, second = 0) {
    return dayjs(new Date(year, month, day, hour, minute, second));
  }

  /**
   * Updates the date after selecting it from the top-bar.
   *
   * @param startDate conversationStart must be larger or equal to this value.
   * @param endDate   conversationStart must be smaller or equal to this value.
   */
  async updateDate(startDate, endDate) {
    const [startYear, startMonth, startDay] =
      CampaignDetail.extractDate(startDate);
    const [endYear, endMonth, endDay] = CampaignDetail.extractDate(endDate);

    runInAction(() => {
      if (this.startDate < this.minDate) {
        this.startYear = this.minYear;
        this.startMonth = this.minMonth;
        this.startDay = this.minDay;
      } else {
        this.startYear = startYear;
        this.startMonth = startMonth;
        this.startDay = startDay;
      }

      this.endYear = endYear;
      this.endMonth = endMonth;
      this.endDay = endDay;

      this.fetchCampaignConversations({
        campaignIds: this.selectedCampaignIds,
      });
    });
  }

  // function to save items to local storage for specific ID
  setSelectedAdditionalFields(selectedAdditionalFields) {
    runInAction(() => {
      if (!this.campaign) {
        this.selectedAdditionalFields = selectedAdditionalFields;
        Cookies.set(
          this.campaignFieldsStorageKey,
          encodeURIComponent(JSON.stringify(this.selectedAdditionalFields)),
          cookiesOption
        );
      } else {
        this.selectedAdditionalFields = selectedAdditionalFields;
        Cookies.set(
          this.campaign?.id,
          encodeURIComponent(JSON.stringify(this.selectedAdditionalFields)),
          cookiesOption
        );
      }
    });
  }

  resetCampaignsDetailTableFilters() {
    this.automationNameFilter = '';
    this.campaign = null;
    this.userDataFilter = null;
    this.selectedCampaignIds = null;
  }
}

decorate(CampaignDetail, {
  automationNameFilter: observable,
  campaign: observable,
  conversationCount: observable,
  conversationsPerPage: observable,
  currentConversations: observable,
  currentPageNumber: observable,
  deleteCampaignConversation: action,
  endDay: observable,
  endMonth: observable,
  endYear: observable,
  fetchAdditionalFields: action,
  fetchCampaignConversations: action,
  fetchKpiFilters: action,
  getAutomatchConversations: action,
  isLoading: observable,
  kpiFilter: observable,
  kpiFilterOptions: observable,
  minDay: observable,
  minMonth: observable,
  minYear: observable,
  phoneNumberFilter: observable,
  resetCampaignsDetailTableFilters: action,
  selectedAdditionalFields: observable,
  selectedCampaignIds: observable,
  setSelectedAdditionalFields: action,
  sortDirection: observable,
  sortField: observable,
  startDay: observable,
  startMonth: observable,
  startYear: observable,
  statusFilter: observable,
  statusFilterOptions: observable,
  timezone: observable,
  updateDate: action,
  userDataFilter: observable,
  userDataOptions: observable,
});

export default CampaignDetail;
