import { find } from 'lodash';
import { optionFragment } from './fragments/builder';
import {
  atsFieldOrderingFragment,
  mappingOptionFragment,
} from './fragments/builder/atsMappingFragments';
import requests from './requests';

const atsFieldMappings = `
 atsFieldMappings {
    id
    type
    dataType
    value
    isDefaultMapping
    prepend
    append
    prependAppendSeparator
    postModifiers
    getModifiers
    atsFieldOrdering: atsfieldorderingSet {
    ...atsFieldOrderingFragment
    }
    mappingOptions {
        id
        option {
          symbol
          id
        }
        atsOptions{
           value
           humanReadableName
           id
        }
    }
    scalarName {
       id
       name
      }
    }
    optionAtsFieldMappings {
      id
      type
      dataType
      isDefaultMapping
      prepend
      append
      prependAppendSeparator
      postModifiers
      mappingOptions {
        id
        option{
          id
          symbol
        }
        atsOptions{
          value
          humanReadableName
          id
        }
      }
      atsFieldOrdering: atsfieldorderingSet {
      ...atsFieldOrderingFragment
      }
    }
    scalarName {
      id
      name
    }
`;

const getStateQuery = `
query GetStateQuery($id: ID!) {
  state(id: $id) {
    ${atsFieldMappings}
    id
    name
    cancelFollowUp
    treeViewPositionX
    treeViewPositionY
    selectedDivergentOption {
      ...option
    }
    divergentOptions {
      ...option
    }
    scalarName {
      id
      name
    }
    defaultOption {
      ...option
    }
    options {
      ...option
    }
    divergentOptions {
      ...option
    }
  }
}
${optionFragment}
`;

const syncOptionsMutation = `
mutation SyncOptions($stateId: ID!) {
  syncOptions(stateId: $stateId) {
    ok
    state {
      defaultOption {
        id
      }
      options {
        ...option
      }
    }
  }
}
${optionFragment}
`;

const mutationQuery = `
  mutation StateMutation($data: StateUpdateGenericType!) {
    stateMutation(newState: $data) {
      state {
        ${atsFieldMappings}
        id
        name
        draft
        cancelFollowUp
        isSkippable
        alwaysStore
        treeViewPositionX
        treeViewPositionY
        answerRequired
        scalarName {
          id
          name
        }
        selectedDivergentOption {
          id
        }
        replyType
        replyProcessorKey
        processors: stateProcessor {
          id
          icon
          priority
          category
          description
          humanReadableName
          name
          type
          processorType
          isImportantGoal
          googleAnalyticsEvent {
            id
            eventLabel
            eventAction
            systemName
          }
          processorDependencies {
            customProcessor {
              modelName
              id
            }
          }
          finalGoalCampaigns {
            id
            name
          }
        }
        validator {
          id
          name
        }
      }
      ok
    }
  }
   ${atsFieldOrderingFragment}
`;

const createAtsOption = `
  mutation CreateAtsOption($newAtsOption: AtsOptionCreateGenericType!) {
    createAtsOption(newAtsoption: $newAtsOption) {
      ok
      atsoption {
        id
        value
        humanReadableName
      }
    }
  }
  `;

const createAtsMappingOption = `
  mutation CreateAtsMappingOption($newAtsmappingoption: AtsMappingOptionCreateGenericType!) {
    createAtsMappingOption(newAtsmappingoption: $newAtsmappingoption) {
      ok
      atsmappingoption {
        id
        option {
          id
          symbol
        }
        atsOptions {
          id
          humanReadableName
          value
        }
      }
    }
  }

  `;

const updateAtsMappingOption = `
mutation UpdateAtsMappingOption($newAtsmappingoption: AtsMappingOptionUpdateGenericType!) {
    updateAtsMappingOption(newAtsmappingoption: $newAtsmappingoption) {
      ok
      atsmappingoption {
        ...mappingOption
      }
    }
  }
  ${mappingOptionFragment}
  `;

const deleteAtsOption = `
  mutation DeleteAtsOption($id: ID!) {
    deleteAtsOption(id: $id) {
      ok
      atsoption {
        id
        value
        humanReadableName
      }
    }
  }
  `;

const deleteAtsMappingOption = `
  mutation DeleteAtsMappingOption($id: ID!) {
    deleteAtsMappingOption(id: $id) {
      ok
      atsmappingoption {
        id
        option {
          id
          symbol
        }
        atsOptions {
          id
          humanReadableName
          value
        }
      }
    }
  }
  `;

const removeDefaultOptionFromOptions = (defaultOption, options) =>
  options.filter((option) => defaultOption.id !== option.id);

const setupNextState = (option) => {
  if (!option.nextState && option.nextStateMachine) {
    return { ...option, nextState: option.nextStateMachine.name };
  }
  return option;
};

// Removes the empty option label that is created when creating the default option
const removeEmptyOptionLabelFromDefaultOption = (
  actionWithOptions,
  defaultOption
) => {
  const { optionlabelSet } = actionWithOptions;
  const optionLabelToRemove = find(optionlabelSet, {
    option: { id: defaultOption.id },
  });
  if (optionLabelToRemove) {
    return optionlabelSet.filter(
      (optionLabel) => optionLabel.id !== optionLabelToRemove.id
    );
  }
  return optionlabelSet;
};

const State = {
  get: async (id) => {
    const state = await requests.gql.chatserver.query(getStateQuery, { id });
    const modifiedState = { ...state.data.state };
    modifiedState.defaultOption = modifiedState.defaultOption
      ? setupNextState(modifiedState.defaultOption)
      : null;
    modifiedState.options = modifiedState.options.map(setupNextState);
    if (modifiedState.defaultOption) {
      modifiedState.options = removeDefaultOptionFromOptions(
        modifiedState.defaultOption,
        modifiedState.options
      );

      const actionThatContainsActionWithOptions = find(
        modifiedState.defaultOption.actions,
        (action) => action.action.actionWithOptions
      );
      if (actionThatContainsActionWithOptions) {
        const filteredOptionlabelSet = removeEmptyOptionLabelFromDefaultOption(
          actionThatContainsActionWithOptions.action.actionWithOptions,
          modifiedState.defaultOption
        );
        actionThatContainsActionWithOptions.action.actionWithOptions.optionlabelSet =
          filteredOptionlabelSet;
      }
    }

    return modifiedState;
  },
  update: async (data) => requests.gql.chatserver.mutate(mutationQuery, data),
  getResponses: async (stateId, chatbotId) => {
    const response = await requests.get('/api/v1/freetext-messages/', {
      state_id: stateId,
      project_id: chatbotId,
    });
    return response;
  },
  syncOptions: async (stateId) =>
    requests.gql.chatserver.mutate(syncOptionsMutation, { stateId }),
  createAtsOption: async (newAtsOption) =>
    requests.gql.chatserver.mutate(createAtsOption, newAtsOption),
  createAtsMappingOption: async (newAtsMappingOption) =>
    requests.gql.chatserver.mutate(createAtsMappingOption, newAtsMappingOption),
  updateAtsMappingOption: async (newAtsMappingOption) =>
    requests.gql.chatserver.mutate(updateAtsMappingOption, newAtsMappingOption),
  deleteAtsOption: async (id) =>
    requests.gql.chatserver.mutate(deleteAtsOption, { id }),
  deleteAtsMappingOption: async (id) =>
    requests.gql.chatserver.mutate(deleteAtsMappingOption, { id }),
};

export default State;
