import { decorate, observable, action } from 'mobx';
import BaseStore from 'stores/BaseStore';

class ActionResult extends BaseStore {
  id;
  actionData;
  actionSteps;
  campaignConversationId;
  campaignId;
  clientId;
  clientStateId;
  errorReason;
  executionTime;
  externalId;
  processorId;
  result;
  successful;
  tenant;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.id = params?.id;
    this.actionData = params?.actionData;
    this.actionSteps = params?.actionSteps;
    this.campaignConversationId = params?.campaignConversationId;
    this.campaignId = params?.campaignId;
    this.clientId = params?.clientId;
    this.clientStateId = params?.clientStateId;
    this.errorReason = params?.errorReason;
    this.executionTime = params?.executionTime;
    this.externalId = params?.externalId;
    this.processorId = params?.processorId;
    this.result = params?.result;
    this.successful = params?.successful;
    this.tenant = params?.tenant;
  }
}

decorate(ActionResult, {
  id: observable,
  actionData: observable,
  actionSteps: observable,
  campaignConversationId: observable,
  campaignId: observable,
  clientId: observable,
  clientStateId: observable,
  errorReason: observable,
  executionTime: observable,
  externalId: observable,
  processorId: observable,
  result: observable,
  successful: observable,
  tenant: observable,
  setup: action,
});

export default ActionResult;
