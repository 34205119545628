import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { message } from 'antd'
import { injectIntl } from 'react-intl'

@inject('commonStore')
@injectIntl
@observer
class NoInternetAlert extends Component {
  render() {
    const {
      intl: { formatMessage },
      commonStore: { isOnline },
    } = this.props
    const content = formatMessage({ id: 'components/NoInternetAlert/message' })
    if (!isOnline) {
      message.warning(content, 0)
      return <div />
    }
    message.destroy()
    return <div />
  }
}

export default NoInternetAlert
