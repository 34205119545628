import BaseStore from 'stores/BaseStore';
import agent from 'agent';
import { action, decorate, observable, runInAction } from 'mobx';
import WhatsAppVariation from './WhatsAppVariation.store';

class WhatsAppVariations extends BaseStore {
  variations = [];
  isLoading;

  async fetch() {
    this.isLoading = true;
    const variations = await agent.WhatsAppVariation.list();
    runInAction(() => {
      if (variations && variations.length > 0) {
        this.variations = variations.map(
          (variation) => new WhatsAppVariation(variation)
        );
      }
      this.isLoading = false;
    });
  }

  async create(newVariation) {
    this.isLoading = true;
    const result = await agent.WhatsAppVariation.create(newVariation);
    const whatsAppVariation = new WhatsAppVariation(result?.whatsappvariation);
    runInAction(() => {
      this.variations.push(whatsAppVariation);
      this.isLoading = false;
    });
    return result;
  }

  async update(newVariation) {
    this.isLoading = true;
    const result = await agent.WhatsAppVariation.update(newVariation);
    runInAction(() => {
      const variationIdx = this.variations.findIndex(
        (variation) => variation.id === newVariation.id
      );
      this.variations[variationIdx] = newVariation;
      this.isLoading = false;
    });
    return result;
  }

  async delete(id) {
    this.isLoading = true;
    await agent.WhatsAppVariation.delete(id);
    runInAction(() => {
      this.variations = this.variations.filter(
        (variation) => variation.id !== id
      );
      this.isLoading = false;
    });
  }
}

decorate(WhatsAppVariations, {
  variations: observable,
  isLoading: observable,
  fetch: action,
  create: action,
  update: action,
  delete: action,
});

export default WhatsAppVariations;
