import { action, decorate, observable, runInAction, toJS } from 'mobx'
import _ from 'lodash'
import agent from 'agent'
import BaseStore from 'stores/BaseStore'

class GroupAppointment extends BaseStore {
  id
  type
  maxNrAttendees
  planRange
  offsetDays
  maxNrEventToShow
  calendarAuthHandler
  calendarAuthToken
  calendarIsAuthenticated
  filterMap

  constructor(params) {
    super()
    this.setup(params)
    this.save = _.debounce(this.save, 1300)
  }

  setup({
    id,
    type,
    maxNrAttendees,
    planRange,
    offsetDays,
    calendarAuthHandler,
    calendarAuthToken,
    maxNrEventToShow,
    filterMap,
  }) {
    this.id = id
    this.type = type
    this.maxNrAttendees = maxNrAttendees
    this.planRange = planRange
    this.offsetDays = offsetDays
    this.maxNrEventToShow = maxNrEventToShow
    this.calendarAuthHandler = calendarAuthHandler
    this.calendarAuthToken = calendarAuthToken
    this.calendarIsAuthenticated =
      calendarAuthToken !== null && calendarAuthToken !== ''
    this.filterMap = toJS(JSON.parse(filterMap))
  }

  async save() {
    const data = {
      id: this.id,
      type: this.type,
      maxNrAttendees: this.maxNrAttendees,
      planRange: this.planRange,
      offsetDays: this.offsetDays,
      maxNrEventToShow: this.maxNrEventToShow,
      calendarAuthHandler: this.calendarAuthHandler,
      calendarAuthToken: this.calendarAuthToken,
      filterMap: JSON.stringify(this.filterMap),
    }

    await agent.GroupAppointment.update(data)
  }

  static async create(data) {
    const groupAppointment = await agent.GroupAppointment.create(data)
    return new GroupAppointment(groupAppointment)
  }

  static async delete(id) {
    await agent.GroupAppointment.delete(id)
  }

  async updateCalendarToken(token) {
    const result = await agent.GroupAppointment.updateCalendarToken(
      this.id,
      token,
    )
    if (result) {
      runInAction(() => {
        this.calendarIsAuthenticated = true
      })
    }
    return result
  }

  deleteFilterMap(key) {
    runInAction(() => {
      delete this.filterMap[key]
    })
  }

  editFilterMap(oldKey, newKey, newValue) {
    runInAction(() => {
      if (oldKey !== newKey) {
        Object.defineProperty(
          this.filterMap,
          newKey,
          Object.getOwnPropertyDescriptor(this.filterMap, oldKey),
        )
        delete this.filterMap[oldKey]
      }
      this.filterMap[newKey] = newValue
    })
  }
}

decorate(GroupAppointment, {
  id: observable,
  type: observable,
  maxNrAttendees: observable,
  planRange: observable,
  offsetDays: observable,
  calendarAuthHandler: observable,
  calendarAuthToken: observable,
  calendarIsAuthenticated: observable,
  maxNrEventToShow: observable,
  filterMap: observable,
  setup: action,
  save: action,
  create: action,
  delete: action,
})

export default GroupAppointment
