import requests from './requests';

const deleteMutation = `
    mutation DeleteStateGroup($id: ID!) {
            deleteStateGroupMutation(id: $id) {
            ok
        }
    }
`;

const mutationQuery = `
mutation UpdateStateGroup($data: StateGroupUpdateGenericType!) {
    updateStateGroupMutation(newStategroup: $data) {
      stategroup {
        id
        name
        initialState {
          id
          name
        }
        states {
          id
          name
          draft
          isSkippable
          alwaysStore
          answerRequired
          scalarName {
            id
            name
          }
          replyType
          replyProcessorKey
          processors: stateProcessor {
            id
            icon
            priority
            category
            description
            humanReadableName
            name
            type
            processorType
            isImportantGoal
            processorDependencies {
              customProcessor {
                modelName
                id
              }
            }
            finalGoalCampaigns {
              id
              name
            }
          }
          validator {
            id
            name
          }          
        }
      }
      ok
    }
  }
`;

const getListByFlowIdQuery = `
query GetStateGroupsByFlow($id: ID!) {
  stateMachine(id: $id) {
    ...stateMachine
  }
}

fragment stateMachine on StateMachineNode {
  name
  stateGroups: stategroupSet {
    id
    name
    position
    initialState {
      id
      name
      draft
      isSkippable
      alwaysStore
      answerRequired
      ...processor
      scalarName {
        id
        name
      }
    }
    states {
      id
      name
      draft
      isSkippable
      alwaysStore
      answerRequired
      treeViewPositionX
      treeViewPositionY
      scalarName {
        id
        name
      }
      ...processor
    }
  }
}

fragment processor on StateNode {
  replyType
  replyProcessorKey
  processors: stateProcessor {
    id
    icon
    priority
    category
    description
    humanReadableName
    name
    type
    processorType
    isImportantGoal
    googleAnalyticsEvent {
      id
      eventLabel
      eventAction
      systemName
    }
    processorDependencies {
      customProcessor {
        modelName
        id
      }
    }
    finalGoalCampaigns {
      id
      name
    }
  }
  validator {
    id
    name
  }
}
`;

const cloneStateGroup = `
  mutation CloneStateGroup(
  $stateGroupIdentifier:ID!, 
  $originClient:String!, 
  $originStateMachine:ID!, 
  $destinationStateMachine:ID!,
  $newStateGroupName:String
  ) {
    cloneStateGroup(
      stateGroupIdentifier:$stateGroupIdentifier,
      originClient:$originClient,
      originStateMachine:$originStateMachine,
      destinationStateMachine:$destinationStateMachine,
      newStateGroupName:$newStateGroupName
    ) {
      ok
    }
  }
`;

const allQuery = `
query AllStateGroupsWithStates{
  stateGroups: allStateGroups(limit: 1000) {
    id
    name
    initialState {
      id
      name
    }
    flow: stateMachine {
      id
    }
    states {
      id
      name
    }
  }
}
`;

const StateGroup = {
  all: async () => requests.gql.chatserver.query(allQuery),
  delete: async (id) => requests.gql.chatserver.mutate(deleteMutation, { id }),
  update: (data) => requests.gql.chatserver.mutate(mutationQuery, data),
  getListByFlowId: async (id) =>
    requests.gql.chatserver.query(getListByFlowIdQuery, { id }),
  clone: async (data) => requests.gql.chatserver.mutate(cloneStateGroup, data),
};

export default StateGroup;
