import { decorate, observable, runInAction } from 'mobx';
import BaseStore from './BaseStore';
import WizardStepStore from './WizardStepStore';
import agent from '../agent';

class ProcessStore extends BaseStore {
  id = '';
  order = 0;
  finishedTimestamp = null;
  isFinished = false;
  title = '';
  wizardSteps = [];
  description = '';

  constructor(args) {
    super();
    this.id = args.id;
    this.order = args.order;
    this.finishedTimestamp = args.finishedTimestamp;
    this.isFinished = args.isFinished;
    this.title = args.title;
    this.description = args.description;
    const sortedWizardSteps = args.wizardSteps.sort(
      (a, b) => a.order - b.order
    );
    this.wizardSteps = sortedWizardSteps.map(
      (step) => new WizardStepStore(step)
    );
  }

  async save() {
    const data = {
      newProcess: {
        id: this.id,
        isFinished: this.isFinished,
      },
    };

    await agent.Wizard.updateProcess(data);
  }

  setIsFinished(value) {
    runInAction(() => {
      this.isFinished = value;
    });
  }
}

decorate(ProcessStore, {
  id: observable,
  order: observable,
  finishedTimestamp: observable,
  isFinished: observable,
  title: observable,
  description: observable,
});

export default ProcessStore;
