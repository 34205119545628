import { decorate, observable } from 'mobx';
import BaseStore from './BaseStore';

class AtsOptionStore extends BaseStore {
  humanReadableName;
  value;
  id;

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    this.humanReadableName = args.humanReadableName;
    this.value = args.value;
    this.id = args.id;
  }
}

decorate(AtsOptionStore, {
  humanReadableName: observable,
  value: observable,
  id: observable,
});

export default AtsOptionStore;
