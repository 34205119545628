import requests from './requests'

const allGoogleAnalyticsEvents = `
query AllGoogleAnalyticsEvents {
  allGoogleAnalyticsEvents {
    id
    eventLabel
    eventAction
    systemName
  }
}
`

const createGoogleAnalyticsEvent = `
mutation CreateGaEvent($newGoogleanalyticsevent: GoogleAnalyticsEventCreateGenericType!) {
  createGoogleAnalyticsEvent(newGoogleanalyticsevent: $newGoogleanalyticsevent) {
    ok
    googleanalyticsevent {
      id
      systemName
      eventAction
      eventLabel
    }
  }
}
`

const updateGoogleAnalyticsEvent = `
mutation UpdateGoogleAnalyticsEvent($newGoogleanalyticsevent: GoogleAnalyticsEventUpdateGenericType!) {
  updateGoogleAnalyticsEvent(newGoogleanalyticsevent:$newGoogleanalyticsevent){
    ok
    googleanalyticsevent {
      id
      eventLabel
      eventAction
      systemName
    }
  }
}
`

const deleteGoogleAnalyticsEvent = `
mutation DeleteGaEvent($id: ID!){
  deleteGoogleAnalyticsEvent(id: $id) {
    ok
  }
}
`

const GoogleAnalyticsEvent = {
  all: async () => requests.gql.chatserver.query(allGoogleAnalyticsEvents),
  create: async data =>
    requests.gql.chatserver.mutate(createGoogleAnalyticsEvent, data),
  update: async data =>
    requests.gql.chatserver.mutate(updateGoogleAnalyticsEvent, data),
  delete: async id =>
    requests.gql.chatserver.mutate(deleteGoogleAnalyticsEvent, { id }),
}

export default GoogleAnalyticsEvent
