import Cookies from 'js-cookie';
import { LANGUAGE_STORAGE_KEY, cookiesOption } from '../constants';
import { languageStore } from '../stores';
import en from './en.json';
import languages from './index';

/**
 * This is a hack to use a react-int's formatMessage-like, so it will be extracted
 * @param {Object} param
 * @param {String} param.id The id in the source file
 * @param {String} param.defaultMessage The default message to return when the id is not present in the languages files
 * @returns {String} message, translated to the user language (set in store)
 * @throws {Error} when not providing none of the id nor the defaultMessage
 */
function fakeFormatMessage({ id, messageContent }) {
  let message;

  if (id || messageContent) {
    const selectedLang = languages[languageStore.language || 'nl-NL'];
    if (selectedLang && id) {
      if (selectedLang[id]) {
        message = selectedLang[id]; // The id is present in the selected Language file
      } else if (id && en[id]) {
        message = en[id]; // Fall-back language (the id in both the function params and the en.json file)
      }
    }
    if (!message && messageContent) {
      message = messageContent; // Get the defaultMessage parameter if it's present
    }
  }

  if (!message) {
    throw Error(
      'Please provide a defaultMessage or an id pointing to the message'
    );
  }

  return message;
}

/**
 * Get selected language key or fallback to 'nl'
 */
function getSelectedLanguage() {
  return Cookies.get(LANGUAGE_STORAGE_KEY);
}

function setSelectedLanguage(lang) {
  Cookies.set(LANGUAGE_STORAGE_KEY, lang, cookiesOption);
}

export { fakeFormatMessage, getSelectedLanguage, setSelectedLanguage };
