import requests from './requests'

const query = `
mutation ActionWithOptionsUpdate($data: ActionWithOptionsUpdateGenericType!) {
    actionWithOptionsUpdate(newActionwithoptions: $data) {
        ok
        actionwithoptions {
            id
            content: message
            optionlabelSet {
              id
              label
              option {
                id
                symbol
              }
            }
        }
    }
}
`

const updateOptionOrderQuery = `
  mutation UpdateOptionOrdering($optionIDs: [ID], $actionID: ID) {
    updateOptionLabelOrdering(optionIDs: $optionIDs, actionWithOptionId: $actionID) {
      ok
    }
  }
`

const ActionWithOptions = {
  update: data => requests.gql.chatserver.mutate(query, data),
  updateOptionOrder: data =>
    requests.gql.chatserver.mutate(updateOptionOrderQuery, data),
}

export default ActionWithOptions
