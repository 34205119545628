import { decorate, observable, action, runInAction } from 'mobx'
import BaseStore from 'stores/BaseStore'

class CampaignAnalytics extends BaseStore {
  numberOfConversationsFinished = 0
  numberOfErrors = 0
  numberOfMessagesDelivered = 0
  numberOfMessagesRead = 0
  numberOfMessagesSent = 0
  numberOfReplies = 0
  numberOfTargets = 0
  numberOfImportantGoalsReached = 0

  constructor(params) {
    super()
    this.setup(params)
  }

  setup(params) {
    this.numberOfConversationsFinished = params.number_of_conversations_finished
    this.numberOfErrors = params.number_of_errors
    this.numberOfMessagesDelivered = params.number_of_messages_delivered
    this.numberOfMessagesRead = params.number_of_messages_read
    this.numberOfMessagesSent = params.number_of_messages_sent
    this.numberOfReplies = params.number_of_replies
    this.numberOfTargets = params.number_of_targets
    this.numberOfImportantGoalsReached =
      params.number_of_important_goals_reached
  }
}

decorate(CampaignAnalytics, {
  numberOfConversationsFinished: observable,
  numberOfErrors: observable,
  numberOfMessagesDelivered: observable,
  numberOfMessagesRead: observable,
  numberOfMessagesSent: observable,
  numberOfReplies: observable,
  numberOfTargets: observable,
  numberOfImportantGoalsReached: observable,
})

export default CampaignAnalytics
