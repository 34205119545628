import { decorate, observable, action } from 'mobx'
import _ from 'lodash'
import agent from 'agent'
import BaseStore from './BaseStore'

class Keyword extends BaseStore {
  id
  text
  constructor(params) {
    super()
    this.setup(params)
    this.save = _.debounce(this.save, 1500)
  }

  setup({ id, text }) {
    this.id = id
    this.text = text
  }

  async save() {
    const data = {
      id: this.id,
      text: this.text,
    }
    await agent.Keyword.update(data)
  }

  static async create(text, intentId) {
    const keyword = await agent.Keyword.create({ text, intent: intentId })
    return new Keyword(keyword)
  }

  static async delete(id) {
    await agent.Keyword.delete(id)
  }
}

decorate(Keyword, {
  id: observable,
  text: observable,
  setup: action,
  save: action,
})

export default Keyword
