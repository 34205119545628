import requests from '../requests'

const mutation = `
mutation UpdateGeoLocationMessage($data: GeoLocationMessageUpdateGenericType!) {
    updateGeoLocationMessageMutation(newGeolocationmessage: $data) {
        geolocationmessage {
            id
            lat
            lng
        }
      ok
    }
}  
`

const GeoLocationMessage = {
  update: data => requests.gql.chatserver.mutate(mutation, data),
}

export default GeoLocationMessage
