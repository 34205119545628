import { decorate, observable, runInAction, action } from 'mobx'
import BaseStore from './BaseStore'
import agent from '../agent'
import Setting from './ChatbotSettingStore/SettingStore'

class WizardStepStore extends BaseStore {
  id = ''
  order = 0
  title = ''
  description = ''
  isFinished = false
  finishedTimestamp = null
  infoUrl = ''
  settings = []
  media = null
  note = ''

  constructor(args) {
    super()
    this.id = args.id
    this.order = args.order
    this.title = args.title
    this.description = args.description
    this.isFinished = args.isFinished
    this.finishedTimestamp = args.finishedTimestamp
    this.infoUrl = args.infoUrl
    this.note = args.note
    this.settings = args.settings.map((setting) => {
      const {
        id,
        chatbot,
        key,
        value,
        settingType,
        adminOnly,
        helpText,
        section,
      } = setting
      return new Setting(
        id,
        chatbot,
        key,
        value,
        settingType,
        adminOnly,
        helpText,
        section,
      )
    })
    this.media = args.media

  }

  async save() {
    const data = {
      newWizardstep: {
        id: this.id,
        isFinished: this.isFinished,
      },
    }
    await agent.Wizard.updateWizardStep(data)
  }

  async saveNote() {
    const data = {
      newWizardstep: {
        id: this.id,
        note: this.note,
      },
    }
    await agent.Wizard.updateWizardStep(data)
  }

  setIsFinished(value) {
    runInAction(() => {
      this.isFinished = value
    })
  }
}

decorate(WizardStepStore, {
  id: observable,
  order: observable,
  title: observable,
  description: observable,
  isFinished: observable,
  finishedTimestamp: observable,
  infoUrl: observable,
  settings: observable,
  media: observable,
  setIsFinished: action,
})

export default WizardStepStore
