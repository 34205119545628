import { decorate, observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import agent from 'agent'
import BaseStore from './BaseStore'

class Block extends BaseStore {
  id
  startTime
  endTime
  day

  constructor(params) {
    super()
    this.setup(params)
    this.save = _.debounce(this.save, 1300)
  }

  setup({ id, startTime, endTime, day }) {
    this.id = id
    this.startTime = startTime
    this.endTime = endTime
    this.day = day.toLowerCase()
  }

  async save() {
    const data = {
      id: this.id,
      startTime: this.startTime,
      endTime: this.endTime,
      day: this.day.toUpperCase(),
    }
    if (this.hasChanged) {
      await agent.Block.update(data)
    } else {
      runInAction(() => {
        this.hasChanged = false
      })
    }
  }

  static async create(data) {
    const block = await agent.Block.create(data)
    return new Block(block)
  }

  static async delete(id) {
    await agent.Block.delete(id)
  }
}

decorate(Block, {
  id: observable,
  startTime: observable,
  endTime: observable,
  day: observable,
  setup: action,
  save: action,
})

export default Block
