import React from 'react'
import styled from 'styled-components'
import { ExclamationCircleOutlined } from '@ant-design/icons'

export const Container = styled.div`
    flex: 1;
    height: 100%
    width: 100%
    display: flex;
    justify-content: center;
    align-items: center;
`

// export const ErrorIcon = styled(props => <Icon type="exclamation-circle" {...props} />)`
export const ErrorIcon = styled(props => (
  <ExclamationCircleOutlined {...props} />
))`
  font-size: 26px;
  color: red;
`

export const DefaultFallback = () => (
  <Container>
    <ErrorIcon />
  </Container>
)
