import requests from './requests';
import { userStore } from '../stores';

const TEMPLATE_CHAT_SERVER_LINK =
  process.env.REACT_APP_TEMPLATE_CHAT_SERVER_LINK;
const TEMPLATE_CHAT_SERVER_TOKEN =
  process.env.REACT_APP_TEMPLATE_CHAT_SERVER_TOKEN;

const getAllProcesorTemplates = `
query GetProcessorTemplates($processor_ProcessorType_In: String, $processor_ProcessorType_NotIn: String, $adminOnly: Boolean) {
  allProcessorTemplates(processor_ProcessorType_In: $processor_ProcessorType_In, processor_ProcessorType_NotIn: $processor_ProcessorType_NotIn, adminOnly: $adminOnly) {
    id
    name
    icon
    description
    processor {
      id
      icon
      priority
      category
      description
      humanReadableName
      name
      type
      processorType
      isImportantGoal
      googleAnalyticsEvent {
        id
        eventLabel
        eventAction
        systemName
      }
      processorDependencies {
        customProcessor {
          modelName
          id
        }
      }
      finalGoalCampaigns {
        id
        name
      }
    }
  }
}
`;

const ProcessorTemplate = {
  all: async (processorTypes, excludeProcessorTypes) =>
    requests.gql.chatserver.query(
      getAllProcesorTemplates,
      {
        processor_ProcessorType_In: processorTypes,
        processor_ProcessorType_NotIn: excludeProcessorTypes,
        adminOnly: userStore.isSuperuser,
      },
      TEMPLATE_CHAT_SERVER_LINK,
      TEMPLATE_CHAT_SERVER_TOKEN
    ),
};

export default ProcessorTemplate;
