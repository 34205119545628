import BaseStore from 'stores/BaseStore';
import { decorate, observable } from 'mobx';

class Notification extends BaseStore {
  id;
  minutes;
  type;

  constructor(args) {
    super();
    this.id = args?.id;
    this.minutes = args?.minutes;
    this.type = args?.type;
  }
}

decorate(Notification, {
  id: observable,
  minutes: observable,
  type: observable,
});

export default Notification;
