import requests from './requests'

const queryGetMappers = `
query GetAllMappers($ids: [ID]) {
  allMappers(ids: $ids) {
    id
    name
    data
  }
}
`

const mutationUpdateMapper = `
  mutation updateMapper($newMapper: MapperUpdateGenericType!) {
    updateMapper(newMapper: $newMapper) {
      ok
    }
  }
`
const queryGenerateBlueprintForStatemachine = `
query generateBlueprintForStatemachine($statemachineId: String) {
  generateBlueprintForStatemachine(statemachineId:$statemachineId)
}
`

const Mapper = {
  all: async (ids) => {
    return requests.gql.chatserver.query(queryGetMappers, {
      ids: ids ? ids : null,
    })
  },
  update: async data => {
    return requests.gql.chatserver.mutate(mutationUpdateMapper, data)
  },
  fetchBluePrint: async statemachineId => {
    return requests.gql.chatserver.query(
      queryGenerateBlueprintForStatemachine,
      { statemachineId },
    )
  },
}

export default Mapper
