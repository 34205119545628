import requests from 'agent/requests';
import _ from 'lodash';
import { TEMPLATE_CHAT_SERVER_LINK } from '../../constants';
import {
  campaignFragment,
  definitionFragment,
  processorFragment,
  templateFragment,
} from './fragments';

const initialCampaignQuery = /* GraphQL */ `
    query Campaigns {
        allCampaigns {
            id
            name
            created
            type
            status
            isSmartInbox
            analyticsDocumentId
            hasActiveTemplate
            followUp {
                id
            }
        }
    }
`;

const listQuery = /* GraphQL */ `
    query Campaigns {
        allCampaigns {
            id
            name
            description
            whitelist
            atsBatchActions {
                id
                entityType {
                    id
                }
                externalId
                name
                notificationBody
                notificationHeader
                url
            }
            analyticsDocumentId
            type
            status
            isSmartInbox
            created
            clientDescription
            clientDescriptionImages
            created
            flow {
                id
                name
                description
            }
            requiredData {
                name
                humanReadableName
                description
            }
            atsFieldMappings {
                id
                scalarName {
                    id
                    name
                }
                atsFieldOrdering: atsfieldorderingSet {
                    atsField {
                        id
                        getPath
                        humanReadableNames {
                            ...HumanReadableNamesFragment
                        }
                        entityType {
                            id
                            name
                            humanReadableNames {
                                ...HumanReadableNamesFragment
                            }
                        }
                    }
                    number
                }
            }
            whatsappDefinition {
                ...definitionFragment
            }
            broadcast {
                ...BroadcastFragment
            }
            onFinishProcessor {
                ...processorFragment
            }
            onFailProcessor {
                ...processorFragment
            }
            whatsappDefinition {
                ...definitionFragment
            }
        }
    }
    ${processorFragment}
    ${definitionFragment()}
`;

const getQuery = /* GraphQL */ `
    query GetCampaign($id: ID!) {
        campaign(id: $id) {
            ...campaign
        }
    }
    ${campaignFragment}
`;

const getCampaignActiveTemplateQuery = /* GraphQL */ `
    query GetCampaign($id: ID!) {
        campaign(id: $id) {
            id
            broadcast {
                id
            }
            whatsappDefinition {
                id
                supportsBatchAction
                templateIdentifier
                activeTemplate {
                    ...templateFragment
                }
            }
        }
    }
    ${templateFragment}
`;

const updateMutation = /* GraphQL */ `
    mutation UpdateCampaign($newCampaign: CampaignUpdateGenericType!) {
        updateCampaign(newCampaign: $newCampaign) {
            ok
            campaign {
                id
                name
                description
                whitelist
                analyticsDocumentId
                type
                status
                isSmartInbox
                created
                clientDescription
                clientDescriptionImages
                smartInboxLabels {
                    id
                    name
                    color
                }
                removeSmartInboxLabels {
                    id
                    name
                    color
                }
                atsBatchActions {
                    id
                }
                flow {
                    id
                    name
                    description
                }
                requiredData {
                    name
                    humanReadableName
                    description
                }
                atsFieldMappings {
                    id
                    atsFieldOrdering: atsfieldorderingSet {
                        atsField {
                            id
                            getPath
                            humanReadableNames {
                                id
                                content
                            }
                            entityType {
                                id
                                name
                                humanReadableNames {
                                    id
                                    content
                                    tags {
                                        kind
                                        content
                                    }
                                }
                            }
                        }
                        number
                    }
                }
                whatsappDefinition {
                    ...definitionFragment
                }
                broadcast {
                    id
                    name
                    type
                    allowedDays
                    interval
                    minExecutionTime
                    maxExecutionTime
                    nextBroadcast
                    approachLimit
                }
                onFinishProcessor {
                    ...processorFragment
                }
                onFailProcessor {
                    ...processorFragment
                }
                followUp {
                    id
                    name
                    allowedDays
                    interval
                    minExecutionTime
                    maxExecutionTime
                    smartInboxLabels {
                        id
                        color
                        name
                    }
                    removeSmartInboxLabels {
                        id
                        color
                        name
                    }
                    flow {
                        id
                        name
                    }
                    campaign {
                        id
                    }
                    onFinishProcessor {
                        ...processorFragment
                    }
                    onFailProcessor {
                        ...processorFragment
                    }
                    whatsappDefinition {
                        ...definitionFragment
                    }
                }
            }
        }
    }
    ${definitionFragment()}
    ${processorFragment}
`;

const syncAutomationRequiredDataMutation = /* GraphQL */ `
    mutation SyncAutomationRequiredDataMutation($campaignIdentifier: ID!) {
        syncAutomationRequiredDataMutation(campaignId: $campaignIdentifier) {
            ok
            campaign {
                id
                requiredData {
                    name
                    humanReadableName
                }
            }
        }
    }
`;

const cloneCampaign = /* GraphQL */ `
    mutation CloneCampaign($campaignId: ID!, $newCampaignName: String) {
        cloneCampaign(campaignId: $campaignId, newCampaignName: $newCampaignName) {
            ok
            errorMessage
            campaign {
                ...campaign
            }
        }
    }
    ${campaignFragment}
`;

const createCampaign = /* GraphQL */ `
    mutation createCampaign($newCampaign: CampaignCreateGenericType!) {
        createCampaign(newCampaign: $newCampaign) {
            ok
            errors {
                field
                messages
            }
            campaign {
                id
                name
                description
                whitelist
                analyticsDocumentId
                type
                status
                isSmartInbox
                created
                clientDescription
                clientDescriptionImages
                smartInboxLabels {
                    id
                    name
                    color
                }
                removeSmartInboxLabels {
                    id
                    name
                    color
                }
                flow {
                    id
                    name
                    description
                }
                requiredData {
                    name
                    humanReadableName
                    description
                }
                atsFieldMappings {
                    id
                    atsFieldOrdering: atsfieldorderingSet {
                        atsField {
                            id
                            getPath
                            humanReadableNames {
                                id
                                content
                            }
                            entityType {
                                id
                                name
                                humanReadableNames {
                                    id
                                    content
                                    tags {
                                        kind
                                        content
                                    }
                                }
                            }
                        }
                        number
                    }
                }
                whatsappDefinition {
                    ...definitionFragment
                }
                broadcast {
                    id
                    name
                    type
                    allowedDays
                    interval
                    minExecutionTime
                    maxExecutionTime
                    nextBroadcast
                    approachLimit
                }
                onFinishProcessor {
                    ...processorFragment
                }
                onFailProcessor {
                    ...processorFragment
                }
                followUp {
                    id
                    name
                    allowedDays
                    interval
                    minExecutionTime
                    maxExecutionTime
                    smartInboxLabels {
                        id
                        color
                        name
                    }
                    removeSmartInboxLabels {
                        id
                        color
                        name
                    }
                    flow {
                        id
                        name
                    }
                    campaign {
                        id
                    }
                    onFinishProcessor {
                        ...processorFragment
                    }
                    onFailProcessor {
                        ...processorFragment
                    }
                    whatsappDefinition {
                        ...definitionFragment
                    }
                }
            }
        }
    }
    ${processorFragment}
    ${definitionFragment()}
`;

const deleteCampaign = /* GraphQL */ `
    mutation DeleteCampaign($id: ID!) {
        deleteCampaign(id: $id) {
            ok
        }
    }
`;

const getAutomationTemplate = `
query GetAutomationTemplate {
  allAutomationTemplates(isActive:true) {
    id
    name {
      content
      tags {
        content
        kind
      }
    }
    description {
      content
      tags {
        content
        kind
      }
    }
    steps {
      id
      icon
      order
      translation {
        content
        tags {
          content
          kind
        }
      }
    }
    optionalSteps {
      id
      icon
      order
      translation {
        content
        tags {
          content
          kind
        }
      }
    }
    tags
    campaign {
      id
      type
    }
  }
}
`;

const cloneConfiguration = /* GraphQL */ `
    mutation CloneConfiguration($campaignId: ID!) {
        cloneConfiguration(campaignId: $campaignId) {
            ok
            errorMessage
            campaign {
                ...campaign
            }
        }
    }
    ${campaignFragment}
`;

const toggleConfigurationSchedule = /* GraphQL */ `
    mutation ToggleConfigurationSchedule($campaignId: ID!, $enabled: Boolean!) {
        toggleConfigurationSchedule(campaignId: $campaignId, enabled: $enabled) {
            ok
            errorMessage
            campaign {
                ...campaign
            }
        }
    }
    ${campaignFragment}
`;

const PollAutomatchPublishStatusMutation = /* GraphQL */ `
    mutation PollAutomatchPublishStatusMutation($campaignId: ID!) {
        pollAutomatchPublishStatus(campaignId: $campaignId) {
            ok
            errorMessage
            campaign {
                ...campaign
            }
            pollResult
        }
    }
    ${campaignFragment}
`;

const CampaignAgent = {
  list: async () => {
    const res = await requests.gql.chatserver.query(initialCampaignQuery);
    return _.get(res, 'data.allCampaigns');
  },
  listTemplates: async (templateEnv = null) => {
    const res = await requests.gql.chatserver.query(
      listQuery,
      {},
      templateEnv
        ? `https://${templateEnv}.joboti.com/graphql`
        : TEMPLATE_CHAT_SERVER_LINK
    );
    return _.get(res, 'data.allCampaigns');
  },
  get: async (campaignId) => {
    const res = await requests.gql.chatserver.query(getQuery, {
      id: campaignId,
    });
    return _.get(res, 'data.campaign');
  },
  create: async (newCampaign) => {
    const res = await requests.gql.chatserver.mutate(createCampaign, {
      newCampaign,
    });
    return _.get(res, 'data.createCampaign.campaign');
  },
  delete: async (id) => {
    const res = await requests.gql.chatserver.mutate(deleteCampaign, {
      id,
    });
    return _.get(res, 'data.deleteCampaign');
  },
  getTriggerCampaignData: async (campaignId) => {
    const res = await requests.gql.chatserver.query(
      getCampaignActiveTemplateQuery,
      {
        id: campaignId,
      }
    );
    return _.get(res, 'data.campaign');
  },
  updateCampaign: async (newCampaign) => {
    const res = await requests.gql.chatserver.mutate(updateMutation, {
      newCampaign,
    });
    return res;
  },
  sendWhatsApp: async (data) => {
    // Intercept and attempt to add token to request
    const whatsAppToken = process.env.REACT_APP_WHATSAPP_ENDPOINT_TOKEN;
    if (!whatsAppToken) {
      throw new Error('Could not retrieve WhatsApp API token.');
    }
    data.token = whatsAppToken;
    const response = await requests.post('/api/v1/whatsapp/', data);
    if (!response.message || response.message !== 'OK') {
      throw new Error('Response indicates message was not sent.');
    }
    return response;
  },
  syncAutomationRequiredDataMutation: async (campaignId) => {
    const res = await requests.gql.chatserver.mutate(
      syncAutomationRequiredDataMutation,
      {
        campaignIdentifier: campaignId,
      }
    );
    return _.get(
      res,
      'data.syncAutomationRequiredDataMutation.campaign.requiredData'
    );
  },
  clone: async (id, newName) => {
    const res = await requests.gql.chatserver.mutate(cloneCampaign, {
      campaignId: id,
      newCampaignName: newName,
    });
    return _.get(res, 'data.cloneCampaign');
  },
  getCampaignActions: (chatbot, campaignId) =>
    requests.get('/api/v1/actions/get-successful', {
      project_id: chatbot,
      campaign_id: campaignId,
    }),
  getAllAutomationTemplates: async (templateEnv = null) => {
    const res = await requests.gql.chatserver.query(
      getAutomationTemplate,
      {},
      templateEnv
        ? `https://${templateEnv}.joboti.com/graphql`
        : TEMPLATE_CHAT_SERVER_LINK
    );
    return res.data?.allAutomationTemplates;
  },
  cloneConfiguration: async (campaignId) => {
    const res = await requests.gql.chatserver.mutate(cloneConfiguration, {
      campaignId,
    });
    return res?.data?.cloneConfiguration;
  },
  toggleConfigurationSchedule: async (campaignId, enabled) => {
    const res = await requests.gql.chatserver.mutate(
      toggleConfigurationSchedule,
      {
        campaignId,
        enabled,
      }
    );
    return res.data.toggleConfigurationSchedule;
  },
  PollAutomatchPublishStatus: async (campaignId) => {
    const res = await requests.gql.chatserver.mutate(
      PollAutomatchPublishStatusMutation,
      {
        campaignId,
      }
    );
    return res;
  },
};

export default CampaignAgent;
