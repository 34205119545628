const translationFragment = `
fragment translationFragment on TranslationNode { 
  id
  content
  tags {
    kind 
    content
    id
  }
}
`;

export default translationFragment;
