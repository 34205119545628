import { documentationData } from 'constants/documentation.constants';
import { action, observable } from 'mobx';
import { languageStore } from 'stores';
import BaseStore from './BaseStore';

class CommonStore extends BaseStore {
  @observable isNavigationDrawerOpen = false;
  @observable isFlowListDrawerOpen = true;
  @observable selectedChatbot = { serverAddress: '' };
  @observable selectedStateTags = [];
  @observable isOnline = navigator.onLine;
  @observable documentationLink = 'https://docs.joboti.com';
  @observable documentationButtontext =
    'components/navigation/NavBar/documentation';
  @observable openedSection = '';
  @observable campaignsListDrawerOpen = true;

  constructor(params) {
    super(params);
    window.addEventListener('online', () => {
      this.setAttr('isOnline', true);
    });
    window.addEventListener('offline', () => {
      this.setAttr('isOnline', false);
    });
  }

  @action
  toggleNavigationDrawer() {
    this.isNavigationDrawerOpen = !this.isNavigationDrawerOpen;
  }

  @action
  toggleFlowListDrawer() {
    this.isFlowListDrawerOpen = !this.isFlowListDrawerOpen;
  }

  @action
  openNavigationDrawer() {
    this.isNavigationDrawerOpen = true;
  }

  @action
  closeNavigationDrawer() {
    this.isNavigationDrawerOpen = false;
  }

  @action
  setAttr(name, value) {
    this[name] = value;
  }

  @action
  addTag(tag) {
    this.selectedStateTags.push(tag);
  }

  @action
  setDocsSection(section) {
    this.documentationLink = documentationData[section]?.storyLaneLinks
      ? documentationData[section]?.storyLaneLinks[
          languageStore.selectedLanguage
        ]
      : documentationData[section].docsLink;

    this.documentationButtontext = documentationData[section].buttonText;
  }
}

export default CommonStore;
