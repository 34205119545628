import Analytics from 'agent/analytics';
import { action, decorate, observable, runInAction } from 'mobx';
import moment from 'moment';
import BaseStore from 'stores/BaseStore';
import { userStore } from '../../index';
import AnalyticsMessageStore from './AnalyticsMessage.store';

class SmartInboxAnalytics extends BaseStore {
  outboundMessages = 0;
  readMessages = 0;
  inboundMessages = 0;
  analyticsMessages = [];
  amountOfPages = 0;
  messageCount = 0;
  pageSize = 20;
  pageNum = 1;
  teamNames = [];
  startDate = null;
  endDate = null;
  assignedFlows = [];
  assignedRecruiters = [];
  types = [];
  templateNames = [];
  isInbound;
  senderEntity = ['recruiter', 'candidate', 'chatbot'];
  loading = false;
  typeToFilterOn = [];
  teamNamesToFilterOn = [];
  templateNameToFilterOn = [];
  senderEntityToFilterOn = [];
  assignedToFilterOn = {};
  candidateIdToSearch = '';
  recruiterAverageResponseTime = 0;
  candidateAverageResponseTime = 0;
  exportCSV = false;

  constructor(args) {
    super();
    if (args) this.setup(args);
  }

  setup(args) {
    this.outboundMessages = args.outbound_messages;
    this.readMessages = args.read_messages;
    this.inboundMessages = args.inbound_messages;
  }

  async getSmartInboxAnalytics() {
    this.loading = true;
    const params = {
      projectId: userStore.selectedChatbot.name,
      pageSize: this.pageSize,
      pageNum: this.pageNum,
      fetchOptions: this.pageNum === 1,
      starDate: this.startDate
        ? moment(new Date(this.startDate)).format()
        : null,
      endDate: this.endDate ? moment(new Date(this.endDate)).format() : null,
      humanReadableTypes: this.typeToFilterOn,
      templateNames: this.templateNameToFilterOn,
      isInbound: this.extractValueFromArray('isInbound'),
      isSent: this.extractValueFromArray('isSent'),
      isRead: this.extractValueFromArray('isRead'),
      isReplied: this.extractValueFromArray('isReplied'),
      teamNames: this.teamNamesToFilterOn,
      senderEntities: this.senderEntityToFilterOn,
      assignedTo: JSON.stringify(
        this.filterAgentsFromFlows(this.assignedToFilterOn)
      ),
      candidateId: this.candidateIdToSearch,
      exportCSV: this.exportCSV,
    };

    const data = await Analytics.smartInboxAnalytics(params);

    if (data?.messages) {
      runInAction(() => {
        this.outboundMessages = data.outbound_messages;
        this.readMessages = data.read_messages;
        this.inboundMessages = data.inbound_messages;
        this.amountOfPages = data.amount_of_pages;
        this.messageCount = data.message_count;
        this.analyticsMessages = data.messages.map(
          (message) => new AnalyticsMessageStore(message)
        );
        // this is to cash the properties after the first fetch
        if (this.pageNum === 1) {
          this.teamNames = data.team_name_options;
          this.assignedFlows = data.assignee_options?.flows;
          this.assignedRecruiters = data.assignee_options?.agents;
          this.types = data.human_readable_type_options;
          this.templateNames = data.template_name_options;
          this.recruiterAverageResponseTime = data.avg_response_times.recruiter;
          this.candidateAverageResponseTime = data.avg_response_times.candidate;
          this.distinctiveChats = data.unique_conversations;
        }
        this.loading = false;
      });
    } else {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    }
  }

  extractValueFromArray(property) {
    return Array.isArray(this[property]) ? this[property][0] : this[property];
  }

  filterAgentsFromFlows(assignedToFilterOn) {
    if (!assignedToFilterOn) return;
    const result = { flows: [], agents: [] };

    for (let i = 0; i < assignedToFilterOn.length; i++) {
      const currentAssignedToFilter = assignedToFilterOn[i].trim();
      // when the item is in agents put it in agents
      if (this.assignedFlows.includes(currentAssignedToFilter)) {
        result.flows.push(currentAssignedToFilter);
      } else {
        result.agents.push(currentAssignedToFilter);
      }
    }
    return result;
  }
}

decorate(SmartInboxAnalytics, {
  outboundMessages: observable,
  readMessages: observable,
  inboundMessages: observable,
  analyticsMessages: observable,
  amountOfPages: observable,
  messageCount: observable,
  pageSize: observable,
  pageNum: observable,
  showComingMessages: observable,
  loading: observable,
  getSmartInboxAnalytics: action,
  isInbound: observable,
});

export default SmartInboxAnalytics;
