import Cookies from 'js-cookie';

/**
 * Clear all cookies from the browser. Except the http-only of course :)
 */
export function clearCookies() {
  const allCookies = Cookies.get();
  // Loop through all cookies
  Object.keys(allCookies).forEach((cookie) => {
    // Delete each cookie
    Cookies.remove(cookie);
  });
}
