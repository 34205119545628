import requests from '../requests'

const CampaignAnalyticsAgent = {
  get: async (chatbotId, documentId) => {
    return requests.get('/api/v1/campaign/analytics/', {
      document_id: JSON.stringify(documentId),
      project_id: chatbotId,
    })
  },
}

export default CampaignAnalyticsAgent
