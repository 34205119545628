import requests from './requests';

const createScalarName = `
mutation CreateScalarName($data: ScalarNameCreateGenericType!) {
    createScalarNameMutation(newScalarname: $data) {
      scalarname {
        id
        name
        atsFieldMapping: atsfieldmappingSet (isDefaultMapping:true) {
          id
          scalarName{
            id
            name
          }
        }
        humanReadableNames {
          content
          tags {
            id
            content
          }      
        }
      }
      ok
    }
  }
`;

const updateScalarName = `
mutation UpdateScalarName($data: ScalarNameUpdateGenericType!) {
    updateScalarNameMutation(newScalarname: $data) {
      scalarname {
        id
        name
      }
      ok
    }
`;

const deleteScalarName = `
mutation DeleteScalarName($id: ID!) {
    deleteScalarNameMutation(id: $id) {
      scalarname {
        id
        name
      }
      ok
    }
  }
`;

const ScalarName = {
  create: (data) => requests.gql.chatserver.mutate(createScalarName, { data }),
  update: (data) => requests.gql.chatserver.mutate(updateScalarName, { data }),
  delete: (id) => requests.gql.chatserver.mutate(deleteScalarName, { id }),
};

export default ScalarName;
