import { FirebaseApp } from '../firebase-config'

const database = FirebaseApp.database()
const rootRef = database.ref('dashboard')

const Documentation = {
  getDocumentationArticles: async () => {
    const documentationArticles = []
    const articles = await rootRef.child('documentation').once('value')

    articles.forEach(article => {
      const art = article.val()
      documentationArticles.push(art)
    })
    return documentationArticles
  },
  getGettingStartedArticles: async () => {
    const gettingStartedArticles = []
    const articles = await rootRef.child('getting_started').once('value')

    articles.forEach(article => {
      const art = article.val()
      gettingStartedArticles.push(art)
    })
    return gettingStartedArticles
  },
  getRealeaseNotes: async () => {
    const notes = await rootRef.child('release_notes').once('value')
    return notes.val()
  },
}

export default Documentation
