import {
  calendarUserFragment,
  eventConfigurationFragment,
  eventPreferenceFragment,
  scheduleAvailabilityFragment,
  scheduleAvailabilitySchemaFragment,
} from 'agent/fragments/Calendar';
import _ from 'lodash';
import requests from './requests';
import translationFragment from './translation.Fragment';

const getCalendarUser = `
query GetCalendarUser($userId: ID!) {
  calendarUser(userId: $userId) {
    ...calendarUserFragment
  }
}
${calendarUserFragment}
`;

const createCalendarUser = `
  mutation CreateCalendarUser($newCalendaruser: CalendarUserCreateGenericType!) {
    createCalendarUser(newCalendaruser: $newCalendaruser) {
      ok
      errors {
        field
      }
      calendaruser {
        ...calendarUserFragment
      }
    }
  }
${calendarUserFragment}
`;

const updateCalendarUser = `
  mutation UpdateCalendarUser($newCalendaruser: CalendarUserUpdateGenericType!) {
    updateCalendarUser(newCalendaruser: $newCalendaruser) {
      ok
      calendaruser {
        ...calendarUserFragment
      }
   }
 }
${calendarUserFragment}
`;

const authenticateCalendar = `
  mutation AuthenticateCalendar($code:String!, $userId:ID!, $calendarType: String, $referer:String) {
    authenticateCalendar(code:$code, userId:$userId, calendarType: $calendarType, referer: $referer) {
      ok
      errorMessage
    }
  }
`;

const listEventConfigurations = `
  query {
    allEventConfigurations {
      ...eventConfigurationFragment
    }
  }
  ${translationFragment}
  ${eventConfigurationFragment}
`;

const getEventConfiguration = `
  query GetEventConfiguration($id: ID!) {
    eventConfiguration(id: $id) {
    ...eventConfigurationFragment
    }
  }
  ${eventConfigurationFragment}
  ${translationFragment}
`;

const deleteEventConfiguration = `
  mutation DeleteEventConfiguration($id:ID!){
    deleteEventConfiguration(id:$id) {
     ok
    }
  }
`;

const getEventPreference = `
  query GetEventPreference($id:ID!) {
    eventPreference(id:$id) {
     ...eventPreferenceFragment
    }
  }
${eventPreferenceFragment}
`;

const createEventPreference = `
  mutation CreateEventPreference($newEventpreferences: EventPreferencesCreateGenericType!) {
    createEventPreferences(newEventpreferences: $newEventpreferences) {
      ok
      eventpreferences {
        ...eventPreferenceFragment
      }
   }
 }
${eventPreferenceFragment}
`;

const updateEventPreference = `
  mutation UpdateEventPreference($newEventpreferences: EventPreferencesUpdateGenericType!) {
    updateEventPreferences(newEventpreferences: $newEventpreferences) {
      ok
      eventpreferences {
        ...eventPreferenceFragment
      }
   }
 }
${eventPreferenceFragment}
`;

const deleteEventPreference = `
  mutation DeleteEventPreference($id:ID!){
    deleteEventPreferences(id:$id) {
     ok
    }
  }
`;

const createScheduleAvailability = `
  mutation CreateScheduleAvailability($newScheduleavailability: ScheduleAvailabilityCreateGenericType!) {
    createScheduleAvailability(newScheduleavailability: $newScheduleavailability) {
      ok
      scheduleavailability {
      ...scheduleAvailabilityFragment
      }
    }
  }
  ${scheduleAvailabilityFragment}
`;

const updateScheduleAvailability = `
  mutation UpdateScheduleAvailability($newScheduleavailability: ScheduleAvailabilityUpdateGenericType!) {
    updateScheduleAvailability(newScheduleavailability: $newScheduleavailability) {
      ok
      scheduleavailability {
      ...scheduleAvailabilityFragment
      }
    }
  }
  ${scheduleAvailabilityFragment}
`;

const deleteScheduleAvailability = `
  mutation DeleteScheduleAvailability($id: ID!) {
    deleteScheduleAvailability(id: $id) {
      ok
      scheduleavailability {
        ...scheduleAvailabilityFragment
      }
    }
  }
  ${scheduleAvailabilityFragment}
`;

// event configuration queries (where you can edit the appointment data)
const createEventConfiguration = `
mutation createEventConfiguration($newEventconfiguration: EventConfigurationCreateGenericType!) {
  createEventConfiguration(newEventconfiguration: $newEventconfiguration) {
    ok
    errors {
      field
      messages
    }
    eventconfiguration {
      ...eventConfigurationFragment
    }
  }
}
${eventConfigurationFragment}
${translationFragment}

`;

const updateEventConfiguration = `
  mutation UpdateEventConfiguration($newEventconfiguration: EventConfigurationUpdateGenericType!) {
      updateEventConfiguration(newEventconfiguration: $newEventconfiguration) {
        ok
         errors {
          field
          messages
        }
         eventconfiguration {
      ...eventConfigurationFragment
    }
  }
}
${eventConfigurationFragment}
${translationFragment}
`;

const cloneEventConfiguration = `
mutation CloneEventConfiguration($eventConfigurationId: ID!, $newName: String!) {
  cloneEventConfiguration(eventConfigurationId: $eventConfigurationId, newName: $newName) {
    ok
    errorMessage
    eventConfiguration {
      ...eventConfigurationFragment
    }
  }
}
${eventConfigurationFragment}
${translationFragment}
`;

const createScheduleAvailabilitySchema = `
mutation CreateScheduleAvailabilitySchema($newScheduleavailabilityschema: ScheduleAvailabilitySchemaCreateGenericType!){
  createScheduleAvailabilitySchema(newScheduleavailabilityschema: $newScheduleavailabilityschema) {
     ok
     scheduleavailabilityschema {
           ...scheduleAvailabilitySchemaFragment
      }
  }
}
${scheduleAvailabilitySchemaFragment}
`;

const updateScheduleAvailabilitySchema = `
mutation UpdateScheduleAvailabilitySchema($newScheduleavailabilityschema: ScheduleAvailabilitySchemaUpdateGenericType!){
  updateScheduleAvailabilitySchema(newScheduleavailabilityschema: $newScheduleavailabilityschema) {
      ok
      scheduleavailabilityschema {
        ...scheduleAvailabilitySchemaFragment
      }
  }
}
${scheduleAvailabilitySchemaFragment}
`;

const getScheduleAvailabilitySchema = `
query GetScheduleAvailabilitySchema($id:ID!) {
    scheduleAvailabilitySchema(id:$id) {
     ...scheduleAvailabilitySchemaFragment
    }
  }
${scheduleAvailabilitySchemaFragment}
`;

const getEventPreferenceForUser = `
query GetCalendarUser($userId: ID!, $eventConfigurationIds: [ID]) {
  calendarUser(userId: $userId) {
    id
    eventPreferences(eventConfigurations_In: $eventConfigurationIds) {
      id
      enabled
      eventConfigurations {
        id
        name
        location
      }
    }
  }
}
`;

const Calendar = {
  getCalendarUser: async (userId) => {
    const res = await requests.gql.chatserver.query(getCalendarUser, {
      userId,
    });
    return _.get(res, 'data.calendarUser');
  },
  createCalendarUser: async (newCalendarUser) => {
    const res = await requests.gql.chatserver.mutate(
      createCalendarUser,
      newCalendarUser
    );
    return _.get(res, 'data.createCalendarUser.calendaruser');
  },
  updateCalendarUser: async (newCalendaruser) => {
    const res = await requests.gql.chatserver.mutate(updateCalendarUser, {
      newCalendaruser,
    });
    return _.get(res, 'data.updateCalendarUser.calendaruser');
  },
  authenticateCalendar: async (code, userId, calendarType, referer) => {
    const res = await requests.gql.chatserver.mutate(authenticateCalendar, {
      code,
      userId,
      calendarType,
      referer,
    });
    return _.get(res, 'data.authenticateCalendar');
  },
  listEventConfigurations: async () => {
    const res = await requests.gql.chatserver.query(listEventConfigurations);
    return _.get(res, 'data.allEventConfigurations');
  },
  deleteEventConfiguration: async (id) => {
    const res = await requests.gql.chatserver.mutate(deleteEventConfiguration, {
      id,
    });
    return _.get(res, 'data.deleteEventConfiguration');
  },
  cloneEventConfiguration: async (eventConfigurationId, newName) => {
    const res = await requests.gql.chatserver.mutate(cloneEventConfiguration, {
      eventConfigurationId,
      newName,
    });
    return _.get(res, 'data.cloneEventConfiguration');
  },
  getEventPreference: async (id) => {
    const res = await requests.gql.chatserver.query(getEventPreference, { id });
    return _.get(res, 'data.eventPreference');
  },
  createEventPreference: async (newEventpreferences) => {
    const res = await requests.gql.chatserver.mutate(createEventPreference, {
      newEventpreferences,
    });
    return _.get(res, 'data.createEventPreferences');
  },
  updateEventPreference: async (newEventpreferences) => {
    const res = await requests.gql.chatserver.mutate(updateEventPreference, {
      newEventpreferences,
    });
    return _.get(res, 'data.updateEventPreferences');
  },
  deleteEventPreference: async (id) => {
    const res = await requests.gql.chatserver.mutate(deleteEventPreference, {
      id,
    });
    return _.get(res, 'data.deleteEventPreferences.ok');
  },
  getEventConfiguration: async (id) => {
    const res = await requests.gql.chatserver.query(getEventConfiguration, {
      id,
    });
    return _.get(res, 'data.eventConfiguration');
  },
  createScheduleAvailability: async (newScheduleavailability) => {
    const res = await requests.gql.chatserver.mutate(
      createScheduleAvailability,
      {
        newScheduleavailability,
      }
    );
    return _.get(res, 'data.createScheduleAvailability');
  },
  updateScheduleAvailability: async (newScheduleavailability) => {
    const res = await requests.gql.chatserver.mutate(
      updateScheduleAvailability,
      {
        newScheduleavailability,
      }
    );
    return _.get(res, 'data.updateScheduleAvailability');
  },
  deleteScheduleAvailability: async (id) => {
    const res = await requests.gql.chatserver.mutate(
      deleteScheduleAvailability,
      {
        id,
      }
    );
    return _.get(res, 'data.deleteScheduleAvailability');
  },
  createEventConfiguration: async (newEventconfiguration) => {
    const { data } = await requests.gql.chatserver.mutate(
      createEventConfiguration,
      {
        newEventconfiguration,
      }
    );
    return data.createEventConfiguration;
  },
  updateEventConfiguration: async (updatedEventConfiguration) => {
    const { data } = await requests.gql.chatserver.mutate(
      updateEventConfiguration,
      {
        newEventconfiguration: updatedEventConfiguration,
      }
    );
    return data.updateEventConfiguration;
  },
  createScheduleAvailabilitySchema: async (newScheduleavailabilityschema) => {
    try {
      const { data } = await requests.gql.chatserver.query(
        createScheduleAvailabilitySchema,
        {
          newScheduleavailabilityschema,
        }
      );

      return data?.createScheduleAvailabilitySchema?.scheduleavailabilityschema;
    } catch (error) {
      return error;
    }
  },
  updateScheduleAvailabilitySchema: async (
    updateScheduleavailabilityschema
  ) => {
    try {
      const { data } = await requests.gql.chatserver.query(
        updateScheduleAvailabilitySchema,
        {
          newScheduleavailabilityschema: updateScheduleavailabilityschema,
        }
      );
      return data?.updateScheduleAvailabilitySchema?.scheduleavailabilityschema;
    } catch (error) {
      return error;
    }
  },
  getScheduleAvailabilitySchema: async (id) => {
    const res = await requests.gql.chatserver.query(
      getScheduleAvailabilitySchema,
      {
        id,
      }
    );
    return _.get(res, 'data.scheduleAvailabilitySchema');
  },
  getEventPreferenceForUser: async (userId, eventConfigurationIds) => {
    const res = await requests.gql.chatserver.query(getEventPreferenceForUser, {
      userId,
      eventConfigurationIds,
    });
    return _.get(res, 'data.calendarUser[0].eventPreferences');
  },
};

export default Calendar;
