import requests from 'agent/requests';

const createNewLabel = `
mutation CreateLabel($newLabel:InboxLabelCreateGenericType!) {
  createLabel(newInboxlabel:$newLabel) {
    ok
    inboxlabel {
      id
      color
      name
    }
  }
}
`;

const updateLabel = `
mutation UpdateLabel($newLabel:InboxLabelUpdateGenericType!) {
  updateLabel(newInboxlabel:$newLabel) {
    ok
    inboxlabel {
      id
    }
  }
}`;

const deleteLabel = `
mutation DeleteInboxLabel ($id:ID!) {
  deleteLabel(id: $id) {
    ok
  }
}
`;

const InboxLabel = {
  createInboxLabel: async (newLabel) => {
    const { data } = await requests.gql.chatserver.mutate(createNewLabel, {
      newLabel,
    });
    return data.createLabel;
  },
  updateInboxLabel: async (newLabel) => {
    const { data } = await requests.gql.chatserver.mutate(updateLabel, {
      newLabel,
    });
    return data.updateLabel;
  },
  deleteInboxLabel: async (labelId) => {
    const { data } = await requests.gql.chatserver.mutate(deleteLabel, {
      id: labelId,
    });
    return data.deleteLabel;
  },
};

export default InboxLabel;
