import { decorate, observable, runInAction, action } from 'mobx';
import BaseStore from './BaseStore';
import ProcessStore from './ProcessStore';
import agent from '../agent';

class WizardStore extends BaseStore {
  id = '';
  title = '';
  finishedTimestamp = null;
  chatbot = null;
  processes = [];

  constructor(args) {
    super();
    this.id = args.id;
    this.title = args.title;
    this.finishedTimestamp = args.finishedTimestamp;
    this.chatbot = args.chatbot;

    const sortedProcessors = args.processes.sort((a, b) => a.order - b.order);
    this.processes = sortedProcessors.map(
      (process) => new ProcessStore(process)
    );
  }

  async save() {
    const data = {
      newWizard: {
        id: this.id,
        finishedTimestamp: this.finishedTimestamp,
      },
    };

    await agent.Wizard.updateWizard(data);
  }

  // eslint-disable-next-line class-methods-use-this
  async copyWizardStep({
    destinationClient,
    originClient,
    destinationWizardId,
    originProcessIdentifier,
  }) {
    const data = {
      destinationClient,
      originClient,
      destinationWizardId,
      originProcessIdentifier,
    };

    const res = await agent.Wizard.copyWizardProcess(data);

    if (res.data.cloneWizardProcess.ok) {
      runInAction(() => {
        const newProcess = new ProcessStore(
          res.data.cloneWizardProcess.process
        );
        this.processes.push(newProcess);
      });
    }
    return res.data.cloneWizardProcess.ok;
  }

  setFinishedTimeStamp() {
    runInAction(() => {
      this.finishedTimestamp = new Date();
    });
  }
}

decorate(WizardStore, {
  id: observable,
  title: observable,
  finishedTimestamp: observable,
  chatbot: observable,
  processes: observable,
  copyWizardStep: action,
});

export default WizardStore;
