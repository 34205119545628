import agent from 'agent';
import {
  campaignStatusType,
  campaignTypes,
} from 'constants/campaign.constants';
import { action, computed, decorate, observable, runInAction } from 'mobx';
import { userStore } from 'stores';
import BaseStore from 'stores/BaseStore';
import CampaignAnalytics from 'stores/campaigns/CampaignAnalytics.store';
import AutomationTemplate from './AutomationTemplate.store';
import Campaign from './Campaign.store';
import CampaignDetail from './CampaignDetail.store';

class Campaigns extends BaseStore {
  campaigns = [];
  campaignTemplates = [];
  isLoading;
  selectedCampaign;
  chatbotChanged;
  campaignDetail = new CampaignDetail();
  loadAutomationTemplates = false;
  automationTemplates = [];
  selectedAutomationTemplate = null;
  selectedStatusFilter = campaignStatusType.ALL;
  selectedTypeFilter = '';
  campaignSearchQuery = '';
  automationTemplatesModalOpen = false;
  automationActiveTab = '1';
  abortController = null;
  campaignAnalyticsIsLoading = false;

  async fetch() {
    this.isLoading = true;
    try {
      const campaigns = await agent.Campaign.list();
      runInAction(() => {
        this.campaigns = campaigns?.map((campaign) => new Campaign(campaign));
      });
    } catch (error) {
      console.error('Failed to fetch campaigns', error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async fetchTemplates() {
    this.isLoading = true;
    const templates = await agent.Campaign.listTemplates(
      // Trigger it for the specific template environment (ATS)
      userStore.selectedChatbot.atsSpecificTemplatesEnvironment
    );
    runInAction(() => {
      this.campaignTemplates = templates?.map(
        (template) => new Campaign(template)
      );
      this.isLoading = false;
    });
  }

  async deleteCampaign(id) {
    const res = await agent.Campaign.delete(id);

    runInAction(() => {
      this.campaigns = this.campaigns?.filter(
        (campaign) => campaign?.id !== id
      );
    });

    return res;
  }

  async handleCampaignConversations(signal) {
    try {
      if (!this.selectedCampaign?.id) {
        // If no campaign is selected, fetch conversations for all applicable campaigns
        await this.campaignDetail.fetchCampaignConversations();
      } else {
        const { type, campaignDetail } = this.selectedCampaign;
        const isAutomatch =
          type === campaignTypes.AUTOMATCH_VAC2CAND ||
          type === campaignTypes.AUTOMATCH_CAND2VAC;

        if (isAutomatch) {
          await campaignDetail.getAutomatchConversations({ signal });
        } else {
          await campaignDetail.fetchCampaignConversations({ signal });
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Campaign conversation fetch aborted');
      } else {
        console.error('Failed to fetch campaign conversations:', error);
      }
    }
  }

  async fetchOne(id) {
    // Abort previous request if it exists
    runInAction(() => {
      this.isLoading = true;
    });
    if (this.abortController) {
      this.abortController.abort();
    }

    // Create a new AbortController instance for the current request
    this.abortController = new AbortController();
    const { signal } = this.abortController;

    try {
      const selectedCampaign = await agent.Campaign.get(id, { signal });
      runInAction(() => {
        this.selectedCampaign = selectedCampaign
          ? new Campaign(selectedCampaign)
          : 'error';
      });
      await this.handleCampaignConversations();
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Failed to fetch campaign:', error);
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  // Factory function to create a new Campaign instance with default values
  createEmptyCampaign = action(() => {
    const campaign = new Campaign();

    return campaign;
  });

  async fetchAnalytics() {
    this.campaignAnalyticsIsLoading = true;
    try {
      const campaignDocumentIds = {};
      this.campaigns?.forEach((campaign) => {
        campaignDocumentIds[campaign.analyticsDocumentId] = campaign;
      });

      if (Object.keys(campaignDocumentIds).length === 0) {
        return;
      }

      const tenant = userStore.selectedChatbot.name;

      const campaignAnalytics = await agent.CampaignAnalytics.get(
        tenant,
        Object.keys(campaignDocumentIds)
      );

      this.campaigns?.forEach((campaign) => {
        if (
          Object.prototype.hasOwnProperty.call(
            campaignAnalytics,
            campaign.analyticsDocumentId
          )
        ) {
          runInAction(() => {
            const analytics = campaignAnalytics[campaign.analyticsDocumentId];
            campaign.campaignAnalytics = new CampaignAnalytics(analytics);
          });
        }
      });
    } catch (error) {
      console.error('Error fetching campaign analytics:', error);
    } finally {
      runInAction(() => {
        this.campaignAnalyticsIsLoading = false;
      });
    }
  }

  selectCampaign(campaign) {
    runInAction(() => {
      if (campaign instanceof Campaign || !campaign) {
        this.selectedCampaign = campaign;
      } else {
        this.selectedCampaign = new Campaign(campaign);
      }
    });
  }

  async createCampaign(campaign, defaultFlow, definitions) {
    const {
      whatsAppDefinition,
      flow,
      followUp,
      isSmartInbox,
      status,
      smartInboxLabels,
      removeSmartInboxLabels,
    } = campaign;

    const newCampaign = {
      name: campaign?.name,
      type: campaign?.type,
      whitelist: campaign?.whitelist,
      analyticsDocumentId: campaign?.analyticsDocumentId,
      onFinishProcessor: campaign?.onFinishProcessor?.id,
      onFailProcessor: campaign?.onFailProcessor?.id,
      description: campaign?.description,
      isSmartInbox,
      whatsappDefinition: whatsAppDefinition?.id || definitions[0]?.id,
      flow: isSmartInbox ? null : flow?.id || defaultFlow?.id,
      followUp: followUp?.id,
      atsFieldMappings: campaign?.atsFieldMappings?.map(({ id }) => id),
      status: status || 'A_1',
      clientDescriptionImages: campaign?.clientDescriptionImages,
      clientDescription: campaign?.clientDescription,
      smartInboxLabels: smartInboxLabels.map((label) => label.id),
      removeSmartInboxLabels: removeSmartInboxLabels.map((label) => label.id),
    };
    const res = await agent.Campaign.create(newCampaign);

    runInAction(() => {
      this.campaigns?.push(new Campaign(res));
    });
    return res;
  }

  initializeCampaign(type) {
    runInAction(() => {
      this.selectedCampaign = new Campaign({ type });
    });
  }

  async fetchAutomationTemplates() {
    runInAction(() => {
      this.loadAutomationTemplates = true;
    });
    try {
      const automationTemplates =
        await agent.Campaign.getAllAutomationTemplates(
          userStore?.selectedChatbot?.atsSpecificTemplatesEnvironment
        );

      const { whatsappProvider } = userStore.selectedChatbot;

      // If a customer does NOT have engage enabled => WhatsappProvider == null
      // we Hide all engage automation
      const filteredAutomationTemplates = whatsappProvider
        ? automationTemplates
        : automationTemplates.filter(
            (automation) =>
              automation.campaign.type === campaignTypes.AUTOMATCH_CAND2VAC ||
              automation.campaign.type === campaignTypes.AUTOMATCH_VAC2CAND
          );

      runInAction(() => {
        this.automationTemplates = filteredAutomationTemplates?.map(
          (automation) => new AutomationTemplate(automation)
        );
        this.selectedAutomationTemplate = new AutomationTemplate(
          filteredAutomationTemplates[0]
        );
        this.loadAutomationTemplates = false;
      });
    } catch (error) {
      console.error('error get automation templates');
      runInAction(() => {
        this.loadAutomationTemplates = false;
      });
    }
  }

  async cloneCampaign(id, newName) {
    this.isLoading = true;
    const response = await agent.Campaign.clone(id, newName);
    if (!response?.ok) {
      return null;
    }

    runInAction(() => {
      const newCampaign = new Campaign(response.campaign);
      this.selectedCampaign = newCampaign;
      this.campaigns.push(newCampaign);
      this.isLoading = false;
    });
    // we return the id to use it in the url after we create the campaign
    return this.selectedCampaign?.id;
  }

  get sortedCampaigns() {
    return this.campaigns
      ?.reduce((result, campaign) => {
        if (
          (this.selectedStatusFilter === campaignStatusType.ALL ||
            campaign.status === this.selectedStatusFilter) &&
          campaign.type.includes(this.selectedTypeFilter)
        ) {
          result.push({
            name: campaign.name,
            value: campaign,
            id: campaign?.id,
            created: campaign?.created,
            type: campaign?.type,
          });
        }
        return result;
      }, [])
      .sort((campaign1, campaign2) =>
        campaign1.name.localeCompare(campaign2.name)
      );
  }

  // eslint-disable-next-line getter-return
  get filteredCampaigns() {
    return this.sortedCampaigns?.filter((campaign) =>
      campaign.name
        .toLowerCase()
        .includes(this.campaignSearchQuery.toLowerCase())
    );
  }

  searchCampaigns(searchQuery) {
    runInAction(() => {
      this.campaignSearchQuery = searchQuery;
    });
  }

  statusFilterChange(status) {
    runInAction(() => {
      this.selectedStatusFilter = status;
    });
  }

  typeFilterChange(type) {
    runInAction(() => {
      this.selectedTypeFilter = type;
    });
  }

  resetCampaignsFilters() {
    runInAction(() => {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.selectedStatusFilter = campaignStatusType.ALL;
      this.selectedTypeFilter = '';
      this.campaignSearchQuery = '';
      this.isLoading = false;
      this.selectedCampaign = null;
    });
  }

  toggleAutomationActiveTab(key) {
    runInAction(() => {
      this.automationActiveTab = key;
    });
  }
}

decorate(Campaigns, {
  campaigns: observable,
  isLoading: observable,
  selectedCampaign: observable,
  chatbotChanged: observable,
  campaignDetail: observable,
  fetchOne: action,
  fetch: action,
  fetchTemplates: action,
  fetchAnalytics: action,
  selectCampaign: action,
  initializeCampaign: action,
  cloneCampaign: action,
  createCampaign: action,
  createEmptyCampaign: observable,
  deleteCampaign: action,
  loadAutomationTemplates: observable,
  automationTemplates: observable,
  selectedAutomationTemplate: observable,
  fetchAutomationTemplates: action,
  selectedStatusFilter: observable,
  campaignSearchQuery: observable,
  filteredCampaigns: computed,
  searchCampaigns: action,
  statusFilterChange: action,
  automationTemplatesModalOpen: observable,
  automationActiveTab: observable,
  toggleAutomationActiveTab: action,
  selectedTypeFilter: observable,
  typeFilterChange: action,
  resetCampaignsFilters: action,
  abortController: observable,
  sortedCampaigns: computed,
  campaignAnalyticsIsLoading: observable,
  handleCampaignConversations: action,
});

export default Campaigns;
