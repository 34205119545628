import { processorFragment as fullProcessorFragment } from '../../fragments/builder';
import { automatchFragment } from './automatchFragments';
import { queryStatementFragment } from './queryBuilderFragments';

export const languageTagFragment = /* GraphQL */ `
  fragment languageTagFragment on TagNode {
    id
    kind
    content
    color
    created
    modified
  }
`;

export const buttonFragment = /* GraphQL */ `
  fragment buttonFragment on WhatsAppButtonNode {
    actionType
    text
    phoneNumber
    url
    id
    urlType
  }
`;

export const scalarNameFragment = /* GraphQL */ `
  fragment scalarNameFragment on ScalarNameNode {
    id
    name
    humanReadableName
    description
    createdAt
    updatedAt
  }
`;

export const parameterFragment = /* GraphQL */ `
  fragment parameterFragment on WhatsAppParameterNode {
    id
    name
    index
    atsFieldMapping {
      scalarName {
        id
        name
        humanReadableNames {
            id
            content
            tags {
              kind
              content
            }
          }
    }
    id
    atsFieldOrdering: atsfieldorderingSet {
      atsField {
        id
        getPath
        humanReadableNames {
            id
            content
            tags {
              kind
              content
            }
        }
        entityType {
          id
          name
          humanReadableNames {
             id
            content
            tags {
              kind
              content
            }
          }
        }
      }
      number
    }
  }
}
`;

export const variationFragment = /* GraphQL */ `
  fragment variationFragment on WhatsAppVariationNode {
    id
    content
    created
    modified
    attachmentTitle
    attachmentUrl
    attachmentMimeType
    status
    language
    languageTag {
      ...languageTagFragment
    }
    buttons {
      ...buttonFragment
    }
    parameters {
      ...parameterFragment
    }
  }
  ${languageTagFragment}
  ${buttonFragment}
  ${parameterFragment}
`;

export const templateFragment = /* GraphQL */ `
  fragment templateFragment on WhatsAppTemplateNode {
    id
    templateName
    description
    additionalData
    attachmentType
    version
    status
    creatorEmail
    buttonType
    created
    modified
    variations {
      ...variationFragment
    }
    parameters {
      ...parameterFragment
    }
    scalarNames {
      ...scalarNameFragment
    }
  }
  ${scalarNameFragment}
  ${variationFragment}
`;

export const definitionFragment = (justActiveTemplates) => /* GraphQL */ `
fragment definitionFragment on WhatsAppDefinitionNode {
  id
  name
  supportsBatchAction
  versionsHistoricallyCreated
  isSmartInboxTemplate
  templateIdentifier
  activeTemplate {
    ...templateFragment
  }
  ${
    justActiveTemplates
      ? ''
      : ` availableTemplates {
        ...templateFragment
      }
      displayTemplate {
        ...templateFragment
      }`
  }
  campaignSet {
    id
    name
    type
    atsBatchActions {
      id
      externalId
      name
      entityType {
        id
        name
        allHumanReadableNames {
          id
          content
          tags {
            kind
            content
          }
        }
      }
      notificationHeader
      notificationBody
      url
    }
  }
}
${templateFragment}
`;

export const processorFragment = /* GraphQL */ `
  fragment processorFragment on ProcessorNode {
    id
    name
    humanReadableName
    processorType
  }
`;

export const followUpFragment = /* GraphQL */ `
  fragment followUpFragment on FollowUpNode {
    id
    name
    description
    interval
    minInterval
    allowedDays
    minExecutionTime
    maxExecutionTime
    timeZone
    smartInboxLabels {
      id
      name
      color
    }
    removeSmartInboxLabels {
      id
      name
      color
    }
    flow {
      id
      name
    }
    onFinishProcessor {
      ...processorFragment
    }
    onFailProcessor {
      ...processorFragment
    }
    qualifier {
      ...processorFragment
    }
    whatsappDefinition {
      ...definitionFragment
    }
    whatsappTemplate {
      ...templateFragment
    }
  }
  ${definitionFragment()}
  ${processorFragment}
`;

export const parameterMutationFragment = /* GraphQL */ `
  fragment parameterMutationFragment on WhatsAppParameterMutation {
    ok
    errors {
      field
      messages
    }
    whatsappparameter {
      ...parameterFragment
    }
  }
  ${parameterFragment}
`;

export const buttonMutationFragment = /* GraphQL */ `
  fragment buttonMutationFragment on WhatsAppButtonMutation {
    ok
    errors {
      field
      messages
    }
    whatsappbutton {
      ...buttonFragment
    }
  }
  ${buttonFragment}
`;

export const variationMutationFragment = /* GraphQL */ `
  fragment variationMutationFragment on WhatsAppVariationMutation {
    ok
    errors {
      field
      messages
    }
    whatsappvariation {
      ...variationFragment
    }
  }
  ${variationFragment}
`;

export const templateMutationFragment = /* GraphQL */ `
  fragment templateMutationFragment on WhatsAppTemplateMutation {
    ok
    errors {
      field
      messages
    }
    whatsapptemplate {
      ...templateFragment
    }
  }
  ${templateFragment}
`;

const templateProviderResults = /* GraphQL */ `
ok
errorMessage
`;

export const templateProviderCreateMutationFragment = /* GraphQL */ `
fragment templateProviderCreateMutationFragment on CreateProviderTemplate {
  ${templateProviderResults}
}
`;

export const templateProviderDeleteMutationFragment = /* GraphQL */ `
fragment templateProviderDeleteMutationFragment on DeleteProviderTemplate {
  ${templateProviderResults}
}
`;

export const definitionMutationFragment = /* GraphQL */ `
  fragment definitionMutationFragment on WhatsAppDefinitionMutation {
    ok
    errors {
      field
      messages
    }
    whatsappdefinition {
      ...definitionFragment
    }
  }
  ${definitionFragment()}
`;

export const followUpMutationFragment = /* GraphQL */ `
  fragment followUpMutationFragment on FollowUpMutation {
    ok
    errors {
      field
      messages
    }
    followup {
      ...followUpFragment
    }
  }
  ${followUpFragment}
`;

export const campaignsAtsFieldOrderingFragment = /* GraphQL */ `
  fragment atsFieldOrderingFragment on AtsFieldOrderingNode {
    number
    atsField {
      id
      getPath
      entityType {
        id
        name
        humanReadableNames: allHumanReadableNames {
          id
          content
          tags {
            kind
            content
          }
        }
      }
      humanReadableNames: allHumanReadableNames {
        id
        content
        tags {
          kind
          content
        }
      }
    }
  }
`;

export const broadcastFragment = /* GraphQL */ `
  fragment BroadcastFragment on BroadcastNode {
    id
    name
    type
    allowedDays
    interval
    minExecutionTime
    maxExecutionTime
    nextBroadcast
    approachLimit
  }
`;

export const humanReadableNamesFragment = /* GraphQL */ `
  fragment HumanReadableNamesFragment on HumanReadableNamesType {
    id
    content
    tags {
      kind
      content
    }
  }
`;

export const humanReadableNameOnTypeTranslation = /* GraphQL */ `
  fragment HumanReadableNamesFragment on TranslationNode {
    id
    content
    tags {
      id
      content
    }
  }
`;

export const campaignFragment = /* GraphQL */ `
  fragment campaign on CampaignNode {
    id
    name
    description
    analyticsDocumentId
    type
    status
    created
    isSmartInbox
    whitelist
    engageEnabled
    hasActiveTemplate
    draftAutomatch {
      ...automatchFragment
    }
    activeAutomatch {
      publishJobId
      publishStage
      ...automatchFragment
    }
    atsBatchActions {
      id
      entityType {
        id
      }
    }
    smartInboxLabels {
      id
      color
      name
    }
    removeSmartInboxLabels {
      id
      color
      name
    }
    onFinishProcessor {
      ...processorFragment
    }
    onFailProcessor {
      ...processorFragment
    }
    flow {
      id
      name
    }
    broadcast {
      id
      name
      type
      allowedDays
      interval
      minExecutionTime
      maxExecutionTime
      nextBroadcast
      approachLimit
      targetQuery {
        id
        targetHandler
        baseAtsEntityType {
          id
          name
          systemName
          allHumanReadableNames {
            id
            content
            tags {
              kind
              content
            }
          }
          humanReadableNames {
            id
            content
            tags {
              id
              content
            }
            content
          }
        }
        queryStatements {
          ...queryStatementFragment
        }
      }
    }
    whatsappDefinition {
      ...definitionFragment
    }
    atsFieldMappings {
      id
      scalarName {
        id
        name
      }
      atsFieldOrdering: atsfieldorderingSet {
        atsField {
          id
          getPath
          humanReadableNames {
            id
            content
          }
          entityType {
            id
            name
            humanReadableNames {
              id
              content
              tags {
                kind
                content
              }
            }
          }
        }
        number
      }
    }
    followUp {
      id
      name
      allowedDays
      interval
      minExecutionTime
      maxExecutionTime
      smartInboxLabels {
        id
        name
        color
      }
      removeSmartInboxLabels {
        id
        name
        color
      }
      flow {
        id
        name
      }
      whatsappTemplate {
        ...templateFragment
      }
      campaign {
        id
      }
      onFinishProcessor {
        ...processorFragment
      }
      onFailProcessor {
        ...processorFragment
      }
      whatsappDefinition {
        ...definitionFragment
      }
    }
  }
  ${fullProcessorFragment}
  ${definitionFragment()}
  ${queryStatementFragment}
  ${automatchFragment}
  ${humanReadableNameOnTypeTranslation}
`;
