import { action, decorate, observable } from 'mobx';
import BaseStore from 'stores/BaseStore';
import Translation from 'stores/TranslationStore';
import { getTranslationFromSelectedLanguageTag } from 'utils/translateFromSelectedLanguageTag';
import AtsFieldMapping from '../../AtsFieldMapping.store';

class ConditionStore extends BaseStore {
  id = '';
  allowedDataTypes = [];
  description = '';
  name = '';
  value = '';
  humanReadableNames = [];

  constructor(condition) {
    super();
    if (condition) {
      this.id = condition?.id;
      this.allowedDataTypes = condition?.allowedDataTypes;
      this.description = condition?.description;
      this.name = condition?.name;
      this.value = condition?.value;
      this.atsFieldMapping = new AtsFieldMapping(condition.atsFieldMapping);
      this.humanReadableNames = condition?.humanReadableNames?.map(
        (humanReadableName) => new Translation(humanReadableName)
      );
    }
  }

  get conditionName() {
    return getTranslationFromSelectedLanguageTag(this.humanReadableNames);
  }
}

decorate(ConditionStore, {
  id: observable,
  allowedDataTypes: observable,
  description: observable,
  name: observable,
  value: observable,
  updateCondition: action,
});

export default ConditionStore;
