import { observable, action, computed, toJS, runInAction, decorate } from 'mobx'
import Documentation from 'agent/dashboard/documentation'
import BaseStore from '../BaseStore'

class DocumentationStore extends BaseStore {
  documentationArticles = []

  get getDocumentationArticles() {
    return toJS(this.documentationArticles)
  }

  async loadDocumentationArticles() {
    const result = await Documentation.getDocumentationArticles()
    runInAction(() => {
      this.documentationArticles = result
    })
  }
}

decorate(DocumentationStore, {
  documentationArticles: observable,
  getDocumentationArticles: computed,
  loadDocumentationArticles: action,
})

export default DocumentationStore
