export const queryStatementFragment = /* GraphQL */ `
  fragment queryStatementFragment on QueryStatementNode {
    id
    negate
    order
    operator
    value
    atsFieldMapping {
      id
      atsOptions {
        id
        humanReadableName
        value
      }
      scalarName {
        id
        name
      }
      atsFieldOrdering: atsfieldorderingSet {
        id
        atsField {
          dataType
          id
          getPath
          humanReadableNames {
            ...HumanReadableNamesFragment
          }
          entityType {
            id
            name
            humanReadableNames {
              ...HumanReadableNamesFragment
            }
          }
        }
        number
      }
    }
    condition {
      allowedDataTypes
      id
      name
      value
      humanReadableNames {
        ...HumanReadableNamesFragment
      }
    }
  }
`;
