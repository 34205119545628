import { computed, decorate, observable } from 'mobx';
import { languageStore } from 'stores';
import BaseStore from './BaseStore';

class Modifier extends BaseStore {
  humanReadableNames = [];
  name = '';

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    this.humanReadableNames = args.humanReadableNames;
    this.name = args.name;
  }

  get translatedHumanReadableName() {
    const translatedContent = languageStore.selectedLanguage.substring(0, 2);
    const humanReadableNamesLength = this.humanReadableNames.length;
    return this.humanReadableNames[
      humanReadableNamesLength === 1 || translatedContent === 'nl' ? 0 : 1
    ]?.content;
  }
}

decorate(Modifier, {
  humanReadableNames: observable,
  name: observable,
  translatedHumanReadableName: computed,
});

export default Modifier;
