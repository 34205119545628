import { action, decorate, observable } from 'mobx';
import BaseStore from 'stores/BaseStore';

class RequiredDataField extends BaseStore {
  name = '';
  humanReadableName = '';
  description = '';

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.name = params.name;
    this.humanReadableName = params.humanReadableName;
    this.description = params.description;
  }
}

decorate(RequiredDataField, {
  name: observable,
  humanReadableName: observable,
  description: observable,
  setup: action,
});

export default RequiredDataField;
