import { decorate, observable, action, runInAction } from 'mobx';
import _ from 'lodash';
import agent from 'agent';
import BaseStore from 'stores/BaseStore';
import FacebookLeadPage from 'stores/FacebookLeadPage.store';

class FacebookLeads extends BaseStore {
  leads = [];
  isLoading;

  async fetch() {
    this.isLoading = true;
    const data = await agent.FacebookLead.data();
    runInAction(() => {
      this.leads = data.facebookLeads.map((fl) => new FacebookLeadPage(fl));
      this.isLoading = false;
    });
  }

  async update(token) {
    const data = await agent.FacebookLead.update(token);
  }

  async updateActivity(lead) {
    const data = await agent.FacebookLead.updateActivity(lead);
  }

  async delete(lead) {
    const data = await agent.FacebookLead.delete(lead);
  }
}

decorate(FacebookLeads, {
  leads: observable,
  isLoading: observable,
  fetch: action,
  update: action,
});

export default FacebookLeads;
