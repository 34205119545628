import {action, decorate, observable} from "mobx";
import BaseStore from "./BaseStore";

class InboxCompany extends BaseStore {
  id;
  identifier;
  companyName;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.id = params.id;
    this.identifier = params.identifier || params.carerixId;
    this.companyName = params.companyName;
  }
}

decorate(InboxCompany, {
  id: observable,
  identifier: observable,
  setup: action
});

export default InboxCompany;
