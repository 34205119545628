import { observable, action } from 'mobx'
import _ from 'lodash'

import agent from '../../agent'
import BaseStore from '../BaseStore'

class VideoMessageStore extends BaseStore {
  @observable id = ''
  @observable link = ''

  constructor(args) {
    super()
    this.setup(args)
  }

  @action
  setup(args) {
    this.id = args.id
    this.link = args.link
  }

  @action
  async update(data) {
    const res = await agent.VideoMessage.update(data)
    this.setup(res.data.updateVideoMessageMutation.videomessage)
    const ok = _.get(res, 'data.updateVideoMessageMutation.ok')
    if (!ok) throw Error('Video message not updated')
  }
}

export default VideoMessageStore
