import { observable, action } from 'mobx'

import GenericMessageStore from './GenericMessageStore'
import TagStore from './TagStore'

import agent from '../../agent'
import BaseStore from '../BaseStore'

class VariationStore extends BaseStore {
  @observable id = ''
  @observable tags = []
  @observable message = null

  constructor(args) {
    super()
    this.setup(args)
  }

  @action
  setup(args) {
    this.id = args.id
    this.message = new GenericMessageStore(args.message)
    args.tags.forEach(tag => {
      this.tags.push(new TagStore(tag))
    })
  }

  @action
  addTag(tag) {
    this.tags.push(tag)
    this.updateTags()
  }

  @action
  async updateTags() {
    const data = {
      variationId: this.id,
      tagsId: this.tags.map(tag => tag.id),
    }
    await agent.Variation.updateTags(data)
  }
}

export default VariationStore
