import { action, decorate, observable, runInAction } from 'mobx';
import { userStore } from 'stores';
import AtsFieldMapping from 'stores/AtsFieldMapping.store';
import BaseStore from 'stores/BaseStore';
import ScalarNameStore from 'stores/ScalarNameStore';
import ConditionStore from './Condition.store';

const getAtsFieldType = (atsFieldMapping) =>
  atsFieldMapping.atsFields[
    atsFieldMapping.atsFields.length - 1
  ].dataType?.toLowerCase();

class QueryStatementStore extends BaseStore {
  id = '';
  negate = false;
  order = 0;
  condition = null;
  operator = '';
  value = '';
  scalarName = {};
  atsFieldMapping = {};

  constructor(statement) {
    super();
    if (statement) {
      this.id = statement.id;
      this.negate = statement.negate;
      this.order = statement.order;
      this.condition = statement.condition
        ? new ConditionStore(statement.condition)
        : null;
      this.operator = statement.operator;
      this.value = statement.value;
      this.scalarName = new ScalarNameStore(statement.scalarName);
      this.atsFieldMapping = new AtsFieldMapping(statement.atsFieldMapping);
    }
  }

  async updateQueryStatementAtsFieldMapping(
    targetAtsFieldId,
    atsEntityTypeId,
    targetHandler
  ) {
    const { selectedChatbot } = userStore;
    const {
      data: { autoCreateAtsFieldMapping },
    } = await selectedChatbot.createAtsFieldMapping(
      null,
      targetAtsFieldId,
      atsEntityTypeId,
      targetHandler
    );

    if (autoCreateAtsFieldMapping.ok) {
      const { atsFieldMapping } = autoCreateAtsFieldMapping;
      runInAction(() => {
        const newAtsFieldMappingDataType = getAtsFieldType(atsFieldMapping);
        // when the new field mapping is the same type as the old one we wanna keep the values.
        if (this.atsFieldDataType !== newAtsFieldMappingDataType) {
          this.condition = null;
          this.value = '';
        }
        this.atsFieldMapping = new AtsFieldMapping(atsFieldMapping);
      });
      return this.updateQueryStatement();
    }
    return false;
  }

  async updateQueryStatement() {
    const success = await this.updateGenericType(
      'queryStatement',
      this,
      'Query'
    );
    if (success) return success;
    return false;
  }

  get atsFieldDataType() {
    if (this.atsFieldMapping.atsFields.length) {
      return this.atsFieldMapping.atsFields[
        this.atsFieldMapping.atsFields.length - 1
      ].dataType?.toLowerCase();
    }
    return null;
  }
}

decorate(QueryStatementStore, {
  id: observable,
  negate: observable,
  order: observable,
  condition: observable,
  operator: observable,
  value: observable,
  atsFieldMapping: observable,
  updateStatement: action,
  updateQueryStatementAtsFieldMapping: action,
});

export default QueryStatementStore;
