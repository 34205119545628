export function renderButtons(buttons) {
  if (buttons.length) {
    const buttonsArray = buttons.map(
      btn =>
        `<button class="button chat-widget-trigger" data-id="${btn.dataId}" onclick="loadConversationForButton(this)">${btn.text}</button>`,
    )

    return `<div class="buttons-wrapper">${buttonsArray.join(' ')}</div>\\`
  }
  return '\\'
}

export function renderTimedCall(clientName, time, configVariableName) {
  if (!Number.isNaN(time) && time > 0) {
    return `setTimeout(function() { JobotiBot('${clientName}', ${configVariableName}) }, ${time *
      1000});`
  }
  return `JobotiBot('${clientName}', ${configVariableName})`
}

export function renderIconShape(isRectangle) {
  if (isRectangle) {
    return `botAvatar: {
      desktop: {
          isRectangle: true,
      }
  },`
  }
  return ''
}

export function renderUserData(widgetIconStartFlow) {
  if (widgetIconStartFlow && widgetIconStartFlow.length > 0) {
    return `userData: { start_flow: "${widgetIconStartFlow}"},`
  }
  return ''
}
