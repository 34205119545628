import { decorate, observable, runInAction, action } from 'mobx'
import agent from 'agent'
import _ from 'lodash'
import BaseStore from './BaseStore'
import ListEntitySynonym from './ListEntitySynonym.store'

class ListEntityValue extends BaseStore {
  id
  name
  synonyms

  constructor(args) {
    super(args)
    this.setup(args)
  }

  setup({ id, name, synonyms }) {
    this.id = id
    this.name = name
    this.synonyms = []
    if (synonyms) {
      synonyms.forEach(synonym => {
        this.synonyms.push(new ListEntitySynonym(synonym))
      })
    }
  }

  async save() {
    const data = {
      id: this.id,
      name: this.name,
    }
    await agent.ListEntityValue.update(data)
  }

  static async create(data) {
    const created = await agent.ListEntityValue.create(data)
    return new ListEntityValue(created)
  }

  static async delete(id) {
    await agent.ListEntityValue.delete(id)
  }

  async updateSynonyms(synonyms) {
    this.synonyms = synonyms.map(
      synonym => new ListEntitySynonym({ name: synonym }),
    )
    const data = { id: this.id, synonyms }
    await agent.ListEntitySynonym.update(data)
  }
}

decorate(ListEntityValue, {
  id: observable,
  name: observable,
  synonyms: observable,
  setup: action,
  save: action,
  updateSynonyms: action,
})

export default ListEntityValue
