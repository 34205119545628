import { action, observable, decorate, runInAction } from 'mobx';
import BaseStore from './BaseStore';

class InboxLabel extends BaseStore {
  id = null;
  name = '';
  color = '';

  constructor(args) {
    runInAction(() => {
      super(args);
      this.setup(args);
    });
  }

  setup(args) {
    this.id = args.id;
    this.name = args.name;
    this.color = args.color;
  }
}

decorate(InboxLabel, {
  id: observable,
  name: observable,
  color: observable,
  setup: action,
});

export default InboxLabel;
