import agent from 'agent';
import { action, decorate, observable } from 'mobx';
import TagStore from 'stores/ActionStore/TagStore';
import BaseStore from 'stores/BaseStore';
import WhatsAppParameter from './Parameter/WhatsAppParameter.store';

class WhatsAppVariation extends BaseStore {
  id;
  language;
  languageTag;
  content;
  buttons;
  attachmentTitle;
  attachmentUrl;
  attachmentMimeType;
  status;
  parameters;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.id = params?.id;
    this.language = params?.language;
    this.languageTag = new TagStore(params?.languageTag || {});
    this.content = params?.content;
    this.buttons = params?.buttons?.sort((a, b) => {
      // Assuming id could be undefined or an empty string
      const idA = parseInt(a.id, 10) || 0;
      const idB = parseInt(b.id, 10) || 0;

      return idA - idB;
    });
    this.attachmentTitle = params?.attachmentTitle;
    this.attachmentUrl = params?.attachmentUrl;
    this.attachmentMimeType = params?.attachmentMimeType;
    this.status = params?.status;
    this.parameters = Array.isArray(params?.parameters)
      ? params?.parameters.map((parameter) => new WhatsAppParameter(parameter))
      : [];
  }

  async save() {
    const data = {
      id: this.id,
      language: this.language,
      languageTag: this.languageTag?.id,
      content: this.content,
      buttons: this.buttons,
      attachmentTitle: this.attachmentTitle,
      attachmentUrl: this.attachmentUrl,
      attachmentMimeType: this.attachmentMimeType,
      status: this.status,
      parameters: this.parameters,
    };
    await agent.WhatsAppVariation.update(data);
  }
}

decorate(WhatsAppVariation, {
  id: observable,
  language: observable,
  languageTag: observable,
  content: observable,
  buttons: observable,
  attachmentTitle: observable,
  attachmentMimeType: observable,
  footer: observable,
  status: observable,
  parameters: observable,
  setup: action,
});

export default WhatsAppVariation;
