import _ from 'lodash'
import requests from './requests'

const listQuery = `
  query getAllGroupAppointments{
    allGroupAppointments{
      id
      type
      maxNrAttendees
      planRange
      offsetDays
      maxNrEventToShow
      calendarAuthHandler
      calendarAuthToken
      filterMap
    }
  }
`

const createMutation = `
  mutation createGroupAppointment ($data: GroupAppointmentCreateGenericType!) {
    createGroupAppointment(newGroupappointment: $data){
        groupappointment{
          id
          type
          maxNrAttendees
          planRange
          offsetDays
          maxNrEventToShow
          calendarAuthHandler
          calendarAuthToken
        }
    }
  }
`

const updateMutation = `
  mutation updateGroupAppointment($data: GroupAppointmentUpdateGenericType! ){
    updateGroupAppointment(newGroupappointment: $data){
        groupappointment{
            id
            type
            maxNrAttendees
            planRange
            offsetDays
            maxNrEventToShow
            filterMap
        }
    }
  }
`
const deleteMutation = `
    mutation deleteGroupAppointment($id: ID!){
      deleteGroupAppointment(id: $id){
        ok
      }
    }
`

const mutationUpdateGroupAppointmentCalendarToken = `
mutation updateGroupAppointmentCalendarToken($token:String!,$id:ID!){
  updateGroupAppointmentCalendarToken(token:$token, id:$id) {
    ok
  }
}`

const GroupAppointment = {
  data: async () => {
    const res = await requests.gql.chatserver.query(listQuery)
    return {
      groupAppointments: _.get(res, 'data.allGroupAppointments'),
    }
  },
  create: async data => {
    const res = await requests.gql.chatserver.mutate(createMutation, { data })
    return _.get(res, 'data.createGroupAppointment.groupappointment')
  },
  update: async data => {
    const res = await requests.gql.chatserver.mutate(updateMutation, { data })
    return _.get(res, 'data.updateGroupAppointment.grouppappointment')
  },
  updateCalendarToken: async (id, token) => {
    const response = await requests.gql.chatserver.mutate(
      mutationUpdateGroupAppointmentCalendarToken,
      { id, token },
    )
    const { ok } = response.data.updateGroupAppointmentCalendarToken
    return ok
  },
  delete: async id => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, { id })
    const ok = _.get(res, 'data.deleteGroupAppointment.ok')
    if (!ok) throw new Error('can not delete group appointment')
  },
}

export default GroupAppointment
