import {
  observable,
  action,
  computed,
  toJS,
  runInAction,
  decorate,
} from 'mobx';
import { message } from 'antd';
import { isEmpty } from 'lodash';
import { fakeFormatMessage as formatMessage } from '../locales/utils';
import WidgetSettings from '../agent/widget-settings';
import { stringToColorHex } from '../utils/utils';
import ChatbotAvatar from '../agent/chatbot-avatar';
import { ORIGINAL_IMAGE_PREFIX } from '../constants';
import BaseStore from './BaseStore';
import WidgetPreviewStore from './WidgetPreviewStore';

class WidgetSettingsStore extends BaseStore {
  isLoading = true;

  clientID = '';

  clientName = '';

  widgetPreview = null;

  constructor(id, name) {
    super();
    this.clientID = id;
    this.clientName = name;
    this.widgetPreview = new WidgetPreviewStore(name);
  }

  // Values
  properties = {};

  // Open settings window state
  isSettingsWidgetOpen = false;

  widgetPreview = null;

  clearSettings() {
    this.properties = {};
  }

  async loadData() {
    if (!isEmpty(this.properties)) return;
    this.isLoading = true;
    const result = await WidgetSettings.getSettings(this.clientID);
    runInAction(() => {
      this.properties.widgetIcon = result.widgetIcon;
      this.properties.botAvatar = result.botAvatar;
      this.properties.widgetName = result.widgetName;
      this.properties.autoOpen = result.autoOpen;
      this.properties.showExtraBackButton = result.showExtraBackButton;
      this.properties.hideTextInput = result.hideTextInput;
      this.properties.settingsTemplate = result.settingsTemplate;
      this.properties.widgetChatName = result.widgetChatName;
      this.properties.headerText = result.headerText;
      this.properties.introAppText = result.introAppText;
      this.properties.introductionText = result.introductionText;
      this.properties.introductionTextTranslations =
        result.introductionTextTranslations;
      this.properties.welcomebackMessage = result.welcomebackMessage;
      this.properties.messageIndicatorTitleSingular =
        result.messageIndicatorTitleSingular;
      this.properties.messageIndicatorTitlePlural =
        result.messageIndicatorTitlePlural;
      this.properties.inputPlaceholder = result.inputPlaceholder;
      this.properties.sendButtonText = result.sendButtonText;
      this.properties.MESSAGE_DELAY_DEFAULT = result.MESSAGE_DELAY_DEFAULT || 0;
      this.properties.MESSAGE_DELAY_MAX = result.MESSAGE_DELAY_MAX || 0;
      this.properties.MESSAGE_DELAY_SIZE = result.MESSAGE_DELAY_SIZE || 0;
      this.properties.calendar = result.calendar;
      this.properties.coreColor = stringToColorHex(result.style.coreColor);
      this.properties.coreTextColor = stringToColorHex(
        result.style.coreTextColor
      );
      this.properties.secondaryColor = stringToColorHex(
        result.style.secondaryColor
      );
      this.properties.secondaryTextColor = stringToColorHex(
        result.style.secondaryTextColor
      );

      this.isLoading = false;
    });
  }

  updateProperty(name, value) {
    this.properties[name] = value;
  }

  toggleSettingWidowState() {
    this.isSettingsWidgetOpen = !this.isSettingsWidgetOpen;
  }

  async saveChanges() {
    const body = toJS(this.properties);
    // Use the minified image when saving
    body.botAvatar = body.botAvatar.replace(ORIGINAL_IMAGE_PREFIX, '');
    body.widgetIcon = body.widgetIcon.replace(ORIGINAL_IMAGE_PREFIX, '');

    try {
      message.loading(
        formatMessage({ id: 'stores/WidgetSettingsStore/savingChanges' })
      );
      await WidgetSettings.saveChanges(this.clientID, body);
      message.success(
        formatMessage({ id: 'stores/WidgetSettingsStore/settingsUpdated' })
      );
    } catch (error) {
      message.error(
        formatMessage({
          id: 'stores/WidgetSettingsStore/anErrorHasOccurredWhileSavingYourSettings',
        })
      );
    }
  }

  get getProps() {
    return toJS(this.properties);
  }

  get isSettingsOpen() {
    return this.isSettingsWidgetOpen;
  }

  uploadFile = async (file, property) => {
    if (
      file.name &&
      typeof file.name === 'string' &&
      typeof property === 'string'
    ) {
      const downloadUrl = await ChatbotAvatar.uploadAvatar(
        this.clientName,
        file,
        property
      );
      if (downloadUrl) {
        runInAction(() => {
          this.properties[property] = downloadUrl;
        });
      }
    } else {
      message.error(
        formatMessage({
          id: 'stores/WidgetSettingsStore/errorPleaseSelectAValideFile',
        }),
        2
      );
    }
  };

  get getWidgetIcon() {
    return this.isLoading ? '' : this.properties.widgetIcon;
  }
}

// decorate
decorate(WidgetSettingsStore, {
  isLoading: observable,
  clientID: observable,
  clientName: observable,
  properties: observable,
  isSettingsWidgetOpen: observable,
  widgetPreview: observable,
  uploadFile: action,
  saveChanges: action,
  clearSettings: action,
  loadData: action,
  toggleSettingWidowState: action,
  updateProperty: action,
  addIntroTextProperty: action,
  getProps: computed,
  isSettingsOpen: computed,
  getWidgetIcon: computed,
});

export default WidgetSettingsStore;
