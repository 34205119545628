import _ from 'lodash'
import requests from './requests'

const createMutation = `
mutation CreateKeyword($data: KeywordCreateGenericType!) {
  createKeyword(newKeyword: $data) {
    keyword {
      id
      text
    }
  }
}
`

const updateMutation = `
mutation UpdateKeyword($data: KeywordUpdateGenericType!) {
  updateKeyword(newKeyword: $data) {
    keyword {
      id
      text
    }
  }
}
`

const deleteMutation = `
mutation DeleteKeyword($id: ID!) {
  deleteKeyword(id: $id) {
    keyword {
      id
      text
    }
    ok
  }
}
`

const Keyword = {
  create: async data => {
    const res = await requests.gql.chatserver.mutate(createMutation, { data })
    return _.get(res, 'data.createKeyword.keyword')
  },
  update: async data => {
    const res = await requests.gql.chatserver.mutate(updateMutation, { data })
    return _.get(res, 'data.updateKeyword.keyword')
  },
  delete: async id => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, { id })
    const ok = _.get(res, 'data.deleteKeyword.ok')
    if (!ok) throw new Error('can not delete keyword')
  },
}

export default Keyword
