import requests from './requests';

const messageClassFragment = `
fragment messageClass on MessageNode {
  id
  variations {
    id
    tags {
      id
      color
      content
      kind
    }
    message {
      id
      textMessage {
        id
        content
      }
      videoMessage {
        id
        link
      }
      imageMessage {
        id
        link
      }
      documentMessage {
        id
        link
      }
      linkMessage {
        id
        placeholder
        link
        isDocument
      }
      geoLocationMessage {
        id
        lat
        lng
      }
    }
  }
}
`;

const updateQuery = `
mutation OptionLabelUpdate($data: OptionLabelUpdateGenericType!) {
    optionLabelUpdate(newOptionlabel: $data){
      optionlabel {
        id
        label
        enabled
        messageClass {
          ...messageClass
        }
        option {
          id
        }
      }
      ok
      errors {
        field
      }
    }
  }
${messageClassFragment}
`;

const createMutation = `
mutation CreateOptionLabel($data: OptionLabelCreateGenericType!) {
  optionLabelCreate(newOptionlabel: $data) {
    optionlabel {
      id
      label
      enabled
      messageClass {
        ...messageClass
      }
      option {
        id
      }
    }
    ok
  }
}
${messageClassFragment}
`;

const deleteMutation = `
mutation DeleteOptionLabel($id: ID!) {
  optionLabelDelete(id: $id) {
    ok
  }
}
`;

const OptionLabel = {
  create: (data) => requests.gql.chatserver.mutate(createMutation, data),
  update: (data) => requests.gql.chatserver.mutate(updateQuery, data),
  delete: (id) => requests.gql.chatserver.mutate(deleteMutation, { id }),
};

export default OptionLabel;
