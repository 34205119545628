import _ from 'lodash';
import { action, decorate, observable, runInAction, toJS } from 'mobx';

import agent from '../../agent';
import { arrayMove } from '../../utils/utils';
import BaseStore from '../BaseStore';
import MessageStore from './MessageStore';
import OptionLabelStore from './OptionLabelStore';

class ActionWithOptionsStore extends BaseStore {
  id = '';
  content = '';
  optionlabelSet = [];
  messageClass = null;

  constructor(args) {
    super();

    this.setup(args);
    this.save = _.debounce(this.save, 2000);
  }

  setup(args) {
    this.id = args.id;
    this.actionId = args.actionId;
    this.content = args.content;
    this.optionlabelSet = [];
    this.messageClass = new MessageStore(args.messageClass);
    args.optionlabelSet.forEach((optionLabel) =>
      this.optionlabelSet.push(new OptionLabelStore(optionLabel))
    );
  }

  async update(data) {
    const res = await agent.ActionWithOptions.update(data);
    this.setup(res.data.actionWithOptionsUpdate.actionwithoptions);
    const ok = _.get(res, 'data.actionWithOptionsUpdate.ok');
    if (!ok) throw Error('Action not updated');
  }

  moveOption(optionToMove, delta) {
    const optionLabelSet = toJS(this.optionlabelSet);
    const index = _.findIndex(optionLabelSet, (a) => a.id === optionToMove.id);
    const newIndex = index + delta;
    if (newIndex < 0 || newIndex === optionLabelSet.length) return; // Already at the top or bottom.
    const orderedOptionLabels = arrayMove(optionLabelSet, index, newIndex);
    runInAction(() => {
      this.optionlabelSet = orderedOptionLabels.map(
        (opt) => new OptionLabelStore(opt)
      );
    });
  }

  moveUpOption(optionToMove) {
    this.moveOption(optionToMove, -1);
    this.save();
  }

  moveDownOption(optionToMove) {
    this.moveOption(optionToMove, 1);
    this.save();
  }

  async save() {
    const data = {
      actionID: this.id,
      optionIDs: this.optionlabelSet.map((option) => option.id),
    };
    const res = await agent.ActionWithOptions.updateOptionOrder(data);
    const ok = _.get(res, 'data.updateOptionLabelOrdering.ok');
    if (!ok) {
      throw Error("Couldn't update option order");
    }
  }

  async createOption(optionId) {
    let positionToInsert = 0;
    // Find the last position of the option label
    if (this.optionlabelSet.length > 0) {
      const { position } = _.maxBy(
        this.optionlabelSet,
        (optionLabel) => optionLabel.position
      );
      positionToInsert = position;
    }

    const params = {
      data: {
        label: 'new button',
        action: this.id,
        number: positionToInsert + 1,
        option: optionId,
      },
    };
    const res = await agent.OptionLabel.create(params);
    const { ok, optionlabel } = res.data.optionLabelCreate;
    if (!ok) throw Error('Could not create option label');
    runInAction(() => {
      optionlabel.position = positionToInsert;
      this.optionlabelSet.push(new OptionLabelStore(optionlabel));
    });
  }

  async deleteOption(id) {
    _.remove(this.optionlabelSet, { id });
    const res = await agent.OptionLabel.delete(id);
    const ok = _.get(res, 'data.optionLabelDelete.ok');
    if (!ok) throw Error('Could not delete option label');
  }

  setOptionLabelSet(options) {
    this.optionlabelSet = options;
  }
}

decorate(ActionWithOptionsStore, {
  id: observable,
  content: observable,
  optionlabelSet: observable,
  messageClass: observable,
  setup: action,
  update: action,
  moveOption: action,
  createOption: action,
  deleteOption: action,
  setOptionLabelSet: action,
});

export default ActionWithOptionsStore;
