import { decorate, observable } from 'mobx';
import BaseStore from 'stores/BaseStore';

class CampaignTargetEntity extends BaseStore {
  actionId = '';
  actionStatus = '';
  actionType = '';
  isPartiallyCreated = false;
  parentActionId = null;
  startedAt = '';

  constructor(params) {
    super();
    this.setup(params);
  }

  setup(params) {
    this.actionId = params.actionId;
    this.actionType = params.actionType;
    this.actionStatus = params.actionStatus;
    this.isPartiallyCreated = params.isPartiallyCreated;
    this.parentActionId = params.parentActionId;
    this.startedAt = params.startedAt;
  }
}

decorate(CampaignTargetEntity, {
  actionId: observable,
  actionStatus: observable,
  actionType: observable,
  isPartiallyCreated: observable,
  parentActionId: observable,
  startedAt: observable,
});

export default CampaignTargetEntity;
