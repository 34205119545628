import { action, decorate, observable, runInAction } from 'mobx';
import BaseStore from './BaseStore';
import agent from 'agent';
import AgentStore from './AgentStore';

class AgentsStore extends BaseStore {
  agents;
  currentAgent;
  allTeams;

  async fetchAgent(agentId) {
    const response = await agent.Agents.get(agentId);
    runInAction(() => {
      this.currentAgent = new AgentStore(response.data.agent);
    });
  }

  async fetchAllTeams() {
    const response = await agent.Agents.getAllTeams();

    runInAction(() => {
      this.allTeams = response.data.allTeams;
    });
  }
}

decorate(AgentsStore, {
  agents: observable,
  currentAgent: observable,
  allTeams: observable,
  fetchAgent: action,
  fetchAllTeams: action,
});

export default AgentsStore;
