import { decorate, observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import agent from 'agent'
import BaseStore from './BaseStore'
import Intent from './Intent.store'

class Intents extends BaseStore {
  intents = []
  intent
  modelId
  isloading
  selectedIntentId

  async fetch() {
    this.isLoading = true
    const models = await agent.Intent.modelsList()
    if (models.length === 0) {
      throw new Error('No default model to get intents')
    }
    const model = models[0]
    runInAction(() => {
      this.modelId = model.id
      const newIntents = model.intents.map(intent => new Intent(intent))
      this.intents = newIntents
      this.isLoading = false
    })
  }

  async fetchOne(id) {
    this.isLoading = true
    const intent = await Intent.get(id)
    runInAction(() => {
      this.intent = intent
      this.isLoading = false
    })
  }

  async addIntent(name) {
    const data = {
      name,
      model: this.modelId,
    }
    const intent = await Intent.create(data)
    runInAction(() => {
      this.intents.unshift(intent)
    })
  }

  async deleteIntent(id) {
    _.remove(this.intents, { id })
    await Intent.delete(id)
  }
}

decorate(Intents, {
  intents: observable,
  intent: observable,
  modelId: observable,
  isLoading: observable,
  selectedIntentId: observable,
  fetch: action,
  fetchOne: action,
  deleteIntent: action,
})

export default Intents
