import agent from 'agent';
import { DATE_RANGE_TYPE } from 'constants/calendarOverview.constants';
import {
  DEFAULT_END_EXECUTION_TIME,
  DEFAULT_START_EXECUTION_TIME,
} from 'constants/campaign.constants';
import { action, decorate, observable, runInAction } from 'mobx';
import { userStore } from 'stores';
import BaseStore from 'stores/BaseStore';
import {
  getWorkDaysValuesArr,
  sortScheduleAvailabilities,
} from 'utils/calendarOverview.utils';
import EventConfiguration from './EventConfiguration.store';
import ScheduleAvailability from './ScheduleAvailability.store';
import ScheduleAvailabilitySchema from './ScheduleAvailabilitySchema.store';

class EventPreference extends BaseStore {
  id;
  dailyLimit = 10;
  planOffsetDays = 1;
  sameDayOffsetHours = 0;
  planRange = 4;
  scheduleAvailabilities = [];
  timeAfterEvent = 0;
  timeBeforeEvent = 0;
  eventConfigurations;
  enabled = false;
  isLoading = false;
  dayNameForNewAvailability = null;
  isCreatingNewEventPreference = false;
  isAnyEventPreferenceInputUpdated = false;
  relatedCalendarUser = null;
  offsetRangeValue = 1;
  offsetRangeType = DATE_RANGE_TYPE.DAY;
  scheduleAvailabilitySchema = null;
  isUpdatingAvailabilities = false;
  isSwitchedToUseAvailabilitySchema = false;
  isScheduleAvailabilitySchemaModalOpen = false;

  constructor(args) {
    super();
    this.setup(args);
  }

  setup(args) {
    this.id = args?.id;
    this.dailyLimit = args?.dailyLimit;
    this.planOffsetDays = args?.planOffsetDays;
    this.sameDayOffsetHours = args?.sameDayOffsetHours;
    this.planRange = args?.planRange;
    this.timeAfterEvent = args?.timeAfterEvent;
    this.timeBeforeEvent = args?.timeBeforeEvent;
    this.enabled = args?.enabled;
    this.eventConfigurations = args?.eventConfigurations?.map(
      (eventConfiguration) => new EventConfiguration(eventConfiguration)
    );
    if (args?.scheduleAvailabilities?.length) {
      const sortedTimeSegments = sortScheduleAvailabilities(
        args.scheduleAvailabilities
      );
      this.scheduleAvailabilities = sortedTimeSegments.map(
        (scheduleAvailability) => new ScheduleAvailability(scheduleAvailability)
      );
    }
    this.offsetRangeValue =
      args?.sameDayOffsetHours > 0
        ? args?.sameDayOffsetHours
        : args?.planOffsetDays || 1;
    this.offsetRangeType =
      args?.sameDayOffsetHours > 0 ? DATE_RANGE_TYPE.HOUR : DATE_RANGE_TYPE.DAY;
    if (args?.scheduleAvailabilitySchema?.id) {
      this.scheduleAvailabilitySchema = new ScheduleAvailabilitySchema(
        args.scheduleAvailabilitySchema
      );
    } else {
      this.scheduleAvailabilitySchema = null;
    }
  }

  get getProperties() {
    return {
      planOffsetDays: this.isCreatingNewEventPreference
        ? this.planOffsetDays || 1
        : this.planOffsetDays || 0,
      sameDayOffsetHours: this.sameDayOffsetHours || 0,
      planRange: this.planRange || 4,
      timeBeforeEvent: this.timeBeforeEvent || 0,
      timeAfterEvent: this.timeAfterEvent || 0,
      dailyLimit: this.dailyLimit || 10,
      enabled: this.isCreatingNewEventPreference ? true : this.enabled,
      scheduleAvailabilities: this.scheduleAvailabilitySchema?.id
        ? []
        : this.scheduleAvailabilities.map((availability) => availability?.id),
      scheduleAvailabilitySchema: this.scheduleAvailabilitySchema?.id
        ? this.scheduleAvailabilitySchema?.id
        : null,
      eventConfigurations: [
        userStore.calendarSettings?.configurationToEdit?.id,
      ],
    };
  }

  resetStore(useDefaultEventPreferenceValues = false) {
    const defaultEventPreference =
      userStore.calendarSettings.configurationToEdit?.defaultEventPreference;

    runInAction(() => {
      this.id = null;
      this.dailyLimit =
        useDefaultEventPreferenceValues && defaultEventPreference?.id
          ? defaultEventPreference.dailyLimit
          : 10;
      this.planOffsetDays =
        useDefaultEventPreferenceValues && defaultEventPreference?.id
          ? defaultEventPreference.planOffsetDays
          : 1;
      this.sameDayOffsetHours =
        useDefaultEventPreferenceValues && defaultEventPreference?.id
          ? defaultEventPreference.sameDayOffsetHours
          : 0;
      this.planRange =
        useDefaultEventPreferenceValues && defaultEventPreference?.id
          ? defaultEventPreference.planRange
          : 4;
      this.timeAfterEvent =
        useDefaultEventPreferenceValues && defaultEventPreference?.id
          ? defaultEventPreference.timeAfterEvent
          : 0;
      this.timeBeforeEvent =
        useDefaultEventPreferenceValues && defaultEventPreference?.id
          ? defaultEventPreference.timeBeforeEvent
          : 0;
      this.enabled = false;
      this.eventConfigurations = null;
      this.scheduleAvailabilities = [];
      this.scheduleAvailabilitySchema = null;
      this.offsetRangeValue =
        useDefaultEventPreferenceValues && defaultEventPreference?.id
          ? defaultEventPreference.offsetRangeValue
          : 1;
      this.offsetRangeType =
        useDefaultEventPreferenceValues && defaultEventPreference?.id
          ? defaultEventPreference.offsetRangeType
          : DATE_RANGE_TYPE.DAY;
    });
  }

  async getEventPreference() {
    runInAction(() => {
      this.isLoading = true;
    });

    const eventPreference = await agent.Calendar.getEventPreference(this?.id);
    runInAction(() => {
      this.setup(eventPreference);
      this.isLoading = false;
    });
  }

  async updateEventPreference(fieldsToUpdate) {
    let newEventpreference;
    if (fieldsToUpdate) {
      newEventpreference = {
        id: this.id,
        ...fieldsToUpdate,
      };
    } else {
      newEventpreference = { id: this.id, ...this.getProperties };
    }

    const updatedEventPreference = await agent.Calendar.updateEventPreference(
      newEventpreference
    );
    if (updatedEventPreference?.ok) {
      runInAction(() => {
        this.setup(updatedEventPreference.eventpreferences);
        this.isAnyEventPreferenceInputUpdated = false;

        // update ui for defaultEventPreference
        if (
          userStore.calendarSettings?.configurationToEdit
            ?.defaultEventPreference?.id === this.id
        ) {
          userStore.calendarSettings.configurationToEdit.defaultEventPreference =
            this;
        }
      });
    }
  }

  async createDefaultScheduleAvailabilities(isUpdateEventPreference) {
    if (!userStore.employee.calendarUser && !this.relatedCalendarUser) return;
    runInAction(() => {
      this.isUpdatingAvailabilities = true;
    });

    const daysValues = getWorkDaysValuesArr();
    const scheduleAvailabilities = await Promise.all(
      daysValues.map(async (day) => {
        const newScheduleavailability = {
          day,
          startTime: DEFAULT_START_EXECUTION_TIME,
          endTime: DEFAULT_END_EXECUTION_TIME,
        };
        const newAvailability = await agent.Calendar.createScheduleAvailability(
          newScheduleavailability
        );

        return newAvailability?.scheduleavailability;
      })
    );
    // we pass true when we close sideDrawer to update the server
    if (isUpdateEventPreference) {
      const scheduleAvailabilitiesIds = scheduleAvailabilities.map(
        (availability) => availability?.id
      );
      await this.updateEventPreference({
        scheduleAvailabilities: scheduleAvailabilitiesIds,
      });
    }

    runInAction(() => {
      const sortedTimeSegments = sortScheduleAvailabilities(
        scheduleAvailabilities
      );
      this.scheduleAvailabilities = sortedTimeSegments.map(
        (scheduleAvailability) => new ScheduleAvailability(scheduleAvailability)
      );
      this.isUpdatingAvailabilities = false;
    });
  }

  async createEventPreference() {
    if (!userStore.employee.calendarUser && !this.relatedCalendarUser) return;

    runInAction(() => {
      this.isLoading = true;
      this.isCreatingNewEventPreference = true;
    });

    // if there is a defaultAvailabilitiesSchema, we don't create default values
    if (this?.scheduleAvailabilities?.length === 0) {
      if (this.relatedCalendarUser?.defaultAvailabilitySchema?.id) {
        runInAction(() => {
          this.scheduleAvailabilitySchema =
            this.relatedCalendarUser?.defaultAvailabilitySchema;
          this.scheduleAvailabilities = [];
        });
      } else {
        await this.createDefaultScheduleAvailabilities();
      }
    }
    const eventPreferenceToCreate = this.getProperties;
    const newEventPreference = await agent.Calendar.createEventPreference(
      eventPreferenceToCreate
    );

    if (newEventPreference?.ok) {
      // get all user event preferences ids
      const eventPreferencesIds =
        this?.relatedCalendarUser?.eventPreferences?.map(
          (eventPreference) => eventPreference.id
        );

      // save the new event preference on user calendar
      this.relatedCalendarUser.updateCalendarUser({
        eventPreferences: [
          ...eventPreferencesIds,
          newEventPreference.eventpreferences.id,
        ],
      });

      // update ui
      runInAction(() => {
        this.setup(newEventPreference.eventpreferences);
        this.isLoading = false;
        this.isCreatingNewEventPreference = false;

        if (
          this?.relatedCalendarUser?.id ===
          userStore?.employee?.calendarUser?.id
        ) {
          userStore.calendarSettings.loggedInUserEventPreferences = userStore
            ?.calendarSettings?.loggedInUserEventPreferences?.length
            ? [...userStore.calendarSettings.loggedInUserEventPreferences, this]
            : [this];
        }
      });
    }
  }

  async deleteEventPreference() {
    const promiseArr = [];
    this.scheduleAvailabilities.forEach((availability) =>
      promiseArr.push(this.deleteScheduleAvailability(availability.id))
    );
    await Promise.all(promiseArr);
    const isEventPreferenceDeleted = await agent.Calendar.deleteEventPreference(
      this.id
    );
    if (isEventPreferenceDeleted) {
      runInAction(() => {
        this.resetStore();
      });
    }
    return isEventPreferenceDeleted;
  }

  async createScheduleAvailability(newScheduleavailability) {
    runInAction(() => {
      this.dayNameForNewAvailability = newScheduleavailability.day;
    });
    // create availability
    const newAvailability = await agent.Calendar.createScheduleAvailability(
      newScheduleavailability
    );
    if (newAvailability?.ok) {
      runInAction(() => {
        const sortedTimeSegments = sortScheduleAvailabilities([
          new ScheduleAvailability(newAvailability.scheduleavailability),
          ...this.scheduleAvailabilities,
        ]);
        this.scheduleAvailabilities = sortedTimeSegments;
        this.dayNameForNewAvailability = null;
      });

      // update event preference
      await this.updateEventPreference();
    }
  }

  async updateScheduleAvailability(newScheduleavailability) {
    const updateScheduleAvailability =
      await agent.Calendar.updateScheduleAvailability(newScheduleavailability);
    if (updateScheduleAvailability.ok) {
      runInAction(() => {
        const sortedTimeSegments = sortScheduleAvailabilities([
          new ScheduleAvailability(
            updateScheduleAvailability.scheduleavailability
          ),
          ...this.scheduleAvailabilities.filter(
            (scheduleAvailability) =>
              scheduleAvailability.id !==
              updateScheduleAvailability.scheduleavailability.id
          ),
        ]);
        this.scheduleAvailabilities = sortedTimeSegments;
      });
    }
  }

  async deleteScheduleAvailability(scheduleAvailabilityId) {
    const deleteScheduleAvailability =
      await agent.Calendar.deleteScheduleAvailability(scheduleAvailabilityId);
    if (deleteScheduleAvailability.ok) {
      runInAction(() => {
        this.scheduleAvailabilities = this.scheduleAvailabilities.filter(
          (scheduleAvailability) =>
            scheduleAvailability.id !== scheduleAvailabilityId
        );
      });
    }
  }
}

decorate(EventPreference, {
  id: observable,
  dailyLimit: observable,
  planOffsetDays: observable,
  sameDayOffsetHours: observable,
  planRange: observable,
  scheduleAvailabilities: observable,
  timeAfterEvent: observable,
  timeBeforeEvent: observable,
  eventConfigurations: observable,
  enabled: observable,
  isLoading: observable,
  dayNameForNewAvailability: observable,
  isCreatingNewEventPreference: observable,
  isAnyEventPreferenceInputUpdated: observable,
  relatedCalendarUser: observable,
  offsetRangeValue: observable,
  offsetRangeType: observable,
  scheduleAvailabilitySchema: observable,
  isUpdatingAvailabilities: observable,
  isSwitchedToUseAvailabilitySchema: observable,
  isScheduleAvailabilitySchemaModalOpen: observable,
  getEventPreference: action,
  deleteEventPreference: action,
  updateEventPreference: action,
  createEventPreference: action,
  createScheduleAvailability: action,
  updateScheduleAvailability: action,
  deleteScheduleAvailability: action,
  resetStore: action,
  createDefaultScheduleAvailabilities: action,
});

export default EventPreference;
