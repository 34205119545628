import { action, decorate, observable, runInAction } from 'mobx';

import agent from 'agent';
import BaseStore from 'stores/BaseStore';
import ActionResult from './ActionResult.store';

class ActionResults extends BaseStore {
  results = [];
  isLoading = false;
  isReplaying = false;
  amountOfPages;
  resultCount;
  selectedClientId;
  ProcessorsPageNumber = 1;

  async clearSelectedClientId() {
    this.isLoading = true;
    runInAction(() => {
      this.selectedClientId = '';
      this.isLoading = false;
    });
  }

  async setSelectedClientId(clientId) {
    this.isLoading = true;
    runInAction(() => {
      this.selectedClientId = clientId;
      this.isLoading = false;
    });
  }

  async fetch(
    processorId,
    selectedChatbotName,
    startDate,
    endDate,
    pageNum,
    pageSize,
    selectedClientId = null
  ) {
    this.isLoading = true;
    const result = await agent.Action.get(
      processorId,
      selectedChatbotName,
      startDate,
      endDate,
      pageNum,
      pageSize,
      selectedClientId || this.selectedClientId
    );

    runInAction(() => {
      this.results = result?.results?.map(
        (actionResult) => new ActionResult(actionResult)
      );
      this.amountOfPages = result.amountOfPages;
      this.resultCount = result.resultCount;
      this.isLoading = false;
    });
  }

  async replayAction(replayActionInfo, fetchInfo) {
    this.isReplaying = true;

    await agent.Action.replayAction(...Object.values(replayActionInfo));
    await this.fetch(...Object.values(fetchInfo));

    runInAction(() => {
      this.isReplaying = false;
    });
  }

  async replayActions(replayActionsInfo, fetchInfo) {
    this.isReplaying = true;

    for (const replayActionInfo of replayActionsInfo) {
      await agent.Action.replayAction(...Object.values(replayActionInfo));
    }

    await this.fetch(...Object.values(fetchInfo));

    runInAction(() => {
      this.isReplaying = false;
    });
  }
}

decorate(ActionResults, {
  results: observable,
  isLoading: observable,
  isReplaying: observable,
  amountOfPages: observable,
  resultCount: observable,
  selectedClientId: observable,
  clearSelectedClientId: action,
  setSelectedClientId: action,
  fetch: action,
  replayAction: action,
  replayActions: action,
  ProcessorsPageNumber: observable,
});

export default ActionResults;
