import { decorate, observable, action, runInAction } from 'mobx';
import BaseStore from 'stores/BaseStore'
import agent from '../../agent';


class Report extends BaseStore {
  id = null;
  title = null;
  description = null;
  url = null;
  isLoading;
  spreadSheetUrl;

  constructor(args) {
    super()

    this.id = args.id
    this.title = args.title
    this.url =  args.url
    this.description = args.description
  }

  async generateReports(url, body) {
    this.isLoading = true;
    const result = await agent.CustomAnalytics.generateReport(url, body);
    runInAction(() => {
      this.spreadSheetUrl = result.spreadsheet_url;
      this.isLoading = false;
    })
    return result;
  }

}

decorate(Report, {
  id: observable,
  title: observable,
  description: observable,
  url: observable,
  isLoading: observable,
  spreadSheetUrl: observable,
  call: action,
  generateReports: action,
})

export default Report;
