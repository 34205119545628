import agent from 'agent';
import { action, decorate, observable, runInAction } from 'mobx';

import BaseStore from 'stores/BaseStore';
import { uniqueArrayValues } from 'utils/utils';

import WhatsAppDefinition from './WhatsAppDefinition.store';

class WhatsAppDefinitions extends BaseStore {
  definitions = [];
  definitionTemplates = [];
  isLoading;
  selectedDefinition = new WhatsAppDefinition();

  /**
   * Synchronizes the WhatsApp templates in the back-end
   */
  async sync() {
    // Execute the sync mutation
    const syncResponse = await agent.WhatsAppDefinition.sync();

    // If the request failed then we will return
    if (!syncResponse.ok) return syncResponse.ok;

    // Request succeeded update the templates

    runInAction(() => {
      this.definitions = syncResponse.whatsappDefinitions.map(
        (whatsappDefinition) => new WhatsAppDefinition(whatsappDefinition)
      );
    });

    return syncResponse.ok;
  }

  /**
   * This function clones a WhatsAppDefinition from JobotiTemplates to the current chatbot.
   * NOTE: This, for security reasons, only works for WhatsAppDefinitions from Joboti Templates.
   *
   * @param id        The id of the WhatsAppDefinition to clone.
   * @param newName   The new name of the cloned WhatsAppDefinition.
   * @returns {Promise<boolean>}
   */
  async clone(id, newName) {
    const response = await agent.WhatsAppDefinition.clone(id, newName);

    if (!response.ok) {
      return false;
    }

    runInAction(() => {
      this.definitions.push(
        new WhatsAppDefinition(response.whatsappDefinition)
      );
    });

    return true;
  }

  fetchDefinitionTemplates = async () => {
    const definitions = await agent.WhatsAppDefinition.listTemplates();
    runInAction(() => {
      this.definitionTemplates = definitions.map(
        (definition) => new WhatsAppDefinition(definition)
      );
    });
  };

  async clearSelectedDefinition() {
    this.isLoading = true;
    runInAction(() => {
      this.selectedDefinition = new WhatsAppDefinition();
      this.isLoading = false;
    });
  }

  async setSelectedDefinition(definition) {
    this.isLoading = true;
    // fetch the definition if it has no templates
    const res = await agent.WhatsAppDefinition.get(definition.id);
    // we are doing this to update the same instance of the definition
    definition.setup(res);

    runInAction(() => {
      this.selectedDefinition = definition;
      this.isLoading = false;
    });
  }

  async fetch() {
    this.isLoading = true;
    const definitions = await agent.WhatsAppDefinition.list();
    runInAction(() => {
      this.definitions = definitions.map(
        (definition) => new WhatsAppDefinition(definition)
      );
      this.isLoading = false;
    });
  }

  async fetchActive() {
    if (this.definitions.length > 0) return;
    this.isLoading = true;
    const definitions = await agent.WhatsAppDefinition.listActive();
    runInAction(() => {
      this.definitions = definitions.map(
        (definition) => new WhatsAppDefinition(definition)
      );
      this.isLoading = false;
    });
  }

  async fetchOne(id) {
    this.isLoading = true;
    const definition = await agent.WhatsAppDefinition.get(id);
    runInAction(() => {
      this.selectedDefinition = definition
        ? new WhatsAppDefinition(definition)
        : false;
      this.isLoading = false;
    });
  }

  async refreshOne(id) {
    this.isLoading = true;
    const definition = await agent.WhatsAppDefinition.get(id);
    runInAction(() => {
      const definitionIdx = this.definitions
        .map((definition) => definition.id)
        .indexOf(id);
      this.definitions[definitionIdx] = new WhatsAppDefinition(definition);
      this.isLoading = false;
    });
  }

  async create(newDefinition) {
    this.isLoading = true;
    const result = await agent.WhatsAppDefinition.create(newDefinition);
    const whatsAppDefinition = new WhatsAppDefinition(
      result.whatsappdefinition
    );
    runInAction(() => {
      this.definitions.push(whatsAppDefinition);
      this.isLoading = false;
    });
    return result;
  }

  async update(definition, updateDefinition) {
    this.isLoading = true;
    const result = await agent.WhatsAppDefinition.update(updateDefinition);
    runInAction(() => {
      const definitionIdx = this.definitions.findIndex(
        (definition) => definition.id === updateDefinition.id
      );
      this.definitions[definitionIdx] = definition;
      this.isLoading = false;
    });
    return result;
  }

  async updateTemplate(
    definition,
    updateTemplate,
    variationIds,
    parameterIds,
    creatorEmail
  ) {
    this.isLoading = true;

    const {
      id,
      templateName,
      description,
      category,
      version,
      status,
      buttonType,
    } = updateTemplate;
    const template = {
      id,
      templateName: templateName,
      scalarNames: [],
      variations: variationIds,
      parameters: parameterIds,
      creatorEmail: creatorEmail,
      description,
      category,
      status,
      buttonType,
      version,
    };

    await agent.WhatsAppTemplate.update(template);

    runInAction(() => {
      const definitionIdx = this.definitions
        .map((storeDefinition) => storeDefinition.id)
        .indexOf(definition.id);
      const templateIdx = this.definitions[definitionIdx].availableTemplates
        .map((storeTemplate) => storeTemplate.id)
        .indexOf(template.id);

      this.definitions[definitionIdx].availableTemplates[templateIdx] =
        updateTemplate;
      this.isLoading = false;
    });
  }

  async updateActiveTemplate(definition, activatedTemplate) {
    this.isLoading = true;

    const updateDefinition = {
      id: definition.id,
      name: definition.name,
      isSmartInboxTemplate: definition.isSmartInboxTemplate,
      versionsHistoricallyCreated: definition.versionsHistoricallyCreated,
      templateIdentifier: definition.templateIdentifier,
      activeTemplate: activatedTemplate.id,
      availableTemplates: definition.availableTemplates.map(
        (template) => template.id
      ),
    };

    // TODO: check if this needs to be removed later
    delete updateDefinition.hasChanged;

    await agent.WhatsAppDefinition.update(updateDefinition);

    runInAction(() => {
      const definitionIdx = this.definitions
        .map((storeDefinition) => storeDefinition.id)
        .indexOf(definition.id);

      definition.activeTemplate = activatedTemplate;

      this.definitions[definitionIdx] = definition;
      this.isLoading = false;
    });
  }

  async delete(definition) {
    this.isLoading = true;

    const templateIds = definition.availableTemplates.map(
      (template) => template.id
    );
    const uniqueButtonIds = definition.availableTemplates
      .map((template) =>
        template.variations.map((variation) =>
          variation.buttons.map((button) => button.id)
        )
      )
      .flat(2)
      .filter(uniqueArrayValues);
    const uniqueParameterIds = definition.availableTemplates
      .map((template) => template.parameters.map((parameter) => parameter.id))
      .flat()
      .filter(uniqueArrayValues);

    if (uniqueButtonIds.length > 0) {
      await this.deleteButtons(uniqueButtonIds);
    }

    if (uniqueParameterIds.length > 0) {
      await this.deleteParameters(uniqueParameterIds);
    }

    await this.deleteTemplates(templateIds);
    await agent.WhatsAppDefinition.delete(definition.id);

    runInAction(() => {
      this.definitions = this.definitions.filter(
        (item) => item.id !== definition.id
      );
      this.isLoading = false;
    });
  }

  async deleteAllTemplatesAndDefinition(id) {
    await agent.WhatsAppDefinition.deleteAllTemplatesAndDefinition(id);

    runInAction(() => {
      this.definitions = this.definitions.filter((item) => item.id !== id);
    });
  }

  async deleteTemplates(ids) {
    const uniqueVariationIds = this.definitions
      .map((definition) => definition.availableTemplates)
      .flat()
      .filter((template) => ids.indexOf(template.id) > -1)
      .map((template) => template.variations.map((variation) => variation.id))
      .flat()
      .filter(uniqueArrayValues);

    const uniqueButtonIds = this.definitions
      .map((definition) => definition.availableTemplates)
      .flat()
      .filter((template) => ids.indexOf(template.id) > -1)
      .map((template) =>
        template.variations.map((variation) =>
          variation.buttons.map((button) => button.id)
        )
      )
      .flat(2)
      .filter(uniqueArrayValues);

    const uniqueParameterIds = this.definitions
      .map((definition) => definition.availableTemplates)
      .flat()
      .filter((template) => ids.indexOf(template.id) > -1)
      .map((template) => template.parameters.map((parameter) => parameter.id))
      .flat()
      .filter(uniqueArrayValues);

    if (uniqueButtonIds.length > 0) {
      await this.deleteButtons(uniqueButtonIds);
    }

    if (uniqueParameterIds.length > 0) {
      await this.deleteParameters(uniqueParameterIds);
    }

    await this.deleteVariations(uniqueVariationIds);
    await agent.WhatsAppTemplate.deleteTemplates(ids);

    runInAction(() => {
      this.definitions.map((definition) => {
        definition.availableTemplates = definition.availableTemplates.filter(
          (template) => ids.indexOf(template.id) === -1
        );
        return definition;
      });
    });
  }

  async deleteTemplate(id) {
    this.isLoading = true;

    const variationsToDelete =
      this.selectedDefinition.availableTemplates.filter(
        (template) => template.id === id
      )[0].variations;
    const variationIdsToDelete = [];
    const buttonIdsToDelete = [];
    const parameterIdsToDelete = [];

    variationsToDelete.forEach((variation) => {
      variationIdsToDelete.push(variation.id);
      if (variation.buttons) {
        variation.buttons.forEach((button) => {
          buttonIdsToDelete.push(button.id);
        });
      }
      if (variation.parameters) {
        variation.parameters.forEach((parameter) => {
          parameterIdsToDelete.push(parameter.id);
        });
      }
    });
    if (buttonIdsToDelete.length > 0) {
      await this.deleteButtons(buttonIdsToDelete);
    }

    if (parameterIdsToDelete.length > 0) {
      await this.deleteParameters(parameterIdsToDelete);
    }

    await this.deleteVariations(variationIdsToDelete);
    await agent.WhatsAppTemplate.delete(id);

    runInAction(() => {
      this.selectedDefinition.availableTemplates =
        this.selectedDefinition.availableTemplates.filter(
          (template) => template.id !== id
        );
      this.isLoading = false;
    });
  }

  async deleteVariations(ids) {
    await agent.WhatsAppVariation.deleteVariations(ids);
    runInAction(() => {
      this.selectedDefinition.availableTemplates.map((template) => {
        template.variations = template.variations.filter(
          (variation) => ids.indexOf(variation.id) === -1
        );
        return template;
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteButtons(ids) {
    await agent.WhatsAppButton.deleteButtons(ids);
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteParameters(ids) {
    await agent.WhatsAppParameter.deleteParameters(ids);
  }
}

decorate(WhatsAppDefinitions, {
  definitions: observable,
  isLoading: observable,
  selectedDefinition: observable,
  definitionTemplates: observable,
  sync: action,
  fetchTemplates: action,
  cloneWhatsAppDefinition: action,
  clearSelectedDefinition: action,
  setSelectedDefinition: action,
  fetch: action,
  fetchActive: action,
  fetchOne: action,
  refreshOne: action,
  create: action,
  update: action,
  updateTemplate: action,
  updateActiveTemplate: action,
  delete: action,
  deleteTemplates: action,
  deleteTemplate: action,
  deleteVariations: action,
  deleteButtons: action,
});

export default WhatsAppDefinitions;
