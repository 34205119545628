import requests from "../requests";
import _ from "lodash";
import {REACT_APP_SETUP_WHATSAPP_SECRET} from "../../constants";

const getAllChannels = `
  query AllWhatsappChannels {
    allWhatsappChannels {
      id
      name
      channelId
      whatsappNumber
      createdAt
    }
  }
`;

const getAllAccounts = `
query GetAllAccounts {
  allWhatsappAccounts {
    accounts
    suggestedAccount
  }
}
`;

const setupWhatsApp = `
mutation SetupWhatsApp($accountName: String!, $secret:String!) {
  setupWhatsapp(accountName: $accountName, secret:$secret) {
    ok
    errorMessage
  }
}
`;

const whatsAppProfileInformation = `
query WhatsAppProfileInformation {
  whatsappProfileInformation {
    profileText
    description
    websites
    profilePicture
    companyName
    address
  }
}
`;

const updateWhatsAppProfileInformation = `
mutation UpdateWhatsAppProfileInformation($profileText: String, $description: String, $websites: [String], $profilePicture: String, $companyName: String, $address: String) {
  updateWhatsappProfileInformation(profileText: $profileText, description: $description, websites: $websites, profilePicture: $profilePicture, companyName: $companyName, address: $address) {
    ok
    errorMessage
  }
}
`;

const WhatsApp = {
  allChannels: async () => {
    const res = await requests.gql.chatserver.query(getAllChannels);
    return _.get(res, 'data.allWhatsappChannels');
  },
  allAccounts: async () => {
    const res = await requests.gql.chatserver.query(getAllAccounts);
    return  _.get(res, 'data.allWhatsappAccounts');
  },
  setup: async (accountName, secret=REACT_APP_SETUP_WHATSAPP_SECRET) => {
    const res = await requests.gql.chatserver.mutate(setupWhatsApp, {
      accountName,
      secret
    });
    return _.get(res, 'data.setupWhatsapp');
  },
  fetchProfile: async () => {
    const res = await requests.gql.chatserver.query(whatsAppProfileInformation);
    return _.get(res, 'data.whatsappProfileInformation');
  },
  update: async (profileText, description, websites, profilePicture, companyName, address) => {
    const res = await requests.gql.chatserver.mutate(updateWhatsAppProfileInformation, {
      profileText,
      description,
      websites,
      profilePicture,
      companyName,
      address,
    });
    return _.get(res, 'data.updateWhatsappProfileInformation');
  }
};

export default WhatsApp;
