import { action, decorate, observable, runInAction } from 'mobx';
import BaseStore from './BaseStore';
import agent from 'agent';
import Team from './TeamsStore';

class Agent extends BaseStore {
  id;
  devices;
  name;
  email;
  isActive;
  phone;
  teams;
  isAdmin;
  teamIds;

  constructor(args) {
    super(args);
    this.setup(args);
  }

  setup = (args) => {
    if (args) {
      runInAction(() => {
        this.id = args.id;
        this.devices = args.devices;
        this.name = args.name;
        this.email = args.email;
        this.phone = args.phone;
        this.isActive = args.isActive;
        this.teams = args.teams ? args.teams.map((team) => new Team(team)) : [];
        this.isAdmin = args.isAdmin;
      });
    }
  };

  async update() {
    if (!this.id) return;
    const newAgent = {
      id: this.id,
      teams: this.teamIds,
      isAdmin: this.isAdmin,
      devices: this?.devices?.map((device) => device.id),
      phone: this.phone,
      name: this.name,
      email: this.email,
      isActive: this.isActive,
    };
    const response = await agent.Agents.update(newAgent);

    return response.data.updateAgent.ok;
  }
}

decorate(Agent, {
  id: observable,
  devices: observable,
  name: observable,
  email: observable,
  phone: observable,
  isActive: observable,
  setup: action,
  teams: observable,
  isAdmin: observable,
  teamIds: observable,
  update: action,
});

export default Agent;
