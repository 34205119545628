import requests from './requests';

const syncTemplateVariables = `
mutation {
  syncAtsField {
    ok
    errorMessage
  }
}
`;

const getAtsFieldOptions = `
query GetAtsFieldOptions( $atsFieldMappingId: ID) {
    atsFieldOptions(atsFieldMappingId:$atsFieldMappingId) {
      humanReadableName
      value
    }
  }
  `;

const AtsFieldMappingAgent = {
  syncTemplateVariables: () =>
    requests.gql.chatserver.mutate(syncTemplateVariables),
  getAtsFieldOptions: async (atsFieldMappingId) =>
    requests.gql.chatserver.mutate(getAtsFieldOptions, { atsFieldMappingId }),
};

export default AtsFieldMappingAgent;
