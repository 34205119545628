import superagent from 'superagent';
import { commonStore, userStore } from '../stores';

const API_ROOT = process.env.REACT_APP_API_HOST_NAME;
const CHATBOT_MANAGEMENT_ROOT =
  process.env.REACT_APP_CHATBOT_MANAGEMENT_HOST_NAME;

const responseBody = (res) => {
  if (res.type === 'text/csv') {
    return res.text;
  }
  return res.body;
};

const handleError = (err) => {
  return err;
};

const tokenPlugin = (token) => (req) =>
  req.set('Authorization', `Token ${token}`);

const jwtPlugin =
  (authorization = null) =>
    (req) => {
      if (authorization) {
        return req.set('Authorization', authorization);
      }

      return req;
    };

const requests = {
  del: (url, body) =>
    superagent
      .del(`${API_ROOT}${url}`, body)
      .withCredentials()
      .then(responseBody)
      .catch(handleError),
  get: (url, params = {}) =>
    superagent
      .get(`${API_ROOT}${url}`, params)
      .withCredentials()
      .then(responseBody)
      .catch(handleError),
  manualGet: (url, params = {}) =>
    superagent
      .get(`${url}`, params)
      .withCredentials()
      .then(responseBody)
      .catch(handleError),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .withCredentials()
      .then(responseBody)
      .catch(handleError),
  post: (url, body, authorization = null) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .withCredentials()
      .use(jwtPlugin(authorization))
      .then(responseBody)
      .catch(handleError),
  plainPost: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .withCredentials()
      .then(responseBody)
      .catch(handleError),
  manualPost: (url, body) =>
    superagent
      .withCredentials()
      .post(url, body)
      .then(responseBody)
      .catch(handleError),
  gql: {
    router: {
      query: (query, variables) =>
        superagent
          .get(`${API_ROOT}/graphql`)
          .withCredentials()
          .query({ query })
          .query({ variables: JSON.stringify(variables) })
          .then(responseBody)
          .catch(handleError),
      mutate: (query, variables) =>
        superagent
          .post(`${API_ROOT}/graphql`)
          .withCredentials()
          .query({ query })
          .send({
            query,
            variables: JSON.stringify(variables),
          })
          .then(responseBody)
          .catch(handleError),
    },
    chatserver: {
      query: (
        query,
        variables,
        graphQlServer = `https://${commonStore.selectedChatbot.serverAddress}/graphql`
      ) =>
        superagent
          .post(`${graphQlServer}`)
          .withCredentials()
          .send({
            query,
            variables: JSON.stringify(variables),
          })
          .then(responseBody)
          .catch(handleError),
      mutate: (
        query,
        variables,
        graphQlServer = `https://${commonStore.selectedChatbot.serverAddress}/graphql`
      ) =>
        superagent
          .post(`${graphQlServer}`)
          .withCredentials()
          .send({
            query,
            variables: JSON.stringify(variables),
          })
          .then(responseBody)
          .catch(handleError),
    },
  },
  chatbotManagement: {
    post: (url, body, token = '') =>
      superagent
        .post(`${CHATBOT_MANAGEMENT_ROOT}${url}`, body)
        .use(tokenPlugin(token))
        .then(responseBody)
        .catch(handleError),
  },
};

export default requests;
