import { TEMPLATE_CHAT_SERVER_LINK } from '../constants';
import { optionFragment } from './fragments/builder';
import requests from './requests';

const updateActionOrder = `
  mutation UpdateActionOrdering($optionId: ID, $actionIDs: [ID]) {
    updateActionOrdering(optionId: $optionId, actionIDs: $actionIDs) {
      ok
    }
  }
`;

const createStandardAction = `
mutation AddStandardAction($optionId: ID!) {
  standardActionCreate(optionId: $optionId) {
    ok
    action {
      ...action
    }
  }
}

fragment action on ActionNode {
  id
  editable
  standardAction {
    id
    content: message
    messageClass {
      ...messageClass
    }
  }
}

fragment messageClass on MessageNode {
  id
  variations {
    id
    tags {
      id
      color
      content
      kind
    }
    message {
      id
      textMessage {
        id
        content
      }
    }
  }
}
`;

const updateNextStateMutation = `
  mutation UpdateNextStateInOption($optionId: ID, $stateId: ID) {
    updateNextState(optionId: $optionId, stateId: $stateId) {
      ok
    }
  }
`;

const updateNextStateMachineMutation = `
  mutation UpdateNextStateMachineInOption($optionId: ID, $stateMachineId: ID) {
    updateNextStateMachine(optionId: $optionId, stateMachineId: $stateMachineId) {
      ok
    }
  }
`;

const createOptionMutation = `
  mutation CreateOption($data: CreateOptionInput!) {
    optionCreate(newOption: $data) {
      option {
        ...option
      }
      ok
    }
  }
  ${optionFragment}
`;

const updateOptionMutation = `
  mutation UpdateOption($data: OptionUpdateGenericType!) {
    optionUpdate(newOption: $data) {
      option {
        id
        name
        cancelFollowUp
        symbol
        type: symbolType
        customData
        routingInstruction
        nextStateMachine {
          id
        }
        processors {
          id
          icon
          priority
          category
          description
          humanReadableName
          name
          type
          processorType
          isImportantGoal
          googleAnalyticsEvent {
            id
            eventLabel
            eventAction
            systemName
          }
          processorDependencies {
            customProcessor {
              modelName
              id
            }
          }
          finalGoalCampaigns {
            id
            name
          }
        }
        smartInboxLabels {
          id
          color
          name
        }
        removeSmartInboxLabels {
          id
          name
          color
        }
      }
      ok
    }
  }
`;

const deleteOptionMutation = `
  mutation DeleteOption($id: ID!) {
    optionDelete(id: $id) {
      option {
        id
        name
        symbol
        type: symbolType
      }
      ok
    }
  }
`;

const getDivergentOptionsTemplate = `
{
    allDivergentOptionTemplates {
    ...option    
  }
}
  ${optionFragment}
`;

const copyDivergentOptionsTemplate = `
mutation CopyDivergentOption($divergentOptionId: ID!, $stateId: ID!) {
  copyDivergentOption(divergentOptionId: $divergentOptionId, stateId: $stateId) {
    ok  
    errorMessage
    divergentOption {
      ...option
    }
  }
}
  ${optionFragment}
`;

const Option = {
  create: (data) => requests.gql.chatserver.mutate(createOptionMutation, data),
  update: (data) => requests.gql.chatserver.mutate(updateOptionMutation, data),
  delete: (id) => requests.gql.chatserver.mutate(deleteOptionMutation, { id }),
  updateActionOrder: (data) =>
    requests.gql.chatserver.mutate(updateActionOrder, data),
  addStandardAction: (id) =>
    requests.gql.chatserver.mutate(createStandardAction, { optionId: id }),
  updateNextState: (data) =>
    requests.gql.chatserver.mutate(updateNextStateMutation, data),
  updateNextStateMachine: (data) =>
    requests.gql.chatserver.mutate(updateNextStateMachineMutation, data),

  getDivergentOptionsTemplate: async () => {
    const { data } = await requests.gql.chatserver.query(
      getDivergentOptionsTemplate,
      {},
      TEMPLATE_CHAT_SERVER_LINK
    );
    return data;
  },
  copyDivergentOptionsTemplate: async (divergentOptionId, stateId) =>
    requests.gql.chatserver.mutate(copyDivergentOptionsTemplate, {
      divergentOptionId,
      stateId,
    }),
};

export default Option;
