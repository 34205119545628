import BaseStore from 'stores/BaseStore';
import { decorate, observable } from 'mobx';

class ScheduleAvailability extends BaseStore {
  id;
  day;
  startTime = '09:00:00';
  endTime = "17:00:00'";

  constructor(args) {
    super();
    this.id = args?.id;
    this.day = args?.day;
    this.startTime = args?.startTime;
    this.endTime = args?.endTime;
  }
}

decorate(ScheduleAvailability, {
  id: observable,
  day: observable,
  startTime: observable,
  endTime: observable,
});

export default ScheduleAvailability;
