import requests from '../requests';

const deleteConversationQuery = `
  mutation DeleteCampaignConversation($campaignConversationId:ID!) {
  deleteCampaignConversation(campaignConversationId:$campaignConversationId) {
    ok
    errorMessage
  }
}
`;

const testQuery = /* GraphQL */ `
  query GetAllAshlTargetEntities(
    $automationId: String!
    $page: Int!
    $pageSize: Int!
    $fetchOptions: Boolean!
  ) {
    allAshlTargetEntities(
      automationId: $automationId
      page: $page
      pageSize: $pageSize
      fetchOptions: $fetchOptions
    ) {
      targetEntities {
        targetEntityId
        invocationId
        type
        correlations
        displayInfo
        lastActivityAt
        isPartiallyCreated
        actionId
        actionType
        actionStatus
      }
      totalNumberOfResults
      userDataOptions
    }
  }
`;

const allAshlTargetEntityActions = /* GraphQL */ `
  query GetAllAshlTargetEntityActions(
    $automationId: String!
    $targetEntityId: String!
  ) {
    allAshlTargetEntityActions(
      automationId: $automationId
      targetEntityId: $targetEntityId
    ) {
      actionId
      parentActionId
      actionType
      actionStatus
      isPartiallyCreated
      startedAt
    }
  }
`;

const getAshlActionSteps = /* GraphQL */ `
  query GetAshlActionSteps($actionId: String!, $automationId: String!) {
    ashlActionSteps(actionId: $actionId, automationId: $automationId) {
      actionStepId
      title
      logs
      successful
      processedAt
    }
  }
`;

const CampaignConversationAgent = {
  delete: async (conversationId) => {
    try {
      return await requests.gql.chatserver.mutate(deleteConversationQuery, {
        campaignConversationId: conversationId,
      });
    } catch (error) {
      return error;
    }
  },
  get: async ({
    tenant,
    campaignIds,
    pageNum,
    pageSize,
    startDate,
    endDate,
    phoneNumber,
    statusFilter,
    kpiFilter,
    userDataFilter,
    sortField,
    sortDirection,
    fetchOptions = false,
    exportCSV,
    conversationDataFields,
  }) => {
    const result = await requests.get('/api/v1/campaign/conversations/', {
      campaign_id: campaignIds?.join(', '),
      project_id: tenant,
      page_num: pageNum,
      page_size: pageSize,
      start_date: startDate,
      end_date: endDate,
      phone_number: phoneNumber,
      // We do the checks below to avoid an empty lists from being sent. This would make processing complexer.
      status_filter: statusFilter ? JSON.stringify(statusFilter) : null,
      kpi_filter: kpiFilter ? JSON.stringify(kpiFilter) : null,
      user_data_filter: userDataFilter ? JSON.stringify(userDataFilter) : null,
      sort_field: sortField,
      sort_direction: sortDirection,
      fetch_options: fetchOptions,
      export: exportCSV,
      conversation_data_fields: conversationDataFields,
    });

    return result;
  },
  getAutomatchConversations: async ({ automationId, page, pageSize }) => {
    const { data } = await requests.gql.chatserver.query(testQuery, {
      automationId,
      page,
      pageSize,
      fetchOptions: page === 0,
    });
    return data.allAshlTargetEntities;
  },

  getAllAshlTargetEntityActions: async ({ automationId, targetEntityId }) => {
    const { data } = await requests.gql.chatserver.query(
      allAshlTargetEntityActions,
      {
        automationId,
        targetEntityId,
      }
    );
    return data.allAshlTargetEntityActions;
  },
  getAshlActionSteps: async ({ actionId, automationId }) => {
    const { data } = await requests.gql.chatserver.query(getAshlActionSteps, {
      actionId,
      automationId,
    });
    return data?.ashlActionSteps;
  },
  fetchMessages: async (
    chatbotId,
    conversationStart,
    conversationEnd,
    clientId,
    campaignConversationId
  ) => {
    const result = await requests.get('/api/v1/campaign/conversation/', {
      project_id: chatbotId,
      conversation_start: conversationStart,
      conversation_end: conversationEnd || null,
      client_id: clientId,
      campaign_conversation_id: campaignConversationId,
    });
    return result;
  },
};

export default CampaignConversationAgent;
