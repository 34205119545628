import { action, decorate, observable, runInAction } from 'mobx'
import BaseStore from '../BaseStore'

class Setting extends BaseStore {
  id

  chatbot

  key

  value

  settingType

  adminOnly

  helpText

  valid = true

  section

  setValue(value) {
    this.value = value
  }

  setValidity(valid) {
    this.valid = valid
  }

  constructor(
    id,
    chatbot,
    key,
    value,
    settingType,
    adminOnly,
    helpText,
    section,
  ) {
    super()
    this.id = id
    this.chatbot = chatbot
    this.key = key
    this.value = value
    this.settingType = settingType
    this.adminOnly = adminOnly
    this.helpText = helpText
    this.section = section
  }
}

decorate(Setting, {
  id: observable,
  chatbot: observable,
  key: observable,
  value: observable,
  settingType: observable,
  adminOnly: observable,
  helpText: observable,
  valid: observable,
  section: observable,
  setValue: action,
  setValidity: action,
})

export default Setting
