import { observable, decorate, action } from 'mobx';
import BaseStore from 'stores/BaseStore';
import agent from 'agent';

class FacebookLeadPage extends BaseStore {
  pageId;
  pageName;
  isActive;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup({ pageId, pageName, isActive }) {
    this.pageId = pageId;
    this.pageName = pageName;
    this.isActive = isActive;
  }
}

decorate(FacebookLeadPage, {
  pageId: observable,
  pageName: observable,
  isActive: observable,
  setup: action,
});

export default FacebookLeadPage;
