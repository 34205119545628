import { action, observable } from 'mobx';
import BaseStore from './BaseStore';

export default class Template extends BaseStore {
  @observable id = '';

  @observable name = '';

  @observable description = '';

  @observable icon = '';

  constructor(args) {
    super();
    this.setup(args);
  }

  @action
  setup(args) {
    this.id = args.id;
    this.name = args.name;
    this.description = args.description;
    this.icon = args.icon;
  }
}
