import requests from '../requests'

const mutation = `
mutation UpdateVideoMessage($data: VideoMessageUpdateGenericType!) {
    updateVideoMessageMutation(newVideomessage: $data) {
        videomessage {
            id
            link
        }
      ok
    }
}  
`

const VideoMessage = {
  update: data => requests.gql.chatserver.mutate(mutation, data),
}

export default VideoMessage
