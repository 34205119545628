import requests from './requests';

const getEmailTemplatesQuery = `
  query GetAllEmailTemplates {
    allEmailTemplates {
      toEmail
      fromEmail
      isHtml
      plainText
      isText
      templateKey
      id
      htmlTemplate
      subject
      useNewEditor
    }
  }
`;

const getEmailTemplateQuery = `
query GetEmailTemplate($id: ID!) {
  emailTemplate(id:$id) {
    toEmail
    fromEmail
    isHtml
    plainText
    isText
    templateKey
    id
    htmlTemplate
    subject
    useNewEditor
  }
}
`;

const updateEmailTemplateMutation = `
  mutation UpdateEmailTemplate($newEmailtemplate: EmailTemplateUpdateGenericType!) {
    updateEmailTemplate(newEmailtemplate:$newEmailtemplate) {
      ok
      emailtemplate {
        id
        toEmail
        subject
      }
    }
  }
`;

const EmailTemplate = {
  get: async (id) => {
    return requests.gql.chatserver.query(getEmailTemplateQuery, { id });
  },
  all: async () => {
    return requests.gql.chatserver.query(getEmailTemplatesQuery, null);
  },
  update: async (data) => {
    return requests.gql.chatserver.mutate(
      updateEmailTemplateMutation,
      data
    );
  },
};

export default EmailTemplate;
