import agent from 'agent';
import { toNumber } from 'lodash';
import { action, decorate, observable, runInAction } from 'mobx';
import { userStore } from 'stores';
import CalendarAvailabilities from 'stores/CalendarAvailability/CalendarAvailabilities.store';
import BaseStore from './BaseStore';
import CalendarUser from './CalendarOverview/CalendarUser.store';

class Employee extends BaseStore {
  id;
  name;
  firstName;
  lastName;
  language;
  email;
  chatbots;
  role;
  calendarIsAuthenticated;
  calendarAvailabilities;
  agentId;
  calendarUser = null;
  isLoading;
  isLoadingCalendarUser;

  constructor(params) {
    super();
    this.setup(params);
  }

  setup({
    id,
    language,
    name,
    firstName,
    lastName,
    role,
    email,
    chatbots,
    calendarIsAuthenticated,
    agentId,
    calendarUser,
    isLoading,
  }) {
    this.id = id;
    this.language = language;
    this.name = name;
    this.firstName = firstName;
    this.lastName = lastName;
    this.role = role;
    this.email = email;
    this.chatbots = chatbots;
    this.calendarIsAuthenticated = calendarIsAuthenticated;
    this.calendarAvailabilities = new CalendarAvailabilities();
    this.agentId = agentId;
    if (calendarUser) {
      this.calendarUser = new CalendarUser(calendarUser);
    }
    this.isLoading = !!isLoading;
  }

  async update(propertyToUpdate) {
    const payload = {
      id: this.id,
      name: this.name,
      email: this.email,
      role: this.role,
      ...propertyToUpdate,
    };
    const employee = await agent.Employee.update(payload);

    runInAction(() => {
      this.setup({ ...employee, calendarUser: this.calendarUser });
    });
  }

  // I am not sure whether we are using this function or not!
  // TODO: use the update function and remove this one..
  async changePassword(newPassword) {
    const payload = {
      id: this.id,
      name: this.name,
      email: this.email,
      chatbots: this.chatbots.map((chatbot) => chatbot.id),
      password: newPassword,
      role: this.role,
    };

    const updatedEmployee = await agent.Employee.update(payload);

    this.setup(updatedEmployee);
  }

  async getCalendarUser() {
    runInAction(() => {
      this.isLoadingCalendarUser = true;
    });
    try {
      const calendarUser = await agent.Calendar.getCalendarUser(this.id);
      if (calendarUser?.length) {
        runInAction(() => {
          this.calendarUser = new CalendarUser(calendarUser[0]);
          if (
            toNumber(this.calendarUser.userRouterId) === toNumber(userStore.id)
          ) {
            userStore.calendarSettings.loggedInUserEventPreferences =
              this.calendarUser.eventPreferences;
          }
          this.isLoadingCalendarUser = false;
        });
      }
    } catch (error) {
      console.error('failed to get calendar user', error);
      runInAction(() => {
        this.isLoadingCalendarUser = false;
      });
    }
  }

  async createCalendarUser(calendarType, otherProperties) {
    // first check if there is no calendar user
    runInAction(() => {
      this.isLoadingCalendarUser = true;
    });
    try {
      const newCalendarUser = {
        newCalendaruser: {
          userRouterId: this.id,
          firstName: this.firstName || this.name || this.email,
          lastName: this.lastName || this.name || this.email,
          email: this.email,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          calendar: calendarType,
          ...otherProperties,
        },
      };

      const calendarUser = await agent.Calendar.createCalendarUser(
        newCalendarUser
      );

      if (calendarUser?.id) {
        runInAction(() => {
          this.calendarUser = new CalendarUser(calendarUser);
          if (
            toNumber(this.calendarUser.userRouterId) === toNumber(userStore.id)
          ) {
            userStore.calendarSettings.loggedInUserEventPreferences =
              this.calendarUser.eventPreferences;
          }
          this.isLoadingCalendarUser = false;
        });
      }
    } catch (error) {
      console.error('failed to create calendar user', error);
      runInAction(() => {
        this.isLoadingCalendarUser = false;
      });
    }
  }
}

decorate(Employee, {
  id: observable,
  language: observable,
  name: observable,
  firstName: observable,
  lastName: observable,
  email: observable,
  role: observable,
  calendarAvailabilities: observable,
  calendarIsAuthenticated: observable,
  agentId: observable,
  calendarUser: observable,
  isLoading: observable,
  changePassword: action,
  setup: action,
  update: action,
  getCalendarUser: action,
  createCalendarUser: action,
  isLoadingCalendarUser: observable,
});

export default Employee;
