import {
  observable,
  action,
  toJS,
  runInAction,
  decorate,
  computed,
} from 'mobx';
import { message } from 'antd';
import { isEmpty } from 'lodash';
import settingTypes from 'constants/settingTypes';
import {
  CHATBOT_SETTINGS_ATS,
  CHATBOT_SETTINGS_CHATBOT_ENTITY,
} from 'constants/entities.constants';
import { fakeFormatMessage as formatMessage } from '../../locales/utils';
import ChatbotSetting from '../../agent/chatbotSetting';
import Setting from './SettingStore';
import _ from 'lodash';

class ChatbotSettingStore {
  isLoading = true;
  settings = [];
  atsCredentials = [];

  updatedSettings = [];

  async loadData() {
    if (!isEmpty(this.settings)) return;

    this.isLoading = true;
    const result = await ChatbotSetting.all();
    runInAction(() => {
      this.settings = result.data.allChatbotSettings
        .map((setting) => {
          let { value } = setting;
          if (setting.settingType === settingTypes.BOOLEAN) {
            value = setting.value.toLowerCase() === 'true';
          } else if (setting.settingType === settingTypes.NUMBER) {
            value = parseFloat(setting.value, 10);
          }

          return new Setting(
            setting.id,
            setting.chatbot.id,
            setting.key,
            value,
            setting.settingType,
            setting.adminOnly,
            setting.helpText,
            setting.section
          );
        })
        .sort((a, b) => {
          if (a.key.toLowerCase() < b.key.toLowerCase()) return -1;
          if (a.key.toLowerCase() > b.key.toLowerCase()) return 1;
          return 0;
        });
      this.isLoading = false;
    });
  }

  async getAtsChatbotSettings(section) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      const chatbotSettings = await ChatbotSetting.all(section);
      runInAction(() => {
        if (chatbotSettings?.data?.allChatbotSettings.length > 0) {
          const { allChatbotSettings: atsChatbotSettings } =
            chatbotSettings?.data;

          this.atsCredentials = _.orderBy(
            atsChatbotSettings,
            [(settings) => settings.key.toLowerCase()],
            ['asc']
          ).map((settings) => {
            let { value } = settings;
            if (settings.settingType === settingTypes.BOOLEAN) {
              value = settings.value.toLowerCase() === 'true';
            } else if (settings.settingType === settingTypes.NUMBER) {
              value = parseFloat(settings.value, 10);
            }
            return new Setting(
              settings.id,
              settings.chatbot.id,
              settings.key,
              value,
              settings.settingType,
              settings.adminOnly,
              settings.helpText,
              settings.section
            );
          });
        }
        this.isLoading = false;
      });
    } catch (error) {
      console.error(error);
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  updateSetting(id, value) {
    const settingIndex = this.updatedSettings.findIndex(
      (setting) => setting.id === id
    );
    if (settingIndex !== -1) {
      this.updatedSettings[settingIndex].value = value;
    } else {
      this.updatedSettings.push({ id, value });
    }
  }

  clearSettings() {
    this.updatedSettings = [];
    this.settings = [];
    this.atsCredentials = [];
  }

  /**
   * Save changes to the database
   *
   * @param showNotification  Indicates if we should display a notification after saving the data.
   * @param settingsCategory  Indicates if we should update the chatbot settings or ats settings.
   */
  async save(showNotification = true, settingsCategory) {
    let invalidSettings;

    const targetSettings =
      settingsCategory === CHATBOT_SETTINGS_CHATBOT_ENTITY
        ? this.settings
        : this.atsCredentials;

    invalidSettings = targetSettings.filter(
      (setting) => setting.valid === false
    );

    if (!isEmpty(invalidSettings)) {
      message.error(
        formatMessage({
          id: 'components/sections/SettingSection/ChatbotSettings/index/invalidFields',
        })
      );
    } else {
      if (showNotification) {
        message.loading(
          formatMessage({ id: 'stores/WidgetSettingsStore/savingChanges' })
        );
      }
      await ChatbotSetting.update(toJS(this.updatedSettings))
        .then(() => {
          if (showNotification) {
            message.destroy();
            message.success(
              formatMessage({
                id: 'stores/WidgetSettingsStore/settingsUpdated',
              })
            );
          }
        })
        .catch(() => {
          message.error(
            formatMessage({
              id: 'stores/WidgetSettingsStore/anErrorHasOccurredWhileSavingYourSettings',
            })
          );
        });
    }
  }
  get sections() {
    return [...new Set(this.settings.map((setting) => setting.section))];
  }
}

decorate(ChatbotSettingStore, {
  isLoading: observable,
  settings: observable,
  atsCredentials: observable,
  updatedSettings: observable,
  loadData: action,
  updateSetting: action,
  clearSettings: action,
  save: action,
  getAtsChatbotSettings: action,
  sections: computed,
});

export default ChatbotSettingStore;
