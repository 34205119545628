import { observable, action } from 'mobx'
import _ from 'lodash'

import agent from '../../agent'
import BaseStore from '../BaseStore'

class GeoLocationStore extends BaseStore {
  @observable id = ''
  @observable lat = ''
  @observable lng = ''

  constructor(args) {
    super()
    this.setup(args)
  }

  @action
  setup(args) {
    this.id = args.id
    this.lat = args.lat
    this.lng = args.lng
  }

  @action
  async update(data) {
    const res = await agent.GeoLocationMessage.update(data)
    this.setup(res.data.updateGeoLocationMessageMutation.geolocationmessage)
    const ok = _.get(res, 'data.updateGeoLocationMessageMutation.ok')
    if (!ok) throw Error('Geo location message not updated')
  }
}

export default GeoLocationStore
