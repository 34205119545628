import {decorate, observable, action, runInAction} from 'mobx'
import BaseStore from '../../BaseStore'
import agent from "agent";
import WhatsAppChannel from "./WhatsAppChannel.store";

class WhatsAppChannels extends BaseStore {
  selectedWhatsAppChannel = '';
  selectedChannel = null;
  requiresConfiguration = false;
  whatsAppChannels = [];
  accounts = [];
  setupResult = [];
  suggestedAccount = '';
  isLoading = false;
  isLoadingAccounts = true;

  constructor() {
    super()
  }

  async fetchAll() {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const channels = await agent.WhatsApp.allChannels();
      runInAction(() => {
        this.whatsAppChannels = channels.map(channel => new WhatsAppChannel(channel));
      });
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async fetchAllAccounts() {
    runInAction(() => {
      this.isLoadingAccounts = true
    })

    try {
      const response = await agent.WhatsApp.allAccounts()
      runInAction(() => {
        this.accounts = response.accounts;
        this.suggestedAccount = response.suggestedAccount;
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.isLoadingAccounts = false
      })
    }
  }

  async setup(accountName) {
    try {
      const response = await agent.WhatsApp.setup(accountName);
      runInAction(() => {
        this.whatsAppChannels.push(new WhatsAppChannel(response.channel));
        this.setupResult = JSON.parse(response.result);
      })
    } catch (error) {
      console.log(error)
    } finally {
    }
  }
}

decorate(WhatsAppChannels, {
  selectedWhatsAppChannel: observable,
  selectedChannel: observable,
  requiresConfiguration: observable,
  whatsAppChannels: observable,
  isLoading: observable,
  accounts: observable,
  suggestedAccount: observable,
  isLoadingAccounts: observable,
  fetchAll: action,
  fetchAllAccounts: action,
  setup: action
})

export default WhatsAppChannels
