import { decorate, observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import agent from 'agent'
import BaseStore from './BaseStore'
import Entity from './Entity.store'

class Entities extends BaseStore {
  entities = []
  entity
  modelId
  isloading

  selectedEntityId

  async fetch() {
    this.isLoading = true
    const models = await agent.Entity.modelsList()
    if (models.length === 0) {
      throw new Error('No default model to get entities')
    }
    const model = models[0]
    runInAction(() => {
      this.modelId = model.id
      const newEntities = model.entities.map(entity => new Entity(entity))
      this.entities = newEntities
      this.isLoading = false
    })
  }

  async fetchWithValues() {
    this.isLoading = true
    const models = await agent.Entity.modelsListWithValues()
    if (models.length === 0) {
      throw new Error('No default model to get entities')
    }
    const model = models[0]
    runInAction(() => {
      this.modelId = model.id
      const newEntities = model.entities.map(entity => new Entity(entity))
      this.entities = newEntities
      this.isLoading = false
    })
  }

  async fetchOne(id) {
    this.isLoading = true
    const entity = await Entity.get(id)
    runInAction(() => {
      this.entity = entity
      this.isLoading = false
    })
  }

  async addListEntity(name) {
    const entity = await Entity.createListEntity(name)
    runInAction(() => {
      this.entities.unshift(entity)
    })
  }

  async deleteEntity(id) {
    const entity = _.find(this.entities, { id })
    const entityId = entity.id
    const listEntityId = entity.listEntity.id
    _.remove(this.entities, { id })
    await Entity.delete(entityId, listEntityId)
  }
}

decorate(Entities, {
  entities: observable,
  entity: observable,
  modelId: observable,
  isLoading: observable,
  selectedEntityId: observable,
  fetch: action,
  fetchWithValues: action,
  fetchOne: action,
  deleteEntity: action,
})

export default Entities
