const scheduleAvailabilityFragment = `
  fragment scheduleAvailabilityFragment on ScheduleAvailabilityNode {
    id
    day
    startTime
    endTime
  }
`;

const scheduleAvailabilitySchemaFragment = `
 fragment scheduleAvailabilitySchemaFragment on ScheduleAvailabilitySchemaNode {
    id
    name
    timeZone
    eventpreferencesSet {
        id
        enabled
        eventConfigurations {
          id
          name
          location
        }
    }
    scheduleAvailabilities {
      ...scheduleAvailabilityFragment
    }
  }
  ${scheduleAvailabilityFragment}
`;

const calendarUserFragment = `
fragment calendarUserFragment on CalendarUserNode {
  id
  userRouterId
  email
  firstName
  lastName
  isCalendarAuthenticated
  blockedStatuses
  excludedDays
  calendar
  timeZone
  defaultAvailabilitySchema {
    ...scheduleAvailabilitySchemaFragment
  }
  availabilitySchemas {
    ...scheduleAvailabilitySchemaFragment
  }
  teams {
    id
    name
  }
  eventPreferences {
    id
    enabled
    scheduleAvailabilitySchema {
     ...scheduleAvailabilitySchemaFragment
    }
    eventConfigurations {
      id
      name
      location
    }
  }
  spokenLanguages
}
  ${scheduleAvailabilitySchemaFragment}
`;

const eventPreferenceFragment = `
fragment eventPreferenceFragment on EventPreferenceNode {
  id
  dailyLimit
  timeBeforeEvent
  timeAfterEvent
  planRange
  planOffsetDays
  sameDayOffsetHours
  enabled
   eventConfigurations {
     id
     name
      location
     }
  scheduleAvailabilitySchema {
    id
    name
    timeZone
    eventpreferencesSet {
        id
        enabled
        eventConfigurations {
          id
          name
          location
        }
    }
    scheduleAvailabilities {
      ...scheduleAvailabilityFragment
    }
  }
  scheduleAvailabilities {
    ...scheduleAvailabilityFragment
  }
}
  ${scheduleAvailabilityFragment}
`;

const eventConfigurationFragment = `
  fragment eventConfigurationFragment on EventConfigurationNode {
    id
    name
    defaultEventPreference {
      ...eventPreferenceFragment
    }
    subject {
      ...translationFragment
    }
    description {
      ...translationFragment
    }
    duration
    location
    customLocation {
      ...translationFragment
    }
    scalarNameSelector {
      id
    }
    selectorValues
    color
    defaultAttendees
  }
  ${eventPreferenceFragment}
`;

export {
  calendarUserFragment,
  eventConfigurationFragment,
  eventPreferenceFragment,
  scheduleAvailabilityFragment,
  scheduleAvailabilitySchemaFragment,
};
