import _ from 'lodash';
import requests from 'agent/requests';

const tagMutationFragment = `
fragment tagMutationFragment on TagMutation {
  tag {
    id
    kind
    content
    color
  }
  ok
  errors {
    field
    messages
  }
}
`;

const createMutation = `
mutation CreateTag($newTag: TagCreateGenericType!) {
  createTag(newTag: $newTag) {
    ...tagMutationFragment
  }
}
${tagMutationFragment}
`;

const updateMutation = `
mutation UpdateTag($newTag: TagUpdateGenericType!) {
  updateTag(newTag: $newTag) {
    ...tagMutationFragment
  }
}
${tagMutationFragment}
`;

const deleteMutation = `
mutation DeleteTag($id: ID!) {
  deleteTag(id: $id) {
  	...tagMutationFragment
  }
}
${tagMutationFragment}
`;

const Tag = {
  create: async (newTag) => {
    const res = await requests.gql.chatserver.mutate(createMutation, {
      newTag,
    });
    return _.get(res, 'data.createTag');
  },
  update: async (newTag) => {
    const res = await requests.gql.chatserver.mutate(updateMutation, {
      newTag,
    });
    return _.get(res, 'data.updateTag');
  },
  delete: async (id) => {
    const res = await requests.gql.chatserver.mutate(deleteMutation, {
      id,
    });
    return _.get(res, 'data.deleteTag');
  },
};

export default Tag;
